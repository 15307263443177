import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'
import {
  ExamTypeDisplay,
  PublishedUrlInput,
  HideScoresCheckbox,
  DeleteAnswersButton,
  wizardFields,
  ReviewButtonsCheckbox,
  ReviewButtonsCheckboxVote,
  YletunnusCheckbox,
  ExamSeriesSelect,
  ExamSeriesCheckbox,
  EpisodeCodeInput,
  SeriesCodeInput,
  FingerprintNameInput,
  EnableShareCheckbox,
  StartTimeInput,
  HideStatisticsCheckbox,
  DisableSavingCheckbox,
  RevealTimeInput,
  EndTimeInput,
  DangerZone,
} from '../../misc/Settings/Settings'
import {
  TV_EPISODE,
  ARCHTYPE_TEST,
  POLL,
  OTHER_EXAM_TYPE,
  VOTE,
  REVIEW_ONLY_EXAM_TYPE,
} from '../../../constants/definitions/exams'
import Tags from '../../elements/Tags/Tags'
import { getSeriesData } from '../../../redux/modules/series/selectors'
import { getNode } from '../../../redux/modules/nodes/selectors'
import './settingsform.scss'
import * as PropTypes from '../../../constants/definitions/propTypes'
import { getIsExamOrSeriesLoading } from '../../../redux/modules/loadState/selectors'
import { Spinner } from '../../elements/Loader/Loader'
import PrimaryHeader from '../../elements/Typography/PrimaryHeader.tsx'
import ConfirmDialog from '../../../confirmDialog/ConfirmDialog.tsx'
import { fetchSeriesList } from '../../../redux/middleware/seriesService/seriesService'

const getCommonFields = (props, seriesList) => {
  const { exam } = props
  return [
    ...wizardFields[exam.exam_type](props),
    <ExamSeriesCheckbox {...props} key="exam-series-checkbox" />,
    <ExamSeriesSelect {...props} key="exam-series-select" />,
    seriesList,
  ]
}

const getSettings = (props, seriesList) => {
  const { exam } = props
  switch (exam.exam_type) {
    case TV_EPISODE:
      return [
        ...getCommonFields(props, seriesList),
        <SeriesCodeInput {...props} examKey="seriesCode" key="series-code-input" />,
        <EpisodeCodeInput {...props} examKey="episodeCode" key="episode-code-input" />,
        <FingerprintNameInput {...props} examKey="fingerprintName" key="fingerprint-name-input" />,
        <StartTimeInput {...props} key="start-time-input" />,
        <EndTimeInput {...props} key="end-time-input" />,
        <RevealTimeInput {...props} key="reveal-time-input" />,
        <PublishedUrlInput {...props} examKey="publishedUrl" key="published-url-input" />,
        <DangerZone key="danger-zone">
          <DeleteAnswersButton {...props} />
        </DangerZone>,
      ]
    case ARCHTYPE_TEST:
      return [
        ...getCommonFields(props, seriesList),
        <EnableShareCheckbox {...props} examKey="enableShare" key="exam-enable-share-checkbox" />,
        <PublishedUrlInput {...props} examKey="publishedUrl" key="published-url-input" />,
      ]
    case POLL:
      return [
        ...getCommonFields(props, seriesList),
        <PublishedUrlInput {...props} examKey="publishedUrl" key="published-url-input" />,
      ]
    case OTHER_EXAM_TYPE:
      return [
        ...getCommonFields(props, seriesList),
        <ReviewButtonsCheckbox {...props} examKey="hideReviewButtons" key="review-assignment" />,
        <DisableSavingCheckbox {...props} examKey="disable_saving" key="disable-saving-checkbox" />,
        <HideStatisticsCheckbox {...props} examKey="hide_statistics" key="show-statistics" />,
        <YletunnusCheckbox {...props} examKey="forceYletunnus" key="yletunnus-checkbox" />,
        <EnableShareCheckbox {...props} examKey="enableShare" key="exam-enable-share-checkbox" />,
        <HideScoresCheckbox {...props} examKey="hideScores" key="hideScores-checkbox" />,
        <PublishedUrlInput {...props} examKey="publishedUrl" key="published-url-input" />,
        <DangerZone key="danger-zone">
          <DeleteAnswersButton {...props} />
        </DangerZone>,
      ]
    case VOTE:
      return [
        ...getCommonFields(props, seriesList),
        <HideStatisticsCheckbox {...props} examKey="hide_statistics" key="show-statistics" />,
        <ReviewButtonsCheckboxVote
          {...props}
          examKey="hideReviewButtons"
          key="review-assignment"
        />,
        <EnableShareCheckbox {...props} examKey="enableShare" key="exam-enable-share-checkbox" />,
        <PublishedUrlInput {...props} examKey="publishedUrl" key="published-url-input" />,
        <DangerZone key="danger-zone">
          <DeleteAnswersButton {...props} />
        </DangerZone>,
      ]
    case REVIEW_ONLY_EXAM_TYPE:
      return [
        ...getCommonFields(props, seriesList),
        <ReviewButtonsCheckbox {...props} examKey="hideReviewButtons" key="review-assignment" />,
        <DisableSavingCheckbox {...props} examKey="disable_saving" key="disable-saving-checkbox" />,
        <YletunnusCheckbox {...props} examKey="forceYletunnus" key="yletunnus-checkbox" />,
        <EnableShareCheckbox {...props} examKey="enableShare" key="exam-enable-share-checkbox" />,
        <PublishedUrlInput {...props} examKey="publishedUrl" key="published-url-input" />,
        <DangerZone key="danger-zone">
          <DeleteAnswersButton {...props} />
        </DangerZone>,
      ]
    default:
      return [
        ...getCommonFields(props, seriesList),
        <ReviewButtonsCheckbox {...props} examKey="hideReviewButtons" key="review-assignment" />,
        <DisableSavingCheckbox {...props} examKey="disable_saving" key="disable-saving-checkbox" />,
        <HideStatisticsCheckbox {...props} examKey="hide_statistics" key="show-statistics" />,
        <YletunnusCheckbox {...props} examKey="forceYletunnus" key="yletunnus-checkbox" />,
        <EnableShareCheckbox {...props} examKey="enableShare" key="exam-enable-share-checkbox" />,
        <PublishedUrlInput {...props} examKey="publishedUrl" key="published-url-input" />,
        <DangerZone key="danger-zone">
          <DeleteAnswersButton {...props} />
        </DangerZone>,
      ]
  }
}
const SettingsForm = props => {
  const { exam, isLoading, series } = props

  // If user refreshes the Settings tab, the series endpoint must be refetched.
  useEffect(() => {
    if (series && series.length === 0) {
      props.fetchSeriesList()
    }
  }, [])

  if (!exam.exam_type) {
    return null
  }

  if (isLoading) {
    return (
      <div className="yo-settingsform--loading">
        <Spinner />
      </div>
    )
  }

  const tags = exam.series_uuids || []
  const seriesList = <Tags key="exam-series-tags" seriesAsTags tags={tags} />
  const settings = getSettings(props, seriesList)

  return (
    <div className="yo-settingsform">
      <PrimaryHeader>Settings</PrimaryHeader>
      <ExamTypeDisplay exam={props.exam} key="exam-type-select" />
      <TransitionGroup>{settings}</TransitionGroup>
      <ConfirmDialog />
    </div>
  )
}

SettingsForm.propTypes = {
  exam: PropTypes.exam.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSeriesList: () => dispatch(fetchSeriesList()),
    dispatch,
  }
}

const mapStateToProps = state => ({
  exam: state.exam,
  isLoading: getIsExamOrSeriesLoading(state),
  series: getSeriesData(state),
  node: getNode(state, state.exam.uuid),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsForm))
