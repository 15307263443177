import React from 'react'
import { uniq } from 'ramda'
import moment from 'moment'
import { updateExam, updateExamType } from '../../../redux/modules/exam/exam'
import { generateTitle } from './settingFunctions'
import { updateNodeContent } from '../../../redux/modules/node/actions.ts'
import ConfirmDialogManager from '../../../confirmDialog/ConfirmDialogManager.ts'
import { deleteExamAnswers } from '../../../redux/middleware/examService/examService'

const withSettingModifier = WrappedComponent => {
  class WithSettingModifier extends React.Component {
    onChangeCheckbox = e => {
      const { dispatch, examKey } = this.props
      dispatch(updateExam({ [examKey]: e.target.checked === true }))
    }

    onChangeExamValue = e => {
      const { dispatch, examKey, exam } = this.props
      const value = e.target.value.replace(/ /g, '')
      dispatch(updateExam({ [examKey]: value }))
      const title = generateTitle({ ...exam, [examKey]: value })
      this.updateTitle(title)
    }

    onChangeExamType = e => {
      const { dispatch } = this.props
      dispatch(updateExamType(e.target.value))
    }

    onChangePublished = () => {
      const { dispatch, exam } = this.props
      dispatch(updateExam({ published: true, publishedAt: exam.publishedAt || moment() }))
    }

    onChangeSubject = e => {
      const { dispatch, exam } = this.props
      dispatch(updateExam({ subject: e.target.value, category: '' }))
      const title = generateTitle({ ...exam, subject: e.target.value })
      this.updateTitle(title)
    }

    onChangeExamSeries = e => {
      const { dispatch, exam } = this.props
      const uuids = exam.series_uuids ? exam.series_uuids : []
      const seriesUuids = [...uuids, e.target.value]
      dispatch(updateExam({ series_uuids: uniq(seriesUuids) }))
    }

    onChangeShowExamSeries = e => {
      const { dispatch } = this.props
      dispatch(updateExam({ showExamSeries: e.target.checked === true }))
      if (e.target.checked === false) {
        dispatch(updateExam({ series_uuids: [] }))
      }
    }

    onChangeTime = ({ value, key }) => {
      const { dispatch } = this.props
      dispatch(updateExam({ [key]: moment(value).isValid() ? moment(value).format() : undefined }))
    }

    onDeleteAnswers = () => {
      const { exam, dispatch } = this.props
      ConfirmDialogManager.show(
        'This operation will remove all user answers from the database from this exam. Are you sure?',
      ).then(accepted => {
        if (accepted && exam.uuid) {
          dispatch(deleteExamAnswers(exam.uuid))
        }
      })
    }

    updateTitle = title => {
      const { dispatch, node } = this.props
      if (title) {
        dispatch(updateNodeContent(node.id, { title }))
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onChangeCheckbox={this.onChangeCheckbox}
          onChangeExamSeries={this.onChangeExamSeries}
          onChangeExamType={this.onChangeExamType}
          onChangeExamValue={this.onChangeExamValue}
          onChangePublished={this.onChangePublished}
          onChangeShowExamSeries={this.onChangeShowExamSeries}
          onChangeSubject={this.onChangeSubject}
          onChangeTime={this.onChangeTime}
          onDeleteAnswers={this.onDeleteAnswers}
        />
      )
    }
  }
  WithSettingModifier.displayName = 'WithSettingModifier'
  return WithSettingModifier
}

export default withSettingModifier
