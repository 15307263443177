import React, { ChangeEventHandler, FC } from 'react'
import { ChoiceBox } from '@yleisradio/yds-components-react'
import { getIcon, MultipleChoiceButtonStatus } from '../MultipleChoiceQuestion/MultipleChoiceOption'

interface Props {
  checked: boolean
  id: string
  label: string
  name: string
  content?: string
  hasIcon?: boolean
  attributes: { [key: string]: string }
  status: MultipleChoiceButtonStatus
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const CheckboxOption: FC<Props> = ({
  id,
  checked,
  label,
  hasIcon,
  name,
  attributes,
  onChange,
  content,
  status,
}) => {
  const icon = getIcon(hasIcon, status)
  return (
    <div data-testid={`checkbox-option-${id}`}>
      <ChoiceBox
        checked={checked}
        content={content}
        icon={icon}
        id={id}
        isDisabled={Boolean(attributes.disabled)}
        label={label}
        name={name}
        onChange={onChange}
        status={status || undefined}
        {...attributes}
      />
    </div>
  )
}

export default CheckboxOption
