import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { updateValue } from '../../../redux/modules/node/actions.ts'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import Fragment from '../../node/Fragment/Fragment'
import MultipleChoiceOption, {
  MultipleChoiceOptionImageContent,
  extractImages,
  getStatus,
  getStatusAriaLabel,
} from '../MultipleChoiceQuestion/MultipleChoiceOption.tsx'
import { optionLayoutTypes } from '../../../constants/definitions/entities/entities.ts'
import { getExamType } from '../../../redux/modules/exam/selectors'
import { REVIEW_ONLY_EXAM_TYPE } from '../../../constants/definitions/exams'
import '../MultipleChoiceQuestion/multiple-choice-question.scss'
import CheckboxOption from './CheckboxOption.tsx'

// Helper functions
const hasMaxAnswers = (options, nodeId) => {
  const maxAnswers = options.filter(x => x.correct === true).length
  const nodeList = document.querySelectorAll(`*[name^="${nodeId}--options"]`)
  const answerCount = Object.values(nodeList).filter(x => x.checked === true).length
  return answerCount > maxAnswers
}

const Option = withTranslation()(props => {
  const { examType, hasCorrectAnswers, node, onChange, option, settings, submitted, value } = props
  const isChecked = Boolean(value && value[option.id]?.trim() === option.text?.trim())
  const isCorrect = option.correct === true
  const isReviewOnlyExam = examType === REVIEW_ONLY_EXAM_TYPE
  const hasIcon = isChecked && submitted && !isReviewOnlyExam && !settings.noVisualization
  const status = settings.noVisualization
    ? null
    : getStatus({
        submitted,
        checked: isChecked,
        correct: isCorrect,
        isReviewOnlyExam,
        hasCorrectAnswers,
      })
  const attributes = {
    'aria-label': getStatusAriaLabel(props.t, status),
    'data-value': option.text,
    'data-option-id': option.id,
    disabled: submitted,
  }
  const text = extractImages(option.text)
  const richText = (
    <>
      <RichTextContent content={text.labelWithoutImages} />
      {status && isCorrect && option.points && (
        <span className="yo-multiple-choice-question__option-points">{`(${option.points} p)`}</span>
      )}
    </>
  )

  if (settings.demandCorrect) {
    return (
      <CheckboxOption
        attributes={attributes}
        checked={isChecked}
        content={text.images ? <RichTextContent content={text.images.join('')} /> : null}
        hasIcon={hasIcon}
        id={`${node.id}-${option.id}`}
        label={richText}
        name={`${node.id}--checkbox`}
        onChange={onChange}
        status={status}
      />
    )
  }
  return (
    <MultipleChoiceOption
      attributes={attributes}
      checked={isChecked}
      content={<MultipleChoiceOptionImageContent images={text.images} />}
      hasIcon={hasIcon}
      label={richText}
      onChange={onChange}
      status={status}
    />
  )
})

// Component
const CustomizedFeedback = props => {
  const { children, content, options, settings = {}, examType } = props
  const hasCorrectAnswers = Boolean(options.find(x => x.correct === true))
  const renderedOptions = options.map(option => {
    return (
      <Option {...props} hasCorrectAnswers={hasCorrectAnswers} key={option.id} option={option} />
    )
  })

  const classes = classnames('yo-multiple-choice-question__options', {
    'yo-multiple-choice-question__options--two-columns':
      settings.optionsLayout === optionLayoutTypes.labels,
  })

  return (
    <Fragment
      displayNumber={content.displayNumber}
      examType={examType}
      text={<RichTextContent content={content.text} />}
    >
      {children}
      <div className={classes}>{renderedOptions}</div>
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => ({
  submitted: ownProps.submitted,
  yleTunnus: state.yleTunnus,
  examType: getExamType(state),
})

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onChange: e => {
      const value = e.target.checked ? e.target.getAttribute('data-value') : undefined
      const optionId = e.target.getAttribute('data-option-id')
      const { demandCorrect } = ownProps.settings

      const evaluateCompleted = state => {
        if (demandCorrect) {
          const correctCount = ownProps.options.filter(o => o.correct).length
          const answersCount = Object.values(state.value).filter(v => v !== undefined).length
          return correctCount === answersCount
        }
        return typeof state.value === 'object'
      }
      // For demand correct answers setting, allow same amount of answers than correct answers
      if (!demandCorrect || (demandCorrect && !hasMaxAnswers(ownProps.options, ownProps.id))) {
        dispatch(updateValue(ownProps.id, { [optionId]: value }, evaluateCompleted, !demandCorrect))
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedFeedback)
