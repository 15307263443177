import uuid from 'uuid'
import { POST, parseJSON } from '../dataService/apiV1'
import { addToast, flipToast } from '../../modules/toasts/toasts'

export const UPLOAD_SHARE_CONFIG = 'api/UPLOAD_SHARE_CONFIG'

export const uploadShareConfig = (uploadData, fileName) => ({
  type: UPLOAD_SHARE_CONFIG,
  uploadData,
  fileName,
})

const shareService = () => next => action => {
  let toastUUID
  next(action)

  switch (action.type) {
    case UPLOAD_SHARE_CONFIG:
      toastUUID = uuid.v4()
      next(addToast('progress', 'Syncing share configuration...', undefined, toastUUID))
      return POST(`/share/upload/${action.fileName}`, JSON.stringify(action.uploadData))
        .then(parseJSON)
        .then(body => {
          if (body) {
            return next(flipToast(toastUUID, 'success', 'Synced successful'))
          }
          throw new Error('error syncing share config')
        })
        .catch(() => {
          next(flipToast(toastUUID, 'error', 'Synced error', 5000))
        })

    default:
      break
  }
  return null
}

export default shareService
