export const ADD_CHILD = 'node/ADD_CHILD'
export const ADD_TAG = 'node/ADD_TAG'
export const CREATE = 'node/CREATE'
export const REMOVE_CHILD = 'node/REMOVE_CHILD'
export const REMOVE_CONTENT = 'node/REMOVE_CONTENT'
export const REMOVE_TAG = 'node/REMOVE_TAG'
export const UPDATE = 'node/UPDATE'
export const UPDATE_CONTENT = 'node/UPDATE_CONTENT'
export const UPDATE_SETTING = 'node/UPDATE_SETTING'
export const REORDER = 'nodes/REORDER'
export const ADD_OPTION = 'node/ADD_OPTION'
export const CLEAR_SCORE = 'node/CLEAR_SCORE'
export const CLEAR_VALUE = 'node/CLEAR_VALUE'
export const DELETE = 'node/DELETE'
export const REMOVE_OPTION = 'node/REMOVE_OPTION'
export const REMOVE_VALUE = 'node/REMOVE_VALUE'
export const SET_SCORE = 'node/SET_SCORE'
export const SET_MAX_SCORE = 'node/SET_MAX_SCORE'
export const SUBMIT = 'node/SUBMIT'
export const UNSUBMIT = 'node/UNSUBMIT'
export const UPDATE_VALUE = 'node/UPDATE_VALUE'
export const SAVE_OPTION_ORDER = 'node/SAVE_OPTION_ORDER'
export const UPDATE_VALUES_ORDER = 'node/UPDATE_VALUES_ORDER'
export const UPDATE_VALUES_ORDER_SCORED = 'node/UPDATE_VALUES_ORDER_SCORED'
export const UPDATE_ROOT_NODETYPE_ID = 'node/UPDATE_ROOT_NODETYPE_ID'
