import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { reject } from 'ramda'
import {
  LISTENING_COMPREHENSION,
  MATRICULATION_EXAM_TYPE,
} from '../../../constants/definitions/exams'
import { nodeTypes, questionTypes } from '../../../constants/definitions/entities/entities.ts'
import Icon from '../../elements/Icon/Icon'
import { getExamRoot } from '../../../redux/modules/nodes/selectors'
import './toc.scss'
import { scrollToView } from '../../../utils/common'

class TableOfContents extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: this.props.expanded,
    }

    this.onClickToggle = this.onClickToggle.bind(this)
  }

  onClickToggle() {
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }

  render() {
    const { exam, nodes, t, examRoot } = this.props

    if (typeof nodes === 'undefined' || exam.exam_type !== MATRICULATION_EXAM_TYPE) {
      return null
    }

    const parentIds = examRoot.childIds
    // We only want to include top-level questions which are not type of TEXT
    const isInvalid = node => !parentIds.includes(node.id) || node.nodeTypeId === nodeTypes.TEXT.id

    // An array of all questions that are of valid nodeType
    const validQs = Object.values(reject(isInvalid, nodes)).sort((a, b) => a.order - b.order)

    // Render all valid questions in the correct fashion
    const tocItems = validQs.map(question => {
      const { id } = question
      const { displayNumber, questionType } = question.content
      const { material, titleEssay } = question.settings

      const materialItem = material ? (
        <span className="yo-toc-item__suffix yo-toc-item__suffix--material">{t('material')}</span>
      ) : null

      const titleEssayItem = titleEssay ? (
        <span className="yo-toc-item__suffix yo-toc-item__suffix--title-essay">
          {t('title-essay')}
        </span>
      ) : null

      const questionTypeName =
        questionType && questionTypes.find(type => type.id === questionType)
          ? questionTypes.find(type => type.id === questionType).name
          : null

      // As requested, don't display questionType on listening comprehension exams
      const questionTypeItem =
        questionTypeName && exam.category !== LISTENING_COMPREHENSION ? (
          <span className="yo-toc-item__suffix yo-toc-item__suffix--questiontype">
            {questionTypeName}
          </span>
        ) : null

      // Scroll into element
      const onClickScroll = id => {
        scrollToView(id)
      }

      return (
        <CSSTransition classNames="yo-toc-items" key={id} timeout={120}>
          <div className="yo-toc-item">
            <span
              className="yo-toc-item__text"
              onClick={() => onClickScroll(id)}
              role="button"
              tabIndex="0"
            >
              {`${t('question')} `}
              {displayNumber}
              {questionTypeItem}
              {(material || titleEssay) && (
                <span className="yo-toc-item__suffix yo-toc-item__suffix--material-essay">
                  {titleEssayItem}
                  {materialItem}
                </span>
              )}
            </span>
          </div>
        </CSSTransition>
      )
    })

    const tocHeader = this.state.expanded
      ? t('hide-table-of-contents')
      : t('show-table-of-contents')

    const toggleIconClass = classnames('yo-toc-header__icon', {
      'yo-toc-header__icon--expanded': this.state.expanded,
    })

    return (
      <div className="yo-toc">
        <span className="yo-toc-header" onClick={this.onClickToggle} role="button" tabIndex="0">
          <span className={toggleIconClass}>
            <Icon icon="maximize" />
          </span>
          <span className="yo-toc-header__text">{tocHeader}</span>
        </span>
        <TransitionGroup className="yo-toc-items">
          {this.state.expanded && tocItems}
        </TransitionGroup>
      </div>
    )
  }
}

TableOfContents.defaultProps = {
  expanded: false,
}

const mapStateToProps = state => ({
  exam: state.exam,
  nodes: state.entities.nodes,
  examRoot: getExamRoot(state),
})

export default withTranslation(['TableOfContents'])(connect(mapStateToProps)(TableOfContents))
