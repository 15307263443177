import React from 'react'
import ImsImage from '../../shortcodes/ImsImage/ImsImage'
import './share-preview.scss'

const SharePreview = ({ title, description, imageId }) => (
  <div className="yo-share-preview__wrapper">
    <h4>Share preview</h4>
    {title && description && imageId ? (
      <div className="yo-share-preview__container">
        {imageId && (
          <div className="yo-share-preview__image">
            <ImsImage id={imageId} imageLink="false" showCopyright="false" />
          </div>
        )}
        <div className="yo-share-preview__title">{title}</div>
        <div className="yo-share-preview__description">{description}</div>
      </div>
    ) : (
      <div className="yo-share-preview__description">
        Share not available. Please fill compulsory fields.
      </div>
    )}
  </div>
)

export default SharePreview
