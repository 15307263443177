import React from 'react'
import defaultProps from './defaultProps'

const User = ({ fill, width, height }) => (
  <svg
    height={height}
    version="1.0"
    viewBox="0 0 30 36"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      fill={fill}
      stroke="none"
      transform="translate(0.000000,36.000000) scale(0.100000,-0.100000)"
    >
      <path
        className="yo-icon-svg-path"
        d="M110 353 c-49 -18 -71 -74 -47 -123 14 -28 14 -30 -3 -30 -34 0 -60
        -47 -60 -109 0 -32 5 -62 12 -69 8 -8 53 -12 135 -12 111 0 123 2 133 19 27
        51 -1 156 -44 167 -21 5 -24 9 -16 24 14 26 12 82 -3 101 -25 33 -69 46 -107
        32z"
      />
    </g>
  </svg>
)

User.defaultProps = defaultProps

export default User
