import React from 'react'
import { withRouter } from 'react-router-dom'
import uuid from 'uuid'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { fetchSeriesList, postSeries } from '../../../redux/middleware/seriesService/seriesService'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import Button from '../../elements/Button/Button.tsx'
import { updateExam } from '../../../redux/modules/exam/exam'
import { fetchExams, newExam } from '../../../redux/modules/exams/exams'
import { toggleEditing, toggleEditingOrder } from '../../../redux/modules/series/series'
import ExamList from '../../layout/ExamList/ExamList'
import { filterExamsBySeriesId } from '../../../redux/modules/filters/exams/bySeriesId'
import { IconButton } from '../../elements/IconButtons/IconButtons'
import SeriesForm from '../SeriesForm/SeriesForm'
import { copyToClipboard } from '../../../utils/common'
import { flipToast } from '../../../redux/modules/toasts/toasts'
import './series-container.scss'
import SeriesExamOrder from '../SeriesExamOrder/SeriesExamOrder'
import { resetAllFilters } from '../../../redux/modules/filters/exams/resetFilters'
import { getExams } from '../../../redux/modules/exams/selectors'
import { getExamFiltersPublishStatus } from '../../../redux/modules/filters/exams/selectors'
import { PUBLISHED } from '../../../redux/modules/filters/exams/byPublishStatus'
import SecondaryHeader from '../../elements/Typography/SecondaryHeader.tsx'

class SeriesContainer extends React.Component {
  componentDidMount() {
    this.props.fetchSeries()
  }

  render() {
    const {
      series,
      history,
      onClickCreate,
      t,
      match,
      toggleEdit,
      toggleEditingOrder,
      isEditing,
      onClickSave,
      isLoading,
      isEditingOrder,
      onEmbedClick,
      exams,
      isPublished,
    } = this.props
    const uuid = match.params.seriesId
    if (!series) {
      return null
    }
    const createOrSaveBtn = isEditing ? (
      <Button
        isDisabled={isLoading}
        onClick={() => {
          onClickSave(series)
          toggleEdit()
        }}
        text={t('save')}
      />
    ) : (
      <>
        {!isEditingOrder && (
          <Button
            attributes={{ 'data-testid': 'button-series__add-exam' }}
            onClick={() => onClickCreate(history, uuid)}
            text={t('add-exam')}
          />
        )}
        {isEditingOrder && <Button onClick={() => onClickSave(series)} text="Save" />}
        {!isEditingOrder && isPublished === PUBLISHED && exams?.length > 0 && (
          <Button onClick={toggleEditingOrder} text="View exam order" variant="secondary" />
        )}
        {isEditingOrder && (
          <Button onClick={toggleEditingOrder} text="Cancel" variant="secondary" />
        )}
      </>
    )
    const editSeries = (
      <IconButton icon="edit" onClick={toggleEdit} title={t('NodeEditable:edit')} />
    )
    const seriesContent = isEditing ? (
      <SeriesForm series={series} />
    ) : (
      <div>
        <SecondaryHeader>{series.name}</SecondaryHeader>
        <div className="yo-series-container__desc">
          <RichTextContent content={series.description} />
        </div>
      </div>
    )

    return (
      <div className="yo-series-container">
        <div>
          <div className="yo-series-container__icon-buttons">
            <IconButton
              height="18"
              icon="export"
              onClick={() => onEmbedClick(`47-${match.params.seriesId}`)}
              title={t('NodeEditable:Copy to clipboard')}
              width="18"
            />
            {!isEditingOrder && editSeries}
          </div>
          {seriesContent}
          <div className="yo-series-container__create">{createOrSaveBtn}</div>
          {isEditingOrder ? <SeriesExamOrder /> : <ExamList fetchExams={fetchExams} />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const uuid = ownProps.match.params.seriesId
  return {
    isEditing: state.series.editing,
    isLoading: state.series.loading,
    isEditingOrder: state.series.editingOrder,
    series: state.series.data.find(x => x.uuid === uuid),
    exams: getExams(state),
    isPublished: getExamFiltersPublishStatus(state),
  }
}

const mapDispatchToProps = dispatch => ({
  fetchSeries: () => {
    dispatch(fetchSeriesList())
  },
  onClickCreate: (history, uuid) => {
    // Create new exam
    dispatch(newExam())
    // ...and attach it to series
    dispatch(updateExam({ series_uuids: [uuid], showExamSeries: true }))
    history.push({ pathname: '/exams/create', state: { addToSeries: true } })
  },
  setExamFilter: id => {
    dispatch(filterExamsBySeriesId(id))
  },
  toggleEdit: () => {
    dispatch(toggleEditing())
  },
  onClickSave: series => {
    dispatch(postSeries(series))
  },
  onEmbedClick: seriesId => {
    const clipboardEl = copyToClipboard(seriesId)
    if (clipboardEl && clipboardEl.value) {
      dispatch(flipToast(uuid.v4(), 'success', 'Series ID copied to clipboard.'))
    }
  },
  toggleEditingOrder: () => {
    dispatch(toggleEditingOrder())
  },
  resetExamFilters: () => dispatch(resetAllFilters()),
})
export default withTranslation(['Series'])(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SeriesContainer)),
)
