import React from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { MultipleChoiceQuestion, CustomizedFeedback } from '../../contentComponents'
import { getOptionIdByNodeId, isInEditor, isInPreview } from '../../../utils/common'
import { nodeTypes } from '../../../constants/definitions/entities/entities.ts'
import PollResults from '../../layout/PollResults/PollResults'

class PollContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldShowResult: false,
    }
  }

  onClickShowPollResults = () => {
    this.setState({ shouldShowResult: true })
  }

  getComponent = () => {
    const { shouldShowResult } = this.state
    const {
      node: { id, nodeTypeId },
      examEndTime,
    } = this.props
    const pollSubmitted = getOptionIdByNodeId(id)
    const isPollEnded = moment().isAfter(moment(examEndTime))
    const isPollMultipleChoice = nodeTypeId === nodeTypes.POLL_MULTIPLE_CHOICE.id
    if (pollSubmitted || isPollEnded || shouldShowResult) {
      return PollResults
    }

    return isPollMultipleChoice ? MultipleChoiceQuestion : CustomizedFeedback
  }

  render() {
    const { t, location } = this.props

    const Component = this.getComponent()
    const isResultButtonVisible =
      !isInEditor(location) && !isInPreview(location) && Component !== PollResults

    return (
      <>
        <Component {...this.props} />
        {isResultButtonVisible && (
          <div
            className="yo-multiple-choice-question__show-poll-results"
            onClick={this.onClickShowPollResults}
            role="button"
            tabIndex={0}
          >
            {t('show-poll-results')}
          </div>
        )}
      </>
    )
  }
}

export default withTranslation(['PollContainer'])(withRouter(PollContainer))
