import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ChevronDown } from '@yleisradio/yds-icons-react'
import { findSpecialQuestionIdsWithSumResults } from '../../../utils/examHelpers'
import {
  fetchFeedbackStatsByExamUuid,
  fetchFeedbackStatSums,
} from '../../../redux/modules/statistics/statistics'
import Input from '../../elements/Input/Input'
import Button from '../../elements/Button/Button'
import './vote-result-settings.scss'

type CountryFilter = 'domestic' | 'foreign' | null

interface Props {
  displayResults: boolean
  nodes: any
  id: string
  fetchResults: (countryFilter: CountryFilter) => void
  fetchResultSums: (ids: string[], countryFilter: CountryFilter) => void
  onSetDisplayResults: (boolean) => void
}

const VoteResultSettings: React.FC<Props> = ({
  fetchResults,
  fetchResultSums,
  nodes,
  displayResults,
  onSetDisplayResults,
}) => {
  const [countryFilter, setCountryFilter] = useState<CountryFilter>(null)
  const [showCountryFilter, setShowCountryFilter] = useState<boolean>(false)

  useEffect(() => {
    if (displayResults) {
      fetchResults(countryFilter)
      const questionIdsWithSumResults = findSpecialQuestionIdsWithSumResults(nodes)
      if (questionIdsWithSumResults.length) {
        fetchResultSums(questionIdsWithSumResults, countryFilter)
      }
    }
  }, [displayResults, countryFilter])

  return (
    <>
      <Input
        checked={displayResults}
        key="display-results"
        label="Show Results"
        onChange={e => onSetDisplayResults(e.target.checked)}
        type="checkbox"
      />
      <Button
        iconAfter={<ChevronDown />}
        onClick={() => setShowCountryFilter(!showCountryFilter)}
        size="xs"
        text="Filter results by country"
        variant="text"
      />
      {showCountryFilter && (
        <div className="yo-vote-results-filter">
          <Input
            checked={countryFilter === null}
            label="All (default)"
            onChange={() => setCountryFilter(null)}
            type="radio"
          />
          <Input
            checked={countryFilter === 'domestic'}
            label="Finland only"
            onChange={() => setCountryFilter('domestic')}
            type="radio"
          />
          <Input
            checked={countryFilter === 'foreign'}
            label="Outside Finland only"
            onChange={() => setCountryFilter('foreign')}
            type="radio"
          />
        </div>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch, { id }) => ({
  fetchResults: (countryFilter: CountryFilter) =>
    dispatch(fetchFeedbackStatsByExamUuid(id, { countryFilter, isInEditor: true })),
  fetchResultSums: (ids: string[], countryFilter: CountryFilter) =>
    dispatch(fetchFeedbackStatSums(ids, { countryFilter, isInEditor: true })),
})

export default connect(null, mapDispatchToProps)(VoteResultSettings)
