/* eslint-disable import/no-extraneous-dependencies */

/* eslint-disable global-require */

/** IE11 polyfills need to be explicitly imported */
require('react-app-polyfill/ie11');
/** Stable polyfills follow browserlist settings in package.json */


require('react-app-polyfill/stable');

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();

  window.Promise = require('promise/lib/es6-extensions.js');
}