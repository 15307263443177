import React, { useEffect, useState } from 'react'
import { ChevronDown } from '@yleisradio/yds-icons-react'
import Button from '../Button/Button'
import Input from '../Input/Input'
import { Spinner } from '../Loader/Loader'
import { GET } from '../../../redux/middleware/dataService/apiV1'
import './option-answers.scss'
import { formatDate } from '../../../utils/common'
import config from '../../../config'

type Answer = {
  yletunnus: string
  nickname: string
  answer_data: { [key in string | number]: string }
  created_at: string
}

type AnswerSum = {
  answer: string
  count: number
}

interface AnswersTableProps {
  answers: Array<AnswerSum | Answer>
  isSumAnswers: boolean
}

interface Props {
  questionUuid: string
  optionId: string
  isSumChecked?: boolean
}

const AnswersTable: React.FC<AnswersTableProps> = ({ answers, isSumAnswers }) => {
  return (
    <table className="yo-answers-table">
      <thead>
        <tr className="yo-answers-table-tr">
          {isSumAnswers ? (
            <>
              <th>Answer</th>
              <th>Count</th>
            </>
          ) : (
            <>
              <th>Nickname</th>
              <th>Answer</th>
              <th>Timestamp</th>
              <th></th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {isSumAnswers
          ? (answers as Array<AnswerSum>).map(({ answer, count }) => (
              <tr className="yo-answers-table-tr" key={answer}>
                <td>{answer}</td>
                <td>{count}</td>
              </tr>
            ))
          : (answers as Array<Answer>).map(({ yletunnus, nickname, answer_data, created_at }) => (
              <tr className="yo-answers-table-tr" key={yletunnus}>
                <td>{nickname}</td>
                <td>{JSON.stringify(answer_data)}</td>
                <td>{formatDate(new Date(created_at))}</td>
                <td>
                  <a
                    className="yo-answers-table-contact-link"
                    href={`${config.contacts_app_url}?surrogate_id=${yletunnus}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Contact
                  </a>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  )
}

const LIMIT = 20

const OptionAnswers: React.FC<Props> = ({ questionUuid, optionId, isSumChecked }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [answers, setAnswers] = useState<Array<AnswerSum | Answer>>([])
  const [isSumAnswers, setIsSumAnswers] = useState(Boolean(isSumChecked))
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    let isSubscribed = true

    const fetchAnswerStats = async () => {
      const data = await GET(
        `/questions/${questionUuid}/options/${optionId}/${
          isSumAnswers ? 'stats' : 'answers'
        }.json?limit=${LIMIT}&offset=${LIMIT * offset}`,
      )
      const fetchedAnswers = await data.json()

      if (isSubscribed) {
        setAnswers(fetchedAnswers.data)
      }
    }

    if (isOpen) {
      setIsLoading(true)
      fetchAnswerStats()
        .catch(err => console.log(err))
        .finally(() => setIsLoading(false))
    }
    return () => {
      isSubscribed = false
    }
  }, [isOpen, isSumAnswers, offset])

  const onToggleOpen = () => {
    setOffset(0)
    setIsLoading(!isOpen)
    setIsOpen(!isOpen)
  }

  const onToggleSumAnswers = () => {
    setOffset(0)
    setAnswers([])
    setIsSumAnswers(!isSumAnswers)
  }

  return (
    <div className="yo-text-results">
      <Button
        iconAfter={<ChevronDown />}
        onClick={onToggleOpen}
        size="xs"
        text="Answers"
        variant="text"
      />
      {isOpen && isLoading && <Spinner />}
      {isOpen && !isLoading && (
        <>
          {offset === 0 && answers.length === 0 ? (
            <p>No answers</p>
          ) : (
            <>
              <Input
                checked={isSumAnswers}
                idSuffix={optionId}
                label="Sum similar answers"
                onChange={onToggleSumAnswers}
                type="checkbox"
              />
              <AnswersTable answers={answers} isSumAnswers={isSumAnswers} />
              <div className="yo-text-results-buttons">
                <Button
                  isDisabled={offset === 0}
                  onClick={() => {
                    setOffset(offset - 1)
                  }}
                  size="xs"
                  text="Prev"
                  variant="text"
                />
                <Button
                  isDisabled={answers.length < LIMIT}
                  onClick={() => {
                    setOffset(offset + 1)
                  }}
                  size="xs"
                  text="Next"
                  variant="text"
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default OptionAnswers
