// This is a custom shortcode type for facebook/lexical library.
// We use this type in editor to render shortcodes.
//
// Check documentation for more info
// https://lexical.dev/docs/concepts/nodes

import { ShortcodeName } from 'constants/definitions/shortcodes'
import { EditorConfig, LexicalNode, NodeKey, SerializedTextNode, Spread, TextNode } from 'lexical'

export type SerializedShortcodeNode = Spread<{ shortcode: ShortcodeName }, SerializedTextNode>

export class ShortcodeNode extends TextNode {
  __shortcode: ShortcodeName

  constructor(text: string, shortcode: ShortcodeName, key?: NodeKey) {
    super(text, key)
    this.__shortcode = shortcode
    // mode = 1 means a token => backspace deletes the whole shortcode and editing is impossible
    this.__mode = 1
  }

  static getType(): string {
    return 'shortcode'
  }

  static clone(node: ShortcodeNode): ShortcodeNode {
    return new ShortcodeNode(node.__text, node.__shortcode, node.__key)
  }

  static importJSON(serializedNode: SerializedShortcodeNode): ShortcodeNode {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return $createShortcodeNode(serializedNode.text, serializedNode.shortcode)
  }

  exportJSON(): SerializedShortcodeNode {
    return {
      ...super.exportJSON(),
      shortcode: this.getShortcode(),
      type: 'shortcode',
    }
  }

  getShortcode() {
    const self = this.getLatest()
    return self.__shortcode
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config)
    element.style.color = 'var(--text-accent)'
    return element
  }

  updateDOM(prevNode: ShortcodeNode, dom: HTMLElement, config: EditorConfig): boolean {
    return super.updateDOM(prevNode, dom, config)
  }
}

export function $createShortcodeNode(text: string, shortcode: ShortcodeName): ShortcodeNode {
  return new ShortcodeNode(text, shortcode)
}

export function $isShortcodeNode(node: LexicalNode): boolean {
  return node instanceof ShortcodeNode
}
