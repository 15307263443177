import React from 'react'
import classNames from 'classnames'

// If null or undefined is passed as value the component will be considered as "uncontrolled"
// component. Using empty string as default for defaultValue to prevent that.
const TextInput = ({
  attributes,
  autofocus,
  defaultValue = '',
  description,
  disabled,
  id,
  label,
  onChange,
  optional,
  required,
  t,
  width,
}) => {
  const inputClassNames = classNames('yo-input__input yo-input__input--text', {
    'yo-input__input--narrow': width === 'narrow',
  })

  return (
    <label className="yo-input__label" htmlFor={id}>
      {label}
      {required && <span className="yo-input__required">*</span>}
      {optional && <span className="yo-input__optional">{t('common:optional')}</span>}
      <input
        autoFocus={autofocus}
        className={inputClassNames}
        disabled={disabled}
        id={id}
        onChange={onChange}
        type="text"
        value={defaultValue}
        {...attributes}
      />
      {description && <div className="yo-input__description">{t(description)}</div>}
    </label>
  )
}

export default TextInput
