import { ChangeEvent } from 'react'
import moment from 'moment'

export interface ValidationRule {
  required: boolean
  message: string
  rule: (value: any) => boolean
}

const validationRules: { [name: string]: ValidationRule } = {
  POINT: {
    required: false,
    rule(e: ChangeEvent<HTMLInputElement>) {
      const { value } = e.target
      if (!this.required && value === '') {
        return true
      }
      return new RegExp('^-?(\\d+\\.\\d+|\\d+)$').test(value) || value === ''
    },
    message: 'invalid-decimal-mark',
  },
  NON_ZERO_INTEGER: {
    required: true,
    rule(e: ChangeEvent<HTMLInputElement>) {
      const { value } = e.target
      if (!this.required && value === '') {
        return true
      }
      return /^(?!0+$)\d+$/.test(value)
    },
    message: 'invalid-number', // TODO: change this
  },
  POSITIVE_INTEGER: {
    required: true,
    rule(e: ChangeEvent<HTMLInputElement>) {
      const { value } = e.target
      if (!this.required && value === '') {
        return true
      }
      return /^[1-9]\d*$/.test(value)
    },
    message: 'invalid-number', // TODO: change this
  },
  INTEGER: {
    required: true,
    rule(e: ChangeEvent<HTMLInputElement>) {
      const { value } = e.target
      if (!this.required && value === '') {
        return true
      }
      return /^-?[0-9]\d*$/.test(value)
    },
    message: 'invalid-number', // TODO: change this
  },
  NOT_EMPTY: {
    required: true,
    rule(e: ChangeEvent<HTMLInputElement>) {
      const { value } = e.target
      return value.trim() !== ''
    },
    message: 'invalid-empty-text',
  },
  YLE_DOMAIN: {
    required: false,
    rule(e: ChangeEvent<HTMLInputElement>) {
      const { value } = e.target
      const regexDomain = /^https:\/\/([a-zA-Z\d-]+\.){0,}yle\.fi(?:$|\/)/
      return (!this.required && value === '') || regexDomain.test(value)
    },
    message: 'invalid-yle-domain',
  },
  DATE: {
    required: true,
    rule(value: string | moment.Moment) {
      if (!this.required && value === '') {
        return true
      }
      return moment(value).isValid()
    },
    message: 'invalid-date',
  },
}

export default validationRules
