import { fetchSubjectList } from '../../middleware/examService/examService'

// Actions.
export const RECEIVE = 'subjects/RECEIVE'

// Reducer.
export default function reducer(state = [], action) {
  switch (action.type) {
    case RECEIVE:
      return action.subjects
    default:
      return state
  }
}

// Action creators.
export function fetchSubjects(filters) {
  return dispatch => dispatch(fetchSubjectList(filters))
}

export const receiveSubjects = subjects => ({
  type: RECEIVE,
  subjects,
})
