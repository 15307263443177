import React from 'react'
import { connect } from 'react-redux'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import ShareReview from '../ShareReview/ShareReview'
import SharePreview from '../SharePreview/SharePreview'
import { getExamId } from '../../../redux/modules/nodes/selectors'
import { getShareUrl } from '../ShareReview/ShareReviewHelper'
import { getExamState } from '../../../redux/modules/exam/selectors'
import './characters.scss'
import SecondaryHeader from '../../elements/Typography/SecondaryHeader.tsx'

const CharacterPreview = ({ character, examId, exam }) => {
  const { share, title, description } = character
  const showSharePreview = exam.enableShare && share && Object.values(share).some(v => v !== '')
  const url = share ? getShareUrl(share, examId) : null

  return (
    <div className="yo-characters-preview">
      <SecondaryHeader>{title}</SecondaryHeader>
      <div className="yo-characters-preview__desc">
        <RichTextContent content={description} />
      </div>
      {share && url && <ShareReview title={share.title} url={url} />}
      {showSharePreview && (
        <SharePreview description={share.description} imageId={share.imageId} title={share.title} />
      )}
    </div>
  )
}
const mapStateToProps = state => ({
  examId: getExamId(state),
  exam: getExamState(state),
})
export default connect(mapStateToProps)(CharacterPreview)
