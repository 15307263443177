import React from 'react'
import ProgressBar from '../ProgressBar/ProgressBar.tsx'
import './timer.scss'

class Timer extends React.Component {
  componentDidUpdate(prevProps) {
    const { progress, onComplete } = this.props
    if ((!progress || progress <= 0) && progress !== prevProps.progress) {
      onComplete()
    }
  }

  render() {
    const { progress, second } = this.props
    return (
      <div className="yo-timer__wrapper">
        <ProgressBar isTimer progress={progress} />
        <div className="yo-timer__second">{second ? Number(second).toFixed(1) : 0}s</div>
      </div>
    )
  }
}

export default Timer
