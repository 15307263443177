import uuid from 'uuid'
import { assoc, assocPath, compose, curry, dissoc, reduce, omit, hasIn } from 'ramda'
import {
  rootNodeExamTypes,
  TV_EPISODE,
  ARCHTYPE_TEST,
  commonExamSettings,
  POLL,
  VOTE,
  REVIEW_ONLY_EXAM_TYPE,
} from '../constants/definitions/exams'
import { nodeTypes } from '../constants/definitions/entities/entities.ts'
import { getOptionIdByNodeId } from './common'

export function generateNewExamState(nodeTypeId = nodeTypes.EXAM_ROOT.id) {
  const newUuid = uuid.v4()
  const isExamRoot = nodeTypeId === nodeTypes.EXAM_ROOT.id

  const rootNode = {
    [newUuid]: {
      id: newUuid,
      nodeTypeId,
      childIds: [],
      options: [],
      content: { title: 'Untitled', text: 'Description' },
      order: 1,
    },
  }

  const defaultExamState = {
    ...commonExamSettings,
    exam_type: rootNodeExamTypes[nodeTypeId],
  }

  const state = {
    exam: isExamRoot
      ? omit(['episodeCode', 'seriesCode', 'fingerprintName', 'startTime'], defaultExamState)
      : omit(
          [
            'category',
            'forceYletunnus',
            'hide_statistics',
            'disable_saving',
            'hideScores',
            'hideReviewButtons',
            'semester',
            'subject',
            'year',
          ],
          defaultExamState,
        ),
    entities: {
      choices: {},
      options: {},
      nodes: rootNode,
      reviews: [],
      characters: {
        info: [],
        choices: [],
      },
    },
    editing: {
      status: 'edit',
      parameters: {
        nodeId: newUuid,
      },
    },
  }

  return { uuid: newUuid, state }
}

function resetQuestions(qs) {
  const questions = qs.filter(q => q !== null)

  // Remove unwanted keys from question
  const removeKeys = compose(dissoc('id'), dissoc('exam_id'))

  // Reduce array of questions to an object ({ UUID: Question })
  const byUUID = reduce((qs, q) => assoc(q.uuid, q, qs), {})

  const newUUIDs = q => ({
    ...removeKeys(q),
    newUUID: uuid.v4(),
  })

  const qsWithNewUUIDs = questions.map(newUUIDs)

  const newChildIDs = curry((qs, q) => ({
    ...q,
    new_child_ids: q.child_ids.filter(id => hasIn(id, qs)).map(id => qs[id].newUUID),
  }))

  const updateUUID = q => ({
    ...dissoc('newUUID', q),
    uuid: q.newUUID,
  })

  const updateChildID = q => ({
    ...dissoc('new_child_ids', q),
    child_ids: q.new_child_ids,
  })

  // Add new UUIDs to questions, add new child IDs to questions,
  // update the references, and remove unwanted keys
  return qsWithNewUUIDs
    .map(newChildIDs(byUUID(qsWithNewUUIDs)))
    .map(compose(updateUUID, updateChildID))
}

export function resetExam(newUUID, exam) {
  const reset = compose(
    dissoc('id'),
    dissoc('published_at'),
    assocPath(['classification', 'authors'], {}),
    assocPath(['classification', 'published'], false),
  )

  return {
    ...reset(exam),
    uuid: newUUID,
    name: `Duplicate of ${exam.name}`,
    questions: resetQuestions(exam.questions),
  }
}

export const getRootNodeTypeId = examType => {
  switch (examType) {
    case TV_EPISODE:
      return nodeTypes.TV_EPISODE_ROOT.id
    case ARCHTYPE_TEST:
      return nodeTypes.ARCHTYPE_ROOT.id
    case POLL:
      return nodeTypes.POLL_ROOT.id
    case VOTE:
      return nodeTypes.VOTE_ROOT.id
    case REVIEW_ONLY_EXAM_TYPE:
      return nodeTypes.REVIEW_ONLY_ROOT.id
    default:
      return nodeTypes.EXAM_ROOT.id
  }
}

export const getStatisticsCreatedAt = ({ statisticsCreatedAt }, locale) =>
  statisticsCreatedAt
    ? new Date(statisticsCreatedAt).toLocaleString(locale || 'fi', { timeStyle: 'short' })
    : null

export const getTotalCount = (
  { statistics, id, value },
  options = { plusOneSelectedOption: true },
) => {
  const hasAnswered = getOptionIdByNodeId(id) || value
  if (statistics) {
    const count = statistics.map(x => x.count).reduce((a, b) => a + b, 0)
    if (hasAnswered && options.plusOneSelectedOption) {
      return count + 1
    }
    return count
  }
  return hasAnswered ? 1 : 0
}

export const findSpecialQuestionIdsWithSumResults = nodes =>
  Object.values(nodes)
    .filter(({ nodeTypeId }) => {
      return nodeTypeId === nodeTypes.SCORED_VOTE_QUESTION.id
    })
    .map(({ id }) => id)

export const getScoreSums = ({ id, statisticSums }, option) => {
  const optionId = String(option.id)
  return statisticSums?.find(x => x.option_id === optionId)?.score_sum ?? 0
}

export const getStats = (
  { id, statistics, value },
  option,
  totalCount,
  options = { plusOneSelectedOption: true },
) => {
  if (totalCount === 0) {
    return {
      percentage: 0,
      count: 0,
    }
  }
  const optionId = String(option.id)
  const isSelectedOption = value
    ? Object.keys(value)[0] === optionId
    : getOptionIdByNodeId(id) === optionId
  const optionStats = statistics ? statistics.find(x => x.option_id === optionId) : 0
  const count = optionStats ? optionStats.count : 0
  let formattedCount = count
  if (isSelectedOption && options.plusOneSelectedOption) {
    // Add user selection to count because of api cache
    formattedCount = count + 1
  }

  // Round percentage to one decimal
  return {
    percentage: Math.round((formattedCount / totalCount) * 100 * 10) / 10,
    count: formattedCount,
  }
}
