import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { getToken, getUserID } from '../../../utils/tokenHelpers'
import { resetAllFilters } from '../../../redux/modules/filters/exams/resetFilters'
import { resetPaginations } from '../../../redux/modules/paginations/paginations'
import { resetPublishStatusFilter } from '../../../redux/modules/filters/exams/byPublishStatus'
import PrimaryHeader from '../../elements/Typography/PrimaryHeader.tsx'
import './app-header.scss'

const AppHeader = ({ onClickLink }) => (
  <div className="yo-app-header">
    <div className="yo-app-header__links">
      {process.env.REACT_APP_ENV === 'test' && (
        <PrimaryHeader className="yo-app-header__test">Test environment</PrimaryHeader>
      )}

      <NavLink
        activeClassName="yo-app-header__link--active"
        className="yo-app-header__link"
        onClick={e => onClickLink(e, '/by-author')}
        to={`/exams/by-author/${getUserID(getToken())}`}
      >
        My exams
      </NavLink>

      <NavLink
        activeClassName="yo-app-header__link--active"
        className="yo-app-header__link"
        key="list"
        onClick={e => onClickLink(e, '/list')}
        to="/exams/list"
      >
        All exams
      </NavLink>

      <NavLink
        activeClassName="yo-app-header__link--active"
        className="yo-app-header__link"
        data-testid="app-header-series"
        key="series"
        onClick={e => onClickLink(e, '/series/list')}
        to="/series/list"
      >
        All series
      </NavLink>

      <a
        className="yo-app-header__link"
        href="https://docs.google.com/a/yle.fi/document/d/1qBI08BHFNtLLn7h9NETD9C7hp1NE4Si4WBt2eaZ0hKQ/edit?usp=sharing"
        rel="noopener noreferrer"
        target="_blank"
      >
        Instructions
      </a>

      <a
        className="yo-app-header__link"
        href="https://docs.google.com/forms/d/e/1FAIpQLSdLVF_HK58q8dRyoKXlxE6NeOVQykyNLNB15EGEcW5Ln_hvYg/viewform?usp=sf_link"
        rel="noopener noreferrer"
        target="_blank"
      >
        Report a problem
      </a>

      <NavLink className="yo-app-header__link yo-app-header__link--logout" to="/logout">
        Log out
      </NavLink>
    </div>
  </div>
)

const mapDispatchToProps = dispatch => ({
  onClickLink: (e, path) => {
    e.stopPropagation()
    if (window.location.href.match(path)) {
      dispatch(resetAllFilters())
      dispatch(resetPublishStatusFilter())
      dispatch(resetPaginations())
    }
  },
})

export default connect(null, mapDispatchToProps)(AppHeader)
