import * as R from 'ramda'
import getNextOptionId from './utils'

// Actions
import { HANDLE_EXAM_DATA } from '../../middleware/examService/examService'
import { HANDLE_QUESTIONS_DATA } from '../questions/questions'
import { UPDATE_SETTING } from '../node/actions.ts'

const ADD_CHOICE = 'options/ADD_CHOICE'
export const CREATE = 'options/CREATE'
const DELETE = 'options/DELETE'
const REMOVE_CHOICE = 'options/REMOVE_CHOICE'
const UPDATE = 'options/UPDATE'

const choices = (state = {}, action = {}) => {
  switch (action.type) {
    case ADD_CHOICE:
      return Object.assign({}, state, {
        choices: [...state.choices, action.choiceId],
      })

    case REMOVE_CHOICE:
      return Object.assign({}, state, {
        choices: state.choices.filter(choiceId => choiceId !== action.choiceId),
      })

    default:
      return state
  }
}

// Reducer
export default function reducer(state = {}, action = {}) {
  let optionId

  if (action.parentId && action.optionId) {
    optionId = `${action.parentId}--${action.optionId}`
  }

  switch (action.type) {
    case CREATE:
      return R.assoc(optionId, action.option, state)

    case UPDATE:
      return Object.assign({}, state, {
        [optionId]: Object.assign({}, state[optionId], action.option),
      })

    case DELETE:
      return R.dissoc(optionId, state)

    case HANDLE_EXAM_DATA:
      return Object.assign({}, action.data.entities.options)

    case HANDLE_QUESTIONS_DATA:
      return Object.assign({}, action.data.entities.options)

    case ADD_CHOICE:
    case REMOVE_CHOICE:
      return Object.assign({}, state, {
        [optionId]: choices(state[optionId], action),
      })

    case UPDATE_SETTING: {
      const { customizedPoints } = action.setting
      // When customizedPoints setting is set, we want to apply
      // points = 1 to every correct option and 0 to incorrect option.
      // When customizedPoints is unset, we want to apply
      // points = undefined to every option.
      if (customizedPoints !== undefined) {
        const getDefaultPoints = option => {
          let points
          if (customizedPoints) {
            points = option.correct ? '1' : '0'
          }
          return points
        }
        return Object.entries(state)
          .filter(([_, option]) => option.parent === action.nodeId)
          .reduce(
            (prev, [optionId, option]) => ({
              ...prev,
              [optionId]: { ...option, points: getDefaultPoints(option) },
            }),
            state,
          )
      }
      return state
    }

    default:
      return state
  }
}

// Action creators
// export const createOptionWithId = (optionId, option) => ({
export const createOptionWithId = (optionId, option, parentId) => ({
  type: CREATE,
  optionId,
  option: Object.assign({}, option, { id: optionId }),
  parentId,
})

// Creates an option and returns the id.
export const createOption = (option, parent) => (dispatch, getState) => {
  const id = getNextOptionId(getState())
  dispatch(createOptionWithId(id, option, parent))

  return id
}

export const deleteOption = (optionId, parentId) => ({
  type: DELETE,
  optionId,
  parentId,
})

export const updateOption = (optionId, option) => ({
  type: UPDATE,
  optionId,
  option,
  parentId: option.parent,
})

export const addChoice = (choiceId, optionId, parentId) => ({
  type: ADD_CHOICE,
  choiceId,
  optionId,
  parentId,
})

export const removeChoice = (choiceId, optionId, parentId) => ({
  type: REMOVE_CHOICE,
  choiceId,
  optionId,
  parentId,
})
