import React from 'react'
import defaultProps from './defaultProps'

const Delete = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M3.39 7.113l1.1 10.908C4.55 18.483 6.777 20 10 20c3.225-.002 5.45-1.518 5.51-1.98l1.103-10.907C14.93 8.055 12.413 8.5 10 8.5c-2.41 0-4.928-.445-6.61-1.387zm9.778-5.603l-.86-.95C11.98.085 11.62 0 10.917 0h-1.83c-.7 0-1.062.086-1.393.56l-.86.95C4.264 1.96 2.4 3.15 2.4 4.03v.17C2.4 5.745 5.803 7 10 7c4.198 0 7.6-1.254 7.6-2.8v-.17c0-.88-1.862-2.07-4.432-2.52zM12.07 4.34L11 3H9L7.932 4.34h-1.7s1.862-2.22 2.11-2.522c.19-.23.385-.318.637-.318h2.042c.253 0 .447.088.637.318.247.3 2.11 2.522 2.11 2.522h-1.7z"
      fill={fill}
    />
  </svg>
)

Delete.defaultProps = defaultProps

export default Delete
