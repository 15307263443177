import React from 'react'
import { withTranslation } from 'react-i18next'

const InputCounters = ({ value, t }) => (
  <div className="yo-input__counter" data-testid="input-counters">
    <WordCounter t={t} value={value} />
    <CharCounter t={t} value={value} />
  </div>
)

const WordCounter = ({ value, t }) => {
  // Counts the amount of typed words using the following rules:
  //   1. Remove trailing whitespace (e.g. space, tab, newline)
  //   2. String of characters that are separated by whitespace are considered to be words
  const words = value ? value.trim().split(/\s+/).length : 0

  return (
    <div className="yo-input__counter-words">
      {t('InputCounters:count', { context: 'words', count: words })}
    </div>
  )
}

const CharCounter = ({ value, t }) => {
  // Counts the amount of typed characters
  // Ignores all whitespace characters (e.g. space, tab, newline)
  const chars = value ? value.replace(/\s/g, '').trim().length : 0

  return (
    <div className="yo-input__counter-chars">
      {t('InputCounters:count', { context: 'characters', count: chars })}
    </div>
  )
}

export default withTranslation()(InputCounters)
