import config from '../../../config'
import { getToken } from '../../../utils/tokenHelpers'
import { showGoogleLoginPrompt } from '../../../components/misc/GoogleLogin/GoogleLogin.tsx'

export const apiURL = path => [config.backend.url, '/v1', path].join('')

export const parseJSON = response => {
  if (response && response.ok) {
    // check if response has content
    return response.status === 204 ? {} : response.json()
  }
  // if unauthorized lets make sure token needs to be refreshed
  if (response && response.status === 401) {
    showGoogleLoginPrompt()
  }

  throw response
}

export const DELETE = path =>
  fetch(apiURL(path), {
    headers: new Headers({ Authorization: `Bearer ${getToken()}` }),
    method: 'DELETE',
  })

export const GET = path =>
  fetch(apiURL(path), {
    headers: new Headers({ Authorization: `Bearer ${getToken()}` }),
    method: 'GET',
  })

export const POST = (path, body) =>
  fetch(apiURL(path), {
    body,
    headers: new Headers({
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  })
