import { FieldType, NodeType, TehtavaNode } from './types'
import * as genericFieldTypes from './genericFieldTypes'
import * as matriculationExamFieldTypes from './matriculationExamFieldTypes'
import * as questionNodes from './questionNodes'
import * as containerNodes from './containerNodes'
import * as contentNodes from './contentNodes'
import * as multipleChoiceQuestionNodes from './multipleChoiceQuestionNodes'

export * from './types'
export { default as validationRules } from './validationRules'
export {
  default as metaOptions,
  META_OPTION_ESSAY,
  META_OPTION_JOKER,
  META_OPTION_MATERIAL,
  META_OPTION_NO_MATERIAL,
} from './matriculationExamMetaOptions'
export const nodeTypes: { [name: string]: TehtavaNode } = {
  ...questionNodes,
  ...containerNodes,
  ...contentNodes,
  ...multipleChoiceQuestionNodes,
}
export const rootNodeTypes: NodeType[] = [
  NodeType.EXAM_ROOT,
  NodeType.TV_EPISODE_ROOT,
  NodeType.ARCHTYPE_ROOT,
  NodeType.POLL_ROOT,
  NodeType.VOTE_ROOT,
  NodeType.REVIEW_ONLY_ROOT,
]
export { default as optionLayoutTypes } from './optionLayoutTypes'
export { default as questionTypes } from './questionTypes'
// Let's define all different kinds of fields that are shown in the Sidebar when editing a question.
export const fieldTypes: { [name: string]: FieldType } = {
  ...genericFieldTypes,
  ...matriculationExamFieldTypes,
}
