import React from 'react'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'

const CheckboxInput = ({
  attributes,
  checked,
  defaultChecked = false,
  description,
  disabled,
  id,
  label,
  name,
  onChange,
  suffix,
  value,
}) => (
  <div>
    <label className="yo-input__label yo-input__label--checkbox" htmlFor={id}>
      <input
        checked={checked || defaultChecked}
        className="yo-input__input yo-input__input--checkbox"
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={value}
        {...attributes}
      />
      <span className="yo-input__label-wrapper">
        <span className="yo-input__label yo-input__label--title">
          {<RichTextContent content={label} />}
        </span>
      </span>
      {suffix}
    </label>
    {description && <div className="yo-input__description">{description}</div>}
  </div>
)

export default CheckboxInput
