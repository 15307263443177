import PropTypes from 'prop-types'
import React from 'react'
import Icon from '../Icon/Icon'
import './icon-buttons.scss'
import './icon-button.scss'

export const IconButtons = ({ children }) => (
  <div className="yo-icon-buttons" data-testid="yo-icon-buttons">
    {children}
  </div>
)

export const IconButton = ({ attributes, icon, onClick, fill, width, height, title }) => (
  <button {...attributes} className="yo-icon-button" onClick={onClick} title={title} type="button">
    <Icon fill={fill} height={height} icon={icon} width={width} />
  </button>
)

IconButton.defaultProps = {
  title: '',
  onClick: () => {},
  fill: undefined,
}

IconButton.propTypes = {
  // Title text for the button.
  title: PropTypes.string,
  // String representation of the icon to be used.
  icon: PropTypes.string.isRequired,
  // Click handler for the button.
  onClick: PropTypes.func,
  // Fill color for the icon.
  fill: PropTypes.string,
}
