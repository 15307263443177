import React, { useLayoutEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { i18n } from 'i18next'
import { getStatus, getMessage } from './selectors'
import { resolveConfirmDialog } from './actions'
import { ConfirmDialogStatus } from './ConfirmDialogManager'
import Dialog from '../components/layout/Dialog/Dialog'
import Button from '../components/elements/Button/Button'
import SpacedGroup from '../components/elements/SpacedGroup/SpacedGroup'
import './confirmDialog.scss'

interface Props {
  status: ConfirmDialogStatus
  message: string | null
  resolveConfirmDialog: ({ accepted }: { accepted: boolean }) => {}
  t: i18n['t']
}

const ConfirmDialog: React.FC<Props> = ({ status, message, resolveConfirmDialog, t }: Props) => {
  const handleOnConfirm = () => resolveConfirmDialog({ accepted: true })
  const handleOnCancel = () => resolveConfirmDialog({ accepted: false })
  const dialogRef = React.useRef<Dialog>()
  const dialogVisible = status !== ConfirmDialogStatus.Hidden
  const [dialogOffsetY, setDialogOffsetY] = React.useState(0)

  useLayoutEffect(() => {
    const { current: dialog } = dialogRef
    if (dialogVisible && dialog) {
      const containerY = Math.max(
        0,
        dialog.parentElement?.parentElement?.getBoundingClientRect()?.y || 0,
      )
      const { y } = dialog.getBoundingClientRect()
      if (y < 0) {
        setDialogOffsetY(Math.abs(y) + containerY)
      }
    } else if (dialogOffsetY) {
      setDialogOffsetY(0)
    }
  }, [dialogVisible])

  return (
    <>
      {dialogVisible && (
        <Dialog
          dialogButtons={
            <SpacedGroup classNames={['yo-confirm-dialog-buttons']}>
              <Button
                attributes={{ 'data-testid': 'confirm-dialog-cancel-button' }}
                isDisabled={false}
                key="cancel"
                onClick={handleOnCancel}
                text={t('common:cancel')}
                variant="secondary"
              />
              <Button
                attributes={{ 'data-testid': 'confirm-dialog-confirm-button' }}
                isDisabled={false}
                key="confirm"
                onClick={handleOnConfirm}
                text={t('common:confirm')}
              />
            </SpacedGroup>
          }
          dialogMessage={message}
          offsetY={dialogOffsetY}
          onDialogCleared={handleOnCancel}
          ref={dialogRef}
        />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  status: getStatus(state),
  message: getMessage(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({ resolveConfirmDialog }, dispatch)

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog))
