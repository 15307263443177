import { useRef, useEffect } from 'react'

export default function useTimeout(callback, delay) {
  const savedCallback = useRef()
  const savedTimeoutId = useRef()
  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setTimeout(tick, delay)
      savedTimeoutId.current = id
      return () => clearInterval(id)
    }

    return () => clearInterval(savedTimeoutId.current)
  }, [delay])
}
