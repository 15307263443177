export const ALL_SUBJECTS = 'all-subjects'

// Actions
const CLEAR_SUBJECT_FILTER = 'filters/exams/CLEAR_SUBJECT'
const SET_SUBJECT_FILTER = 'filters/exams/SET_SUBJECT'

// Action creators
export const filterExamsBySubject = subject => ({
  type: SET_SUBJECT_FILTER,
  subject,
})

export const resetSubjectFilter = () => ({
  type: CLEAR_SUBJECT_FILTER,
})

const defaultState = ALL_SUBJECTS

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SUBJECT_FILTER:
      return action.subject

    case CLEAR_SUBJECT_FILTER:
      return defaultState

    default:
      return state
  }
}
