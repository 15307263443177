import * as R from 'ramda'
import uuid from 'uuid'

// Actions
import { HANDLE_EXAM_DATA } from '../../middleware/examService/examService'
import { HANDLE_QUESTIONS_DATA } from '../questions/questions'

const CREATE = 'choices/CREATE'
const DELETE = 'choices/DELETE'
const UPDATE = 'choices/UPDATE'

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case CREATE:
      return R.assoc(action.choice.id, action.choice, state)

    case UPDATE:
      return Object.assign({}, state, {
        [action.choiceId]: Object.assign({}, state[action.choiceId], action.choice),
      })

    case DELETE:
      return R.dissoc(action.choiceId, state)

    case HANDLE_EXAM_DATA:
      return Object.assign({}, action.data.entities.choices)

    case HANDLE_QUESTIONS_DATA:
      return Object.assign({}, action.data.entities.choices)

    default:
      return state
  }
}

// Action creators
export const createChoiceWithId = (choiceId, choice) => ({
  type: CREATE,
  choice: Object.assign({}, choice, { id: choiceId }),
})

// Creates a choice entity and returns the id.
export const createChoice = choice => dispatch => {
  const id = uuid.v4()

  dispatch(createChoiceWithId(id, choice))

  return id
}

export const deleteChoice = choiceId => ({
  type: DELETE,
  choiceId,
})

export const updateChoice = (choiceId, choice) => ({
  type: UPDATE,
  choiceId,
  choice,
})
