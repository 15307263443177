import React from 'react'
import defaultProps from './defaultProps'

const Bold = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 32 32" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M22.12 15.145C23.294 13.753 24 11.957 24 10c0-4.41-3.59-8-8-8H6v28h12c4.41 0 8-3.59 8-8 0-2.905-1.556-5.453-3.88-6.855zM12 6h3.172c1.75 0 3.172 1.794 3.172 4s-1.423 4-3.172 4H12V6zm4.97 20H12v-8h4.97c1.826 0 3.312 1.794 3.312 4s-1.486 4-3.313 4z"
      fill={fill}
    />
  </svg>
)

Bold.defaultProps = defaultProps

export default Bold
