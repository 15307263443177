import React from 'react'
import { connect } from 'react-redux'
import { setYleTunnus, setYleTunnusLoaded } from '../../../redux/modules/yleTunnus/yleTunnus'
import { getYleTunnusUser } from '../../../utils/yleTunnus'

const YleTunnusHoc = WrappedComponent => {
  class Wrapper extends React.Component {
    componentDidMount() {
      this.props.updateYleTunnus()
      window.addEventListener('tehtavaYleTunnusChanged', this.props.updateYleTunnus)
      // Fallback so we render something even if tunnus is not available
      this.timerHandle = setTimeout(() => {
        // eslint-disable-next-line no-console
        console.error('YLE Tunnus Is Not Available')
        this.props.setYleTunnusLoaded()
      }, 3000)
    }

    componentDidUpdate() {
      const { yleTunnus } = this.props
      if (yleTunnus.loaded) {
        clearTimeout(this.timerHandle)
      }
    }

    componentWillUnmount() {
      window.removeEventListener('tehtavaYleTunnusChanged', this.props.updateYleTunnus)
      clearTimeout(this.timerHandle)
    }

    render() {
      const { yleTunnus } = this.props

      return yleTunnus.loaded ? <WrappedComponent {...this.props} /> : null
    }
  }

  const mapDispatchToProps = dispatch => ({
    updateYleTunnus: () => dispatch(setYleTunnus(getYleTunnusUser())),
    setYleTunnusLoaded: () => dispatch(setYleTunnusLoaded()),
  })

  const mapStateToProps = state => ({
    yleTunnus: state.yleTunnus,
  })

  return connect(mapStateToProps, mapDispatchToProps)(Wrapper)
}

export default YleTunnusHoc
