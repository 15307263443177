import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import Button from '../../elements/Button/Button.tsx'
import config from '../../../config'

const onClick = () => {
  const { math_editor } = config
  window.open(
    math_editor,
    'IMS',
    '_blank,width=980,height=800,scrollbars=yes,location=yes,status=yes',
  )
}

const MathField = ({ t, label, onUpdateInputValues }) => {
  const inputId = `yo-dialog__title--${label}`
    .replace(/ /g, '-')
    .toLowerCase()
    .trim()

  return (
    <label className="yo-dialog__label" htmlFor={inputId}>
      <span className="yo-dialog__title">{label}</span>
      <span className="yo-dialog__input yo-dialog__input--wrapper">
        <input
          autoFocus
          className="yo-dialog__input"
          id={inputId}
          onChange={e => onUpdateInputValues('text', e.target.value)}
          placeholder="LaTeX"
          type="text"
        />
        <Button onClick={onClick} size="sm" text={t('Editor')} />
      </span>
    </label>
  )
}

MathField.propTypes = {
  // Label for the input.
  label: PropTypes.string.isRequired,
}

export default withTranslation(['MathField'])(MathField)
