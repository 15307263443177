import React from 'react'
import { withTranslation } from 'react-i18next'
import Input from '../../elements/Input/Input'
import Button from '../../elements/Button/Button.tsx'
import InputExtended from '../../elements/Input/InputExtended'
import CharacterPreview from './CharacterPreview'
import {
  updateCharacterInfo,
  clearCharacterEditing,
  deleteCharacter,
} from '../../../redux/modules/characters/characters'
import ShareInput from '../ShareInput/ShareInput'

class CharacterEdit extends React.Component {
  constructor(props) {
    super(props)
    this.onChangeDescription = this.onChangeDescription.bind(this)
    this.onClickCloseEdit = this.onClickCloseEdit.bind(this)
    this.onTitleChange = this.onTitleChange.bind(this)
    this.onClickDeleteCharacter = this.onClickDeleteCharacter.bind(this)
    this.onUpdateShareTitle = this.onUpdateShareTitle.bind(this)
    this.onUpdateShareDescription = this.onUpdateShareDescription.bind(this)
    this.onUpdateShareImage = this.onUpdateShareImage.bind(this)
  }

  onChangeDescription(e) {
    const { dispatch, character } = this.props
    dispatch(updateCharacterInfo(character.id, { description: e }))
  }

  onTitleChange(e) {
    const { dispatch, character } = this.props
    dispatch(updateCharacterInfo(character.id, { title: e.target.value }))
  }

  onClickCloseEdit() {
    const { dispatch } = this.props
    dispatch(clearCharacterEditing())
  }

  onClickDeleteCharacter() {
    const { character, dispatch, t } = this.props
    const delConfirmed = window.confirm(t('common:confirm-delete', { item: 'character' }))
    if (delConfirmed) {
      dispatch(deleteCharacter(character.id))
      dispatch(clearCharacterEditing())
    }
  }

  onUpdateShareTitle(e) {
    this.updateCharacterShareInfo('title', e.target.value)
  }

  onUpdateShareDescription(e) {
    this.updateCharacterShareInfo('description', e.target.value)
  }

  onUpdateShareImage(imageId) {
    this.updateCharacterShareInfo('imageId', imageId)
  }

  updateCharacterShareInfo(key, value) {
    const { dispatch, character } = this.props
    dispatch(
      updateCharacterInfo(character.id, {
        share: {
          ...character.share,
          id: character.id,
          [key]: value,
        },
      }),
    )
  }

  render() {
    const { character, characters, dispatch, enableShare } = this.props
    const share = character.share ? character.share : {}
    const submitText = characters.createId ? 'Submit' : 'Close'

    return (
      <div className="yo-character-edit__container">
        <div className="yo-character-edit__editor">
          <Input
            autofocus
            defaultValue={character.title}
            label="Title"
            onChange={this.onTitleChange}
            type="text"
          />
          <InputExtended
            defaultValue={character.description}
            label="Description"
            onChange={this.onChangeDescription}
            type="rich-text"
          />
          {enableShare && (
            <ShareInput
              description={share.description}
              dispatch={dispatch}
              imageId={share.imageId}
              onUpdateDescription={this.onUpdateShareDescription}
              onUpdateImage={this.onUpdateShareImage}
              onUpdateTitle={this.onUpdateShareTitle}
              title={share.title}
            />
          )}

          <div className="yo-character-edit__button-container">
            <Button
              classNames={['yo-character-edit__button']}
              onClick={this.onClickDeleteCharacter}
              size="sm"
              text="Delete"
              variant="secondary"
            />
            <Button
              classNames={['yo-character-edit__button']}
              onClick={this.onClickCloseEdit}
              size="sm"
              text={submitText}
            />
          </div>
        </div>
        <div className="yo-character-edit__preview">
          <CharacterPreview character={character} />
        </div>
      </div>
    )
  }
}

export default withTranslation(['Character'])(CharacterEdit)
