import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createChoice } from '../../../redux/modules/choices/choices'
import { addChoice } from '../../../redux/modules/options/options'
import Button from '../../elements/Button/Button.tsx'
import ChoiceInput from '../../elements/ChoiceInput/ChoiceInput'
import './editor-choices.scss'

/**
 * Component for creating accordion list of choices in a node entity.
 *
 * @param {string} option - Option entity
 * @param {object} nodeType - Object describing the option node
 * @param {array} choices - Array of choice entities
 * @param {function} oonAddChoiceClick - Click hanlder for adding new choices to an option
 */
const EditorChoices = ({ option, choices, onAddChoiceClick, nodeType }) => (
  <div className="yo-editor-choices">
    <div className="yo-editor-choices__title">Choices</div>
    {choices.map(choice => (
      <ChoiceInput
        choice={choice}
        key={choice.id}
        nodeType={nodeType}
        option={option}
        value={option.value}
      />
    ))}
    <Button
      attributes={{ 'data-testid': 'button-add-choice' }}
      onClick={onAddChoiceClick}
      size="sm"
      text="Add choice"
    />
  </div>
)

const mapDispatchToProps = (dispatch, ownProps) => {
  const actions = bindActionCreators({ addChoice, createChoice }, dispatch)

  return {
    onAddChoiceClick: () => {
      // Create choice entity and get the id.
      const choiceId = actions.createChoice()

      // Add the choice to an option entity.
      actions.addChoice(choiceId, ownProps.option.id, ownProps.option.parent)
    },
  }
}

export default connect(null, mapDispatchToProps)(EditorChoices)
