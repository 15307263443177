import config from '../../../config'

// eslint-disable-next-line
export const getShareUrl = (share, examId) => {
  const shareId = share ? share.id : null
  const locationPath = window.location.pathname.substr(1)

  // Check for svenska & subdomains svenska-test etc.
  const platform = /\.svenska(-[^.]+)?\.yle\.fi$/.test('.'.concat(window.location.hostname))
    ? 'svenska'
    : 'ylefi'

  return shareId
    ? `${config.share_api_url}/47-${examId}/${shareId}.html?url=${locationPath}&platform=${platform}`
    : null
}
