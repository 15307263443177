import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import InputExtended from '../../elements/Input/InputExtended'
import Select from '../../elements/Select/Select'
import { TV_EPISODE } from '../../../constants/definitions/exams'
import EditorSettingsTimestamps from './EditorSettingsTimestamps'
import EditorSettingsTimedQuestion from './EditorSettingsTimedQuestion'
import ScoredVoteScoring from './ScoredVoteScoring.tsx'
import { updateNodeSetting } from '../../../redux/modules/node/actions.ts'
import { NodeType, optionLayoutTypes } from '../../../constants/definitions/entities/entities.ts'

const shouldDisable = ({ settings, field, node }) => {
  switch (field.name) {
    case 'points':
      return settings.customizedPoints || settings.timedQuestion
    case 'optionsLayout':
      return settings.timedQuestion
    case 'customizedPoints':
      return settings.requireAllCorrect
    case 'requireAllCorrect':
      return (
        node.nodeTypeId === 'CUSTOMIZED_FEEDBACK' &&
        (!settings.demandCorrect || settings.customizedPoints)
      )
    case 'hideWordCounter':
      return settings.textareaSize === '1'
    default:
      return false
  }
}

const shouldRender = ({ field, exam }) => {
  // examTypeFilter can filter a setting field for specific exam types
  return !(field.examTypeFilter && !field.examTypeFilter.includes(exam.exam_type))
}

const EditorSettings = ({ exam, node, onChangeSettings, settingFields }) => {
  const { settings } = node
  const fields = settingFields.map(field => {
    if (!shouldRender({ field, exam })) {
      return null
    }
    let ComponentName = InputExtended

    const value =
      node.settings[field.name] !== undefined ? node.settings[field.name] : field.defaultValue

    const props = {
      attributes: { 'data-testid': `setting-field-${field.name}` },
      defaultValue: value,
      name: field.name,
      description: field.description,
      inline: field.inline,
      isSeparator: field.isSeparator,
      label: field.displayName,
      onChange: e => onChangeSettings({ nodeId: node.id, field, e }),
      type: field.editorFormatter,
      width: field.displayWidth,
      disabled: shouldDisable({ settings, field, node }),
      nodeId: node.id,
      validation: field.validation,
    }

    if (field.editorFormatter === 'select') {
      ComponentName = Select
      props.options = field.options
      props.defaultValue = value
    } else if (field.editorFormatter === 'checkbox') {
      props.defaultChecked = value
    } else if (field.editorFormatter === 'dateTimePicker') {
      props.value = value && moment(value)
      props.defaultValue = undefined
      props.hasNowButton = true
      props.hasClearButton = true
      props.inputProps = { readOnly: true }
      props.allowPastDates = true
    }

    return <ComponentName key={`${node.id}_${field.name}`} {...props} />
  })

  const isTimedQuestionSettings = node.settings.timedQuestion && exam.exam_type !== TV_EPISODE
  return (
    <div className="yo-editor__settings">
      {node.nodeTypeId === NodeType.SCORED_VOTE_QUESTION && <ScoredVoteScoring node={node} />}
      {fields}
      {isTimedQuestionSettings && <EditorSettingsTimedQuestion node={node} />}
      {exam.exam_type === TV_EPISODE && <EditorSettingsTimestamps node={node} />}
    </div>
  )
}

const mapStateToProps = state => ({
  exam: state.exam,
})

const getFieldValue = (field, e) => {
  if (field.editorFormatter === 'checkbox') {
    return e.target.checked
  }
  if (field.editorFormatter === 'dateTimePicker') {
    return e || undefined
  }
  return e.target.value
}

const mapDispatchToProps = dispatch => ({
  onChangeSettings: ({ nodeId, field, e }) => {
    const defaultSetting = {
      [field.name]: getFieldValue(field, e),
    }
    const newSettings = (fieldName => {
      switch (fieldName) {
        case 'demandCorrect':
          return {
            ...defaultSetting,
            optionsLayout: optionLayoutTypes.radios,
            requireAllCorrect: false,
          }
        case 'customizedPoints':
          return {
            ...defaultSetting,
            points: defaultSetting.customizedPoints ? '' : '1',
          }
        case 'timedQuestion':
          return {
            ...defaultSetting,
            optionsLayout: optionLayoutTypes.labels,
          }
        default:
          return defaultSetting
      }
    })(field.name)

    dispatch(updateNodeSetting(nodeId, newSettings))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(EditorSettings)
