import React from 'react'
import { connect } from 'react-redux'
import { update } from 'ramda'
import { Accordion, AccordionItem } from '../../node/Accordion/Accordion'
import Input from '../../elements/Input/Input'
import Button from '../../elements/Button/Button.tsx'
import { updateNodeSetting } from '../../../redux/modules/node/actions.ts'
import { IconButtons, IconButton } from '../../elements/IconButtons/IconButtons'
import EditorSettingsDuration from './EditorSettingsDuration'

const EditorSettingsTimestamps = ({
  node,
  onAddTimestampClick,
  onTimestampChange,
  onDeleteClick,
}) => {
  const timestamps = node.settings.timestamps || []
  const timestampItems = timestamps.map(timestamp => {
    const suffix = (
      <IconButtons>
        <IconButton icon="delete" onClick={e => onDeleteClick(e, timestamp.key)} />
      </IconButtons>
    )
    return (
      <AccordionItem
        isInitiallyExpanded
        key={timestamp.key}
        suffix={suffix}
        title="Timestamp at ..."
      >
        <Input
          defaultValue={timestamp.min}
          inline
          label="Minute:"
          onChange={e =>
            onTimestampChange(e, {
              name: 'min',
              timestamp,
            })
          }
          type="number"
          width="narrow"
        />
        <Input
          defaultValue={timestamp.sec}
          inline
          label="Second:"
          onChange={e =>
            onTimestampChange(e, {
              name: 'sec',
              timestamp,
            })
          }
          type="number"
          width="narrow"
        />
      </AccordionItem>
    )
  })
  const timestampsId = 'yo-input__editor-settings-timestamps'

  const showTimestamps = timestamps && timestamps.length > 0

  return (
    <div>
      <EditorSettingsDuration node={node} />
      <div>
        <label className="yo-input__label" htmlFor={timestampsId}>
          Timestamps
        </label>
        {showTimestamps && <Accordion id={timestampsId}>{timestampItems}</Accordion>}
      </div>
      <Button
        attributes={{ 'data-testid': 'button__add-option' }}
        onClick={() => onAddTimestampClick(node)}
        size="sm"
        text="Add timestamp"
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { node } = ownProps
  const timestamps = node.settings.timestamps ? node.settings.timestamps : []

  return {
    onAddTimestampClick: () => {
      const keys = Object.keys(timestamps).map(f => timestamps[f].key)
      const newKey = keys.length > 0 ? Math.max(...keys) + 1 : 0
      dispatch(
        updateNodeSetting(node.id, {
          timestamps: [
            ...timestamps,
            {
              key: newKey,
              min: 0,
              sec: 0,
            },
          ],
        }),
      )
    },
    onTimestampChange: (e, { name, timestamp }) => {
      e.stopPropagation()
      const index = timestamps.indexOf(timestamp)
      const newTimestamp = { ...timestamp, [name]: e.target.value }
      dispatch(
        updateNodeSetting(node.id, {
          timestamps: update(index, newTimestamp, timestamps),
        }),
      )
    },
    onDeleteClick: (e, key) => {
      e.stopPropagation()
      dispatch(
        updateNodeSetting(node.id, {
          timestamps: timestamps.filter(f => f.key !== key),
        }),
      )
    },
  }
}

export default connect(null, mapDispatchToProps)(EditorSettingsTimestamps)
