import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { hideDropdown } from '../../../redux/modules/dropdown/dropdown'
import { IconButton } from '../../elements/IconButtons/IconButtons'
import Icon from '../../elements/Icon/Icon'
import { getExamState } from '../../../redux/modules/exam/selectors'
import { getLoadStateLoaded } from '../../../redux/modules/loadState/selectors'
import { getVisibleState } from '../../../redux/modules/dropdown/selectors'
import './dropdown.scss'

class Dropdown extends React.Component {
  constructor(props) {
    super(props)

    this.onClickCopy = this.onClickCopy.bind(this)
  }

  componentWillUnmount() {
    this.props.onLeave()
  }

  // A helper function to copy the input field's value to the clipboard
  onClickCopy(obj) {
    const { t } = this.props
    const el = document.getElementById(obj.value)
    const origValue = el.value
    const copiedClassName = 'yo-dropdown__input-field--copied'

    // If the input field is disabled, don't do anything.
    if (el.disabled) {
      return null
    }

    // Select and copy the contents of the field
    el.select()
    document.execCommand('copy')

    // Disable the input field for a while, so the user will not, even by accident, copy anything
    // that they're not supposed to copy.
    el.disabled = true

    // Notify the user that the text was copied by adding new styles and changing the text of the
    // input field.
    el.classList.add(copiedClassName)
    el.value = t('copied', { item: obj.name })

    // After a while, change the element back to it's original state.
    const timeout = setTimeout(() => {
      el.value = origValue
      el.classList.remove(copiedClassName)
      el.disabled = false
    }, 1500)

    return timeout
  }

  render() {
    // Only continue if the state is fully loaded
    if (!this.props.loaded) {
      return null
    }

    const { exam, isVisible, t } = this.props
    const { exam_type: examType, published, subject } = exam

    // Define the field names and values to be displayed in the dropdown
    const embedFields = [
      {
        name: 'Exam ID',
        value: `47-${exam.uuid}`,
      },
      {
        name: 'Exam type',
        value: examType,
      },
      {
        name: 'Subject',
        value: subject,
      },
    ]

    const dropdownClassNames = classnames('yo-dropdown', {
      'yo-dropdown--hidden': !isVisible,
    })

    const content = embedFields.map(field => {
      if (!field.value) {
        return null
      }
      return (
        <label className="yo-dropdown__input" htmlFor={field.value} key={field.name}>
          <span className="yo-dropdown__input-title">{field.name}</span>
          <input
            className="yo-dropdown__input-field"
            id={field.value}
            readOnly
            value={field.value}
          />
          <IconButton icon="copy" onClick={() => this.onClickCopy(field)} />
        </label>
      )
    })

    const notPublishedNotice = !published ? (
      <div className="yo-dropdown__input-notice">
        <Icon icon="warning" />
        <span className="yo-dropdown__input-notice-text">{t('not-published')}</span>
      </div>
    ) : null

    return (
      <div
        className={dropdownClassNames}
        ref={node => {
          this.node = node
        }}
      >
        {notPublishedNotice}
        {content}
      </div>
    )
  }
}

Dropdown.propTypes = {
  exam: PropTypes.shape({
    authors: PropTypes.object,
    category: PropTypes.string,
    exam_type: PropTypes.string,
    language: PropTypes.string,
    published: PropTypes.bool,
    semester: PropTypes.string,
    subject: PropTypes.string,
    tainted: PropTypes.bool,
    year: PropTypes.string,
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  onLeave: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isVisible: getVisibleState(state),
  exam: getExamState(state),
  loaded: getLoadStateLoaded(state),
})

const mapDispatchToProps = dispatch => ({
  onLeave: () => dispatch(hideDropdown()),
})

export default withTranslation(['Dropdown'])(connect(mapStateToProps, mapDispatchToProps)(Dropdown))
