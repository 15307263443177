/* eslint-disable max-lines */
export const TOPICS = {
  terveystieto: [
    {
      id: '18-252804',
      'fi-FI': 'Terveys voimavarana',
      'sv-FI': 'Hälsan som resurs',
    },
    {
      id: '18-252805',
      'fi-FI': 'Terveys ja ympäristö',
      'sv-FI': 'Hälsa och miljö',
    },
    {
      id: '18-252806',
      'fi-FI': 'Terveys ja yhteiskunta',
      'sv-FI': 'Hälsa och samhälle',
    },
  ],
  yhteiskuntaoppi: [
    {
      id: '18-252831',
      'fi-FI': 'Suomalainen yhteiskunta',
      'sv-FI': 'Det finländska samhället',
    },
    {
      id: '18-252832',
      'fi-FI': 'Taloustieto',
      'sv-FI': 'Ekonomikunskap',
    },
    {
      id: '18-252833',
      'fi-FI': 'Suomi, Eurooppa ja muuttuva maailma',
      'sv-FI': 'Finland, Europa och en värld i förändring',
    },
    {
      id: '18-252834',
      'fi-FI': 'Lakitieto',
      'sv-FI': 'Lagkunskap',
    },
  ],
  psykologia: [
    {
      id: '18-252837',
      'fi-FI': 'Toimiva ja oppiva ihminen',
      'sv-FI': 'Den aktiva och lärande människan',
    },
    {
      id: '18-252838',
      'fi-FI': 'Kehittyvä ihminen',
      'sv-FI': 'Människans utveckling',
    },
    {
      id: '18-252839',
      'fi-FI': 'Tietoa käsittelevä ihminen',
      'sv-FI': 'Människan som informationsbearbetare',
    },
    {
      id: '18-252840',
      'fi-FI': 'Tunteet ja mielenterveys',
      'sv-FI': 'Känslor och mental hälsa',
    },
    {
      id: '18-252841',
      'fi-FI': 'Yksilöllinen ja yhteisöllinen ihminen',
      'sv-FI': 'Människan som individ och social varelse',
    },
  ],
  biologia: [
    {
      id: '18-252843',
      'fi-FI': 'Elämä ja evoluutio',
      'sv-FI': 'Livet och evolutionen',
    },
    {
      id: '18-252844',
      'fi-FI': 'Ekologian perusteet ja ihmisen vaikutus ekosysteemeihin',
      'sv-FI': 'Ekologins grunder och människans inverkan på ekosystemen',
    },
    {
      id: '18-252845',
      'fi-FI': 'Solu ja perinnöllisyys',
      'sv-FI': 'Cellen och ärftligheten',
    },
    {
      id: '18-252846',
      'fi-FI': 'Ihmisen biologia',
      'sv-FI': 'Människans biologi',
    },
    {
      id: '18-252847',
      'fi-FI': 'Biotekniikka ja sen sovellukset',
      'sv-FI': 'Biotekniken och dess tillämpningar',
    },
  ],
  historia: [
    {
      id: '18-252848',
      'fi-FI': 'Ihminen, ympäristö ja historia',
      'sv-FI': 'Människa, miljö och historia',
    },
    {
      id: '18-252849',
      'fi-FI': 'Kansainväliset suhteet',
      'sv-FI': 'Internationella relationer',
    },
    {
      id: '18-252850',
      'fi-FI': 'Itsenäisen Suomen historia',
      'sv-FI': ' Det självständiga Finlands historia',
    },
    {
      id: '18-252851',
      'fi-FI': 'Eurooppalainen ihminen',
      'sv-FI': 'Den europeiska människan',
    },
    {
      id: '18-252852',
      'fi-FI': 'Ruotsin itämaasta Suomeksi',
      'sv-FI': 'Sveriges östra rikshalva blir Finland',
    },
    {
      id: '18-252853',
      'fi-FI': 'Maailman kulttuurit kohtaavat',
      'sv-FI': 'Globala kulturmöten',
    },
  ],
  maantiede: [
    {
      id: '18-252981',
      'fi-FI': 'Maailma muutoksessa',
      'sv-FI': 'En värld i förändring',
    },
    {
      id: '18-252982',
      'fi-FI': 'Sininen planeetta',
      'sv-FI': 'Den blå planeten',
    },
    {
      id: '18-252983',
      'fi-FI': 'Yhteinen maailma',
      'sv-FI': 'En gemensam värld',
    },
    {
      id: '18-252984',
      'fi-FI': 'Geomedia – tutki, osallistu ja vaikuta',
      'sv-FI': 'Geomedia - undersök, delta och påverka',
    },
  ],
  elämänkatsomustieto: [
    {
      id: '18-253378',
      'fi-FI': 'Minä ja hyvä elämä',
      'sv-FI': 'Jag och det goda livet',
    },
    {
      id: '18-253379',
      'fi-FI': 'Minä ja yhteiskunta',
      'sv-FI': 'Individen och samhället',
    },
    {
      id: '18-253380',
      'fi-FI': 'Kulttuurit',
      'sv-FI': 'Kulturer',
    },
    {
      id: '18-253381',
      'fi-FI': 'Katsomukset',
      'sv-FI': 'Åskådningar',
    },
    {
      id: '18-253382',
      'fi-FI': 'Uskonnot ja uskonnottomuus',
      'sv-FI': 'Religioner och irreligiositet',
    },
    {
      id: '18-253383',
      'fi-FI': 'Tulevaisuus',
      'sv-FI': 'Framtiden',
    },
  ],
  filosofia: [
    {
      id: '18-253474',
      'fi-FI': 'Johdatus filosofiseen ajatteluun',
      'sv-FI': 'Introduktion till filosofiskt tänkande',
    },
    {
      id: '18-253475',
      'fi-FI': 'Etiikka',
      'sv-FI': 'Etik',
    },
    {
      id: '18-253476',
      'fi-FI': 'Yhteiskuntafilosofia',
      'sv-FI': 'Samhällsfilosofi',
    },
    {
      id: '18-253477',
      'fi-FI': 'Totuus',
      'sv-FI': 'Sanningen',
    },
  ],
  'uskonto-evlut': [
    {
      id: '18-253478',
      'fi-FI': 'Uskonto ilmiönä - juutalaisuuden, kristinuskon ja islamin jäljillä',
      'sv-FI': 'Religionen som fenomen - i judendomens, kristendomens och islams fotspår',
    },
    {
      id: '18-253479',
      'fi-FI': 'Maailmanlaajuinen kristinusko',
      'sv-FI': 'Kristendomen i världen',
    },
    {
      id: '18-253480',
      'fi-FI': 'Maailman uskontoja ja uskonnollisia liikkeitä',
      'sv-FI': 'Religioner och religiösa rörelser i världen',
    },
    {
      id: '18-253481',
      'fi-FI': 'Uskonto, kulttuuri ja yhteiskunta Suomessa',
      'sv-FI': 'Religion, kultur och samhälle i Finland',
    },
    {
      id: '18-253482',
      'fi-FI': 'Uskonto taiteissa ja populaarikulttuurissa',
      'sv-FI': 'Religion inom konsten och populärkulturen',
    },
    {
      id: '18-253483',
      'fi-FI': 'Uskonto, tiede ja media',
      'sv-FI': 'Religion, vetenskap och medier',
    },
  ],
  'uskonto-ortodoksinen': [
    {
      id: '18-253484',
      'fi-FI': 'Uskonto ilmiönä – ortodoksisuus, juutalaisuus ja islam',
      'sv-FI': 'Religionen som fenomen - i judendomens, kristendomens och islams fotspår',
    },
    {
      id: '18-253485',
      'fi-FI': 'Ortodoksisuus maailmassa',
      'sv-FI': 'Den ortodoxa kyrkan i världen',
    },
    {
      id: '18-253486',
      'fi-FI': 'Maailman uskontoja ja uskonnollisia liikkeitä',
      'sv-FI': 'Religioner och religiösa rörelser i världen',
    },
    {
      id: '18-253487,18-253488',
      'fi-FI': 'Uskonto, kulttuuri ja yhteiskunta Suomessa',
      'sv-FI': 'Religion, kultur och samhälle i Finland',
    },
    {
      id: '18-254148',
      'fi-FI': 'Ortodoksisuuden ja taiteen vuorovaikutus',
      'sv-FI': 'Växelverkan mellan ortodoxin och konsten',
    },
    {
      id: '18-253489',
      'fi-FI': 'Ortodoksisuus, tiede ja media',
      'sv-FI': 'Ortodoxi, vetenskap och medier',
    },
  ],
}

export const getTopicsBySubject = subject => TOPICS[subject] || []
