import React from 'react'
import { withTranslation } from 'react-i18next'
import './node-score.scss'

const NodeScore = ({ hasScores, score, maxScore, nodeId, nodeSettings, titleKey, t }) => {
  const { timePoints, timedQuestion, duration } = nodeSettings
  const pointsFeedback =
    timedQuestion && timePoints
      ? timePoints
          .sort((tp1, tp2) => tp2.breakpoint - tp1.breakpoint)
          .map((timePoint, index) => {
            const prevBreakpoint = index === 0 ? duration : timePoints[index - 1].breakpoint
            return (
              <span key={`${nodeId}-${timePoint.key}`}>
                {prevBreakpoint}-{timePoint.breakpoint}
                s:
                {timePoint.point}p
              </span>
            )
          })
      : null
  return hasScores && typeof score === 'number' && typeof maxScore === 'number' && maxScore > 0 ? (
    <div>
      <div>{t(titleKey, { score, maxScore })}</div>
      {timedQuestion ? <div className="yo-node-score__time-question">{pointsFeedback}</div> : null}
    </div>
  ) : null
}

NodeScore.defaultProps = {
  titleKey: 'points',
}

export default withTranslation(['NodeReview'])(NodeScore)
