// Actions.
const CLEAR = 'dialog/CLEAR'
const SET = 'dialog/SET'
const SET_VALUE = 'dialog/SET_VALUE'

const initialState = {
  values: {},
}

// Reducer.
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.data,
      }

    case SET_VALUE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.key]: action.value,
        },
      }

    case CLEAR:
      return initialState

    default:
      return state
  }
}

// Action creators.
export function setDialog(data) {
  return {
    type: SET,
    data,
  }
}

export const setDialogValue = (key, value) => ({
  key,
  type: SET_VALUE,
  value,
})

export function clearDialog() {
  return {
    type: CLEAR,
  }
}
