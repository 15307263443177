import { reject, isNil } from 'ramda'
import { getTopicsBySubject } from '../constants/definitions/topics'
import { nodeTypes } from '../constants/definitions/entities/entities.ts'
import * as ACTIONS from '../constants/definitions/trackingActions'
/**
 * Adds a widget-spcific prefix to the given event name.
 */
const getPrefixedEventName = eventName => `tehtävä-ui.${eventName}`

/**
 * Tracks a single event through yleAnalytics.
 *
 * @param eventName
 */
export const trackEvent = eventName => {
  const { yleTehtavaApp } = window
  if (yleTehtavaApp && yleTehtavaApp.yleAnalytics) {
    yleTehtavaApp.yleAnalytics.trackEvent(getPrefixedEventName(eventName))
  }
}

// Helpers to get correct string for eventName
const decodeDisplayNumber = string =>
  string
    .replace(/\)$/, '')
    .replace(/\.$/, '')
    .replace(/\+/g, '')
    .trim()

const getDisplayNumber = (node, parent) => {
  const parentNumber =
    parent && parent.content && parent.content.displayNumber
      ? decodeDisplayNumber(parent.content.displayNumber)
      : undefined
  const nodeNumber =
    node && node.content && node.content.displayNumber
      ? decodeDisplayNumber(node.content.displayNumber)
      : undefined
  if (!parentNumber && !nodeNumber) {
    return undefined
  }
  if (parentNumber && nodeNumber) {
    return `${parentNumber}_${nodeNumber}`
  }
  return parentNumber || nodeNumber
}

const getTopicName = (subject, id) =>
  getTopicsBySubject(subject)
    .filter(x => x.id === id)
    .map(x => x['fi-FI'])

const getContentString = (classification, examName) => {
  const { subject, year, semester } = classification
  const contentArr = [subject, year, semester]
  if (!subject) {
    contentArr.push(examName)
  }
  return reject(isNil, contentArr).join(';')
}

const getPageInfo = (isDefaultCarousel, embedOptions, node) => {
  const { subject } = node.examClassification
  let page
  if (isDefaultCarousel) {
    page = 'harjoittele'
  } else if (embedOptions.examId) {
    page = node.examClassification.exam_type
  } else if (embedOptions.tags) {
    const topics = embedOptions.tags.map(tag => getTopicName(subject, tag))
    page = topics.join(';')
  }
  return page
}

const getQuestionInfo = (node, parent) => {
  const questionNumber = getDisplayNumber(node, parent)
  return questionNumber ? `tehtävä${questionNumber}` : undefined
}

const getDefaultCarouselChoices = (isDefaultCarousel, defaultCarouselSelection) => {
  if (!isDefaultCarousel || !defaultCarouselSelection) {
    return null
  }
  const { tags, topics, metaOptions, questionTypes } = defaultCarouselSelection
  const displayTags = tags.length > 0 ? tags.join(';') : null
  const displayTopics = topics.length > 0 ? topics.join(';') : null
  const displayOptions = metaOptions.length > 0 ? metaOptions.join(';') : null
  const displayTypes = questionTypes.length > 0 ? questionTypes.join(';') : null

  return reject(isNil, [displayTags, displayTopics, displayOptions, displayTypes]).join(';')
}

export const getNodeTrackingString = ({
  node,
  isDefaultCarousel,
  embedOptions,
  action,
  parent,
}) => {
  const questionType = nodeTypes[node.nodeTypeId].name
  const content = getContentString(node.examClassification, node.examName)
  const page = getPageInfo(isDefaultCarousel, embedOptions, node)
  const question = getQuestionInfo(node, parent)
  const combinedContent = reject(isNil, [content, question, questionType]).join(';')

  return page ? `${page}.${combinedContent}.${action}` : `${combinedContent}.${action}`
}

export const getExamTrackingString = (exam, rootNode, action) => {
  const examType = exam.exam_type
  const content = getContentString(exam, rootNode.content.title)
  return `${examType}.${content}.${action}`
}

export const getDefaultCarouselTrackingString = (selection, exam) => {
  const choices = getDefaultCarouselChoices(true, selection)
  const content = getContentString(exam)
  const combinedContent = reject(isNil, [content, choices]).join(';')
  return `harjoittele.${combinedContent}.${ACTIONS.SEARCH}`
}
