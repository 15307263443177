import React from 'react'
import { withTranslation } from 'react-i18next'
import './LoginWarning.scss'
import { Notification } from '@yleisradio/yds-components-react'
import { LoginLink } from './Login'

const LoginWarning = ({ t, className = '' }): React.ReactElement => {
  return (
    <div className={['yo-login-warning', className].join(' ')}>
      <Notification level="info" variant="primary">
        {t('yle-tunnus-login-required')}
        <LoginLink className="yo-login-warning__link" data-testid="login-required-link">{`${t(
          'login-long',
        )}.`}</LoginLink>
      </Notification>
    </div>
  )
}

export default withTranslation(['YleTunnus'])(LoginWarning)
