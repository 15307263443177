import questionTypes from './questionTypes'
import {
  MATRICULATION_EXAM_TYPE,
  commonSubjectOptions,
  subjectsWithQuestionTypeOptions,
  subjectsWithMaterialOption,
  subjectsWithTitleEssayOption,
} from '../exams'
import { FieldType, FieldTypeEditorFormatter } from './types'

export const titleEssayQuestion: FieldType = {
  name: 'titleEssay',
  displayName: 'Otsikkoessee',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
  examTypeFilter: [MATRICULATION_EXAM_TYPE],
  isSeparator: true,
  subjectFilter: subjectsWithTitleEssayOption,
}

export const matriculationExamExerciseType: FieldType = {
  name: 'questionType',
  displayName: 'Question type',
  editorFormatter: FieldTypeEditorFormatter.select,
  examTypeFilter: [MATRICULATION_EXAM_TYPE],
  inline: false,
  options: questionTypes,
  subjectFilter: subjectsWithQuestionTypeOptions,
}

export const overrideTitle: FieldType = {
  name: 'overrideTitle',
  displayName: 'Override title',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
  examTypeFilter: [MATRICULATION_EXAM_TYPE],
}

export const materialQuestion: FieldType = {
  name: 'material',
  displayName: 'Aineistoa sisältävä',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
  examTypeFilter: [MATRICULATION_EXAM_TYPE],
  isSeparator: true,
  subjectFilter: subjectsWithMaterialOption,
}

export const jokerQuestion: FieldType = {
  name: 'joker',
  displayName: 'Jokeritehtävä',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
  examTypeFilter: [MATRICULATION_EXAM_TYPE],
  subjectFilter: commonSubjectOptions,
}
