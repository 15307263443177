export default {
  'education-assignment': {
    icon: null,
    text: null,
  },
  other: {
    icon: null,
    text: null,
  },
  'yo-koe': {
    icon: 'logoYTL',
    text: 'YLIOPPILASTUTKINTOLAUTAKUNTA \n STUDENTEXAMENSNÄMNDEN',
  },
}
