import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import * as icons from '../../icons'
import './icon.scss'

/**
 * Component for displaying SVG icons.
 */
const Icon = ({ className, fill, height, icon, size, title, width }) => {
  // Capitalize the icon name.
  const componentName = icon.charAt(0).toUpperCase() + icon.slice(1)
  // Destructure the icon component to a variable.
  const { [componentName]: IconComponent } = icons

  // Make sure the icon exists.
  if (typeof IconComponent === 'undefined') {
    return null
  }

  // Set color and dimensions for the icon.
  const iconProps = {
    fill,
    width,
    height,
  }

  const iconClassNames = classnames(
    'yo-icon',
    {
      'yo-icon--small': size === 'small',
    },
    className,
  )

  return (
    <div className={iconClassNames} title={title}>
      <IconComponent {...iconProps} />
    </div>
  )
}

Icon.propTypes = {
  // String representation of the icon to be used.
  icon: PropTypes.string.isRequired,
  // Fill color for the icon.
  fill: PropTypes.string,
  className: PropTypes.string,
}

Icon.defaultProps = {
  fill: undefined,
  className: undefined,
}

export default Icon
