import { combineReducers } from 'redux'
import carousel from './modules/carousel/carousel'
import choices from './modules/choices/choices'
import dialog from './modules/dialog/dialog'
import dropdown from './modules/dropdown/dropdown'
import editing from './modules/editing/editing'
import editor from './modules/editor/editor'
import embed from './modules/embed/embed'
import exam from './modules/exam/exam'
import exams from './modules/exams/exams'
import examsFiltersByExamType from './modules/filters/exams/byExamType'
import examsFiltersByLanguage from './modules/filters/exams/byLanguage'
import examFiltersByPublishStatus from './modules/filters/exams/byPublishStatus'
import examsFiltersBySearch from './modules/filters/exams/byExamSearch'
import examsFiltersBySubject from './modules/filters/exams/bySubject'
import examsFiltersBySeriesId from './modules/filters/exams/bySeriesId'
import nodes from './modules/nodes/nodes'
import options from './modules/options/options'
import paginations from './modules/paginations/paginations'
import resetFilters from './modules/filters/exams/resetFilters'
import toasts from './modules/toasts/toasts'
import loadState from './modules/loadState/loadState'
import questions from './modules/questions/questions'
import validation from './modules/validation/validation'
import defaultCarousel from './modules/defaultCarousel/defaultCarousel'
import reviews from './modules/reviews/reviews'
import yleTunnus from './modules/yleTunnus/yleTunnus'
import subjects from './modules/subjects/subjects'
import series from './modules/series/series'
import statistics from './modules/statistics/statistics'
import characters from './modules/characters/characters'
import webviewApi from '../webviewApi/reducer'
import confirmDialog from '../confirmDialog/reducer.ts'

const entities = combineReducers({
  choices,
  exams,
  nodes,
  options,
  paginations,
  reviews,
  characters,
})

const examsFilters = combineReducers({
  language: examsFiltersByLanguage,
  examType: examsFiltersByExamType,
  publishStatus: examFiltersByPublishStatus,
  seriesId: examsFiltersBySeriesId,
  search: examsFiltersBySearch,
  subject: examsFiltersBySubject,
})

const rootReducer = combineReducers({
  carousel,
  dialog,
  dropdown,
  editing,
  editor,
  embed,
  entities,
  exam,
  examsFilters,
  loadState,
  resetFilters,
  toasts,
  questions,
  validation,
  yleTunnus,
  defaultCarousel,
  subjects,
  series,
  statistics,
  webviewApi,
  confirmDialog,
})

export default rootReducer
