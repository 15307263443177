import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { createReview, getReviews } from '../../../redux/modules/reviews/reviews'
import {
  getExamState,
  getOptionsOfFirstChildNodeOfExam,
} from '../../../redux/modules/exam/selectors'
import Review from './Review'
import './reviews.scss'
import Button from '../../elements/Button/Button.tsx'
import PrimaryHeader from '../../elements/Typography/PrimaryHeader.tsx'
import { getAllDescendantIds } from '../../../redux/modules/node/node.ts'

const ReviewContainer = ({
  reviews,
  actions,
  enableShare,
  examType,
  exam,
  descs,
  optionsOfFirstChildNodeOfExam,
}) => {
  const reviewComponents = reviews.map(review => (
    <Review enableShare={enableShare} key={review.id} review={review} />
  ))

  return (
    <div className="yo-reviews">
      <PrimaryHeader>Reviews</PrimaryHeader>
      <div>
        {reviewComponents}
        <Button
          attributes={{ 'data-testid': 'add-review-button' }}
          onClick={actions.onClickAddReview}
          text="Add review"
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  reviews: getReviews(state),
  enableShare: getExamState(state).enableShare,
  examType: getExamState(state).exam_type,
  exam: getExamState(state),
  optionsOfFirstChildNodeOfExam: getOptionsOfFirstChildNodeOfExam(state),
  descs: getAllDescendantIds(state.entities.nodes, getExamState(state).uuid),
})

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators({ createReview }, dispatch)
  return {
    actions: {
      onClickAddReview: () => actions.createReview(),
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReviewContainer))
