import { find, propEq, reverse, range } from 'ramda'
import i18n from '../../../utils/i18n'
import {
  LISTENING_COMPREHENSION,
  categoryOptions,
  getSubjectOptions,
  semesterOptions,
  subjectsWithCategoryOptions,
} from '../../../constants/definitions/exams'

const DEFAULT_LANGUAGE = 'en'

export const translateOptions = (options, language = null) => {
  const en = i18n.getFixedT(DEFAULT_LANGUAGE)
  let t
  if (!language) {
    t = key => en(`common:${key}`)
  } else {
    const lang = i18n.getFixedT(language)
    t = key => lang(`common:${key}`)
  }

  return options.map(option => ({
    id: option.id,
    name: t(option.id),
  }))
}

export const generateTitle = (exam, node) => {
  const { category, semester, subject, year } = exam

  // Title should be overridden with a custom one.
  if (node && node.settings && node.settings.overrideTitle === true) {
    return null
  }

  if (!(semester && subject && year)) {
    return null
  }

  // Get the human-readable name of the category.
  // We need to use `translateOptions()` to get the category objects in a proper shape.
  const categories = translateOptions(categoryOptions, exam.language)
  const categoryName =
    category === LISTENING_COMPREHENSION
      ? find(propEq('id', category), categories).name.toLowerCase()
      : ''

  // Get the human-readable name of the subject
  // Since subjects are already shaped { id: ..., name: ... }, we can just use them.
  const subjects = getSubjectOptions(exam.exam_type, exam.language)
  const subjectName = find(propEq('id', subject), subjects).name

  // Get the human-readable name of the semester
  // We need to use `translateOptions()` to get the semester objects in a proper shape.
  const semesters = translateOptions(semesterOptions, exam.language)
  const semesterName = find(propEq('id', semester), semesters).name.toLowerCase()

  // Generate the title
  return `${subjectName} ${categoryName} ${semesterName} ${year}`.replace(/\s+/g, ' ').trim()
}

export const subjectHasCategories = exam =>
  subjectsWithCategoryOptions.map(subject => subject.id).includes(exam.subject)

export const getSeriesOptions = (series, exam) => {
  const uuids = exam.series_uuids ? exam.series_uuids : []
  return series
    .filter(x => !uuids.includes(x.uuid))
    .map(x => ({
      id: x.uuid,
      name: x.name,
    }))
}

export const yearOptions = reverse(range(2006, new Date().getFullYear() + 1)).map(year => ({
  id: year,
  name: year,
}))
