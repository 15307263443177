import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'

import classNames from 'classnames'

import CheckboxInput from './CheckboxInput'
import DateTimePickerInput from './DateTimePickerInput'
import NumberInput from './NumberInput'
import RadioInput from './RadioInput'
import RangeInput from './RangeInput'
import TextArea from './TextArea.tsx'
import TextInput from './TextInput'

import { setInputValidation } from '../../../redux/modules/validation/validation'

import './input.scss'
import { getValidation } from '../../../redux/modules/validation/selectors'

// Create unique DOM id using type, label and suffix
const getInputId = ({ idSuffix, label, type }) => {
  const suffix = idSuffix && `-${idSuffix}`

  const id = `yo-${type}-input--${label}${suffix}`
    .replace(/ /g, '-')
    .toLowerCase()
    .trim()
  return id
}

const withValidation = WrappedInput => {
  const WithValidation = ({ ...props }) => {
    const { label, idSuffix = '', type, onChange, validation, t } = props
    const id = props.label && getInputId({ idSuffix, label, type })
    const stateValidationInput =
      validation && props.stateValidation.find(input => input.inputId === id)
    const isInvalid = stateValidationInput ? !stateValidationInput.isValid : false

    const onChangeWithValidation = val => {
      if (validation) {
        const isValid = validation.rule(val)
        props.setInputValidation({ inputId: id, isValid })
      }

      if (onChange) {
        onChange(val)
      }
    }

    return (
      <>
        <WrappedInput {...props} id={id} isInvalid={isInvalid} onChange={onChangeWithValidation} />
        {isInvalid && validation?.message && (
          <div className="yo-input__description-error">{t(validation.message)}</div>
        )}
      </>
    )
  }
  WithValidation.displayName = 'WithValidation'
  return WithValidation
}
class Input extends React.PureComponent {
  render() {
    const { className, showCorrectAnswers, type, isInvalid, isSeparator, inline } = this.props
    let component = null

    switch (type) {
      case 'checkbox':
        component = <CheckboxInput {...this.props} />
        break

      case 'radio':
        component = <RadioInput {...this.props} />
        break

      case 'text':
        component = <TextInput {...this.props} />
        break

      case 'textarea':
        component = <TextArea {...this.props} />
        break

      case 'range':
        component = <RangeInput {...this.props} />
        break

      case 'dateTimePicker':
        component = <DateTimePickerInput {...this.props} />
        break

      case 'number':
        component = <NumberInput {...this.props} />
        break

      default:
        return null
    }

    const inputParentClassNames = classNames('yo-input', `yo-input--${type}`, className, {
      'yo-input__answer': showCorrectAnswers,
      'yo-input--separator': isSeparator === true,
      'yo-input--inline': inline,
      'yo-input--error': isInvalid,
    })

    return <div className={inputParentClassNames}>{component}</div>
  }
}

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators({ setInputValidation }, dispatch)

  return {
    setInputValidation: ({ inputId, isValid }) => {
      actions.setInputValidation({ inputId, isValid })
    },
  }
}

const mapStateToProps = state => {
  return {
    stateValidation: getValidation(state),
  }
}

export default compose(
  withTranslation(['Input']),
  connect(mapStateToProps, mapDispatchToProps),
  withValidation,
)(Input)
