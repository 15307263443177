import { isValidNumber } from '../../../utils/common'

function getCaseSenstive(string = '', isCaseSensitive) {
  return isCaseSensitive ? string : string.toUpperCase()
}
// Correct value can be a string or multiple ones separated by "|".
const getGapQuestionCorrectValues = option =>
  option.correct
    .split('|')
    .filter(value => value !== '')
    .map(value => getCaseSenstive(value, option.isCaseSensitive))
    .map(x => x.trim())

function correctAnswerCount(node, options) {
  // Whether user has answered to the node.
  if (!node.value) {
    return 0
  }

  // Get answers made by user from node.
  const userAnswers = node.value
  let correctCount = 0

  if (node.nodeTypeId === 'MULTIPLE_CHOICE_QUESTION') {
    options.forEach(option => {
      // Check if option is correct and included in answer made by the user.
      if (
        option.correct === true &&
        Object.keys(userAnswers).includes(option.id.toString()) &&
        typeof node.value[option.id] !== 'undefined'
      ) {
        correctCount += 1
      }
    })

    return correctCount
  }

  if (node.nodeTypeId === 'CUSTOMIZED_FEEDBACK') {
    let pointCount = 0
    options.forEach(option => {
      // Check if option is correct and included in answer made by the user.
      if (
        !node.settings.customizedPoints &&
        option.correct === true &&
        Object.keys(userAnswers).includes(option.id.toString()) &&
        typeof node.value[option.id] !== 'undefined'
      ) {
        correctCount += 1
      }
      // Calculate customized points, if in use
      else if (
        node.settings.customizedPoints &&
        Object.keys(userAnswers).includes(option.id.toString()) &&
        typeof node.value[option.id] !== 'undefined'
      ) {
        pointCount += isValidNumber(Number(option.points)) ? Number(option.points) : 0
      }
    })

    if (node.settings.requireAllCorrect) {
      const correctAnswersCount = options.filter(option => option.correct).length
      return correctCount === correctAnswersCount ? 1 : 0
    }

    return node.settings.customizedPoints ? pointCount : correctCount
  }

  if (node.nodeTypeId === 'MULTIPLE_CHOICE_GAP_QUESTION') {
    options.forEach(option => {
      option.choices
        // Filter out incorrect choices.
        .filter(choice => choice.correct === true)
        .forEach(choice => {
          // Check if user has answered for this option and the answer is the correct one.
          if (option.id in userAnswers && userAnswers[option.id] === choice.text) {
            correctCount += 1
          }
        })
    })

    if (node.settings.requireAllCorrect) {
      return correctCount === options.length ? 1 : 0
    }

    return correctCount
  }
  if (node.nodeTypeId === 'GAP_QUESTION') {
    options
      // Filter out options without correct answer set.
      .filter(option => typeof option.correct === 'string')
      .forEach(option => {
        // Get array of correct values.
        // In this case correct value can be a string or multiple ones separated by "|".
        const correctValues = getGapQuestionCorrectValues(option)
        const userAnswer = getCaseSenstive(userAnswers[option.id], option.isCaseSensitive)

        if (correctValues.includes(userAnswer)) {
          correctCount += 1
        }
      })

    return correctCount
  }

  if (node.nodeTypeId === 'COMBINE_QUESTION') {
    const answers = Object.keys(userAnswers)
    const correctAnswersCount = answers.filter(
      answer => answer.toString() === userAnswers[answer].toString(),
    ).length

    if (node.settings.requireAllCorrect) {
      return correctAnswersCount === answers.length ? 1 : 0
    }

    return correctCount + correctAnswersCount
  }

  if (node.nodeTypeId === 'ORDER_QUESTION') {
    const answers = Object.values(userAnswers)
    const correctAnswersCount = answers.filter(answer => answer.correct).length

    // Only count as correct if all answers are in correct order
    if (node.settings.requireAllCorrect) {
      return correctAnswersCount === answers.length ? 1 : 0
    }

    // Any correct order option gets a point
    return correctCount + correctAnswersCount
  }

  return null
}

const getTimedQuestionPoint = ({ timer, settings }) => {
  if (!settings.timePoints) {
    return 0
  }
  const timedPoint = settings.timePoints
    .sort((tp1, tp2) => tp2.breakpoint - tp1.breakpoint)
    .find(tp => timer >= tp.breakpoint)

  return timedPoint ? Number(timedPoint.point) : 0
}

export const getScore = (node, options) => {
  if (node.submitted !== true) {
    return null
  }
  const hasPointSettings = node.settings && (node.settings.points || node.settings.customizedPoints)
  let points = null
  let score = 0

  // Get points settings from node (how many points one question part is worth of).
  if (hasPointSettings) {
    points = node.settings.timedQuestion
      ? getTimedQuestionPoint(node)
      : Number(node.settings.points) || 1
  }

  // Get number of correct answers for the node.
  const correctAnswers = correctAnswerCount(node, options)

  // Calculate total points for the node.
  if (points && correctAnswers) {
    score = Number((correctAnswers * points).toFixed(2))
  }

  return score
}

export const getMaxScore = (node, options) => {
  // calling this function for nodes that haven't been submitted often breaks their scoring
  if (!node.submitted) {
    return null
  }

  const { settings } = node

  // Make sure node has points set.
  if (
    typeof settings === 'undefined' ||
    !settings ||
    ((typeof settings.points === 'undefined' || !settings.points) &&
      (typeof settings.customizedPoints === 'undefined' || !settings.customizedPoints))
  ) {
    return null
  }

  if (settings.timedQuestion) {
    return settings.timePoints && settings.timePoints.length > 0
      ? Math.max(...settings.timePoints.map(tp => Number(tp.point)))
      : 0
  }

  const pointsByOptions = Number((node.options.length * settings.points).toFixed(2))
  const pointsByQuestion = Number(settings.points)

  switch (node.nodeTypeId) {
    case 'MULTIPLE_CHOICE_QUESTION':
    case 'CUSTOMIZED_FEEDBACK': {
      // points
      // points + allow multiple answers + require all correct
      if (!settings.customizedPoints && (!settings.demandCorrect || settings.requireAllCorrect)) {
        return pointsByQuestion
      }

      const optionsForNode = Object.values(options).filter(x => x.parent === node.id)
      const correctOptions = optionsForNode.filter(x => x.correct === true)

      // points + allow multiple answers
      if (!settings.customizedPoints) {
        return correctOptions.length * pointsByQuestion
      }

      const allOptionPoints = optionsForNode.filter(x => x.points).map(x => Number(x.points))
      const correctOptionPoints = correctOptions.map(x => Number(x.points))

      if (settings.customizedPoints) {
        // custom points + allow multiple answers
        if (settings.demandCorrect) {
          return correctOptionPoints.reduce((a, b) => a + b, 0)
        }

        // custom points
        return Math.max(...correctOptionPoints)
      }

      // negative points + allow multiple answers
      if (settings.demandCorrect) {
        return Number(
          allOptionPoints
            .filter(x => x > 0)
            .reduce((a, b) => a + b, 0)
            .toFixed(2),
        )
      }

      // negative points
      return Math.max(...allOptionPoints)
    }
    case 'ORDER_QUESTION':
    case 'COMBINE_QUESTION':
    case 'GAP_QUESTION':
    case 'MULTIPLE_CHOICE_GAP_QUESTION':
      return settings.requireAllCorrect ? pointsByQuestion : pointsByOptions

    default:
      return null
  }
}
