import classnames from 'classnames'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateEditing } from '../../../redux/modules/editing/editing'
import { toggleEditorSize } from '../../../redux/modules/editor/editor'
import { IconButton } from '../../elements/IconButtons/IconButtons'
import './sidebar-header.scss'
import TertiaryHeader from '../../elements/Typography/TertiaryHeader.tsx'

const SidebarHeader = ({ title, onClickClose, onClickResize, size, t }) => {
  const toggleIconClass = classnames('yo-sidebar-header__resize-button', {
    'yo-sidebar-header__resize-button--expanded': size === 'large',
  })

  return (
    <div className="yo-sidebar-header">
      <TertiaryHeader>{title}</TertiaryHeader>
      <div className="yo-sidebar-header__buttons">
        <span className={toggleIconClass}>
          <IconButton
            height="18"
            icon="swap"
            onClick={onClickResize}
            title={t('common:resize')}
            width="18"
          />
        </span>
        <span className="yo-sidebar-header__close-button">
          <IconButton
            height="18"
            icon="circledCross"
            onClick={onClickClose}
            title={t('common:close')}
            width="18"
          />
        </span>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  size: state.editor.size,
})

const mapDispatchToProps = dispatch => ({
  onClickClose: () => dispatch(updateEditing({})),
  onClickResize: () => dispatch(toggleEditorSize()),
})

export default withTranslation(['SidebarHeader'])(
  connect(mapStateToProps, mapDispatchToProps)(SidebarHeader),
)
