import React from 'react'
import { connect } from 'react-redux'
import { NodeState } from 'constants/definitions/entities/types'
import Fragment from '../../node/Fragment/Fragment'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import { updateValue } from '../../../redux/modules/node/actions'

interface Props {
  node: NodeState
  options: Array<{
    id: number
    placeholder?: string
  }>
  onChange: (value: string, optionId: string) => void
  value?: { [key: number]: string }
  examIsOpen: boolean
}

const TextboxQuestion: React.FC<Props> = ({ node, options, onChange, value, examIsOpen }) => {
  return (
    <Fragment displayNumber={node?.content?.displayNumber}>
      <RichTextContent
        content={node?.content?.text}
        props={{ options, onChange, value, disabled: node?.submitted || !examIsOpen }}
      />
    </Fragment>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value: string, optionId: string) => {
    dispatch(updateValue(ownProps.id, { [optionId]: value }, ownProps.evaluateCompleted))
  },
})

export default connect(null, mapDispatchToProps)(TextboxQuestion)
