import React from 'react'
import defaultProps from './defaultProps'

const Right = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M11,7v2H3v2h8v2l3-3L11,7z M15,2.6V17.4c0,0.551,0.448,0.6,1,0.6c0.553,0,1-0.049,1-0.6V2.6C17,2.047,16.553,2,16,2 C15.448,2,15,2.047,15,2.6z"
      fill={fill}
    />
  </svg>
)

Right.defaultProps = defaultProps

export default Right
