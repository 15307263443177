import React from 'react'
import ReactPaginate from 'react-paginate'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as R from 'ramda'
import { LIMIT_ARRAY } from '../../../redux/modules/paginations/paginations'
import Select from '../../elements/Select/Select'
import './paginate.scss'

const SelectLimit = ({ onChangeLimit, paginations, t }) => {
  const { limit, countTotal } = paginations
  if (countTotal && countTotal > 0) {
    const limitOptions = LIMIT_ARRAY.map(x => ({ id: x, name: x }))

    return (
      <Select
        className="yo-__limit-select"
        defaultValue={limit}
        label={t('common:view')}
        onChange={onChangeLimit}
        options={limitOptions}
      />
    )
  }

  return null
}

const Paginate = ({ paginations, onChangePage, onChangeLimit, t }) => {
  const { limit, page, countTotal } = paginations
  // hide pagination if total count is bigger than smallest value in limit array
  const hidePaginate = R.reduce(R.min, Infinity, LIMIT_ARRAY) > countTotal

  if (!countTotal || hidePaginate) {
    return null
  }

  return (
    <div>
      <SelectLimit onChangeLimit={onChangeLimit} paginations={paginations} t={t} />
      <ReactPaginate
        activeClassName="yo-pagination-page--active"
        breakClassName="yo-pagination-page"
        containerClassName="yo-pagination"
        disableInitialCallback
        forcePage={page}
        marginPagesDisplayed={2}
        nextClassName="yo-pagination-page"
        nextLabel={t('next')}
        nextLinkClassName="yo-pagination-page-link"
        onPageChange={onChangePage}
        pageClassName="yo-pagination-page"
        pageCount={Math.ceil(countTotal / limit)}
        pageLinkClassName="yo-pagination-page-link"
        pageRangeDisplayed={3}
        previousClassName="yo-pagination-page"
        previousLabel={t('previous')}
        previousLinkClassName="yo-pagination-page-link"
        subContainerClassName="yo-pagination-pages"
      />
    </div>
  )
}

const mapStateToProps = state => ({
  paginations: state.entities.paginations,
})

export default withTranslation(['CarouselHeader'])(connect(mapStateToProps)(Paginate))
