import { pick, without } from 'ramda'
import {
  DEFAULT_EXAM_LANGUAGE,
  examSettings,
  DEFAULT_EXAM_TYPE,
} from '../../../constants/definitions/exams'

// Actions
import { HANDLE_EXAM_DATA } from '../../middleware/examService/examService'

export const UPDATE = 'exam/UPDATE'
export const UPDATE_TYPE = 'exam/UPDATE_TYPE'
export const REMOVE_SERIES_ID = 'exam/REMOVE_SERIES_ID'
export const CLEAR_EXAM = 'exam/CLEAR_EXAM'

const TAINT = 'exam/TAINT'
const UNTAINT = 'exam/UNTAINT'
export const UPDATE_AVERAGE_SCORE = 'exam/UPDATE_AVERAGE_SCORE'

// Reducer
const defaultState = {
  language: DEFAULT_EXAM_LANGUAGE,
  exam_type: DEFAULT_EXAM_TYPE,
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE:
      return Object.assign({}, state, action.exam)

    case UPDATE_TYPE:
      return {
        // Keep only those settings that belong to the exam type.
        ...pick([...Object.keys(examSettings[action.examType]), 'uuid'], state),
        exam_type: action.examType,
      }

    case HANDLE_EXAM_DATA:
      return Object.assign({}, defaultState, action.data.exam)

    case TAINT:
      return { ...state, tainted: true }

    case UNTAINT:
      return { ...state, tainted: false }

    case REMOVE_SERIES_ID:
      return {
        ...state,
        series_uuids: without(action.id, state.series_uuids),
      }

    case UPDATE_AVERAGE_SCORE:
      return {
        ...state,
        averageScore: action.averageScore,
      }

    case CLEAR_EXAM:
      return defaultState

    default:
      return state
  }
}

// Action creators
export const updateExamType = examType => ({
  examType,
  type: UPDATE_TYPE,
})

export const updateExam = exam => ({
  type: UPDATE,
  exam,
})

export const taintExam = () => ({
  type: TAINT,
})

export const untaintExam = () => ({
  type: UNTAINT,
})

export const removeSeriesId = id => ({
  type: REMOVE_SERIES_ID,
  id,
})

export const updateExamAverageScore = averageScore => ({
  type: UPDATE_AVERAGE_SCORE,
  averageScore,
})

export const clearExam = () => ({
  type: CLEAR_EXAM,
})
