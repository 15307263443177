import config from '../config'

const fetchTags = yleIds => {
  if (!yleIds) {
    return Promise.reject(new Error('No tags found'))
  }

  const { meta_api_app_id: apiId, meta_api_app_key: apiKey, meta_api_url: metaApiUrl } = config
  const apiQuery = yleIds.join(',')
  const apiUrl = `${metaApiUrl}concepts.json?yle_id=${apiQuery}&app_key=${apiKey}&app_id=${apiId}`

  return fetch(apiUrl)
    .then(result => {
      if (result.ok) {
        return result.json()
      }
      throw new Error('Meta-API call failed')
    })
    .then(result => result.data)
}

export default fetchTags
