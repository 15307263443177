import { NodeState } from './types'

export const evaluateCompletionForMultipleChoiceGapQuestion = (state: NodeState) => {
  let requireN = state.options?.length

  if (state.settings && state.settings.requireN) {
    requireN = parseInt(state.settings.requireN, 10)
  }

  // filter out empty and null values
  const values = Object.keys(state.value!).filter(
    key => String(state.value?.[key]) !== '' && state.value?.[key] !== null,
  )

  return values.length === requireN
}

export const evaluateCompletionValueIsObject = (state: NodeState) => typeof state.value === 'object'

export const evaluateCompletedWhenQuestionCountMatchesAnswerCount = (state: NodeState) =>
  Object.keys(state.value!).length === state.options?.length

export const evaluateCompletedWhenQuestionCountMatchesRequiredAnswersCount = (state: NodeState) => {
  let requiredVotes = state.settings?.scoredVoteScoring?.max ?? 0
  if (typeof requiredVotes === 'string') {
    requiredVotes = parseInt(requiredVotes, 10)
  }
  return Object.keys(state.value!).length === requiredVotes
}

export const evaluateCompletedWhenValueIsNotEmpty = (state: NodeState) => {
  // Should have value with length to be considered as completed.
  const value = state.value?.[state.id!]
  return value !== null && value.length
}
