import React from 'react'
import { Textarea, TextareaDSProps } from '@yleisradio/yds-components-react'

interface Props extends TextareaDSProps {
  attributes: { [key: string]: string }
  defaultValue: string
  disabled: boolean
  textareaSize: string
}

const TextArea = ({
  attributes,
  disabled,
  id,
  label,
  onChange,
  placeholder,
  textareaSize = '10',
  value,
}: Props) => {
  return (
    <Textarea
      {...attributes}
      id={id}
      isDisabled={disabled}
      label={label}
      labelOptions={{ isHidden: true }}
      onChange={onChange}
      placeholder={placeholder}
      rows={parseInt(textareaSize, 10)}
      value={value}
    />
  )
}

export default TextArea
