import React from 'react'
import InputExtended from '../../elements/Input/InputExtended'

const OptionFormItem = ({ option, optionField, onChange }) => {
  const attributes = { 'data-testid': `option-field__${optionField.name}` }

  const inputProps = {
    attributes,
    type: optionField.editorFormatter,
    label: optionField.displayName,
    description: optionField.description || null,
    idSuffix: option.id,
    textareaSize: optionField.textareaSize,
    name: option.label,
    optional: optionField.optional,
    onChange: e => onChange({ e, fieldName: optionField.name }),
    validation: optionField.validation,
    width: optionField.displayWidth,
  }

  if (optionField.editorFormatter === 'checkbox') {
    inputProps.defaultChecked =
      option[optionField.name] !== undefined
        ? option[optionField.name] === true
        : optionField.defaultChecked
  } else {
    inputProps.defaultValue = option[optionField.name]
  }

  return <InputExtended {...inputProps} />
}

export default OptionFormItem
