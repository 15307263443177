import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { loggedIn } from '../GoogleLogin/GoogleLogin.tsx'

/**
 * Authenticated route component. If user is logged in renders component.
 * Otherwise redirects to login
 */
class AuthRoute extends React.Component {
  /**
   * Should only update when location changes since it's route component,
   * so that child components don't re-render unnecessarily
   */

  shouldComponentUpdate(nextProps) {
    return this.props.location !== nextProps.location
  }

  render() {
    const { component: Component, ...rest } = this.props
    const isLoggedIn = loggedIn()
    return (
      <Route
        {...rest}
        render={props =>
          isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect
              push
              to={`/login?redirectTo=${encodeURIComponent(this.props.location.pathname)}`}
            />
          )
        }
      />
    )
  }
}

export default withRouter(AuthRoute)
