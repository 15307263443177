import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import LanguageSwitcher from './components/misc/LanguageSwitcher/LanguageSwitcher.tsx'
import YdsAutoThemeProvider from './components/misc/YdsAutoThemeProvider/YdsAutoThemeProvider.tsx'
import App from './components/App'
import configureStore from './redux'
import './index.scss'

/* eslint import/prefer-default-export: 0 */
export const store = configureStore()

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <YdsAutoThemeProvider>
        <LanguageSwitcher>
          <App />
        </LanguageSwitcher>
      </YdsAutoThemeProvider>
    </Provider>,
    document.getElementById('root') || document.createElement('div'),
  )
}

store.subscribe(() => {
  if (store.getState().loadState.loaded) {
    renderApp()
  }
})

renderApp()
