/* eslint-disable max-lines */
import uuid from 'uuid'
import { curry, uniq } from 'ramda'
import { generateNewExamState, resetExam } from '../../../utils/examHelpers'
import { getToken } from '../../../utils/tokenHelpers'
import { updateEditing } from '../../modules/editing/editing'
import { updateExam, updateExamAverageScore } from '../../modules/exam/exam'
import { clearCollapsedNodes, setOpenedForEditingAt } from '../../modules/editor/editor'
import {
  DELETE as DELETE_EXAM,
  fetchAuthorsExams,
  fetchExams,
  receiveExams,
} from '../../modules/exams/exams'
import {
  clearExamListLoading,
  setExamListLoading,
  setExamLoadState,
  setExamErrorState,
} from '../../modules/loadState/loadState'
import { PERSIST_TTL } from '../../../components/layout/Toaster/Toast'
import { addToast, flipToast } from '../../modules/toasts/toasts'
import { clearValidations } from '../../modules/validation/validation'
import { DELETE, GET, POST, apiURL, parseJSON } from '../dataService/apiV1'
import { serializeForAPI, serializeFromAPI } from '../dataService/stateSerializer'
import { updateTotalCount } from '../../modules/paginations/paginations'
import { applyAnswers, setMaxScores } from '../../modules/nodes/nodes'
import { isFetching, getPaginatedUrl } from '../../../utils/dataService'
import { receiveSubjects } from '../../modules/subjects/subjects'
import { uploadShareConfig } from '../shareService/shareService'
import { getShareConfig } from '../../modules/exam/selectors'

export const CREATE = 'exams/CREATE'
export const EXAM_STORED = 'common/EXAM_STORED'
export const HANDLE_EXAM_DATA = 'common/HANDLE_EXAM_DATA'
export const STORE_EXAM_TO_API = 'api/STORE_EXAM_TO_API'
export const DELETE_EXAM_ANSWERS = 'api/DELETE_EXAM_ANSWERS'

const DUPLICATE = 'exams/DUPLICATE'
const FETCH_AUTHORS_EXAMS = 'api/FETCH_AUTHORS_EXAMS'
const FETCH_EXAM_DATA = 'api/FETCH_EXAM_DATA'
const FETCH_EXAM_LIST = 'api/FETCH_EXAM_LIST'
const FETCH_PUBLISHED_EXAM = 'api/FETCH_PUBLISHED_EXAM'
const FETCH_EXAM_ANSWERS = 'api/FETCH_EXAM_ANSWERS'
const FETCH_SUBJECT_LIST = 'api/FETCH_SUBJECT_LIST'
const CREATE_TV_EPISODE = 'tv/CREATE_TV_EPISODE'
const FETCH_EXAM_LOGS = 'api/FETCH_EXAM_LOGS'
const FETCH_AVERAGE_SCORES = 'api/FETCH_AVERAGE_SCORES'

// Action creators
export const fetchExam = uuid => ({
  type: FETCH_EXAM_DATA,
  uuid,
})

export const fetchExamList = () => ({
  type: FETCH_EXAM_LIST,
})

export const fetchExamListAuthor = () => ({
  type: FETCH_AUTHORS_EXAMS,
})

export const fetchPublishedExam = uuid => ({
  type: FETCH_PUBLISHED_EXAM,
  uuid,
})

export const duplicateExam = (uuid, history) => ({
  type: DUPLICATE,
  uuid,
  history,
})

export const handleExamData = data => ({
  type: HANDLE_EXAM_DATA,
  data,
})

export const storeExamToAPI = () => ({
  type: STORE_EXAM_TO_API,
})

export const fetchExamAnswers = uuid => ({
  type: FETCH_EXAM_ANSWERS,
  uuid,
})

export const fetchAverageScores = uuid => ({
  type: FETCH_AVERAGE_SCORES,
  uuid,
})

export const fetchSubjectList = filters => ({
  type: FETCH_SUBJECT_LIST,
  filters,
})

export const createTvEpisode = (episode, history) => ({
  type: CREATE_TV_EPISODE,
  history,
  episode,
})

export const fetchExamLogs = uuid => ({
  type: FETCH_EXAM_LOGS,
  uuid,
})

export const deleteExamAnswers = uuid => ({
  type: DELETE_EXAM_ANSWERS,
  uuid,
})

export const handleLoadError = err => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

const examsFetcher = url => (url.match(/by-author/) ? fetchAuthorsExams : fetchExams)

const handleDeleteExamResponse = curry((store, resp) => {
  const fetcher = examsFetcher(window.location.href)
  if (resp.ok) {
    return store.dispatch(fetcher())
  }
  throw new Error('error deleting exam')
})

const examService = store => next => action => {
  next(action)
  //! Caution: non-functional programming paradigm ahead!
  // To satisfy our linting rules, we are not allowed to use consts in `case` blocks. So, we just
  // declare the variables here and assign them where needed.
  let authorUUID
  let newExam
  let newUUID
  let nodeUUID
  let toastUUID

  switch (action.type) {
    case CREATE:
      newExam = generateNewExamState()
      next(handleExamData(newExam.state))
      next(updateEditing({ parameters: newExam.uuid }))
      next(updateExam({ uuid: newExam.uuid }))
      next(setExamLoadState(newExam.uuid, true))
      next(clearValidations())
      break

    case DELETE_EXAM:
      return DELETE(`/exams/uuid/${action.uuid}.json`)
        .then(handleDeleteExamResponse(store))
        .catch(handleLoadError)

    case DUPLICATE:
      if (isFetching(store, action.uuid)) {
        return null
      }
      newUUID = uuid.v4()
      action.history.push({
        pathname: `/exam/${newUUID}/editor`,
        state: { duplicate: true },
      })
      next(setExamLoadState(newUUID))
      return GET(`/exams/uuid/${action.uuid}.json`)
        .then(parseJSON)
        .then(resp => {
          const exam = resetExam(newUUID, resp.data[0])
          next(handleExamData(serializeFromAPI([exam])))
          next(updateEditing({ parameters: uuid }))
          next(setExamLoadState(newUUID, true))
          next(clearValidations())
        })
        .catch(handleLoadError)

    case FETCH_AUTHORS_EXAMS:
      // eslint-disable-next-line
      authorUUID = window.location.href.split('/exams/by-author/')[1]
      return GET(`/exams/author/${authorUUID}.json?${getPaginatedUrl(store)}`)
        .then(next(setExamListLoading()))
        .then(parseJSON)
        .then(body => {
          next(receiveExams(body.data))
          next(updateTotalCount(body.meta.count_total))
          next(clearExamListLoading())
        })
        .catch(handleLoadError)

    case FETCH_EXAM_DATA:
      if (isFetching(store, action.uuid)) {
        return null
      }
      next(setExamLoadState(action.uuid))
      return GET(`/exams/uuid/${action.uuid}.json`)
        .then(parseJSON)
        .then(data => {
          next(handleExamData(serializeFromAPI(data.data)))
          next(updateEditing({ parameters: action.uuid }))
          next(setOpenedForEditingAt(new Date().toISOString()))
          next(setExamLoadState(action.uuid, true))
          next(clearCollapsedNodes())
          next(clearValidations())
        })
        .catch(handleLoadError)

    case FETCH_EXAM_LIST:
      next(setExamListLoading())
      return GET(`/exams?${getPaginatedUrl(store)}`)
        .then(parseJSON)
        .then(body => {
          next(receiveExams(uniq(body.data)))
          next(updateTotalCount(body.meta.count_total))
          next(clearExamListLoading())
        })
        .catch(handleLoadError)

    case FETCH_AVERAGE_SCORES:
      return fetch(apiURL(`/public/averagescores.json?exam_uuid=${action.uuid}`))
        .then(parseJSON)
        .then(({ data }) => {
          next(updateExamAverageScore(data[0]))
        })
        .catch(handleLoadError)

    case FETCH_PUBLISHED_EXAM:
      nodeUUID = action.uuid
      if (isFetching(store, nodeUUID)) {
        return null
      }
      next(setExamLoadState(nodeUUID))
      return fetch(apiURL(`/public/exams.json?uuid=${nodeUUID}`))
        .then(parseJSON)
        .then(data => {
          next(handleExamData(serializeFromAPI(data.data)))
          next(setExamLoadState(nodeUUID, true))
        })
        .catch(res => {
          if (res.status === 403) {
            next(setExamErrorState(nodeUUID, 'this-content-is-not-published'))
          } else if (res.status === 404) {
            next(setExamErrorState(nodeUUID, 'content-not-found'))
          }
          handleLoadError(res)
        })

    case STORE_EXAM_TO_API:
      toastUUID = uuid.v4()
      next(addToast('progress', 'Saving...', PERSIST_TTL, toastUUID))
      return POST(
        '/exams',
        JSON.stringify(
          serializeForAPI({
            token: getToken(),
            state: store.getState(),
          }),
        ),
      )
        .then(resp => {
          if (resp.ok) {
            next({ type: EXAM_STORED })
            next(setOpenedForEditingAt(new Date().toISOString()))

            // Check if published & share enabled, then uploads share config
            const { published, enableShare } = store.getState().exam
            if (published && enableShare) {
              getShareConfig(store.getState()).then(({ fileName, uploadData }) =>
                next(uploadShareConfig(uploadData, fileName)),
              )
            }
            return next(flipToast(toastUUID, 'success', 'Save successful'))
          }
          throw new Error('error saving exam')
        })
        .catch(() => next(flipToast(toastUUID, 'error', 'Save failed!', PERSIST_TTL)))

    case FETCH_EXAM_ANSWERS:
      return fetch(apiURL(`/answers/exam/${action.uuid}.json`), {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'include',
        method: 'GET',
      })
        .then(parseJSON)
        .then(data => {
          next(applyAnswers(data.data, store.getState()))
          next(setMaxScores(store.getState().entities.options))
        })
        .catch(handleLoadError)

    case FETCH_SUBJECT_LIST:
      return fetch(
        apiURL(
          `/public/subjects.json?exam_types=${action.filters.examType}&lang=${action.filters.lang}`,
        ),
      )
        .then(parseJSON)
        .then(body => {
          next(receiveSubjects(body.data))
        })
        .catch(handleLoadError)

    case CREATE_TV_EPISODE:
      next(handleExamData(action.episode.state))
      break

    case FETCH_EXAM_LOGS:
      return GET(`/exams/logs/${action.uuid}.json`)

    case DELETE_EXAM_ANSWERS: {
      const toastUuid = uuid.v4()
      next(addToast('progress', 'Deleting exam answers...', PERSIST_TTL, toastUuid))
      return DELETE(`/exams/answers/${action.uuid}.json`)
        .then(() => {
          next(flipToast(toastUuid, 'success', 'Answers removed'))
        })
        .catch(() => {
          next(
            flipToast(
              toastUuid,
              'error',
              'Something went wrong while removing answers',
              PERSIST_TTL,
            ),
          )
        })
    }

    default:
      break
  }

  return null
}

export default examService
