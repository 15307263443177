import React from 'react'

const CharacterLabels = ({ question }) => {
  const labels = question.optionsData.map(o => (
    <div key={o.id}>
      <div className="yo-characters-labels__item">{o.text}</div>
    </div>
  ))
  const charTitle = question.content.displayNumber
    ? `${question.content.displayNumber}. ${question.content.text}`
    : question.content.text
  return (
    <div className="yo-characters-labels">
      <div className="yo-characters-labels__item yo-characters-labels__question">{charTitle}</div>
      <div className="yo-characters-labels__item">Important</div>
      <div>{labels}</div>
    </div>
  )
}

export default CharacterLabels
