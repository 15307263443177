import React from 'react'
import prependHttp from 'prepend-http'
import './link.scss'

const Link = ({ url, text }) => {
  if (!url) {
    return null
  }

  return (
    <a
      className="yo-link"
      href={encodeURI(prependHttp(url))}
      rel="noopener noreferrer"
      target="_blank"
    >
      {text || url}
    </a>
  )
}

export default Link
