import React, { useState } from 'react'
import { connect } from 'react-redux'
import { values } from 'ramda'
import ExamDisclaimer from '../../elements/Disclaimer/Disclaimer'
import Tags from '../../elements/Tags/Tags'
import TableOfContents from '../TOC/TOC'
import ExamLogo from '../ExamLogo/ExamLogo'
import { getAllNodes } from '../../../redux/modules/nodes/selectors'
import ExamInfo from '../ExamInfo/ExamInfo'
import { VOTE } from '../../../constants/definitions/exams'
import { removeFalsy } from '../../../utils/common'
import VoteResultSettings from '../VoteResultSettings/VoteResultSettings.tsx'
import './exam-root.scss'

const authorToLink = ({ email, name }) => (
  <a
    className="yo-exam-root-authors__author-link"
    href={`mailto:${email}`}
    key={email}
    title={email}
  >
    {name}
  </a>
)

/**
 * The root component of an exam
 */
const ExamRoot = ({ authors, children, content, editing, nodes, settings, exam, id }) => {
  const [displayResults, setDisplayResults] = useState(false)

  const authorElems = (
    <div className="yo-exam-root-authors">{values(authors).map(authorToLink)}</div>
  )

  const editorDisplaySettings = removeFalsy([
    exam.exam_type === VOTE && (
      <VoteResultSettings
        displayResults={displayResults}
        id={id}
        key="vote-results-display"
        nodes={nodes}
        onSetDisplayResults={setDisplayResults}
      />
    ),
    // Add additional editor display settings here
  ])

  return (
    <div className="yo-exam-root">
      {editing ? authorElems : null}
      {editing && editorDisplaySettings.length ? (
        <div className="yo-exam-root__editor-display-settings-container">
          {editorDisplaySettings}
        </div>
      ) : null}
      <ExamDisclaimer />
      <ExamLogo />
      {editing && <Tags link nodes={nodes} readOnly />}
      <ExamInfo content={content} settings={settings} />
      <TableOfContents />
      {React.Children.map(children, child => React.cloneElement(child, { displayResults }))}
    </div>
  )
}

const mapStateToProps = state => ({
  nodes: getAllNodes(state),
  exam: state.exam,
})

export default connect(mapStateToProps)(ExamRoot)
