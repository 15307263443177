import React, { useState, useEffect, useCallback, FC } from 'react'
import './google-sign-in.scss'
import PrimaryHeader from '../../elements/Typography/PrimaryHeader'
import config from '../../../config'
import {
  getToken,
  getExpiration,
  setToken,
  getUserEmail,
  removeToken,
  isAboutToExpire,
} from '../../../utils/tokenHelpers'
import useInterval from '../../../hooks/useInterval/useInterval'

type GoogleLoginProps = {
  showLoginButton: boolean
}

type UseGoogleAuthParams = {
  onLogin: () => void
}

type UseGoogleAuth = boolean

export const loggedIn = () => {
  const token = getToken()
  return token && new Date() < getExpiration(token)
}

export const logout = () => {
  removeToken()
  window.google.accounts.id.disableAutoSelect()
}

export const showGoogleLoginPrompt = () => {
  window.google.accounts.id.prompt()
}

export const useGoogleAuth = ({ onLogin }: UseGoogleAuthParams): UseGoogleAuth => {
  const [isInitialized, setIsInitialized] = useState(false)

  const onTimeout = useCallback(() => {
    if (!isInitialized) {
      return
    }
    const token = getToken()
    if (!token) {
      return
    }
    if (isAboutToExpire(token)) {
      showGoogleLoginPrompt()
    }
  }, [isInitialized])

  useInterval(onTimeout, 10000)

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: config.google_client_id,
      auto_select: true,
      callback: ({ credential }) => {
        if (getUserEmail(credential).endsWith('@yle.fi')) {
          setToken(credential)
          onLogin()
        } else {
          logout()
          showGoogleLoginPrompt()
        }
      },
    })
    setIsInitialized(true)
    const token = getToken()
    if (!token || isAboutToExpire(token)) {
      showGoogleLoginPrompt()
    }
  }, [])

  return isInitialized
}

const GoogleLoginButton = () => {
  const renderButton = elem => {
    if (elem !== null) {
      window.google.accounts.id.renderButton(elem, { type: 'standard', size: 'large' })
    }
  }
  return <div ref={renderButton} />
}

export const GoogleLogin: FC<GoogleLoginProps> = ({ showLoginButton }) => {
  return (
    <div className="yo-google-sign-in">
      <PrimaryHeader className="yo-google-sign-in__header">Yle Tehtävä</PrimaryHeader>
      {showLoginButton && <GoogleLoginButton />}
    </div>
  )
}
