import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { AlertCircle } from '@yleisradio/yds-icons-react'
import { YdsThemeProvider } from '@yleisradio/yds-components-react'
import { storeExamToAPI } from '../../../redux/middleware/examService/examService'
import { toggleDropdown } from '../../../redux/modules/dropdown/dropdown'
import Button from '../../elements/Button/Button.tsx'
import './navbar.scss'
import { ARCHTYPE_TEST, POLL, VOTE } from '../../../constants/definitions/exams'
import { updateExam } from '../../../redux/modules/exam/exam'
import i18n from '../../../utils/i18n'
import getOpenedForEditingAt from '../../../redux/modules/editor/selectors'
import ConfirmDialogManager from '../../../confirmDialog/ConfirmDialogManager.ts'
import ConfirmDialog from '../../../confirmDialog/ConfirmDialog.tsx'
import { GET } from '../../../redux/middleware/dataService/apiV1'
import { getToken, getUserEmail } from '../../../utils/tokenHelpers'

const en = i18n.getFixedT('en')

/**
 * Returns the latest log entry for the particular exam.
 * This is quite a heavy function as it fetches all the log entries
 * and just filters out the latest one.
 */
export const fetchLatestLogEntry = async examUuid => {
  // Fetch the whole edit log for this exam
  const response = await GET(`/exams/logs/${examUuid}.json`)

  const responseInJson = await response.json()

  /**
   * Filter out current user's logs records as there is no point warning
   * an user for editing an exam he might be accidentally editing from two sources.
   */
  const currentUserEmail = getUserEmail(getToken())

  const filteredLogs =
    responseInJson.data
      ?.filter(({ author }) => author.email !== currentUserEmail)
      .sort((l1, l2) => l2.id - l1.id) || []

  return filteredLogs.length ? filteredLogs[0].created_at : null
}

const NavBar = ({
  match,
  onClickPopup,
  stateValidation,
  tainted,
  examType,
  published,
  examUuid,
  onSaveNode,
}) => {
  // Verify id exists in router parameters
  if (typeof match.params.id === 'undefined') {
    return null
  }

  const [isLoading, setIsLoading] = useState(false)
  const buttons = []

  const showCharacters = examType === ARCHTYPE_TEST
  const showReviews = ![ARCHTYPE_TEST, VOTE, POLL].includes(examType)
  const showAnswers = examType === VOTE

  const routes = [
    'editor',
    ...(showCharacters ? ['characters'] : []),
    ...(showReviews ? ['reviews'] : []),
    'logs',
    'settings',
    'preview',
    ...(showAnswers ? ['answers'] : []),
    'publish',
  ]

  const isInvalid = stateValidation.filter(input => !input.isValid).length > 0

  const handlePublishDisable = (e, key) => {
    if (published && key === 'publish') {
      e.preventDefault()
    }
  }

  const linkClassNames = key => {
    return !published || key !== 'publish' ? 'yo-navbar__link' : 'yo-navbar__link-disabled'
  }
  const saveClassNames = classnames('yo-navbar__link-button', 'yo-navbar__save-button', {
    'yo-navbar__save-button--active': tainted,
  })

  const onClickSave = async examUuid => {
    setIsLoading(true)
    try {
      await onSaveNode(examUuid)
    } finally {
      setIsLoading(false)
    }
  }

  routes.forEach(key => {
    buttons.push(
      <NavLink
        activeClassName="yo-navbar__link--active"
        className={linkClassNames(key)}
        data-testid={`navbar-${key}`}
        key={key}
        onClick={e => handlePublishDisable(e, key)}
        to={`/exam/${match.params.id}/${key}`}
      >
        {published && key === 'publish' ? 'published' : key}
      </NavLink>,
    )
  })

  const saveButton = (
    <Button
      attributes={{ 'data-testid': 'button__save-exam' }}
      classNames={saveClassNames}
      iconBefore={tainted ? <AlertCircle /> : undefined}
      isDisabled={isLoading || isInvalid || !tainted}
      key="save"
      onClick={() => onClickSave(examUuid)}
      size="sm"
      text="Save"
    />
  )

  const examInfoButton = (
    <Button
      classNames="yo-navbar__link-button"
      key="eaxm-info"
      onClick={onClickPopup}
      size="sm"
      text="Embed"
      variant="secondary"
    />
  )

  buttons.push(examInfoButton, saveButton)

  return (
    <div className="yo-navbar">
      <div className="yo-navbar__links">
        <YdsThemeProvider theme="dark">{buttons}</YdsThemeProvider>
      </div>
      <ConfirmDialog />
    </div>
  )
}

NavBar.propTypes = {
  onClickPopup: PropTypes.func.isRequired,
  onSaveNode: PropTypes.func.isRequired,
  stateValidation: PropTypes.arrayOf(PropTypes.object).isRequired,
  tainted: PropTypes.bool,
}

NavBar.defaultProps = {
  tainted: false,
}

const mapStateToProps = state => ({
  stateValidation: state.validation,
  tainted: state.exam.tainted,
  examType: state.exam.exam_type,
  publishedAt: state.exam.publishedAt,
  published: state.exam.published,
  examUuid: state.exam.uuid,
  openedForEditingAt: getOpenedForEditingAt(state),
})

const mergeProps = (
  { published, publishedAt, openedForEditingAt, ...stateProps },
  { dispatch },
  ownProps,
) => ({
  ...stateProps,
  ...ownProps,
  published,
  dispatch,
  onSaveNode: async examUuid => {
    const lastEdited = await fetchLatestLogEntry(examUuid)

    const lastEditedDate = new Date(lastEdited)

    /**
     * Show confirm modal if the exam was edited by someone else after
     * the current user has opened the form.
     */
    if (lastEditedDate > new Date(openedForEditingAt)) {
      const clearConfirmed = await ConfirmDialogManager.show(en(`ExamConfirm:update-warning`))
      if (!clearConfirmed) {
        return
      }
    }

    if (!published && publishedAt && publishedAt !== ' ' && moment().isSameOrAfter(publishedAt)) {
      dispatch(updateExam({ published: true }))
    }

    await dispatch(storeExamToAPI())
  },
  onClickPopup: () => dispatch(toggleDropdown()),
})

export default withRouter(connect(mapStateToProps, null, mergeProps)(NavBar))
