import { DELETE_EXAM_ANSWERS, EXAM_STORED } from '../examService/examService'
import { UPDATE_AVERAGE_SCORE, taintExam, untaintExam } from '../../modules/exam/exam'
import { SERIES_STORED, UPDATE_SERIES } from '../../modules/series/series'
import { UPDATE_VALUE, REMOVE_VALUE } from '../../modules/node/actionTypes.ts'
import { ADD as TOASTS_ADD } from '../../modules/toasts/toasts'

// Use SET action type if you want to update redux but not taint exam,
// so lets keep 'SET' out of taintable actions
const TAINTABLE_ACTIONS = ['ADD', 'ASSIGN', 'CREATE', 'DELETE', 'REMOVE', 'UNASSIGN', 'UPDATE']
const EXCLUDE_ACTIONS = [
  DELETE_EXAM_ANSWERS,
  'paginations/', // or when setting paginations
  TOASTS_ADD,
  UPDATE_VALUE, // we don't want to taint in preview
  UPDATE_SERIES,
  REMOVE_VALUE,
  UPDATE_AVERAGE_SCORE,
]
const tainter = store => next => action => {
  next(action)

  if (
    TAINTABLE_ACTIONS.some(word => action.type.includes(word)) &&
    !EXCLUDE_ACTIONS.some(word => action.type.includes(word))
  ) {
    store.dispatch(taintExam())
  }

  switch (action.type) {
    case EXAM_STORED:
    case SERIES_STORED:
      store.dispatch(untaintExam())
      break

    default:
      break
  }
}

export default tainter
