import React from 'react'
import Fragment from '../../node/Fragment/Fragment'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'

export const ExamQuestion = ({ content, children, examType }) => {
  return (
    <Fragment
      displayNumber={content.displayNumber}
      examType={examType}
      text={<RichTextContent content={content.text} />}
    >
      {children}
    </Fragment>
  )
}

export default ExamQuestion
