import React, { HTMLAttributes } from 'react'
import {
  Notification as YdsNotification,
  NotificationDSProps,
} from '@yleisradio/yds-components-react'

type NotificationProps = HTMLAttributes<HTMLDivElement> & NotificationDSProps

const Notification = ({
  variant = 'secondary',
  hideIcon = true,
  children,
  ...other
}: NotificationProps) => {
  if (!other.title && !children) {
    return null
  }
  return (
    <YdsNotification {...other} hideIcon={hideIcon} variant={variant}>
      {children}
    </YdsNotification>
  )
}

export default Notification
