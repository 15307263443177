import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { removeOption } from '../../../redux/modules/node/actions.ts'
import { deleteOption } from '../../../redux/modules/options/options'
import { deleteChoice } from '../../../redux/modules/choices/choices'
import { IconButtons, IconButton } from '../../elements/IconButtons/IconButtons'
import { AccordionItem } from '../../node/Accordion/Accordion'
import EditorChoices from './EditorChoices'
import OptionForm from './OptionForm'

/**
 * Component for creating accordion list of options in a node entity.
 *
 * @param {object} option - The option node
 * @param {object} nodeType - Object describing the option node
 * @param {function} onDeleteClick - Click hanlder for deleting the option
 * @param {array} optionFields - Fields definition for options
 * @param {array} choiceFields - Fields definition for choices in options
 */
const OptionItem = ({ option, onDeleteClick, optionFields, choiceFields, nodeType }) => {
  // Create the delete button for the Accordion parent
  const suffix = (
    <IconButtons>
      <IconButton icon="delete" onClick={onDeleteClick} />
    </IconButtons>
  )

  return (
    <AccordionItem
      description={option.text}
      isInitiallyExpanded
      key={option.id}
      suffix={suffix}
      title={option.label}
    >
      <OptionForm option={option} optionFields={optionFields} />
      {option.multivalue && (
        <EditorChoices
          choiceFields={choiceFields}
          choices={option.choices}
          nodeType={nodeType}
          option={option}
        />
      )}
    </AccordionItem>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { optionFields } = ownProps.nodeType

  return { optionFields }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const actions = bindActionCreators({ removeOption, deleteOption, deleteChoice }, dispatch)

  return {
    onDeleteClick: e => {
      // Prevent the Accordion component from toggling when the delete icon is clicked
      e.stopPropagation()

      const { option } = ownProps
      const optionId = option.id

      // Display a confirmation dialog for the user before deleting
      const delConfirmed = window.confirm(ownProps.t('common:confirm-delete', { item: 'option' }))

      if (delConfirmed) {
        // Delete choices from option.
        if (typeof option.choices !== 'undefined' && option.choices.length > 0) {
          option.choices.forEach(choice => {
            actions.deleteChoice(choice.id)
          })
        }

        // Option belongs to a node, remove references to it.
        if (ownProps.nodeId) {
          actions.removeOption(optionId, ownProps.nodeId)
        }

        // Delete option entity.
        actions.deleteOption(optionId, ownProps.nodeId)
      }
    },
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OptionItem))
