import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Accordion, AccordionItem } from '../../node/Accordion/Accordion'
import Input from '../../elements/Input/Input'
import Button from '../../elements/Button/Button.tsx'
import { updateNodeSetting } from '../../../redux/modules/node/actions.ts'
import { IconButton } from '../../elements/IconButtons/IconButtons'
import EditorSettingsDuration from './EditorSettingsDuration'
import './editor-settings.scss'

class EditorSettingsTimedQuestion extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onAddTimePointClick = this.onAddTimePointClick.bind(this)
    this.onTimePointChange = this.onTimePointChange.bind(this)
    this.onDeleteClick = this.onDeleteClick.bind(this)
  }

  onAddTimePointClick() {
    const { node, dispatch } = this.props
    const timePoints = node.settings.timePoints || []
    const keys = Object.keys(timePoints).map(f => timePoints[f].key)
    const newKey = keys.length > 0 ? Math.max(...keys) + 1 : 0
    dispatch(
      updateNodeSetting(node.id, {
        timePoints: [
          ...timePoints,
          {
            key: newKey,
            point: 0,
            breakpoint: 0,
          },
        ],
      }),
    )
  }

  onTimePointChange(e) {
    const { dispatch, node } = this.props
    const { label, key } = e.target.dataset
    const value = e.target.value !== '' ? Number(e.target.value) : ''
    const timePoints = node.settings.timePoints.map(tp =>
      tp.key !== Number(key)
        ? tp
        : {
            ...tp,
            [label]: value,
          },
    )
    dispatch(updateNodeSetting(node.id, { timePoints }))
  }

  onDeleteClick(e) {
    const { key } = e.currentTarget.dataset
    const { dispatch, node } = this.props
    dispatch(
      updateNodeSetting(node.id, {
        timePoints: node.settings.timePoints.filter(f => f.key !== Number(key)),
      }),
    )
  }

  render() {
    const { node, defaultTimePoints, t } = this.props

    const timePoints = node.settings.timePoints || defaultTimePoints
    const id = 'yo-input__editor-settings-timed-question'
    const showTimePoints = timePoints && timePoints.length > 0

    const timePointItems = timePoints.map(timePoint => {
      const attrs = { 'data-key': timePoint.key }
      const suffix = <IconButton attributes={attrs} icon="delete" onClick={this.onDeleteClick} />

      return (
        <AccordionItem isInitiallyExpanded key={timePoint.key} suffix={suffix} title="Breakpoint">
          <Input
            attributes={{
              ...attrs,
              'data-label': 'breakpoint',
            }}
            defaultValue={timePoint.breakpoint}
            inline
            label="Remains"
            onChange={this.onTimePointChange}
            type="number"
            width="narrow"
          />
          <span>seconds or more</span>
          <div>
            <Input
              attributes={{
                ...attrs,
                'data-label': 'point',
              }}
              defaultValue={timePoint.point}
              inline
              label="Point"
              onChange={this.onTimePointChange}
              type="number"
              width="narrow"
            />
          </div>
        </AccordionItem>
      )
    })

    return (
      <div>
        <EditorSettingsDuration node={node} />
        <div className="yo-editor-settings__timed-question--wrapper">
          <label className="yo-input__label" htmlFor={id}>
            Time Breakpoints
            <div className="yo-input__description">{t('timed-question-description')}</div>
            {showTimePoints && <Accordion id={id}>{timePointItems}</Accordion>}
          </label>
        </div>
        <Button
          attributes={{ 'data-testid': 'button__add-option' }}
          onClick={this.onAddTimePointClick}
          size="sm"
          text="Add breakpoint"
        />
      </div>
    )
  }
}

EditorSettingsTimedQuestion.defaultProps = {
  defaultTimePoints: [],
}

export default withTranslation(['Editor'])(connect()(EditorSettingsTimedQuestion))
