import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { MATRICULATION_EXAM_TYPE } from '../../../constants/definitions/exams'
import './disclaimer.scss'
import { getExamState } from '../../../redux/modules/exam/selectors'
import Notification from '../Notification/Notification'
import { LoginLink } from '../../layout/YleTunnus/Login'
import { getLoggedInState } from '../../../redux/modules/yleTunnus/selectors'

export const LoginDisclaimer = withTranslation()(
  connect(state => ({ loggedIn: getLoggedInState(state) }))(({ t, loggedIn, className }) => {
    return !loggedIn ? (
      <div className={className || ''}>
        <Notification hideIcon={false}>
          <p className="yo-disclaimer__login">
            <LoginLink>{t('YleTunnus:logged-out-text-matriculation-exam-1')}</LoginLink>
            {t('YleTunnus:logged-out-text-matriculation-exam-2')}
          </p>
        </Notification>
      </div>
    ) : null
  }),
)

const ExamDisclaimer = ({ exam, t }) => {
  const examType = exam.exam_type
  const year = parseInt(exam.year, 10)

  return (
    examType === MATRICULATION_EXAM_TYPE && (
      <div className={classnames('yo-disclaimer')} data-testid="yo-disclaimer">
        <LoginDisclaimer />
        {year >= 2006 && year <= 2012 && (
          <Notification hideIcon={false}>{t('no-ytl-answers')}</Notification>
        )}
        <Notification hideIcon={false}>{t('common:ytl-notice')}</Notification>
      </div>
    )
  )
}

export const NodeDisclaimer = ({ disclaimer }) => <div className="yo-disclaimer">{disclaimer}</div>

const mapStateToProps = state => ({
  exam: getExamState(state),
})

export default withTranslation(['Disclaimer'])(connect(mapStateToProps)(ExamDisclaimer))
