import React from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { getExamType } from '../../../redux/modules/exam/selectors'
import './gap-select.scss'
import { OTHER_EXAM_TYPE, REVIEW_ONLY_EXAM_TYPE } from '../../../constants/definitions/exams'
import AnswerValidator from '../../node/AnswerValidator/AnswerValidator.tsx'

const GapSelect = ({
  answered,
  correct,
  correctAnswer,
  option,
  validate,
  value,
  onChange,
  examType,
  t,
}) => {
  if (!option) {
    return null
  }

  const isReviewOnlyExam = examType === REVIEW_ONLY_EXAM_TYPE
  const isOtherExam = examType === OTHER_EXAM_TYPE
  const selectClassNames = classnames('yo-gap-select__select', {
    'yo-gap-select__select--validated': validate && answered,
  })

  return (
    <span className="yo-gap-select" key={option.id}>
      {validate && answered && !isReviewOnlyExam && <AnswerValidator correct={correct} />}
      {!isOtherExam && <span className="yo-gap-select__numbering" />}
      <select
        className={selectClassNames}
        data-option-index={option.id}
        data-testid="gap-select"
        disabled={validate}
        onChange={onChange}
        value={value ? value[option.id] : '-'}
      >
        <option disabled value="-">
          {t('choose')}
        </option>
        {option.choices.map(choice => (
          <option key={choice.id} value={choice.text}>
            {choice.text}
          </option>
        ))}
      </select>
      {'hint' in option && option.hint.length > 0 && (
        <span className="yo-gap-select__hint">{` (${option.hint})`}</span>
      )}
      {correctAnswer && !isReviewOnlyExam && (
        <span className="yo-gap-select__answer">{correctAnswer}</span>
      )}
    </span>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { id, options, value } = ownProps
  const option = options.find(o => o.id.toString() === id)

  // Make sure the option really exists.
  if (!option) {
    return {
      option: null,
    }
  }

  // Is there an answer in the option.
  const answered = value && typeof value[option.id] !== 'undefined' && value[option.id] !== null

  // Get correct answers.
  let correctAnswers = []
  if (option.correct !== 'undefined') {
    // Create an array of correct values and check if the answer is included in those.
    correctAnswers = option.choices.filter(o => o.correct === true).map(o => o.text.trim())
  }

  // Is the answer correct.
  let correct = false
  if (answered) {
    correct = correctAnswers.includes(value[option.id])
  }

  return {
    answered,
    correct,
    correctAnswer: (ownProps.submitted && correctAnswers.join(', ')) || null,
    option,
    // Whether the answer should be validated.
    validate: ownProps.submitted,
    examType: getExamType(state),
  }
}

export default withTranslation(['common'])(connect(mapStateToProps)(GapSelect))
