import React from 'react'
import './areenaclip.scss'
import YleMediaPlayer from './YleMediaPlayer'
import i18n from '../../../utils/i18n'

const AreenaClip = ({ playerType, ...props }) => {
  const lang = {
    fi: 'fin',
    sv: 'swe',
    en: 'eng',
  }[i18n.language.split('-')[0]]
  return <YleMediaPlayer playerLang={lang} {...props} />
}

export default AreenaClip
