import { uniq } from 'ramda'
import { apiURL, parseJSON } from '../dataService/apiV1'
import { getQueryParams, isFetching } from '../../../utils/dataService'
import { handleLoadError, handleExamData } from '../examService/examService'
import { updateTotalCount } from '../../modules/paginations/paginations'
import {
  clearExamListLoading,
  setExamListLoading,
  setExamLoadState,
  setQuestionsLoadState,
  setExamErrorState,
} from '../../modules/loadState/loadState'
import { receiveExams } from '../../modules/exams/exams'
import { serializeFromAPI } from '../dataService/stateSerializer'
import { setCarouselItems } from '../../modules/carousel/carousel'
import { setQuestionsLoading, clearQuestionsLoading } from '../../modules/questions/questions'

const FETCH_CAROUSEL_EXAM_LIST = 'api/FETCH_CAROUSEL_EXAM_LIST'
const FETCH_CAROUSEL_EXAM = 'api/FETCH_CAROUSEL_EXAM'

export const fetchCarouselExamList = filters => ({
  type: FETCH_CAROUSEL_EXAM_LIST,
  filters,
})

export const fetchCarouselExam = uuid => ({
  type: FETCH_CAROUSEL_EXAM,
  uuid,
})

const carouselService = store => next => action => {
  next(action)
  let nodeUUID
  switch (action.type) {
    case FETCH_CAROUSEL_EXAM_LIST:
      next(setExamListLoading())
      return fetch(apiURL(`/public/exams?${getQueryParams(store, action.filters)}`))
        .then(parseJSON)
        .then(body => {
          next(receiveExams(uniq(body.data)))
          next(updateTotalCount(body.meta.count_total))
          next(clearExamListLoading())
        })
        .catch(handleLoadError)

    case FETCH_CAROUSEL_EXAM:
      nodeUUID = action.uuid
      if (isFetching(store, nodeUUID)) {
        return null
      }
      next(setExamLoadState(nodeUUID))
      next(setQuestionsLoading())
      return fetch(apiURL(`/public/exams.json?uuid=${nodeUUID}`))
        .then(parseJSON)
        .then(data => {
          next(handleExamData(serializeFromAPI(data.data)))
          next(setExamLoadState(nodeUUID, true))
          next(setQuestionsLoadState())
          // sort carousel items so questions are in same order than in exam
          next(
            setCarouselItems(
              data.data[0].questions.sort((a, b) => a.order_number - b.order_number),
            ),
          )
          next(clearQuestionsLoading())
        })
        .catch(res => {
          if (res.status === 403) {
            next(setExamErrorState(nodeUUID, 'this-content-is-not-published'))
            next(clearQuestionsLoading())
          }
          handleLoadError(res)
        })
    default:
      break
  }
  return null
}

export default carouselService
