import React from 'react'
import { connect } from 'react-redux'
import { Prompt, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

/**
 * Blocks navigation if exam is not saved. Also sets onbeforeunload true
 * to prevent closing browser window/tab if not saved.
 */
class NavigationBlocker extends React.Component {
  render() {
    const { exam, t } = this.props

    window.onbeforeunload = exam.tainted ? () => true : null

    const ALLOWED_EXAM_EDIT_ACTIONS = [
      'characters',
      'create',
      'editor',
      'logs',
      'preview',
      'publish',
      'reviews',
      'settings',
    ]
    return (
      <Prompt
        message={location => {
          const pathArr = location.pathname.split('/')
          const action = pathArr[pathArr.length - 1]

          if (ALLOWED_EXAM_EDIT_ACTIONS.includes(action)) {
            return true
          }
          return t('common:confirm-leave-unsaved')
        }}
        when={Boolean(exam.tainted)}
      />
    )
  }
}

const mapStateToProps = state => ({
  exam: state.exam,
})
export default withTranslation()(withRouter(connect(mapStateToProps)(NavigationBlocker)))
