/* eslint-disable max-lines */
import * as R from 'ramda'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import {
  DEFAULT_EXAM_LANGUAGE,
  DEFAULT_EXAM_TYPE,
  POLL,
} from '../../../constants/definitions/exams'
import { getScore, getMaxScore } from '../../modules/nodes/helpers'
import { getOptions } from '../../modules/options/selectors'
import { getRootNodeTypeId } from '../../../utils/examHelpers'
import { getExamRoot } from '../../modules/nodes/selectors'

function addEditorToClassification({ sub, email, name }, classification) {
  const {
    category,
    exam_type,
    forceYletunnus,
    hideReviewButtons,
    language,
    published,
    semester,
    showExamSeries,
    subject,
    year,
    episodeCode,
    seriesCode,
    fingerprintName,
    startTime,
    revealTime,
    endTime,
    hide_statistics,
    disable_saving,
    hideScores,
    enableShare,
    publishedUrl,
  } = classification

  return {
    category,
    exam_type,
    force_yletunnus: forceYletunnus,
    language,
    published,
    hide_review_buttons: hideReviewButtons,
    semester,
    show_exam_series: showExamSeries,
    subject,
    year,
    episode_code: episodeCode,
    series_code: seriesCode,
    fingerprint_name: fingerprintName,
    start_time: startTime,
    end_time: endTime,
    reveal_time: revealTime,
    hide_statistics,
    disable_saving,
    hideScores,
    enable_share: enableShare,
    published_url: publishedUrl,
    authors: {
      ...classification.authors,
      [sub]: {
        email,
        name,
        edited_at: Math.floor(new Date().getTime() / 1000),
      },
    },
  }
}

// node transformation callback
const nodeTransformation = (node, rootChildIds, options, choices) => {
  const nodeOptions = R.map(
    o =>
      R.merge(options[`${node.id}--${o}`], {
        choices: R.has('choices', options[`${node.id}--${o}`])
          ? R.map(c => choices[c], options[`${node.id}--${o}`].choices)
          : [],
      }),
    node.options,
  )
  return {
    child_ids: node.childIds,
    content: node.content,
    deleted: !node.visible,
    feedback: node.feedback,
    level: R.includes(node.id, rootChildIds) ? 0 : 1,
    main_text: node.content.text,
    multipart: true,
    options: nodeOptions,
    order_number: node.order,
    question_type: node.nodeTypeId,
    max_score: getMaxScore({ ...node, submitted: true }, options),
    settings: node.settings,
    // This is set in nodes reducer,
    // but needs to be here too to work with nodes created before that change.
    tags: node.tags || [],
    uuid: node.id,
  }
}

const serializeCharactersForApi = characters => {
  if (R.isEmpty(characters.info) && R.isEmpty(characters.choices)) {
    return null
  }
  return {
    info: characters.info,
    choices: characters.choices,
  }
}

export function serializeForAPI({ token, state }) {
  const nodes = R.values(state.entities.nodes) // get nodes as array
  const rootNode = getExamRoot(state)

  const output = {
    uuid: rootNode.id,
    name: rootNode.content.title,
    exam_type: state.exam.exam_type || DEFAULT_EXAM_TYPE,
    main_text: rootNode.content.text,
    settings: rootNode.settings || {},
    series_uuids: state.exam.series_uuids ? state.exam.series_uuids : [],
    published_at: state.exam.publishedAt,
    classification: addEditorToClassification(jwtDecode(token), state.exam),
    language: state.exam.language || DEFAULT_EXAM_LANGUAGE,
    tags: [''],
    questions: R.map(
      R.curry(nodeTransformation)(
        R.__, // placeholder argument, map sets this value while looping
        rootNode.childIds,
        state.entities.options,
        state.entities.choices,
      ),
      // get all nodes except EXAM_ROOT
      nodes.filter(node => node !== rootNode),
    ),
    reviews: state.entities.reviews,
    characters: serializeCharactersForApi(state.entities.characters),
  }

  return output
}

const transformQuestionToNode = (q, input) => ({
  childIds: q.child_ids,
  completed: false,
  content: q.content,
  examName: q.exam_name || input.name,
  examId: q.exam_uuid || input.uuid,
  examClassification: q.exam_classification || input.classification,
  feedback: q.feedback || '', // For legacy reasons, feedback might be null in old exams.
  id: q.uuid,
  nodeTypeId: q.question_type,
  options: R.map(o => o.id, q.options),
  order: q.order_number,
  settings: q.settings || {}, // For legacy reasons, settings might be null in old exams.
  submitted: false,
  tags: q.tags,
  visible: true,
})

const generateNodes = input => {
  const nodes = {}
  const questions = R.reject(R.isNil, input.questions)
  const nodeTypeId = getRootNodeTypeId(input.exam_type)

  nodes[input.uuid] = {
    id: input.uuid,
    nodeTypeId,
    childIds: R.map(
      q => q.uuid,
      R.filter(q => q.level === 0, questions),
    ),
    options: [],
    content: {
      title: input.name,
      text: input.main_text,
    },
    order: 1,
    settings: input.settings,
    completed: false,
  }

  R.forEach(q => {
    nodes[q.uuid] = transformQuestionToNode(q, input)
  }, questions)

  return nodes
}

const generateNodesFromQuestions = input => {
  const nodes = {}
  const questions = R.reject(R.isNil, input)

  R.forEach(q => {
    nodes[q.uuid] = transformQuestionToNode(q)
  }, questions)

  return nodes
}

const generateOptions = questions => {
  let retVal = {}

  if (!R.isEmpty(R.reject(R.isNil, questions))) {
    // create an object from array of objects
    retVal = R.indexBy(
      // use the question's `uuid` and the option's `id` property as the object's key
      R.compose(R.join('--'), R.props(['parent', 'id'])),
      // R.prop('id'),
      R.flatten(
        R.map(
          q =>
            R.map(
              // replace choice property of an object with array of choice option id references
              o =>
                R.mergeRight(
                  // remove choices property from an option
                  R.omit('choices', o),
                  // add the `uuid` and `id` references
                  {
                    choices: R.pluck('id', o.choices),
                    parent: R.prop('uuid', q),
                    text: o.text && o.text.trim(),
                  },
                ),
              q.options,
            ),
          questions,
        ),
      ),
    )
  }

  return retVal
}

const generateChoices = questions => {
  let retVal = {}

  if (!R.isEmpty(R.reject(R.isNil, questions))) {
    retVal = R.indexBy(
      // create an object from array of objects,
      R.prop('id'), // use id property of objects as a key
      R.flatten(R.map(q => R.pluck('choices', q.options), questions)), // get all the choices from every option on every question
    )
  }

  return retVal
}

export const serializeClassificationFromAPI = inputClassification => {
  if (!inputClassification) {
    return null
  }

  const { category, year, language, published, semester, subject, authors } = inputClassification
  return Object.assign(
    {},
    {
      // Default values for legacy reasons
      showExamSeries: false,
      forceYletunnus: false,
    },
    R.reject(R.isNil, {
      category,
      year,
      language,
      published,
      semester,
      subject,
      authors,
      tainted: false,
      exam_type: inputClassification.exam_type,
      fingerprintName: inputClassification.fingerprint_name,
      forceYletunnus: inputClassification.force_yletunnus,
      hideReviewButtons: inputClassification.hide_review_buttons,
      enableShare: inputClassification.enable_share,
      showExamSeries: inputClassification.show_exam_series,
      episodeCode: inputClassification.episode_code,
      seriesCode: inputClassification.series_code,
      startTime: inputClassification.start_time,
      endTime: inputClassification.end_time,
      revealTime: inputClassification.reveal_time,
      hide_statistics: inputClassification.hide_statistics,
      disable_saving: inputClassification.disable_saving,
      publishedUrl: inputClassification.published_url,
      hideScores: inputClassification.hideScores,
    }),
  )
}

export const serializeFromAPI = data => {
  const [input] = data
  const choices = generateChoices(input.questions)
  const options = generateOptions(input.questions)
  const classification = serializeClassificationFromAPI(input.classification)

  return {
    rootId: input.uuid,
    meta: classification,
    entities: {
      choices,
      options,
      nodes: generateNodes(input),
      reviews: input.reviews,
      characters: input.characters,
    },
    exam: {
      ...classification,
      series_uuids: input.series_uuids || [],
      publishedAt: input.published_at,
      uuid: input.uuid,
      isOpen: input.is_open,
    },
  }
}

export const serializeQuestionsFromAPI = data => {
  const choices = generateChoices(data)
  const options = generateOptions(data)
  return {
    entities: {
      choices,
      options,
      nodes: generateNodesFromQuestions(data),
    },
  }
}

export const serializeAnswersForApi = (nodes, state) => {
  const { exam_type } = state.exam
  const answerData = []
  R.map(node => {
    const options = getOptions(state.entities, node.id, Object.values(node.options))
    if (node && node.value) {
      const answerObj =
        exam_type === POLL
          ? {
              question_uuid: node.id,
              option_id: Object.keys(node.value)[0],
              option_value: Object.values(node.value)[0],
            }
          : {
              question_uuid: node.id,
              answer_data: node.value,
              score: getScore({ ...node, submitted: true }, options),
            }
      answerData.push(answerObj)
    }
    return node
  }, nodes)
  return answerData
}

export const serializeSeriesForApi = data => ({
  uuid: data.uuid,
  name: data.name,
  classification: data.classification,
  description: data.description,
  tags: data.tags,
})

const sortExamsByPublishedAt = (a, b) => moment(b.published_at) - moment(a.published_at)

const addScoreToExams = series =>
  series.exams
    .map(exam => {
      const scores = series.scores ? series.scores.find(x => x.exam_uuid === exam.uuid) : undefined

      return {
        ...exam,
        scores,
      }
    })
    .sort((a, b) => {
      const {
        classification: { examOrder },
      } = series
      if (examOrder) {
        return examOrder[a.uuid] - examOrder[b.uuid]
      }
      return sortExamsByPublishedAt(a, b)
    })

export const generateExamOrder = exams =>
  exams.reduce((acc, exam) => ({ ...acc, [exam.uuid]: exams.indexOf(exam) }), {})

export const serializeSeriesFromApi = data =>
  data.map(series => ({
    ...series,
    classification: {
      ...series.classification,
      examOrder: series.classification.examOrder
        ? series.classification.examOrder
        : generateExamOrder(series.exams.sort(sortExamsByPublishedAt)),
    },
    exams: addScoreToExams(series),
  }))

export const serializePollsDataFromApi = data =>
  R.uniq(data.map(x => x.question_uuid)).map(uuid => ({
    uuid,
    stats: data
      .filter(x => x.question_uuid === uuid)
      .map(x => ({
        option_id: x.option_id,
        text: x.option_value,
        count: x.count_option,
      })),
    total_answer_count: data
      .filter(x => x.question_uuid === uuid)
      .map(x => x.count_option)
      .reduce((a, b) => a + b, 0),
  }))
