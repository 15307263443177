/* eslint-disable max-lines */
import React from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  EDUCATION_EXAM_TYPE,
  MATRICULATION_EXAM_TYPE,
  OTHER_EXAM_TYPE,
  categoryOptions,
  getSubjectOptions,
  examTypeOptions,
  languageOptions,
  semesterOptions,
  TV_EPISODE,
  ARCHTYPE_TEST,
  POLL,
  VOTE,
  REVIEW_ONLY_EXAM_TYPE,
} from '../../../constants/definitions/exams'
import SecondaryHeader from '../../elements/Typography/SecondaryHeader.tsx'
import Button from '../../elements/Button/Button.tsx'
import Input from '../../elements/Input/Input'
import Select from '../../elements/Select/Select'
import './settings.scss'
import withSettingModifier from './WithSettingModifier'
import {
  translateOptions,
  subjectHasCategories,
  getSeriesOptions,
  yearOptions,
} from './settingFunctions'
import * as CustomPropTypes from '../../../constants/definitions/propTypes'
import AnswerValidator from '../../node/AnswerValidator/AnswerValidator.tsx'
import { validationRules } from '../../../constants/definitions/entities/entities.ts'
import { DATE_TIME_FORMAT } from '../../../constants/definitions/datetime'

export const wizardFields = {
  [MATRICULATION_EXAM_TYPE]: props => [
    <SemesterSelect {...props} key="semester-select" />,
    <SubjectSelect {...props} key="subject-select" />,
    <CategorySelect {...props} examKey="category" key="category-select" />,
    <LanguageSelect {...props} examKey="language" key="language-select" />,
  ],
  [EDUCATION_EXAM_TYPE]: props => [
    <SubjectSelect {...props} key="subject-select" />,
    <LanguageSelect {...props} examKey="language" key="language-select" />,
  ],
  [OTHER_EXAM_TYPE]: props => [
    <LanguageSelect {...props} examKey="language" key="language-select" />,
  ],
  [TV_EPISODE]: props => [<LanguageSelect {...props} examKey="language" key="language-select" />],
  [ARCHTYPE_TEST]: props => [
    <LanguageSelect {...props} examKey="language" key="language-select" />,
  ],
  [POLL]: props => [
    <LanguageSelect {...props} examKey="language" key="language-select" />,
    <EndTimeInput {...props} examKey="endTime" key="end-time-input" />,
  ],
  [VOTE]: props => [
    <LanguageSelect {...props} examKey="language" key="language-select" />,
    <StartTimeInput {...props} examKey="startTime" key="start-time-input" />,
    <EndTimeInput {...props} examKey="endTime" key="end-time-input" />,
  ],
  [REVIEW_ONLY_EXAM_TYPE]: props => [
    <LanguageSelect {...props} examKey="language" key="language-select" />,
  ],
}

const Transition = props => <CSSTransition {...props} classNames="yo-settings" timeout={120} />

export const CategorySelect = withSettingModifier(
  ({ exam, onChangeExamValue, ...props }) =>
    subjectHasCategories(exam) && (
      <Transition {...props}>
        <Select
          className="yo-settings__select"
          defaultValue={exam.category}
          label="Category"
          onChange={onChangeExamValue}
          options={translateOptions(categoryOptions)}
        />
      </Transition>
    ),
)

export const DangerZone = props => (
  <>
    <SecondaryHeader>Danger zone</SecondaryHeader>
    <div>{props.children}</div>
  </>
)

export const DeleteAnswersButton = withSettingModifier(({ onDeleteAnswers }) => (
  <div className="yo-settings__delete-answers">
    <Button
      attributes={{ 'data-testid': 'delete-answers-button' }}
      classNames="yo-settings__delete-answers-button"
      onClick={onDeleteAnswers}
      size="sm"
      text="Delete answers"
      variant="secondary"
    />
    <div className="yo-settings__delete-answers-button-description">
      Deletes all user answers from this exam
    </div>
  </div>
))

export const ExamTypeDisplay = withSettingModifier(({ exam }) => (
  <Select
    attributes={{ 'data-testid': 'select-exam-type' }}
    className="yo-settings__select"
    defaultValue={exam.exam_type}
    disabled
    label="Exam type"
    onChange={() => {}}
    options={translateOptions(examTypeOptions)}
  />
))

export const LanguageSelect = withSettingModifier(({ exam, onChangeExamValue }) => (
  <Select
    attributes={{ 'data-testid': 'select-exam-language' }}
    className="yo-settings__select"
    defaultValue={exam.language}
    label="Language"
    onChange={onChangeExamValue}
    options={translateOptions(languageOptions)}
  />
))

export const PublishedCheckbox = withSettingModifier(({ exam, onChangePublished }) => (
  <>
    <span>Publish status: </span>{' '}
    <strong>
      {exam.published ? 'Published' : 'Not published'}{' '}
      <AnswerValidator correct={exam.published || false} />
    </strong>
    <div className="yo-settings__publish-description">
      After setting publish time, please remember to save.
    </div>
  </>
))

export const PublishedAtInput = withSettingModifier(({ exam, onChangeTime }) => {
  const inputProps = { readOnly: true, disabled: exam.published }
  if (!exam.publishedAt) {
    inputProps.value = ''
  }
  return (
    <Transition>
      <Input
        className="yo-settings__input"
        defaultValue={moment().format(DATE_TIME_FORMAT)}
        hasNowButton={!exam.publishedAt}
        inputProps={inputProps}
        label="Publish time"
        onChange={value => onChangeTime({ value, key: 'publishedAt' })}
        type="dateTimePicker"
        value={exam.publishedAt ? moment(exam.publishedAt).format(DATE_TIME_FORMAT) : undefined}
      />
    </Transition>
  )
})

export const PublishedUrlInput = withSettingModifier(({ exam, onChangeExamValue }) => (
  <Transition>
    <Input
      className="yo-settings__input"
      defaultValue={exam.publishedUrl}
      description="The final full URL where the exam will be published. Also used in API."
      label="(Optional) Published URL"
      onChange={onChangeExamValue}
      type="text"
      validation={validationRules.YLE_DOMAIN}
    />
  </Transition>
))

export const HideScoresCheckbox = withSettingModifier(({ exam, onChangeCheckbox, ...props }) => (
  <Transition {...props}>
    <Input
      className="yo-settings__input"
      defaultChecked={exam.hideScores}
      description="Whether the score is shown under every question after it is answered. (only in other exams)"
      label="Hide Scores"
      onChange={onChangeCheckbox}
      type="checkbox"
    />
  </Transition>
))

export const SubjectSelect = withSettingModifier(({ exam, onChangeSubject, ...props }) => (
  <Transition {...props}>
    <Select
      attributes={{ 'data-testid': 'select-exam-subject' }}
      className="yo-settings__select"
      defaultValue={exam.subject}
      label="Subject"
      onChange={onChangeSubject}
      options={getSubjectOptions(exam.exam_type)}
    />
  </Transition>
))

export const ReviewButtonsCheckbox = withSettingModifier(({ exam, onChangeCheckbox, ...props }) => (
  <Transition {...props}>
    <Input
      attributes={{ 'data-testid': 'checkbox-hide-review' }}
      className="yo-settings__input"
      defaultChecked={exam.hideReviewButtons}
      description="If checked, hides the separate review buttons
        and submits automatically wherever possible."
      label="Hide review buttons and auto submit"
      onChange={onChangeCheckbox}
      type="checkbox"
    />
  </Transition>
))

export const ReviewButtonsCheckboxVote = withSettingModifier(
  ({ exam, onChangeCheckbox, ...props }) => (
    <Transition {...props}>
      <Input
        attributes={{ 'data-testid': 'checkbox-hide-review-vote' }}
        className="yo-settings__input"
        defaultChecked={exam.hideReviewButtons}
        description="Replace question specific vote buttons with a single review button in the bottom"
        label="Hide review buttons"
        onChange={onChangeCheckbox}
        type="checkbox"
      />
    </Transition>
  ),
)

export const EnableShareCheckbox = withSettingModifier(({ exam, onChangeCheckbox, ...props }) => (
  <Transition {...props}>
    <Input
      attributes={{ 'data-testid': 'enable-sharing-checkbox' }}
      className="yo-settings__input"
      defaultChecked={exam.enableShare}
      description="After enabling, fill out necessary share information
        in the Reviews / Characters tab."
      label="Enable sharing"
      onChange={onChangeCheckbox}
      type="checkbox"
    />
  </Transition>
))

const SemesterSelection = withSettingModifier(({ exam, onChangeExamValue, ...props }) => (
  <Transition {...props}>
    <Select
      attributes={{ 'data-testid': 'select-exam-semester' }}
      className="yo-settings__select yo-settings__select--semester"
      defaultValue={exam.semester}
      key={`select-exam-semester-${exam.semester}`}
      label="Semester"
      onChange={onChangeExamValue}
      options={translateOptions(semesterOptions)}
    />
  </Transition>
))
const YearSelect = withSettingModifier(({ exam, onChangeExamValue, ...props }) => (
  <Transition {...props}>
    <Select
      attributes={{ 'data-testid': 'select-exam-year' }}
      className="yo-settings__select yo-settings__select--year"
      defaultValue={exam.year}
      key={`select-exam-year-${exam.year}`}
      label="Year"
      onChange={onChangeExamValue}
      options={yearOptions}
    />
  </Transition>
))
export const SemesterSelect = withSettingModifier(props => (
  <div className="yo-settings__selection yo-settings__selection--year-semester">
    <YearSelect {...props} examKey="year" />
    <SemesterSelection {...props} examKey="semester" />
  </div>
))

export const YletunnusCheckbox = withSettingModifier(({ exam, onChangeCheckbox, ...props }) => (
  <Transition {...props}>
    <Input
      className="yo-settings__input"
      defaultChecked={exam.forceYletunnus}
      label="Force yletunnus login"
      onChange={onChangeCheckbox}
      type="checkbox"
    />
  </Transition>
))

export const ExamSeriesCheckbox = withSettingModifier(({ exam, onChangeShowExamSeries }) => (
  <Input
    className="yo-settings__input"
    defaultChecked={exam.showExamSeries}
    label="Show in series"
    onChange={onChangeShowExamSeries}
    type="checkbox"
  />
))

export const ExamSeriesSelect = withSettingModifier(
  ({ exam, onChangeExamSeries, series, ...props }) => {
    if (exam.showExamSeries) {
      return (
        <Transition {...props}>
          <Select
            attributes={{ 'data-testid': 'select-exam-series' }}
            className="yo-settings__select"
            label="Series"
            onChange={onChangeExamSeries}
            options={getSeriesOptions(series, exam)}
          />
        </Transition>
      )
    }
    return null
  },
)

export const SeriesCodeInput = withSettingModifier(({ exam, onChangeExamValue }) => (
  <Input
    className="yo-settings__input"
    defaultValue={exam.seriesCode}
    label="Series code"
    onChange={onChangeExamValue}
    type="text"
  />
))

export const EpisodeCodeInput = withSettingModifier(({ exam, onChangeExamValue }) => (
  <Input
    className="yo-settings__input"
    defaultValue={exam.episodeCode}
    label="Episode code"
    onChange={onChangeExamValue}
    type="text"
  />
))

export const FingerprintNameInput = withSettingModifier(({ exam, onChangeExamValue }) => (
  <Input
    className="yo-settings__input"
    defaultValue={exam.fingerprintName}
    label="Fingerprint name"
    onChange={onChangeExamValue}
    type="text"
  />
))

export const StartTimeInput = withSettingModifier(({ exam, onChangeTime }) => (
  <Input
    className="yo-settings__input"
    defaultValue={exam.startTime ? moment(exam.startTime).format(DATE_TIME_FORMAT) : undefined}
    label="Start time"
    onChange={value => onChangeTime({ value, key: 'startTime' })}
    type="dateTimePicker"
    validation={{ ...validationRules.DATE, required: false }}
  />
))

export const EndTimeInput = withSettingModifier(({ exam, onChangeTime, ...props }) => (
  <Transition {...props}>
    <Input
      className="yo-settings__input"
      defaultValue={exam.endTime ? moment(exam.endTime).format(DATE_TIME_FORMAT) : undefined}
      description="From this day the result will be frozen and user will not be able to answer to this test anymore."
      label="End time"
      onChange={value => onChangeTime({ value, key: 'endTime' })}
      type="dateTimePicker"
      validation={{ ...validationRules.DATE, required: false }}
    />
  </Transition>
))

export const RevealTimeInput = withSettingModifier(({ exam, onChangeTime }) => (
  <Input
    className="yo-settings__input"
    defaultValue={exam.revealTime ? moment(exam.revealTime).format(DATE_TIME_FORMAT) : undefined}
    label="Reveal answers time"
    onChange={value => onChangeTime({ value, key: 'revealTime' })}
    type="dateTimePicker"
    validation={{ ...validationRules.DATE, required: false }}
  />
))

export const HideStatisticsCheckbox = withSettingModifier(
  ({ exam, onChangeCheckbox, ...props }) => (
    <Transition {...props}>
      <Input
        className="yo-settings__input"
        defaultChecked={exam.hide_statistics}
        description="If checked, statistics are not shown for multiple choice questions."
        label="Hide statistics"
        onChange={onChangeCheckbox}
        type="checkbox"
      />
    </Transition>
  ),
)

export const DisableSavingCheckbox = withSettingModifier(({ exam, onChangeCheckbox, ...props }) => (
  <Transition {...props}>
    <Input
      className="yo-settings__input"
      defaultChecked={exam.disable_saving}
      description="If checked, answers are not saved."
      label="Disable saving"
      onChange={onChangeCheckbox}
      type="checkbox"
    />
  </Transition>
))

ExamTypeDisplay.propTypes = {
  exam: CustomPropTypes.exam.isRequired,
}

CategorySelect.propTypes = {
  exam: CustomPropTypes.exam.isRequired,
  onChangeExamValue: PropTypes.func,
}

LanguageSelect.propTypes = {
  exam: CustomPropTypes.exam.isRequired,
  onChangeExamValue: PropTypes.func,
}

PublishedCheckbox.propTypes = {
  exam: CustomPropTypes.exam.isRequired,
  onChangePublished: PropTypes.func,
}

SubjectSelect.propTypes = {
  exam: CustomPropTypes.exam.isRequired,
  onChangeSubject: PropTypes.func,
}

SemesterSelection.propTypes = {
  exam: CustomPropTypes.exam.isRequired,
  onChangeExamValue: PropTypes.func,
}

SeriesCodeInput.protoTypes = {
  exam: CustomPropTypes.exam.isRequired,
  onChangeSeriesCode: PropTypes.func,
}

EpisodeCodeInput.propTypes = {
  exam: CustomPropTypes.exam.isRequired,
  onChangeExamValue: PropTypes.func,
}

FingerprintNameInput.propTypes = {
  exam: CustomPropTypes.exam.isRequired,
  onChangeExamValue: PropTypes.func,
}

StartTimeInput.propTypes = {
  exam: CustomPropTypes.exam.isRequired,
  onChangeStartTime: PropTypes.func,
}

EndTimeInput.propTypes = {
  exam: CustomPropTypes.exam.isRequired,
  onChangeEndTime: PropTypes.func,
}

YletunnusCheckbox.propTypes = {
  exam: CustomPropTypes.exam,
}
