// Actions
const TOGGLE = 'dropdown/TOGGLE'
const HIDE = 'dropdown/HIDE'

// Action creators
export const hideDropdown = () => ({
  type: HIDE,
})

export const toggleDropdown = () => ({
  type: TOGGLE,
})

const defaultState = {
  isVisible: false,
}

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case HIDE:
      return {
        ...state,
        isVisible: false,
      }

    case TOGGLE:
      return {
        ...state,
        isVisible: !state.isVisible,
      }

    default:
      return state
  }
}
