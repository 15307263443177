import React from 'react'
import { withTranslation } from 'react-i18next'

interface Props {
  onChange: (value: string, optionId: string) => void
  value: { [key: number]: string }
  id: string
  options: Array<{
    id: number
    choices: Array<{ id: string; text: string; label: string }>
  }>
  disabled?: boolean
  t: (s: string) => string
}

const DropdownShortcode: React.FC<Props> = ({ onChange, value, options, id, disabled, t }) => {
  const option = options.find(o => o.id.toString() === id)
  return (
    <select
      data-testid="dropdown-shortcode"
      disabled={disabled}
      onChange={e => {
        onChange(e.target.value, id)
      }}
      value={value && option ? value[option.id] : ''}
    >
      <option disabled value="">
        {t('choose')}
      </option>
      {option?.choices.map(choice => (
        <option key={choice.id} value={choice.text}>
          {choice.text}
        </option>
      ))}
    </select>
  )
}

export default withTranslation(['common'])(DropdownShortcode)
