import { resetExamTypeFilter } from './byExamType'
import { resetLanguageFilter } from './byLanguage'
import { resetSubjectFilter } from './bySubject'
import { resetSeriesIdFilter } from './bySeriesId'
import { resetExamsSearchFilter } from './byExamSearch'

const RESET = 'filters/exams/RESET'

export default function reducer(state = {}) {
  return state
}

export const resetAllFilters = () => dispatch => {
  dispatch({ type: RESET })
  dispatch(resetExamTypeFilter())
  dispatch(resetLanguageFilter())
  dispatch(resetSubjectFilter())
  dispatch(resetSeriesIdFilter())
  dispatch(resetExamsSearchFilter())
}
