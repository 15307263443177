import React from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

// Focus the editor when its unfocused and user presses e.g. Bold
const AutoFocusPlugin = () => {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    editor.focus()
  }, [editor])

  return null
}

export default AutoFocusPlugin
