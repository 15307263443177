import React from 'react'
import './reference.scss'

const Reference = ({ quotation, source }) => (
  <div className="yo-reference">
    <div className="yo-reference__quotation">{quotation}</div>
    <div className="yo-reference__source">{source}</div>
  </div>
)

export default Reference
