import React from 'react'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import onRadioInputKeyDown from './utils'

const RadioInput = ({
  attributes,
  checked,
  defaultChecked,
  id,
  label,
  name,
  onChange,
  onClick,
  suffix,
  value,
}) => {
  const props = {
    checked,
    defaultChecked,
    id,
    name,
    onChange,
    onClick,
    type: 'radio',
    value,
    ...attributes,
  }

  if (defaultChecked && !checked) {
    props.defaultChecked = defaultChecked
  } else {
    props.checked = checked
  }

  return (
    <label className="yo-input__label" htmlFor={id}>
      <div className="yo-input__input--wrapper">
        <input
          className="yo-input__input yo-input__input--radio"
          onKeyDown={onRadioInputKeyDown} /* fixes issue #1011 */
          {...props}
        />
        {<RichTextContent content={label} />}
        {suffix}
      </div>
    </label>
  )
}

export default RadioInput
