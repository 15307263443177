export const META_OPTION_JOKER = 'joker'
export const META_OPTION_MATERIAL = 'material'
export const META_OPTION_ESSAY = 'titleEssay'
export const META_OPTION_NO_MATERIAL = 'noMaterial'

const matriculationExamMetaOptions = [
  {
    id: META_OPTION_JOKER,
    name: 'Jokeritehtävä',
  },
  {
    id: META_OPTION_MATERIAL,
    name: 'Aineistoa sisältävä',
  },
  {
    id: META_OPTION_NO_MATERIAL,
    name: 'Ei sisällä aineistoa',
  },
  {
    id: META_OPTION_ESSAY,
    name: 'Otsikkoessee',
  },
]

export default matriculationExamMetaOptions
