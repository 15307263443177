import React from 'react'
import { TextInput } from '@yleisradio/yds-components-react'

interface Props {
  onChange: (value: string, optionId: string) => void
  value: { [key: number]: string }
  id: string
  options: Array<{
    id: number
    placeholder?: string
  }>
  disabled?: boolean
}

const TextboxShortcode: React.FC<Props> = ({ onChange, value, options, id, disabled }) => {
  const option = options.find(o => o.id.toString() === id)
  return option ? (
    <TextInput
      id={`${option.id.toString()}-textbox-shortcode`}
      isDisabled={disabled}
      label=""
      onChange={e => onChange(e.target.value, id)}
      placeholder={option.placeholder ?? ''}
      value={value ? value[option.id] ?? '' : ''}
    />
  ) : null
}

export default TextboxShortcode
