import PropTypes from 'prop-types'
import React from 'react'
import { IconButton } from '../IconButtons/IconButtons'

/**
 * Component for creating buttons that behave like checkboxes with on/off state.
 */
class IconToggle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: this.props.checked,
    }

    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    const { checked } = this.state
    this.setState(prevState => ({ checked: !prevState.checked }))
    // Pass "checked" state to function provided in props
    // to reveal it outside of this component.
    this.props.handleChange(!checked)
  }

  render() {
    const { icon, attributes } = this.props
    const fill = this.state.checked ? 'green' : 'silver'

    return <IconButton attributes={attributes} fill={fill} icon={icon} onClick={this.onClick} />
  }
}

IconToggle.propTypes = {
  // Whether the toggle should be checked by default.
  checked: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  // Defines string representation of the icon to be used.
  icon: PropTypes.string.isRequired,
}

IconToggle.defaultProps = {
  checked: false,
}

export default IconToggle
