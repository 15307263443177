import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { logout } from './GoogleLogin'

const GoogleLogout = () => {
  const history = useHistory()

  useEffect(() => {
    logout()
    history.push('/login')
  }, [])

  return <>Logging out</>
}

export default GoogleLogout
