import PropTypes from 'prop-types'

export const exam = PropTypes.shape({
  category: PropTypes.string,
  exam_type: PropTypes.string,
  language: PropTypes.string,
  published: PropTypes.bool,
  semester: PropTypes.string,
  subject: PropTypes.string,
  tainted: PropTypes.bool,
  year: PropTypes.string,
  authors: PropTypes.shape({}),
  forceYletunnus: PropTypes.bool,
  hideReviewButtons: PropTypes.bool,
  showExamSeries: PropTypes.bool,
  series_uuids: PropTypes.arrayOf(PropTypes.string),
  hide_statistics: PropTypes.bool,
  disable_saving: PropTypes.bool,
  hideScores: PropTypes.bool,
  enableShare: PropTypes.bool,
  episodeCode: PropTypes.string,
  seriesCode: PropTypes.string,
  fingerprintName: PropTypes.string,
  startTime: PropTypes.string,
})

export const carousel = PropTypes.shape({
  currentItemIndex: PropTypes.number,
  firstLoadedItemIndex: PropTypes.number,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string),
  lastLoadedItemIndex: PropTypes.number,
})
