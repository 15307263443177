import React, { useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import DialogField from './DialogField'
import Button from '../../elements/Button/Button.tsx'
import { clearDialog, setDialogValue } from '../../../redux/modules/dialog/dialog'

import './dialog.scss'

const Dialog = props => {
  const [message, setMessage] = useState(props.dialogMessage)
  useEffect(() => {
    setMessage(props.dialogMessage)
  }, [props.nodeId])

  const {
    dialogButtons,
    dialogFields,
    onClearDialog,
    onClickSubmit,
    setDialogValue,
    shortcode,
    submitText,
    t,
    title,
    values,
    forwardedRef,
    offsetY,
    isShortcode,
  } = props

  const clearDialogContent = () => {
    setMessage(null)
    onClearDialog()
  }

  const getValue = () => {
    if (!isShortcode) {
      return values
    }
    const parameters = []
    Object.keys(values).forEach(key => parameters.push(`${key}=${values[key]}`))
    return `[${shortcode} ${parameters.join('|')}]`
  }

  if (!dialogFields && !message) {
    return null
  }

  let content
  let submitButtonText

  if (!dialogFields) {
    content = message
    submitButtonText = submitText || t('Dialog:submit')
  } else {
    content = dialogFields.map((field, index) => {
      return (
        <DialogField
          field={field}
          index={index}
          key={field.name}
          onUpdateInputValues={setDialogValue}
          values={values}
        />
      )
    })
    submitButtonText = submitText || t('Dialog:submit')
  }

  return (
    <div
      className="yo-dialog"
      data-testid="dialog"
      key={title}
      onKeyDown={e => {
        if (e.keyCode === 27) {
          // The user pressed the escape key. Let's close the dialog.
          e.preventDefault()
          clearDialogContent()
        } else if (e.keyCode === 13) {
          // The user pressed enter. Let's submit!
          e.preventDefault()
          onClickSubmit(getValue())
          clearDialogContent()
        }
      }}
      ref={forwardedRef}
      role="presentation"
    >
      <div
        className="yo-dialog__background"
        onClick={() => clearDialogContent()}
        role="presentation"
      />
      <div className="yo-dialog__popup" style={{ top: POPUP_PADDING_TOP + offsetY }}>
        {title && <div className="yo-dialog__popup-title">{title}</div>}
        <div className="yo-dialog__popup-content">{content}</div>
        {dialogButtons}
        {!dialogButtons && (
          <div className="yo-dialog__popup-buttons">
            {onClickSubmit && (
              <Button
                onClick={() => clearDialogContent()}
                text={t('Dialog:cancel')}
                variant="secondary"
              />
            )}
            <Button
              attributes={{ 'data-testid': 'dialog-submit' }}
              onClick={() => {
                // Pass value returned by the dialog
                // to the callback to handle the return value.
                onClickSubmit(getValue())
                clearDialogContent()
              }}
              text={submitButtonText}
            />
          </div>
        )}
      </div>
    </div>
  )
}

Dialog.propTypes = {
  title: PropTypes.string,
  shortcode: PropTypes.string,
  onClickSubmit: PropTypes.func,
  dialogFields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      defaultValue: PropTypes.string,
      defaultChecked: PropTypes.bool,
      displayName: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  offsetY: PropTypes.number,
  isShortcode: PropTypes.bool,
}

Dialog.displayName = 'Dialog'

Dialog.defaultProps = {
  title: null,
  shortcode: null,
  dialogFields: null,
  onClickSubmit: () => {},
  forwardedRef: undefined,
  offsetY: 0,
  isShortcode: true,
}

const mapStateToProps = state => state.dialog

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClearDialog: () => {
    dispatch(clearDialog())
    if (typeof ownProps.onDialogCleared === 'function') {
      ownProps.onDialogCleared()
    }
  },
  setDialogValue: (key, value) => dispatch(setDialogValue(key, value)),
})

const POPUP_PADDING_TOP = 40

export const DisconnectedDialog = withTranslation()(Dialog)

const ConnectedDialog = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Dialog))

export default React.forwardRef((props, ref) => <ConnectedDialog {...props} forwardedRef={ref} />)
