import React from 'react'
import { withRouter } from 'react-router-dom'
import { knuthShuffle } from 'knuth-shuffle'
import { difference } from 'ramda'
import { withTranslation } from 'react-i18next'
import Fragment from '../../node/Fragment/Fragment'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import './order-question.scss'
import { REVIEW_ONLY_EXAM_TYPE } from '../../../constants/definitions/exams'
import OptionButton from '../../elements/OptionButton/OptionButton.tsx'
import { isInEditor } from '../../../utils/common'

const getOptionButtonType = ({ isReviewOnlyExam, submitted, correct }) => {
  if (!submitted || isReviewOnlyExam) {
    return null
  }
  return correct ? 'correct' : 'wrong'
}
export class OrderQuestion extends React.Component {
  constructor() {
    super()
    this.state = {
      options: [],
    }
  }

  componentDidMount() {
    this.setStateOptions(this.props.options, this.props.location)
  }

  componentDidUpdate(prevProps) {
    // Ran when adding new option to Ordered question.
    if (difference(this.props.options, prevProps.options).length > 0) {
      this.setStateOptions(this.props.options, this.props.location)
    }
  }

  setStateOptions(options, location) {
    this.setState({
      options: isInEditor(location) ? options : knuthShuffle(options.slice(0)),
    })
  }

  render() {
    const {
      submitted,
      selectedValues,
      options,
      onClickOption,
      onClickSelectedValue,
      examType,
      content,
    } = this.props

    const isReviewOnlyExam = examType === REVIEW_ONLY_EXAM_TYPE

    const order = Object.values(selectedValues).length

    const optionItems =
      this.state.options &&
      this.state.options.map(option =>
        Object.keys(selectedValues).includes(option.id.toString()) ? null : (
          <OptionButton
            isClickable={!submitted}
            key={option.id}
            onClick={() => onClickOption({ ...option, order })}
            type={null}
          >
            <RichTextContent content={option.text} />
          </OptionButton>
        ),
      )

    const selectedItems = Object.keys(selectedValues).length > 0 && (
      <div className="yo-order-question__options--selected">
        {Object.values(selectedValues)
          .sort((a, b) => a.order - b.order)
          .map((option, i) => (
            <div className="yo-order-question__option--wrapper" key={`${option.id}--selected`}>
              <div className="yo-order-question__option--index">{i + 1}.</div>
              <OptionButton
                hasIcon
                isClickable={!submitted}
                isSelected={!submitted}
                onClick={() => onClickSelectedValue(option)}
                type={getOptionButtonType({ isReviewOnlyExam, submitted, correct: option.correct })}
              >
                <RichTextContent content={option.text} />
              </OptionButton>
            </div>
          ))}
      </div>
    )

    const correctOrder = submitted && !isReviewOnlyExam && options && (
      <div className="yo-order-question__options--selected">
        {options.map((option, i) => (
          <div className="yo-order-question__option--wrapper" key={option.id}>
            <div className="yo-order-question__option--index">{i + 1}.</div>
            <OptionButton
              type={getOptionButtonType({ isReviewOnlyExam, submitted, correct: true })}
            >
              <RichTextContent content={option.text} />
            </OptionButton>
          </div>
        ))}
      </div>
    )

    return (
      <div>
        <Fragment
          displayNumber={content.displayNumber}
          examType={examType}
          text={<RichTextContent content={content.text} />}
        />
        <div className="yo-order-question">
          {(selectedItems || correctOrder) && (
            <div className="yo-order-question__selected">
              {selectedItems}
              {correctOrder}
            </div>
          )}
          {options && !submitted && optionItems?.length > 0 && (
            <div className="yo-order-question__options">{optionItems}</div>
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation(['CombineQuestion'])(withRouter(OrderQuestion))
