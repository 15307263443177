import { update } from 'ramda'
import { DELETE } from '../reviews/reviews'

const CLEAR_VALIDATION = 'validation/CLEAR_VALIDATION'
const SET_VALIDATION = 'validation/SET_VALIDATION'

// Reducer
export default function reducer(state = [], action) {
  switch (action.type) {
    case SET_VALIDATION: {
      const { inputId, isValid } = action
      const index = state.findIndex(input => input.inputId === inputId)
      if (index === -1) {
        return [...state, { inputId, isValid }]
      }
      return update(index, { inputId, isValid }, state)
    }
    case CLEAR_VALIDATION:
      return []
    case DELETE: {
      const { reviewId } = action
      return state.filter(input => !input.inputId?.endsWith(reviewId))
    }
    default:
      return state
  }
}

// Action creators
export const setInputValidation = ({ inputId, isValid }) => ({
  type: SET_VALIDATION,
  inputId,
  isValid,
})

export const clearValidations = () => ({
  type: CLEAR_VALIDATION,
})
