import React from 'react'
import { withTranslation } from 'react-i18next'
import Button from '../Button/Button.tsx'
import Timer from '../TimerHOC/Timer'
import './timer-start.scss'

class TimerStart extends React.PureComponent {
  componentDidMount() {
    this.handleCarouselStart()
  }

  componentDidUpdate(prevProps) {
    const { submitted, hideReview, questionStarted, nodeId, onTimerComplete } = this.props

    // If user skips in carousel, submit the previous node
    if (prevProps.nodeId !== nodeId && prevProps.questionStarted && !prevProps.submitted) {
      onTimerComplete(prevProps.nodeId)
    }

    // Handle auto start logic
    if (hideReview) {
      if (!questionStarted || (prevProps.submitted !== submitted && !submitted)) {
        this.handleCarouselStart()
      }
    }
  }

  handleCarouselStart = () => {
    const { isCarouselActive, onStartQuestion } = this.props
    if (isCarouselActive) {
      onStartQuestion()
    }
  }

  render() {
    const {
      questionSettings,
      questionStarted,
      renderOptions,
      renderClasses,
      onStartQuestion,
      onTimerComplete,
      timerProgress,
      second,
      submitted,
      isEditor,
      t,
    } = this.props

    const { timedQuestion, timePoints, duration } = questionSettings
    const isValid = duration && timePoints && timePoints.length > 0
    return timedQuestion && !questionStarted && !submitted && !isEditor ? (
      <div className="yo-timer-start__button">
        <Button
          attributes={{ 'data-testid': 'start-timer' }}
          isDisabled={!isValid}
          onClick={onStartQuestion}
          secondary
          text={t('common:start-timer')}
        />
      </div>
    ) : (
      <div>
        {timedQuestion && !isValid && (
          <div className="yo-timer-start__invalid-duration">
            Question duration or breakpoints is invalid.
          </div>
        )}
        {timedQuestion && (
          <Timer onComplete={onTimerComplete} progress={timerProgress} second={second} />
        )}
        <div className={renderClasses}>{renderOptions}</div>
      </div>
    )
  }
}

export default withTranslation(['TimerStart'])(TimerStart)
