import React, { useState, useEffect, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { times } from 'ramda'
import { withTranslation } from 'react-i18next'
import InputExtended from '../../elements/Input/InputExtended'
import { updateNodeSetting } from '../../../redux/modules/node/actions'
import { validationRules } from '../../../constants/definitions/entities/entities'
import './scored-vote-scoring.scss'

const DEFAULT_SCORE = 1
const DEFAULT_REQUIRED_ANSWERS = 1

const ScoredVoteScoring = ({ node, onUpdateNodeSetting, t }) => {
  const scoredVoteScoring = node.settings.scoredVoteScoring || {}

  const [requiredAnswers, setRequiredAnswers] = useState<number>(
    scoredVoteScoring?.max ?? DEFAULT_REQUIRED_ANSWERS,
  )
  const [scores, setScores] = useState<number[]>(scoredVoteScoring?.scores || [DEFAULT_SCORE])

  // dispatch setting changes to store
  useEffect(() => {
    onUpdateNodeSetting(node.id, {
      scoredVoteScoring: { max: requiredAnswers, scores },
    })
  }, [requiredAnswers, scores])

  const onRequiredAnswersChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newRequiredAnswers = parseInt(e.target.value, 10)
    setRequiredAnswers(newRequiredAnswers)
    const maxScoreInScores = Math.max(...scores)

    // Add scoring items
    if (newRequiredAnswers > 0 && newRequiredAnswers > scores.length) {
      // Add new scores with values when there are missing scores
      setScores(prevScores => [
        ...times(n => maxScoreInScores + n + 1, newRequiredAnswers - scores.length).reverse(),
        ...prevScores,
      ])
    }

    // Remove scoring items
    if (newRequiredAnswers > 0 && newRequiredAnswers < scores.length) {
      setScores(prevState => prevState.slice(0, newRequiredAnswers))
    }
  }

  const onScoreChange = (e: ChangeEvent<HTMLInputElement>, scoreIndex: number) => {
    const newScore = parseInt(e.target.value, 10)
    setScores(prevState => {
      const newState = [...prevState]
      newState[scoreIndex] = newScore
      return newState
    })
  }

  const scoresWithOrders = scores.map((score, index) => ({
    score,
    order: index,
  }))

  return (
    <>
      <InputExtended
        attributes={{
          'data-testid': 'input-scored-vote-required-answers',
        }}
        defaultValue={String(requiredAnswers)}
        description="scored-vote-required-answers"
        label="Required Answers"
        onChange={onRequiredAnswersChange}
        type="number"
        validation={validationRules.POSITIVE_INTEGER}
        width="narrow"
      />
      <div className="yo-input__description">{t('vote-score')}</div>
      {scoresWithOrders.map(({ score, order }) => (
        <div className="yo-scored-vote-scoring__score-input-container" key={`score-${order}`}>
          <label className="yo-scored-vote-scoring__score-input-label">
            {`${order + 1}. score:`}
          </label>
          <InputExtended
            attributes={{
              'data-testid': 'input-scored-vote-score',
            }}
            defaultValue={String(score)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onScoreChange(e, order)}
            type="number"
            validation={validationRules.INTEGER}
            width="narrow"
          />
        </div>
      ))}
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  onUpdateNodeSetting: (nodeId, setting) => {
    dispatch(updateNodeSetting(nodeId, setting))
  },
})

export default withTranslation(['ScoredVoteScoring'])(
  connect(undefined, mapDispatchToProps)(ScoredVoteScoring),
)
