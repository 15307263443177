import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Accordion, AccordionItem } from '@yleisradio/yds-components-react'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import SeriesExams from '../SeriesExams/SeriesExams'
import { scrollToView } from '../../../utils/common'
import { LoginButton } from '../../layout/YleTunnus/Login'
import './series-info.scss'
import SecondaryHeader from '../../elements/Typography/SecondaryHeader.tsx'

class SeriesInfo extends React.Component {
  constructor(props) {
    super(props)
    this.onClickExam = this.onClickExam.bind(this)
  }

  onClickExam(exam) {
    const { rootElementId, darkMode } = this.props
    if (exam.published_url) {
      window.location = exam.published_url
    } else {
      // We need to unmount widget first before trying to mount new widget
      ReactDOM.unmountComponentAtNode(document.getElementById(rootElementId))
      window.tehtavaApp.mount(`47-${exam.uuid}`, document.getElementById(rootElementId), {
        darkMode,
        lang: null,
      })
      // Scroll to beginning of exam
      setTimeout(() => scrollToView(rootElementId), 0)
    }
  }

  render() {
    const { series, t, collapsible, yleTunnus, dispatch } = this.props
    if (!series) {
      return null
    }

    const headers = [
      {
        id: 'name',
        name: t('name'),
        class: 'yo-exams__item--name',
      },
      {
        id: 'score',
        name: t('score'),
        class: 'yo-exams__item--score',
      },
      {
        id: 'progress',
        name: t('done'),
        class: 'yo-exams__item--progress',
      },
    ]
    const content = (
      <div className="yo-series-info__content">
        <SecondaryHeader>{series.name}</SecondaryHeader>
        <div className="yo-series-info__desc">
          <RichTextContent content={series.description} />
          {!yleTunnus.nick && (
            <div className="yo-series-info__promote">
              {t('promote-yle-tunnus')}
              <LoginButton
                classNames={['yo-series-info__promote-link']}
                dispatch={dispatch}
                t={t}
              />
            </div>
          )}
        </div>
        <div>
          <SeriesExams
            exams={series.exams}
            headers={headers}
            onClickExam={this.onClickExam}
            seriesClassification={series.classification}
          />
        </div>
      </div>
    )
    const seriesListContent = collapsible ? (
      <Accordion>
        <AccordionItem key={series.uuid} title={series.name}>
          {content}
        </AccordionItem>
      </Accordion>
    ) : (
      <div className="yo-series-info__wrapper">{content}</div>
    )

    return <div className="yo-series-info">{seriesListContent}</div>
  }
}

const mapStateToProps = state => ({
  rootElementId: state.embed.rootElementId,
  yleTunnus: state.yleTunnus,
})

export default withTranslation(['Series'])(withRouter(connect(mapStateToProps)(SeriesInfo)))
