// Actions
const CLEAR_SEARCH_FILTER = 'filters/exams/CLEAR_SEARCH'
const SET_SEARCH_FILTER = 'filters/exams/SET_SEARCH'

// Action creators
export const filterExamsBySearch = searchStr => ({
  type: SET_SEARCH_FILTER,
  searchStr,
})

export const resetExamsSearchFilter = () => ({
  type: CLEAR_SEARCH_FILTER,
})

const defaultState = ''

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SEARCH_FILTER:
      return action.searchStr

    case CLEAR_SEARCH_FILTER:
      return defaultState

    default:
      return state
  }
}
