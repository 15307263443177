import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { nodeTypes } from '../../../constants/definitions/entities/entities.ts'
import { updateNode } from '../../../redux/modules/node/actions.ts'
import { getNodeId, getStatus } from '../../../redux/modules/editing/selectors'
import { getNode, getNodeType } from '../../../redux/modules/nodes/selectors'
import { getOptions } from '../../../redux/modules/options/selectors'
import InputExtended from '../../elements/Input/InputExtended'
import SidebarHeader from '../Sidebar/SidebarHeader'
import Tabs, { TabPanel } from '../Tabs/Tabs'
import EditorOptions from './EditorOptions'
import EditorSettings from './EditorSettings'
import EditorFields from './EditorFields'

const Editor = props => {
  if (props.render === false) {
    return null
  }

  const { node, nodeType, t } = props
  const isSection = nodeType.id === nodeTypes.SECTION.id
  const hasOptions =
    typeof nodeType.optionFields !== 'undefined' || typeof nodeType.choiceFields !== 'undefined'
  const hasSettings = nodeType.settingFields?.length > 0

  return (
    <div className="yo-editor" data-testid="yo-editor">
      <SidebarHeader title={`Edit ${nodeType.displayName}`} />
      <Tabs key={node.id} node={node}>
        <TabPanel label={t('editor')}>
          <EditorFields {...props} />
          {hasOptions && <EditorOptions node={node} nodeType={nodeType} />}
        </TabPanel>
        <TabPanel hide={!hasSettings && !isSection} label={t('settings')}>
          <EditorSettings {...props} />
        </TabPanel>
        <TabPanel hide={nodeType.feedback !== true} label={t('feedback')}>
          <InputExtended
            autofocus
            defaultValue={node.feedback}
            key={node.id}
            label="Feedback"
            onChange={feedback => props.onChangeFeedback({ node, feedback })}
            type="rich-text"
          />
        </TabPanel>
      </Tabs>
    </div>
  )
}

const mapStateToProps = state => {
  // Get status of editing and id of the current node in edit state.
  const editingStatus = getStatus(state)
  const editingNodeId = getNodeId(state)

  let stateProps = {
    render: false,
    node: null,
    fields: [],
    optionFields: [],
    settingFields: [],
  }

  if (state.loadState.loaded === true && editingStatus === 'edit' && editingNodeId) {
    const node = getNode(state, editingNodeId)
    const nodeType = getNodeType(state, node.nodeTypeId)

    stateProps = Object.assign({}, stateProps, {
      render: true,
      node,
      nodeType,
      fields: nodeType.fields || [],
      settingFields: nodeType.settingFields || [],
      optionFields: nodeType.optionFields || [],
    })

    // Get options only for questions that should have embeddable options
    // in rich text editor.
    if (nodeType.shortcode) {
      const options = getOptions(state.entities, node.id, node.options)
      const selectOptions = []

      Object.keys(options).forEach(key => {
        const option = options[key]

        if (option.label) {
          selectOptions.push({
            id: option.id,
            name: option.label,
            attributes: {
              'data-shortcode': nodeType.shortcode,
            },
          })
        }
      })

      stateProps = Object.assign({}, stateProps, {
        selectOptions,
      })
    }
  }

  return {
    ...stateProps,
    exam: state.exam,
  }
}

const mapDispatchToProps = dispatch => ({
  onChangeFeedback: ({ node, feedback }) => {
    dispatch(updateNode(node.id, { ...node, feedback }))
  },
})

export default withTranslation(['Editor'])(connect(mapStateToProps, mapDispatchToProps)(Editor))
