import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { i18n } from 'i18next'
import './gap-option.scss'
import { TextInput } from '@yleisradio/yds-components-react'
import { Check, Close } from '@yleisradio/yds-icons-react'
import { withTranslation } from 'react-i18next'
import { getExamType } from '../../../redux/modules/exam/selectors'
import { OTHER_EXAM_TYPE, REVIEW_ONLY_EXAM_TYPE } from '../../../constants/definitions/exams'

interface Props {
  answered: boolean | undefined
  correct: boolean
  correctAnswer: string | null
  option: any
  validate: boolean
  value: string | undefined
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => {}
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  examType: OTHER_EXAM_TYPE | REVIEW_ONLY_EXAM_TYPE
  t: i18n['t']
}

const GapOption = ({
  answered,
  correct,
  correctAnswer,
  option,
  validate,
  value,
  onBlur,
  onChange,
  examType,
  t,
}: Props) => {
  const isReviewOnlyExam = examType === REVIEW_ONLY_EXAM_TYPE
  const isOtherExam = examType === OTHER_EXAM_TYPE

  const inputValue =
    value && typeof value[option.id] !== 'undefined' && value[option.id] !== null
      ? value[option.id]
      : ''

  // If user has answered, display the right icon, if not, display nothing.
  const inputIcon = () => {
    if (validate && answered && !isReviewOnlyExam && correct) {
      return {
        componentFn: Check,
        ariaLabel: t('common:correct-answer'),
      }
    }

    if (validate && answered && !isReviewOnlyExam && !correct) {
      return {
        componentFn: Close,
        ariaLabel: t('common:wrong-answer'),
      }
    }

    return undefined
  }

  return (
    <div className="yo-gap-option" key={option.id}>
      <div className="yo-gap-option__review">
        {'hint' in option && option.hint.length > 0 && (
          <span className="yo-gap-option__hint">{` (${option.hint})`}</span>
        )}
      </div>
      <div className="yo-gap-option__input">
        {!isOtherExam && <span className="yo-gap-option__numbering" />}
        <TextInput
          data-option-index={option.id}
          data-testid="gap-option"
          disabled={validate}
          icon={inputIcon()}
          id={`gap-option-${option.id}`}
          label=""
          onBlur={onBlur}
          onChange={onChange}
          placeholder=""
          type="text"
          value={inputValue}
        />
      </div>
      {correctAnswer && !isReviewOnlyExam && (
        <span className="yo-gap-option__answer">{correctAnswer}</span>
      )}
    </div>
  )
}

const getCaseSensitive = (string: string, isCaseSensitive: boolean) =>
  isCaseSensitive ? string : string.toUpperCase()

const checkCorrectness = (answer: string, corrrectAnswers: string, isCaseSensitive: boolean) => {
  // Create an array of correct values.
  const correctAnswersArray =
    typeof corrrectAnswers !== 'undefined'
      ? corrrectAnswers
          .split('|')
          .filter(val => val !== '')
          .map(val => {
            return getCaseSensitive(val.trim(), isCaseSensitive)
          })
      : []

  return correctAnswersArray.includes(getCaseSensitive(answer, isCaseSensitive))
}

const mapStateToProps = (state, ownProps) => {
  const { id, options, value } = ownProps
  const option = options.find(o => o.id.toString() === id)

  const hasUserAnswered =
    value && typeof value[option.id] !== 'undefined' && value[option.id] !== null

  const isCorrect = hasUserAnswered
    ? checkCorrectness(value[option.id], option.correct, option.isCaseSensitive)
    : false

  return {
    answered: hasUserAnswered,
    correct: isCorrect,
    correctAnswer: (ownProps.submitted && option.correct.replaceAll('|', ', ')) || null,
    option,
    // Whether the answer should be validated.
    validate: ownProps.submitted,
    examType: getExamType(state),
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(GapOption)
