import React, { ChangeEvent, MouseEvent } from 'react'
import { i18n } from 'i18next'
import { ChoiceButton } from '@yleisradio/yds-components-react'
import { Check, Close } from '@yleisradio/yds-icons-react'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import ProgressBar from '../../elements/ProgressBar/ProgressBar'

export const extractImages = (label: string) => {
  if (!label) {
    return {}
  }
  const imageRegExp = /\[IMS id=.*?\]/g
  const labelWithoutImages = label.replace(imageRegExp, '')
  const images = label.match(imageRegExp)
  return { labelWithoutImages, images }
}

export const MultipleChoiceOptionImageContent = ({ images, showStats, percentage, count }) => (
  <div>
    {showStats && !Number.isNaN(percentage) && (
      <div className="choice-button-stats-container">
        <ProgressBar color={null} count={count} height=".5em" progress={percentage} showProgress />
      </div>
    )}
    <RichTextContent content={images && images.join('')} />
  </div>
)

export const getStatus = ({
  submitted,
  checked,
  correct,
  isReviewOnlyExam,
  hasCorrectAnswers,
}): MultipleChoiceButtonStatus => {
  const showOptionIsCorrect = submitted && correct && !isReviewOnlyExam && hasCorrectAnswers
  const showOptionIsIncorrect =
    submitted && checked && !correct && !isReviewOnlyExam && hasCorrectAnswers
  if (showOptionIsCorrect) {
    return 'success'
  }
  if (showOptionIsIncorrect) {
    return 'error'
  }
  return null
}

export const getStatusAriaLabel = (t: i18n['t'], status: MultipleChoiceButtonStatus) => {
  switch (status) {
    case 'error':
      return t('common:wrong-answer')
    case 'success':
      return t('common:correct-answer')
    default:
      return undefined
  }
}

export type MultipleChoiceButtonStatus = 'success' | 'error' | null

export const getIcon = (hasIcon?: boolean, status?: MultipleChoiceButtonStatus) => {
  if (hasIcon) {
    switch (status) {
      case 'success':
        return <Check />
      case 'error':
        return <Close />
      default:
        return null
    }
  }
  return null
}

interface MultipleChoiceOptionProps {
  attributes: { [key: string]: string }
  checked: boolean
  label: string
  name?: string
  status?: MultipleChoiceButtonStatus
  hasIcon?: boolean
  content?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onClick?: (event: MouseEvent<HTMLInputElement>) => void
}

const MultipleChoiceOption = ({
  attributes,
  checked,
  label,
  name,
  status,
  hasIcon,
  onChange,
  onClick,
  content,
}: MultipleChoiceOptionProps) => {
  const icon = getIcon(hasIcon, status)
  const props = {
    checked,
    status: status || undefined,
    icon,
    name,
    label,
    content,
    onChange,
    onClick,
    isDisabled: Boolean(attributes.disabled),
    ...attributes,
  }

  return (
    <div data-testid="multiple-choice-option">
      <ChoiceButton {...props}></ChoiceButton>
    </div>
  )
}

export default MultipleChoiceOption
