import React from 'react'
import './exam-review.scss'
import ThanksForVoting from '../../node/NodeReview/ThanksForVoting.tsx'

const VoteReview = () => {
  return (
    <div className="yo-exam-review" data-testid="vote-review">
      <ThanksForVoting />
    </div>
  )
}

export default VoteReview
