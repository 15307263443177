import React from 'react'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import ShareReview from '../../layout/ShareReview/ShareReview'
import { getShareUrl } from '../../layout/ShareReview/ShareReviewHelper'
import SecondaryHeader from '../../elements/Typography/SecondaryHeader.tsx'

const CharactersReview = ({ review, examId, exam }) => {
  const url = review ? getShareUrl(review.share, examId) : null
  const displayShare = exam && exam.enableShare && review?.share && url

  return (
    <div className="yo-characters-review" data-testid="characters-review">
      <SecondaryHeader>{review?.title}</SecondaryHeader>
      <div className="yo-characters-review__desc">
        <RichTextContent content={review?.description} />
      </div>
      {displayShare && <ShareReview review={review} title={review?.share.title} url={url} />}
    </div>
  )
}

export default CharactersReview
