const prefix = 'webviewApi/'
export const SHARE_RESULT = `${prefix}SHARE_RESULT`
export const SET_ARTICLE_URL = `${prefix}SET_ARTICLE_URL`
export const SET_LOGIN_REQUIRED = `${prefix}SET_LOGIN_REQUIRED`
export const ONPRESS_LOGIN = `${prefix}ONPRESS_LOGIN`

export const shareResult = ({ url, title, shareId, shareApiUrl, fullShareApiUrl, examId }) => ({
  type: SHARE_RESULT,
  title,
  url,
  shareApiUrl,
  fullShareApiUrl,
  shareId,
  examId,
})

export const setArticleUrl = ({ url }: { url: string }) => ({
  type: SET_ARTICLE_URL,
  url,
})

export const setLoginRequired = ({ required, hidden }: { required: boolean; hidden: boolean }) => ({
  type: SET_LOGIN_REQUIRED,
  required,
  hidden,
})

export const onPressLogin = () => ({
  type: ONPRESS_LOGIN,
})
