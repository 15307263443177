import { serializePollsDataFromApi } from '../dataService/stateSerializer'
import { apiURL, parseJSON } from '../dataService/apiV1'
import { handleLoadError } from '../examService/examService'
import { applyFeedbackStats } from '../../modules/nodes/nodes'

export const FETCH_POLLS = 'api/FETCH_POLLS'

export const fetchPollStats = uuids => ({
  type: FETCH_POLLS,
  uuids,
})

const pollService = () => next => action => {
  next(action)
  switch (action.type) {
    case FETCH_POLLS:
      return fetch(apiURL(`/public/polls?question_uuids=${action.uuids.join(',')}`))
        .then(parseJSON)
        .then(res => {
          next(applyFeedbackStats({ data: serializePollsDataFromApi(res.data) }))
        })
        .catch(handleLoadError)
    default:
      break
  }
  return null
}

export default pollService
