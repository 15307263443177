import React from 'react'
import { connect } from 'react-redux'
import Icon from '../../elements/Icon/Icon'
import logos from '../../../constants/definitions/logos'
import './exam-logo.scss'

const ExamLogo = exam => {
  // Get logo data from logo definitions
  const logo = logos[exam.exam.exam_type]

  // Don't show anything unless a logo is actually defined
  if (typeof logo === 'undefined') {
    return null
  }

  const logoIcon = logo.icon ? (
    <span className="yo-exam-logo__image">
      <Icon height="100" icon={logo.icon} width="100" />
    </span>
  ) : null

  // If a logo has linebreaks, let's separate them to separate <span> elements
  const logoTextRows = logo.text
    ? logo.text.split('\n').map(item => (
        <span className="yo-exam-logo__text-row" key={item}>
          {item}
        </span>
      ))
    : null

  const logoText = logoTextRows ? <span className="yo-exam-logo__text">{logoTextRows}</span> : null

  return (
    (logoIcon || logoText) && (
      <div className="yo-exam-logo">
        {logoIcon}
        {logoText}
      </div>
    )
  )
}

const mapStateToProps = state => ({
  exam: state.exam,
})

export default connect(mapStateToProps)(ExamLogo)
