export enum OptionLayoutType {
  default = '1',
  labels = 'labels',
  radios = 'radios',
}

const optionLayoutTypes = {
  labels: OptionLayoutType.labels,
  radios: OptionLayoutType.radios,
}

export default optionLayoutTypes
