import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Button } from '@yleisradio/yds-components-react'
import { ExternalLink } from '@yleisradio/yds-icons-react'
import { fetchImsData, createImsImageUrl } from './ImsImageHelper'
import './image.scss'
import {
  imsShortCodeLanguageMappings,
  prioritizedImsShortCodeLanguages,
} from '../../../constants/definitions/exams'

const CAPTION_MAX_LENGTH = 120
const IMAGE_MIN_SIZE = 100
const CONTAINER_PADDING = 128
const MAX_WIDGET_WIDTH = 960
const MAX_CONTAINER_WIDTH = 600

class ImsImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imsData: null,
      showFullCaption: this.props.caption.length < CAPTION_MAX_LENGTH,
    }
    this.handleCaptionToggleClick = this.handleCaptionToggleClick.bind(this)
  }

  componentDidMount() {
    if (this.checkImsImage()) {
      this.fetchImsImageData(this.props.id)
    }
  }

  componentDidUpdate(prevProps) {
    // If IMS id in props has changed fetch new image data.
    if (this.checkImsImage() && this.props.id !== prevProps.id) {
      this.fetchImsImageData(this.props.id)
    }
  }

  // Method for parsing alt text or copyright info from IMS image data.
  getDataValue(key, language) {
    const data = this.state.imsData[key]

    if (!Array.isArray(data)) {
      return ''
    }

    const items = language ? data.filter(item => item.language === language) : []

    if (items.length !== 1) {
      return ''
    }
    // eslint-disable-next-line
    const { value } = items[0]

    return value
  }

  getAltTextWithFallbackToOtherLanguages(language) {
    const [firstMatch] = [
      imsShortCodeLanguageMappings[language],
      ...prioritizedImsShortCodeLanguages,
    ]
      .map(lang => this.getDataValue('alt', lang))
      .filter(value => value && value.length > 0)

    return firstMatch || ''
  }

  checkImsImage() {
    // Check if url passed in the props is IMS id.
    return /^\d+-.+/.test(this.props.id)
  }

  fetchImsImageData(imageId) {
    fetchImsData(imageId)
      .then(json => {
        // Store image data in state.
        this.setState({ imsData: json.data })
      })
      .catch(() => {
        this.setState({ imsData: null })
      })
  }

  handleCaptionToggleClick() {
    this.setState(prevState => ({
      showFullCaption: !prevState.showFullCaption,
    }))
  }

  render() {
    if (!this.state.imsData) {
      return null
    }

    // Create image using IMS image data.
    const imageUrl = createImsImageUrl({
      aspect: this.props.aspect,
      imsData: this.state.imsData,
    })
    const { caption, imageLink, showCopyright, language, t } = this.props
    const alt = this.getAltTextWithFallbackToOtherLanguages(language)
    const copyright = this.getDataValue('copyright', 'und')
    const title = caption.length > 0 && copyright.length > 0 ? `${caption}. ${copyright}` : caption
    let size = this.props.size || 100
    const clientWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    // Calculate image container width
    let containerWidth =
      clientWidth > MAX_WIDGET_WIDTH
        ? MAX_WIDGET_WIDTH - CONTAINER_PADDING
        : clientWidth - CONTAINER_PADDING
    // Container width can't be bigger than max container width which is set by css
    if (containerWidth > MAX_CONTAINER_WIDTH) {
      containerWidth = MAX_CONTAINER_WIDTH
    }
    // Calculate image size on screen.
    const imgSize = Math.floor((size * containerWidth) / 100)
    // If image size is less than 100px, set it to ~100px.
    if (imgSize < IMAGE_MIN_SIZE) {
      size = (IMAGE_MIN_SIZE / containerWidth) * IMAGE_MIN_SIZE
    }
    const captionClassnames = classnames('yo-image__captions', {
      'yo-image__captions--center': this.props.alignCenter,
    })

    return (
      <figure className="yo-image">
        <img
          alt={alt}
          className="yo-image__image"
          src={imageUrl}
          title={title}
          width={`${size}%`}
        />

        <figcaption className={captionClassnames}>
          {caption ?? ''}
          {copyright && showCopyright !== 'false' && (
            <span className="yo-image__copyright">{`${t('copyright')}: ${copyright}`}</span>
          )}
          {imageLink !== 'false' && (
            <Button
              as="a"
              className="yo-image__caption-link"
              href={imageUrl}
              iconBefore={<ExternalLink />}
              iconOnly
              rel="noreferrer noopener"
              size="xs"
              target="_blank"
              title={t('open-image-in-new-tab')}
            />
          )}
        </figcaption>
      </figure>
    )
  }
}

ImsImage.propTypes = {
  // IMS public id of the image.
  id: PropTypes.string.isRequired,
  language: PropTypes.string,
  // Image caption.
  caption: PropTypes.string,
  imageLink: PropTypes.string,
  showCopyright: PropTypes.string,
}

ImsImage.defaultProps = {
  caption: '',
  imageLink: '',
  showCopyright: '',
  language: null,
}

export default withTranslation(['ImsImage'])(ImsImage)
