import { createSelector } from 'reselect'
import { getSeriesLoading } from '../series/selectors'

export const getLoadStateLoaded = state => state.loadState.loaded
export const getLoadState = state => state.loadState
export const getLoadedNodeId = state => state.loadState.nodeId
export const getErrorMessage = state => state.loadState.errorMessage
export const getIsExamOrSeriesLoading = createSelector(
  [getLoadStateLoaded, getSeriesLoading],
  (examLoaded, seriesLoading) => !examLoaded || seriesLoading,
)
