import React from 'react'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import './select.scss'

const Select = props => {
  const {
    attributes,
    className,
    defaultLabel = '- Select -',
    defaultValue = '',
    description,
    disabled,
    inline = true,
    label,
    onChange,
    options,
    t,
  } = props

  if (!options || !options.length) {
    return null
  }

  // Allow the use of custom class names for the parent div
  const divClassName = classnames('yo-select', className)

  // For the Question type selector, add a new classname for the select element
  // so it is not displayed in-line with its label.
  const selectClassName = classnames('yo-select__select', {
    'yo-select__select--inline': inline,
  })

  const value = defaultValue
  const inputId = `yo-select--${label}`
    .replace(/ /g, '-')
    .toLowerCase()
    .trim()

  return (
    <div className={divClassName}>
      <label htmlFor={inputId}>
        <span className="yo-select__label">{label}</span>
        <select
          {...attributes}
          className={selectClassName}
          disabled={disabled}
          id={inputId}
          onChange={onChange}
          value={value}
        >
          <option disabled value="">
            {defaultLabel}
          </option>
          {options.map(option => (
            <option key={option.id} value={option.id} {...option.attributes}>
              {option.name}
            </option>
          ))}
        </select>
      </label>
      {description && <div className="yo-select__description">{t(description)}</div>}
    </div>
  )
}

export default withTranslation(['Select'])(Select)
