import { ValidationRule } from './validationRules'
import { OptionLayoutType } from './optionLayoutTypes'

export enum FieldTypeEditorFormatter {
  checkbox = 'checkbox',
  text = 'text',
  select = 'select',
  autocompleteMetaApi = 'autocomplete-meta-api',
  richText = 'rich-text',
  dateTimePicker = 'dateTimePicker',
}

export enum FieldTypeDisplayWidth {
  narrow = 'narrow',
}

export enum FieldTypeSettingFilter {
  customizedPoints = 'customizedPoints',
  hideableText = 'hideableText',
}

export interface FieldTypeSubjectFilter {
  id: string
  name: string
}

export interface FieldTypeOption {
  id: number | string
  name: string
}

export interface FieldType {
  name: string
  description?: string
  displayName: string
  editorFormatter: FieldTypeEditorFormatter
  examTypeFilter?: string[]
  displayWidth?: FieldTypeDisplayWidth
  optional?: boolean
  isSeparator?: boolean
  defaultValue?: any
  settingFilter?: FieldTypeSettingFilter | ((settings: NodeStateSettings) => boolean)
  subjectFilter?: FieldTypeSubjectFilter[]
  options?: FieldTypeOption[]
  validation?: ValidationRule
  inline?: boolean
  content?: NodeContent | {}
}

export enum NodeTypeCategory {
  CONTAINER = 'NODE_TYPE__CONTAINER',
  CONTENT = 'NODE_TYPE__CONTENT',
  QUESTION = 'NODE_TYPE__QUESTION',
  QUESTION_PART = 'NODE_TYPE__QUESTION_PART',
}

export enum NodeType {
  EXAM_ROOT = 'EXAM_ROOT',
  QUESTION_COPY = 'QUESTION_COPY',
  TEXT = 'TEXT',
  MULTIPLE_CHOICE_QUESTION = 'MULTIPLE_CHOICE_QUESTION',
  DROPDOWN_QUESTION = 'DROPDOWN_QUESTION',
  GAP_QUESTION = 'GAP_QUESTION',
  MULTIPLE_CHOICE_GAP_QUESTION = 'MULTIPLE_CHOICE_GAP_QUESTION',
  COMBINE_QUESTION = 'COMBINE_QUESTION',
  ORDER_QUESTION = 'ORDER_QUESTION',
  ESSAY_QUESTION = 'ESSAY_QUESTION',
  TEXTBOX_QUESTION = 'TEXTBOX_QUESTION',
  CUSTOMIZED_FEEDBACK = 'CUSTOMIZED_FEEDBACK',
  EXAM_QUESTION = 'EXAM_QUESTION',
  TV_EPISODE_ROOT = 'TV_EPISODE_ROOT',
  SECTION = 'SECTION',
  ARCHTYPE_ROOT = 'ARCHTYPE_ROOT',
  ARCHTYPE_MULTIPLE_CHOICE = 'ARCHTYPE_MULTIPLE_CHOICE',
  ARCHTYPE_CUSTOMIZED_FEEDBACK = 'ARCHTYPE_CUSTOMIZED_FEEDBACK',
  POLL_ROOT = 'POLL_ROOT',
  POLL_MULTIPLE_CHOICE = 'POLL_MULTIPLE_CHOICE',
  POLL_CUSTOMIZED_FEEDBACK = 'POLL_CUSTOMIZED_FEEDBACK',
  VOTE_ROOT = 'VOTE_ROOT',
  VOTE_QUESTION = 'VOTE_QUESTION',
  SCORED_VOTE_QUESTION = 'SCORED_VOTE_QUESTION',
  REVIEW_ONLY_ROOT = 'REVIEW_ONLY_ROOT',
}

export type EvaluateCallback = (state: NodeState) => boolean

export interface TehtavaNode {
  id: NodeType
  name: string
  displayName: string
  description?: string
  feedback?: boolean
  scoring?: boolean
  reviewable?: boolean
  type: NodeTypeCategory
  childNodeTypeIds: NodeType[]
  fields: FieldType[]
  settingFields?: FieldType[]
  optionFields?: FieldType[]
  choiceFields?: FieldType[]
  shortcode?: string
  maxNumberOfOptions?: number
  evaluateCompleted?: EvaluateCallback
}

export interface NodeOption {
  selectedPosition: number
  score: number
  id: number
  text: string
  choices: []
  correct: boolean
  order: number
}
export interface NodeContent {
  text?: string
  title?: string
  displayNumber?: string
}

export interface ScoredVoteScoring {
  max: number
  scores: number[]
}

export interface NodeStateSettings {
  requireN?: string
  points?: number
  optionsLayout?: OptionLayoutType
  scoredVoteScoring?: ScoredVoteScoring
}

export interface NodeState {
  id?: string
  nodeTypeId?: NodeType
  childIds?: string[]
  options?: number[]
  tags?: string[]
  content?: NodeContent
  value?: NodeOption[]
  settings?: NodeStateSettings
  visible?: boolean
  completed?: boolean
  submitted?: boolean
  feedback?: string
}

// Includes all the node types which can be autosubmitted
export const AutoSubmitNodeTypes = [
  NodeType.ARCHTYPE_CUSTOMIZED_FEEDBACK,
  NodeType.ARCHTYPE_MULTIPLE_CHOICE,
  NodeType.COMBINE_QUESTION,
  NodeType.CUSTOMIZED_FEEDBACK,
  NodeType.GAP_QUESTION,
  NodeType.MULTIPLE_CHOICE_GAP_QUESTION,
  NodeType.MULTIPLE_CHOICE_QUESTION,
  NodeType.ORDER_QUESTION,
  NodeType.POLL_CUSTOMIZED_FEEDBACK,
  NodeType.POLL_MULTIPLE_CHOICE,
]
