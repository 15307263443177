import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import './share-review.scss'
import { Facebook, Twitter, Whatsapp } from '@yleisradio/yds-icons-react'
import Button from '../../elements/Button/Button.tsx'
import { shareResult } from '../../../webviewApi/actions.ts'
import { getArticleUrl } from '../../../webviewApi/selectors'
import { getExamId } from '../../../redux/modules/nodes/selectors'
import config from '../../../config'
import { VOTE } from '../../../constants/definitions/exams'
import { getExamType } from '../../../redux/modules/exam/selectors'

export const withUtmParams = (url, source) => {
  // TODO: Revert this since webview share won't require it anymore
  if (!url) return null
  const u = new URL(url)
  u.searchParams.append('utm_source', `${source}_source`)
  u.searchParams.append('utm_medium', 'social')
  u.searchParams.append('utm_campaign', 'share_api')
  return u.href.replace('/?', '?') // Removes the trailing / before the query
}

const ShareReview = ({ url, review, t, webviewArticleUrl, examId, examType, dispatch }) => {
  const title = review?.share?.title
  const shareId = review?.share?.id
  const wrapperRef = React.useRef()
  React.useEffect(() => {
    if (IS_WEBVIEW_EMBED && wrapperRef.current) {
      setTimeout(() => {
        wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 400)
    }
  }, [])
  const shareOnWebviewContainer = () =>
    dispatch(
      shareResult({
        fullShareApiUrl: `${config.share_api_url}/47-${examId}/${shareId}.html`,
        shareApiUrl: config.share_api_url,
        url: webviewArticleUrl,
        title,
        shareId,
        examId,
      }),
    )
  return (
    <div className="yo-sharing-review__wrapper" ref={wrapperRef}>
      {(IS_WEBVIEW_EMBED && (
        <Button fullWidth onClick={shareOnWebviewContainer} text={t('ShareReview:share')} />
      )) || (
        <>
          <h3>
            {t(examType === VOTE ? 'ShareReview:share-top-voted-choice' : 'ShareReview:share')}
          </h3>
          <div className="yo-sharing-review__icons">
            <FacebookShareButton url={withUtmParams(url, 'facebook')}>
              <Facebook />
            </FacebookShareButton>
            <TwitterShareButton title={title} url={withUtmParams(url, 'twitter')}>
              <Twitter />
            </TwitterShareButton>
            <WhatsappShareButton url={withUtmParams(url, 'whatsapp')}>
              <Whatsapp />
            </WhatsappShareButton>
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  webviewArticleUrl: getArticleUrl(state),
  examId: getExamId(state),
  examType: getExamType(state),
})

export default withTranslation()(connect(mapStateToProps)(ShareReview))
