let yleTunnusUser = {
  loggedIn: false,
  nick: null,
  userId: null,
  initials: null,
  loaded: false,
}

export const getYleTunnusUser = () => yleTunnusUser

// Dispatch yleTunnusUser change.
// We needed a way to dispatch events outside of react to handle yletunnus login changes
// This is catched at Login component
const dispatchYleTunnusChange = () => {
  const event = new Event('tehtavaYleTunnusChanged')
  window.dispatchEvent(event)
}

const onLogin = (userId, nick, initials) => {
  if (!yleTunnusUser.loggedIn && userId !== yleTunnusUser.userId) {
    yleTunnusUser = {
      loggedIn: true,
      nick,
      userId,
      initials,
      loaded: true,
    }
    dispatchYleTunnusChange()
  }
}

const onLogout = () => {
  yleTunnusUser = {
    loggedIn: false,
    nick: null,
    userId: null,
    initials: null,
    loaded: true,
  }
  dispatchYleTunnusChange()
}

export default {
  onLogin,
  onLogout,
}
