import classnames from 'classnames'
import React from 'react'
import { equals, isEmpty } from 'ramda'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { removeTag } from '../../../redux/modules/node/actions.ts'
import { removeTopic, removeLocalTag } from '../../../redux/modules/defaultCarousel/defaultCarousel'
import { removeSeriesId } from '../../../redux/modules/exam/exam'
import fetchTags from '../../../utils/tags'
import Icon from '../Icon/Icon'
import { getSeriesData } from '../../../redux/modules/series/selectors'
import './tags.scss'
import { isEditing } from '../../../utils/common'
import { getExamLanguageOrDefault } from '../../../redux/modules/exam/selectors'
import { getNodeTags } from '../../../redux/modules/nodes/selectors'

class Tags extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tags: null,
      tagIds: [],
    }
  }

  componentDidMount() {
    const { tags, nodes } = this.props
    this.getTagTitles(tags)
    if (nodes) {
      this.getUniqueTagsFromNodes(nodes)
    }
  }

  componentDidUpdate(prevProps) {
    const { tags, nodes } = this.props

    // Compare two arrays whether their amount of items and items itself match
    const arraysAreEqual = (a, b) => a.length === b.length && a.every((v, i) => v === b[i])

    // When a new tag is added or existing tag is removed from the question.
    if (
      typeof prevProps.tags !== 'undefined' &&
      typeof tags !== 'undefined' &&
      !arraysAreEqual(prevProps.tags, tags)
    ) {
      this.getTagTitles(tags)
    }

    if (typeof prevProps.nodes !== 'undefined' && typeof nodes !== 'undefined') {
      // Convert nested node objects to arrays of ids for comparison
      const previousNodeIds = Object.keys(prevProps.nodes)
      const currentNodeIds = Object.keys(nodes)

      if (!arraysAreEqual(previousNodeIds, currentNodeIds)) {
        this.getUniqueTagsFromNodes(nodes)
      }
    }
  }

  getTagTitles(yleIds) {
    const { localMetaApi, series, seriesAsTags } = this.props
    const { tagIds } = this.state
    if (localMetaApi) {
      this.setState({ tags: yleIds })
    } else if (seriesAsTags && !isEmpty(series)) {
      this.setState({
        tags: yleIds.map(x => {
          const matchingSeries = series.find(a => a.uuid === x)
          return {
            id: x,
            title: matchingSeries ? matchingSeries.name : null,
          }
        }),
      })
    } else {
      if (!yleIds || (typeof yleIds !== 'undefined' && !yleIds.length)) {
        // No tags, reset state to not display old tags.
        this.setState({ tags: null, tagIds: [] })
        return
      }

      // Do not make Meta API request if no new tags have been added.
      if (equals(tagIds, yleIds)) {
        return
      }

      fetchTags(yleIds).then(result => {
        this.setState({ tags: result, tagIds: yleIds })
      })
    }
  }

  getUniqueTagsFromNodes(nodes) {
    const allTags = [] // Array for storing all tags from the Nodes in the Exam

    // Let's go through all Node Objects of the Exam and push each one of their Tags into allTags[]
    Object.keys(nodes).forEach(key => {
      const node = nodes[key]
      if (node && node.tags) {
        node.tags.map(tag => allTags.push(tag))
      }
    })

    // No tags in Nodes that were passed as props. Reset state to hide old tags.
    if (allTags.length === 0) {
      this.setState({ tags: null, tagIds: [] })
      return
    }

    const uniqueTags = [...new Set(allTags)] // Set() returns only the unique values

    if (uniqueTags.length) {
      this.getTagTitles(uniqueTags)
    }
  }

  renderTag(tag) {
    const { language } = this.props
    const examLang = language.substr(0, 2)
    const {
      isEditing,
      link,
      readOnly,
      removeTag,
      secondary,
      localMetaApi,
      topicsAsTags,
      onClickOpenCarousel,
      seriesAsTags,
    } = this.props
    const tagClassNames = classnames('yo-tags__tag', {
      'yo-tags__tag--read-only': readOnly,
      'yo-tags__tag--carousel-clickable': onClickOpenCarousel && !isEditing,
      'yo-tags__tag--secondary': secondary,
    })
    return (
      <div className={tagClassNames} key={tag.id}>
        {link && (
          <Link
            className="yo-tags__tag-link"
            to={`/questions/by-tags/${tag.title[examLang]}/${tag.id}`}
          >
            <span className="yo-tags__tag-title">{tag.title[examLang]}</span>
          </Link>
        )}

        {onClickOpenCarousel && !isEditing && (
          <span
            className="yo-tags__tag-title"
            onClick={e => onClickOpenCarousel(e, tag)}
            role="button"
            tabIndex="0"
          >
            {tag.title[examLang]}
          </span>
        )}

        {((!link && isEditing) || topicsAsTags) && (
          <span className="yo-tags__tag-title">{tag.title[examLang]}</span>
        )}

        {(localMetaApi || seriesAsTags) && <span className="yo-tags__tag-title">{tag.title}</span>}

        {!readOnly && (
          <span
            className="yo-tags__tag-close"
            onClick={() => removeTag(tag.id)}
            role="button"
            tabIndex="0"
          >
            <Icon height="12" icon="circledCross" size="small" width="12" />
          </span>
        )}
      </div>
    )
  }

  render() {
    const { tags } = this.state

    if (!tags) {
      return null
    }

    return <div className="yo-tags">{tags && tags.map(tag => this.renderTag(tag))}</div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  isEditing: isEditing(),
  language: getExamLanguageOrDefault(state),
  tags: ownProps.tags || getNodeTags(state, ownProps.nodeId),
  series: getSeriesData(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeTag(tag) {
    if (ownProps.topicsAsTags) {
      dispatch(removeTopic(tag))
    } else if (ownProps.localMetaApi) {
      dispatch(removeLocalTag(tag))
    } else if (ownProps.seriesAsTags) {
      dispatch(removeSeriesId(tag))
    } else {
      dispatch(removeTag(ownProps.nodeId, tag))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Tags)
