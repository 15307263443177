// Actions
const SET_YLE_TUNNUS = 'yle_tunnus/SET_YLE_TUNNUS'
const SET_YLE_TUNNUS_LOADED = 'yle_tunnus/SET_YLE_TUNNUS_LOADED'

// Action creators
export const setYleTunnus = yleTunnus => ({
  type: SET_YLE_TUNNUS,
  yleTunnus,
})

export const setYleTunnusLoaded = () => ({
  type: SET_YLE_TUNNUS_LOADED,
})

// Reducer
const defaultState = {
  nick: null,
  userId: null,
  initials: null,
  loaded: false,
}

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case SET_YLE_TUNNUS:
      return {
        ...defaultState,
        nick: action.yleTunnus ? action.yleTunnus.nick : null,
        userId: action.yleTunnus ? action.yleTunnus.userId : null,
        initials: action?.yleTunnus?.initials,
        loaded: action.yleTunnus ? action.yleTunnus.loaded : true,
      }

    case SET_YLE_TUNNUS_LOADED:
      return {
        ...state,
        loaded: true,
      }

    default:
      return state
  }
}
