import {
  FieldType,
  FieldTypeDisplayWidth,
  FieldTypeEditorFormatter,
  FieldTypeSettingFilter,
  NodeStateSettings,
} from './types'
import validationRules from './validationRules'
import optionLayoutTypes, { OptionLayoutType } from './optionLayoutTypes'
import {
  MATRICULATION_EXAM_TYPE,
  EDUCATION_EXAM_TYPE,
  OTHER_EXAM_TYPE,
  TV_EPISODE,
  ARCHTYPE_TEST,
  POLL,
  VOTE,
  REVIEW_ONLY_EXAM_TYPE,
} from '../exams'

export const caseSensitive: FieldType = {
  name: 'isCaseSensitive',
  displayName: 'Case-sensitive',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
}

export const correct: FieldType = {
  name: 'correct',
  displayName: 'Correct answer',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
}

// Same as correct, but only for REVIEW_ONLY_EXAM_TYPE
export const correctReviewOnly: FieldType = {
  name: 'correct',
  displayName: 'Give points',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
  examTypeFilter: [REVIEW_ONLY_EXAM_TYPE],
}

// Same as correct, but for all exam types other than REVIEW_ONLY_EXAM_TYPE
export const correctNotReviewOnly: FieldType = {
  name: 'correct',
  displayName: 'Correct answer',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
  examTypeFilter: [
    MATRICULATION_EXAM_TYPE,
    EDUCATION_EXAM_TYPE,
    OTHER_EXAM_TYPE,
    TV_EPISODE,
    ARCHTYPE_TEST,
    POLL,
    VOTE,
  ],
}

export const customizedPoints: FieldType = {
  name: 'customizedPoints',
  description: 'Separate points for each answer (Editor tab).',
  displayName: 'Customized points',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
}

export const demandCorrect: FieldType = {
  name: 'demandCorrect',
  description: 'Maximum numbers of answers = number of correct answers.',
  displayName: 'Allow multiple answers',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
}

export const displayNumber: FieldType = {
  name: 'displayNumber',
  displayName: 'Display number',
  displayWidth: FieldTypeDisplayWidth.narrow,
  editorFormatter: FieldTypeEditorFormatter.text,
}

export const hint: FieldType = {
  name: 'hint',
  displayName: 'Hint',
  editorFormatter: FieldTypeEditorFormatter.text,
  optional: true,
}

export const placeholder: FieldType = {
  name: 'placeholder',
  displayName: 'Placeholder',
  editorFormatter: FieldTypeEditorFormatter.text,
  optional: true,
}

export const optionsLayout: FieldType = {
  name: 'optionsLayout',
  defaultValue: '1',
  displayName: 'Options layout',
  editorFormatter: FieldTypeEditorFormatter.select,
  options: [
    { id: optionLayoutTypes.radios, name: 'One column' },
    { id: optionLayoutTypes.labels, name: 'Two columns' },
  ],
}

export const maxAnswers: FieldType = {
  name: 'maxAnswers',
  defaultValue: '1',
  displayName: 'Maximum number of answers',
  editorFormatter: FieldTypeEditorFormatter.text,
  displayWidth: FieldTypeDisplayWidth.narrow,
  validation: validationRules.NON_ZERO_INTEGER,
}

export const hideTitle: FieldType = {
  name: 'hideTitle',
  displayName: 'Hide title',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
}

export const timedQuestion: FieldType = {
  name: 'timedQuestion',
  displayName: 'Timed question',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
}

export const points: FieldType = {
  name: 'points',
  displayName: 'Points',
  displayWidth: FieldTypeDisplayWidth.narrow,
  editorFormatter: FieldTypeEditorFormatter.text,
  description: 'points-desc',
  defaultValue: '1',
  settingFilter: FieldTypeSettingFilter.customizedPoints,
  validation: validationRules.POINT,
}

export const tags: FieldType = {
  name: 'tags',
  displayName: 'Tags',
  editorFormatter: FieldTypeEditorFormatter.autocompleteMetaApi,
}

export const text: FieldType = {
  name: 'text',
  displayName: 'Text',
  editorFormatter: FieldTypeEditorFormatter.richText,
}

export const textareaSize: FieldType = {
  name: 'textareaSize',
  defaultValue: '10',
  description: 'textarea-size-desc',
  displayName: 'Textarea size',
  editorFormatter: FieldTypeEditorFormatter.select,
  options: [
    { id: 1, name: 'x-small' },
    { id: 5, name: 'small' },
    { id: 10, name: 'medium' },
    { id: 20, name: 'large' },
  ],
}

export const hideWordCounter: FieldType = {
  name: 'hideWordCounter',
  defaultValue: false,
  displayName: 'Hide counters',
  description: 'Hide word and character counter below textarea.',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
}

export const hideableText: FieldType = {
  name: 'hideableText',
  defaultValue: false,
  displayName: 'Hideable text',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
}

export const title: FieldType = {
  name: 'title',
  displayName: 'Title',
  editorFormatter: FieldTypeEditorFormatter.text,
}

export const foldOutTitle: FieldType = {
  name: 'foldOutTitle',
  displayName: 'Fold out title',
  editorFormatter: FieldTypeEditorFormatter.text,
  settingFilter: FieldTypeSettingFilter.hideableText,
}

export const showTotalPointsForExamQuestion: FieldType = {
  name: 'showTotalPointsForExamQuestion',
  defaultValue: false,
  displayName: 'Show total points for question',
  description: 'Total points will be shown at the bottom of the question.',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
}

export const noVisualization: FieldType = {
  name: 'noVisualization',
  description: 'This will disable correct answer visualization.',
  displayName: 'Disable visualization',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
  examTypeFilter: [
    MATRICULATION_EXAM_TYPE,
    EDUCATION_EXAM_TYPE,
    OTHER_EXAM_TYPE,
    TV_EPISODE,
    ARCHTYPE_TEST,
    POLL,
    VOTE,
  ],
}

export const requireAllCorrect: FieldType = {
  name: 'requireAllCorrect',
  description: 'User has to make all correct answers to get points',
  displayName: 'Require all correct',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
}

export const freeTextInput: FieldType = {
  name: 'freeTextInput',
  description: 'Let user answer with written text',
  displayName: 'Free text input',
  editorFormatter: FieldTypeEditorFormatter.checkbox,
  settingFilter: (settings: NodeStateSettings) =>
    settings.optionsLayout === OptionLayoutType.radios ||
    settings.optionsLayout === OptionLayoutType.default,
}

export const startTime: FieldType = {
  name: 'startTime',
  description:
    'Time when Hengaillaan question starts to accept answers. If not set, answers are not accepted.',
  displayName: 'Start time',
  editorFormatter: FieldTypeEditorFormatter.dateTimePicker,
  validation: { ...validationRules.DATE, required: false },
}

export const endTime: FieldType = {
  name: 'endTime',
  description:
    'Time when Hengaillaan question finishes to accept answers. If not set, answers are accepted until the end of episode.',
  displayName: 'End time',
  editorFormatter: FieldTypeEditorFormatter.dateTimePicker,
  validation: { ...validationRules.DATE, required: false },
}
