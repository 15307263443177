import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { editCharacter } from '../../../redux/modules/characters/characters'
import { IconButton } from '../../elements/IconButtons/IconButtons'
import './characters.scss'

const CharactersHeader = props => {
  const { characters, t, onClickEdit, forwardedRef } = props
  const labels = characters.info.map((character, i) => {
    const nameClasses = classnames('yo-characters-title__container', {
      'yo-characters-title__container-first': i === 0,
    })

    return (
      <div className={nameClasses} key={`character-name-${character.id}`}>
        <div className="yo-characters-title__button">
          <IconButton
            attributes={{ 'data-characterid': character.id }}
            icon="edit"
            onClick={() => onClickEdit(character.id)}
            title={t('NodeEditable:edit')}
          />
        </div>
        <div className="yo-characters-title">{character.title}</div>
      </div>
    )
  })

  return (
    <div className="yo-characters-header" ref={forwardedRef}>
      {labels}
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  onClickEdit: id => {
    dispatch(editCharacter(id))
  },
})

const ConnectedCharacterHeader = withTranslation(['Character'])(
  connect(null, mapDispatchToProps)(CharactersHeader),
)

export default React.forwardRef((props, ref) => (
  <ConnectedCharacterHeader {...props} forwardedRef={ref} />
))
