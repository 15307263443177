import React from 'react'
import defaultProps from './defaultProps'

const Edit = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M18.174 1.826c-1.102-1.102-2.082-.777-2.082-.777l-8.64 8.63L6 14l4.317-1.454 8.634-8.638s.325-.98-.776-2.082zm-7.57 9.78l-.47.47-1.473.5c-.103-.24-.233-.478-.497-.74-.264-.265-.5-.395-.74-.5l.5-1.472.47-.47s.777-.09 1.538.673c.763.76.675 1.538.675 1.538zM16 17H3V4h5l2-2H3c-1.1 0-2 .9-2 2v13c0 1.1.9 2 2 2h13c1.1 0 2-.9 2-2v-7l-2 2v5z"
      fill={fill}
    />
  </svg>
)

Edit.defaultProps = defaultProps

export default Edit
