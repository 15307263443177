import { createSelector } from 'reselect'
import { getExamState } from '../exam/selectors'
import { getLoggedInState } from '../yleTunnus/selectors'
import { isInPreview, isInEditor } from '../../../utils/common'
import { POLL, VOTE } from '../../../constants/definitions/exams'

export const getQuestionsState = state => state.questions
export const getQuestionsFetching = state => state.questions.isFetching
export const getQuestionsData = state => state.questions.data
/**
 * selector for boolean - should we save answers to api
 */
export const getQuestionAnswersSaveStatus = (state, location) =>
  createSelector([getExamState, getLoggedInState], ({ exam_type, disable_saving }, loggedIn) => {
    const inEditor = isInEditor(location)
    const inPreview = isInPreview(location)
    if (inEditor || inPreview) return false

    return ((loggedIn || exam_type === POLL) && !disable_saving) || exam_type === VOTE
  })(state)
