import { useEffect, useState } from 'react'

const darkQuery = '(prefers-color-scheme: dark)'

const useAutoDarkMode = (): 'light' | 'dark' => {
  const mediaQueryList = window.matchMedia && window.matchMedia(darkQuery)
  const [theme, setTheme] = useState<'light' | 'dark'>(
    mediaQueryList && mediaQueryList.matches ? 'dark' : 'light' || 'light',
  )

  useEffect(() => {
    const onChangeTheme = (e: MediaQueryListEvent) => {
      setTheme(e.matches ? 'dark' : 'light')
    }

    try {
      mediaQueryList && mediaQueryList.addEventListener('change', onChangeTheme)
    } catch (_) {
      // This browser does not support MediaQueryList change event
      //
      // https://caniuse.com/mdn-api_mediaquerylist_change_event
    }

    return () => {
      try {
        mediaQueryList && mediaQueryList.removeEventListener('change', onChangeTheme)
        // eslint-disable-next-line no-empty
      } catch (_) {}
    }
  }, [])

  return theme
}

export default useAutoDarkMode
