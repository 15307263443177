import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import Button from '../../elements/Button/Button.tsx'
import config from '../../../config'

const ImsField = ({ onUpdateInputValues, t, label, description }) => {
  const [value, setValue] = React.useState('')

  const onChange = value => {
    setValue(value)
    onUpdateInputValues('id', value)
  }

  const onClick = () => {
    const { ims_url: imsUrl } = config
    const imsWindow = window.open(
      imsUrl,
      'IMS',
      '_blank,width=980,height=800,scrollbars=yes,location=yes,status=yes',
    )

    // Callback for receiving IMS image data using postmessage.
    const receiveImsMessage = event => {
      // Verify message origin.
      if (event.origin !== imsUrl) {
        return
      }

      // Update input value using message data.
      onChange(event.data.images_api_id)

      // Remove listener and close the IMS window.
      window.removeEventListener('message', receiveImsMessage, false)
      imsWindow.close()
    }

    window.addEventListener('message', receiveImsMessage, false)
  }

  const inputId = `yo-dialog__title--${label}`
    .replace(/ /g, '-')
    .toLowerCase()
    .trim()

  return (
    <Fragment>
      <label className="yo-dialog__label" htmlFor={inputId}>
        <span className="yo-dialog__title">{label}</span>
        <span className="yo-dialog__input yo-dialog__input--wrapper">
          <input
            className="yo-dialog__input"
            data-testid="ims-field-input"
            id={inputId}
            onChange={e => onChange(e.target.value)}
            type="text"
            value={value}
          />
          <Button
            attributes={{ 'data-testid': 'ims-field-button' }}
            onClick={onClick}
            size="sm"
            text={t('select')}
          />
        </span>
      </label>
      {description && <div className="yo-dialog__description">{t(description)}</div>}
    </Fragment>
  )
}

ImsField.propTypes = {
  // Label for the input.
  label: PropTypes.string.isRequired,
}

export default withTranslation(['ImsField'])(ImsField)
