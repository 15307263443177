import React from 'react'
import { withTranslation } from 'react-i18next'
import Icon from '../Icon/Icon'
import './loader.scss'

export const Dots = () => (
  <div className="yo-loader__dots">
    <div className="yo-loader__dot yo-loader__dot--dot1" />
    <div className="yo-loader__dot yo-loader__dot--dot2" />
    <div className="yo-loader__dot yo-loader__dot--dot3" />
  </div>
)

export const Loader = () => (
  <div className="yo-loader__animation yo-loader__animation--horizontal" />
)

const spinner = ({ t }) => (
  <div className="yo-loader">
    <div className="yo-loader__animation yo-loader__animation--spinner">
      <Icon fill="gray" icon="cycle" />
    </div>
    <div className="yo-loader__message">{t('Loader:loading')}</div>
  </div>
)

export const Spinner = withTranslation(['Loader'])(spinner)

export const ScaleOut = () => <div className="yo-loader__scaleout" />
