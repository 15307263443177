import React from 'react'
import { connect } from 'react-redux'
import { updateSeries, updateExistingSeries } from '../../../redux/modules/series/series'
import { seriesFields, seriesSettings } from '../../../constants/definitions/series'
import InputExtended from '../../elements/Input/InputExtended'
import TertiaryHeader from '../../elements/Typography/TertiaryHeader.tsx'

const SeriesForm = ({ series, onChangeField, onUpdateSettings }) => {
  return (
    <>
      {seriesFields.map((field, index) => (
        <div className="yo-series__field" key={field.name}>
          <InputExtended
            autofocus={index === 0}
            defaultValue={series[field.name]}
            label={field.displayName}
            onChange={e => onChangeField(series.uuid, field.name, e)}
            required={field.required}
            type={field.editorFormatter}
          />
        </div>
      ))}
      <TertiaryHeader>Settings</TertiaryHeader>
      {seriesSettings.map(field => (
        <div className="yo-series__field" key={field.name}>
          <InputExtended
            defaultChecked={series.classification[field.name]}
            label={field.displayName}
            onChange={e =>
              onUpdateSettings({
                uuid: series.uuid,
                field,
                e,
              })
            }
            type={field.editorFormatter}
            value={series.classification[field.name]}
          />
        </div>
      ))}
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  onChangeField: (uuid, fieldName, e) => {
    const value = e.target ? e.target.value : e
    if (uuid && uuid !== '') {
      dispatch(updateExistingSeries({ uuid, fieldName, value }))
    } else {
      dispatch(updateSeries({ fieldName, value }))
    }
  },
  onUpdateSettings: ({ uuid, field, e }) => {
    let value = e
    if (e.target) {
      value = field.editorFormatter === 'checkbox' ? e.target.checked === true : e.target.value
    }

    if (uuid && uuid !== '') {
      dispatch(updateExistingSeries({ uuid, fieldName: field.name, value, isClassification: true }))
    } else {
      dispatch(updateSeries({ fieldName: field.name, value, isClassification: true }))
    }
  },
})

export default connect(null, mapDispatchToProps)(SeriesForm)
