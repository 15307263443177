import {
  KEYCODE_ARROW_DOWN,
  KEYCODE_ARROW_UP,
  KEYCODE_ARROW_RIGHT,
  KEYCODE_ARROW_LEFT,
} from '../../../constants/definitions/keycodes'

// This onKeyDown handler fixes the issue #1011,
// where arrow keys caused early submit of the selection
// when autosubmit was on.
const onRadioInputKeyDown = e => {
  let direction
  if (e.keyCode === KEYCODE_ARROW_DOWN || e.keyCode === KEYCODE_ARROW_RIGHT) {
    e.preventDefault()
    direction = 1
  }
  if (e.keyCode === KEYCODE_ARROW_UP || e.keyCode === KEYCODE_ARROW_LEFT) {
    e.preventDefault()
    direction = -1
  }
  if (direction) {
    const name = e.target?.getAttribute('name')
    // querySelectorAll returns a collection of type NodeList
    const inputElements = document.querySelectorAll(`input[name="${name}"]`)
    let currIndex
    inputElements.forEach((elem, i) => {
      if (elem === e.target) {
        currIndex = i
      }
    })
    if (currIndex !== undefined) {
      const nextElement = inputElements[currIndex + direction]
      if (nextElement) {
        nextElement.focus()
      }
    }
  }
}

export default onRadioInputKeyDown
