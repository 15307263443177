import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import './exam-review.scss'
import Notification from '../../elements/Notification/Notification.tsx'
import { getShareUrl } from '../../layout/ShareReview/ShareReviewHelper'
import ShareReview from '../../layout/ShareReview/ShareReview'
import NodeScore from '../../node/NodeScore/NodeScore'
import { getSeriesClassification } from '../../../redux/modules/series/selectors'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'

const ExamReview = ({ score, maxScore, review, exam, examId, seriesHideStats }) => {
  const showAverageScore =
    Boolean(exam.disable_saving) === false &&
    Boolean(seriesHideStats) === false &&
    Boolean(exam.hideScores) === false

  const calculatedAverageScore =
    exam.averageScore &&
    typeof exam.averageScore.average === 'number' &&
    typeof exam.averageScore.max_score === 'number'
      ? Math.round(exam.averageScore.average * 10) / 10
      : null

  const url = review ? getShareUrl(review.share, examId) : null
  const displayShare = exam.enableShare && url && review.share
  const hasReview = review && review.description

  return (
    <div className="yo-exam-review" data-testid="exam-review">
      <Notification>
        <div className="yo-node-review-notification-content">
          {hasReview && <RichTextContent content={review.description} />}
          <NodeScore
            hasScores
            maxScore={maxScore}
            nodeSettings={{}}
            score={score}
            titleKey="ExamReview:points"
          />
          {showAverageScore && calculatedAverageScore !== null && (
            <NodeScore
              hasScores
              maxScore={exam.averageScore.max_score}
              nodeSettings={{}}
              score={calculatedAverageScore}
              titleKey="ExamReview:average-score"
            />
          )}
        </div>
      </Notification>

      {displayShare && <ShareReview review={review} url={url} />}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    seriesHideStats: getSeriesClassification(state).hideStatistics,
  }
}

export const ExamReviewWithoutHoCs = ExamReview

export default withTranslation()(connect(mapStateToProps)(ExamReview))
