import uuid from 'uuid'
import { HANDLE_EXAM_DATA } from '../../middleware/examService/examService'

// Actions
export const CREATE = 'characters/CREATE'
export const DELETE_CHOICE = 'characters/UPDATE_CHOICE'
export const UPDATE_INFO = 'characters/UPDATE_INFO'
export const ADD_CHOICES = 'characters/ADD_CHOICES'
export const CLEAR_EDITING = 'characters/CLEAR_EDITING'
export const UPDATE_IMPORTANT = 'characters/UPDATE_IMPORTANT'
export const EDIT_CHARACTER = 'characters/EDIT_CHARACTER'
export const DELETE_QUESTION_CHOICES = 'characters/DELETE_QUESTION_CHOICES'
export const DELETE_CHARACTER = 'characters/DELETE_CHARACTER'
export const UPDATE_SHARE_URL = 'characters/UPDATE_SHARE_URL'

const defaultState = {
  editId: null,
  createId: null,
  info: [],
  choices: [],
}
// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case CREATE:
      return {
        ...state,
        createId: action.newCharacter.id,
        info: [...state.info, action.newCharacter],
      }

    case DELETE_CHOICE:
      return {
        ...state,
        choices: state.choices.filter(
          choice =>
            choice.character_id !== action.characterId ||
            choice.question_id !== action.questionId ||
            choice.option_id !== action.optionId,
        ),
      }

    case UPDATE_INFO:
      return {
        ...state,
        info: state.info.map(info => {
          if (info.id !== action.id) {
            return info
          }
          return {
            ...info,
            ...action.info,
          }
        }),
      }
    case ADD_CHOICES:
      return {
        ...state,
        choices: [...state.choices, ...action.choices],
      }

    case UPDATE_IMPORTANT:
      return {
        ...state,
        choices: state.choices.map(choice => {
          if (
            choice.character_id !== action.characterId ||
            choice.question_id !== action.questionId
          ) {
            return choice
          }
          return {
            ...choice,
            important: action.important,
          }
        }),
      }

    case CLEAR_EDITING:
      return {
        ...state,
        editId: null,
        createId: null,
      }

    case EDIT_CHARACTER:
      return {
        ...state,
        editId: action.characterId,
      }

    case DELETE_QUESTION_CHOICES:
      return {
        ...state,
        choices: state.choices.filter(x => x.question_id !== action.questionId),
      }

    case DELETE_CHARACTER:
      return {
        ...state,
        info: state.info.filter(x => x.id !== action.characterId),
        choices: state.choices.filter(x => x.character_id !== action.characterId),
      }

    case HANDLE_EXAM_DATA:
      if (action.data.entities.characters) {
        return {
          ...defaultState,
          info: Object.assign([], action.data.entities.characters.info),
          choices: Object.assign([], action.data.entities.characters.choices),
        }
      }
      return { ...defaultState }

    default:
      return state
  }
}

// Action creators
export const createCharacter = () => {
  const newCharacter = {
    id: uuid.v4(),
    title: '',
    description: '',
    share: {
      title: '',
      description: '',
      imageId: '',
    },
  }
  return {
    type: CREATE,
    newCharacter,
  }
}

export const updateCharacterInfo = (id, info) => ({
  type: UPDATE_INFO,
  id,
  info,
})

export const deleteCharacterChoice = (characterId, questionId, optionId) => ({
  type: DELETE_CHOICE,
  characterId,
  questionId,
  optionId,
})

export const addCharacterChoices = choices => ({
  type: ADD_CHOICES,
  choices,
})

export const clearCharacterEditing = () => ({
  type: CLEAR_EDITING,
})

export const updateCharacterImportant = (characterId, questionId, important) => ({
  type: UPDATE_IMPORTANT,
  characterId,
  questionId,
  important,
})

export const editCharacter = characterId => ({
  type: EDIT_CHARACTER,
  characterId,
})

export const deleteChoicesByQuestionId = questionId => ({
  type: DELETE_QUESTION_CHOICES,
  questionId,
})

export const deleteCharacter = characterId => ({
  type: DELETE_CHARACTER,
  characterId,
})
