import React from 'react'
import { Accordion, AccordionItem } from '@yleisradio/yds-components-react'
import Fragment from '../../node/Fragment/Fragment'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'

type NodeSettings = {
  hideableText?: boolean
}

type Node = {
  settings?: NodeSettings
}

type TextContentProps = {
  text: string
  foldOutTitlle: string
}

type TextProps = {
  content: TextContentProps
  node?: Node
}

const TextContent = ({ content }: TextProps) => (
  <Fragment>
    <RichTextContent content={content.text} />
  </Fragment>
)
const AccordionTextContent = ({ content }) => {
  return (
    <Accordion>
      <AccordionItem title={content.foldOutTitle}>
        <TextContent content={content} />
      </AccordionItem>
    </Accordion>
  )
}

const Text = ({ node, ...props }: TextProps) => {
  return node?.settings?.hideableText ? (
    <AccordionTextContent {...props} />
  ) : (
    <TextContent {...props} />
  )
}

export default Text
