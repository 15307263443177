import { uniq } from 'ramda'
import { fetchCarouselExamList } from '../../middleware/carouselService/carouselService'
import { metaOptions, questionTypes } from '../../../constants/definitions/entities/entities.ts'

// Actions
const SET_META_OPTIONS = 'default_carousel/SET_META_OPTIONS'
const SET_QUESTION_TYPES = 'default_carousel/SET_QUESTION_TYPES'
const UPDATE_META_OPTIONS = 'default_carousel/UPDATE_META_OPTIONS'
const UPDATE_QUESTION_TYPES = 'default_carousel/UPDATE_QUESTION_TYPES'
const CLEAR_QUESTION_TYPES = 'default_carousel/CLEAR_QUESTION_TYPES'
const ADD_TOPIC = 'default_carousel/ADD_TOPIC'
const ADD_LOCAL_TAG = 'default_carousel/ADD_LOCAL_TAG'
const REMOVE_TOPIC = 'default_carousel/REMOVE_TOPIC'
const REMOVE_LOCAL_TAG = 'default_carousel/REMOVE_LOCAL_TAG'
const CLEAR_DEFAULT_CAROUSEL = 'default_carousel/CLEAR_DEFAULT_CAROUSEL'
const CLEAR_LOCAL_TAGS = 'default_carousel/CLEAR_LOCAL_TAGS'

// Filter out listening-comprehension until decision about UI is done
const defaultQuestionTypes = questionTypes.filter(x => x.id !== '-removed-')

// Reducer
const defaultState = {
  metaOptions,
  questionTypes: defaultQuestionTypes,
  topics: [],
  tags: [],
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_META_OPTIONS:
      return {
        ...state,
        metaOptions: state.metaOptions.map(option =>
          action.options.includes(option.id)
            ? { ...option, value: true }
            : { ...option, value: undefined },
        ),
      }
    case SET_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: state.questionTypes.map(option =>
          action.options.includes(option.id)
            ? { ...option, value: true }
            : { ...option, value: undefined },
        ),
      }
    case UPDATE_META_OPTIONS:
      return {
        ...state,
        metaOptions: state.metaOptions.map(option =>
          option.id === action.optionId ? { ...option, value: action.value } : option,
        ),
      }

    case UPDATE_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: state.questionTypes.map(option =>
          option.id === action.optionId ? { ...option, value: action.value } : option,
        ),
      }

    case ADD_TOPIC:
      return {
        ...state,
        topics: [...state.topics, action.topic],
      }

    case ADD_LOCAL_TAG:
      return {
        ...state,
        tags: uniq([...state.tags, action.tag]),
      }

    case REMOVE_TOPIC:
      return {
        ...state,
        topics: state.topics.filter(x => x.id !== action.topicId),
      }

    case REMOVE_LOCAL_TAG:
      return {
        ...state,
        tags: state.tags.filter(t => t.id !== action.tagId),
      }

    case CLEAR_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: defaultQuestionTypes,
      }

    case CLEAR_DEFAULT_CAROUSEL:
      return { ...defaultState, tags: [...state.tags] }

    case CLEAR_LOCAL_TAGS:
      return {
        ...state,
        tags: [],
      }

    default:
      return state
  }
}

// Action creators
export const setMetaOptions = options => ({
  type: SET_META_OPTIONS,
  options,
})

export const setQuestionTypes = options => ({
  type: SET_QUESTION_TYPES,
  options,
})

export const updateMetaOptions = (optionId, value) => ({
  type: UPDATE_META_OPTIONS,
  optionId,
  value,
})

export const updateQuestionTypes = (optionId, value) => ({
  type: UPDATE_QUESTION_TYPES,
  optionId,
  value,
})

export const clearQuestionTypes = () => ({
  type: CLEAR_QUESTION_TYPES,
})

export const addTopic = topic => ({
  type: ADD_TOPIC,
  topic,
})

export const removeTopic = topicId => ({
  type: REMOVE_TOPIC,
  topicId,
})

export const removeLocalTag = tagId => ({
  type: REMOVE_LOCAL_TAG,
  tagId,
})

export const clearDefaultCarousel = () => ({
  type: CLEAR_DEFAULT_CAROUSEL,
})

export const fetchCarouselExams = filters => dispatch => dispatch(fetchCarouselExamList(filters))

export const addLocalTag = tag => ({
  type: ADD_LOCAL_TAG,
  tag,
})

export const clearLocalTags = () => ({
  type: CLEAR_LOCAL_TAGS,
})
