import React, { Fragment } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { fetchExamLogs } from '../../../redux/middleware/examService/examService'
import './exam-logs.scss'
import Icon from '../../elements/Icon/Icon'
import { Spinner } from '../../elements/Loader/Loader'

class ExamLogs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      logs: [],
      isLoading: true,
    }
  }

  componentDidMount() {
    const { match, fetchLogs } = this.props
    fetchLogs(match.params.id)
      .then(resp => (resp && resp.ok ? resp.json() : {}))
      .then(body => {
        this.setState({
          logs: body.data.sort((l1, l2) => l2.id - l1.id) || [],
          isLoading: false,
        })
      })
  }

  renderTimestamp = createdAt =>
    moment(createdAt)
      .format('DD-MM-YYYY, HH:mm')
      .toString()

  renderResponseIcon = ok => {
    const classNames = classnames('yo-exam-logs__response-icon', {
      'yo-exam-logs__response-icon--incorrect': !ok,
    })
    return (
      <span className={classNames}>
        <Icon icon={ok ? 'check' : 'cross'} />
      </span>
    )
  }

  renderLogRow = log => (
    <div className="yo-exam-logs__log" key={log.id}>
      <div className="yo-exam-logs__log-col">{log.id}</div>
      <div className="yo-exam-logs__log-col">{this.renderResponseIcon(log.response.ok)}</div>
      <div className="yo-exam-logs__log-col">{log.author.name}</div>
      <div className="yo-exam-logs__log-col">{this.renderTimestamp(log.created_at)}</div>
      <div className="yo-exam-logs__log-col yo-exam-logs__log-col--message">{`${log.response
        .message || 'N/A'}`}</div>
    </div>
  )

  render() {
    return (
      <div>
        {// eslint-disable-next-line no-nested-ternary
        this.state.isLoading === true ? (
          <div className="yo-exam-logs__spinner-wrapper">
            <Spinner />
          </div>
        ) : this.state.logs.length > 0 ? (
          <Fragment>
            <div className="yo-exam-logs__log yo-exam-logs__logs-header">
              <div className="yo-exam-logs__log-col">Log ID</div>
              <div className="yo-exam-logs__log-col">OK?</div>
              <div className="yo-exam-logs__log-col">Author</div>
              <div className="yo-exam-logs__log-col">Timestamp</div>
              <div className="yo-exam-logs__log-col yo-exam-logs__log-col--message">Message</div>
            </div>
            {this.state.logs.map(log => this.renderLogRow(log))}
          </Fragment>
        ) : (
          'No logs available for this exam'
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchLogs: uuid => Promise.resolve(dispatch(fetchExamLogs(uuid))),
})

export default connect(null, mapDispatchToProps)(ExamLogs)
