export const seriesFields = [
  {
    name: 'name',
    displayName: 'Name',
    editorFormatter: 'text',
    required: true,
  },
  {
    name: 'description',
    displayName: 'Description',
    editorFormatter: 'rich-text',
    required: false,
  },
]

export const seriesSettings = [
  {
    name: 'hideStatistics',
    displayName: 'Hide statistics',
    editorFormatter: 'checkbox',
  },
  {
    name: 'hideTunnusIcon',
    displayName: 'Hide tunnus icon',
    editorFormatter: 'checkbox',
  },
]
