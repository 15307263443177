import { useEffect } from 'react'

const useInterval = (onTimeout: () => void, milliseconds: number) => {
  useEffect(() => {
    const intervalId = window.setInterval(onTimeout, milliseconds)
    return () => {
      clearInterval(intervalId)
    }
  }, [onTimeout, milliseconds])
}

export default useInterval
