import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getNodes, getNodeType } from '../../../redux/modules/nodes/selectors'
import NodeScore from '../NodeScore/NodeScore'

const sum = arr => arr.reduce((a, b) => a + b)
const isSubmitted = questions => !questions.find(q => !q.submitted)

const NodeReviewScore = ({ node, childNodes }) => {
  let { score, maxScore } = node
  const { settings, id, exclude, nodeTypeId } = node
  const nodeType = getNodeType(undefined, nodeTypeId)
  const shouldSumScoresOfChildren =
    nodeType.id === 'EXAM_QUESTION' && settings.showTotalPointsForExamQuestion

  if (shouldSumScoresOfChildren && isSubmitted(childNodes)) {
    score = sum(childNodes.map(c => c.score || 0))
    maxScore = sum(childNodes.map(c => c.maxScore || 0))
  }

  return (
    <NodeScore
      hasScores={nodeType.scoring}
      maxScore={!exclude && maxScore}
      nodeId={id}
      nodeSettings={settings}
      score={!exclude && score}
      titleKey={shouldSumScoresOfChildren ? 'question-points' : 'points'}
    />
  )
}

const mapStateToProps = (state, { node }) => ({
  childNodes: (node.childIds?.length > 0 && getNodes(state, node.childIds)) || [],
})

export default withRouter(connect(mapStateToProps)(NodeReviewScore))
