import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import InputExtended from '../../elements/Input/InputExtended'
import { IconButtons, IconButton } from '../../elements/IconButtons/IconButtons'
import {
  deleteReview,
  updateReview,
  updateReviewShare,
} from '../../../redux/modules/reviews/reviews'
import { validationRules } from '../../../constants/definitions/entities/entities.ts'
import ShareInput from '../../layout/ShareInput/ShareInput'
import Notification from '../../elements/Notification/Notification.tsx'
import SharePreview from '../../layout/SharePreview/SharePreview'
import RichTextContent from '../RichTextContent/RichTextContent'
import './reviews.scss'

export const MATCH_MODE_SCORE_RANGE = 'MATCH_MODE_SCORE_RANGE'
export const MATCH_MODE_QUESTION_OPTION = 'MATCH_MODE_QUESTION_OPTION'

class Review extends React.PureComponent {
  onChangeDescription = e => {
    const { dispatch, review } = this.props
    dispatch(updateReview(review.id, { description: e }))
  }

  onChangeMinScore = e => {
    const { dispatch, review } = this.props
    dispatch(updateReview(review.id, { minScore: e.target.value }))
  }

  onChangeMaxScore = e => {
    const { dispatch, review } = this.props
    dispatch(updateReview(review.id, { maxScore: e.target.value }))
  }

  onClickDelete = () => {
    const { dispatch, review, t } = this.props
    const confirmText = t('confirm-delete', { item: 'review' })

    // Confirm deletion of review.
    if (window.confirm(confirmText)) {
      dispatch(deleteReview(review.id))
    }
  }

  onUpdateShareDescription = e => {
    this.updateReviewShare('description', e.target.value)
  }

  onUpdateShareImage = imageId => {
    this.updateReviewShare('imageId', imageId)
  }

  onUpdateShareTitle = e => {
    this.updateReviewShare('title', e.target.value)
  }

  updateReviewShare = (key, value) => {
    const { dispatch, review } = this.props
    dispatch(
      updateReviewShare(review.id, {
        ...review.share,
        id: review.id,
        [key]: value,
      }),
    )
  }

  render() {
    const { review, enableShare } = this.props

    const share = review.share ? review.share : {}
    return (
      <div className="yo-reviews__container">
        <div className="yo-reviews__review" key={review.id}>
          <div className="yo-reviews__scores">
            <div className="yo-reviews__scores-input">
              <InputExtended
                defaultValue={review.minScore}
                idSuffix={review.id}
                label="Score from"
                name="minScore"
                onChange={this.onChangeMinScore}
                type="text"
                validation={validationRules.POINT}
                width="narrow"
              />
            </div>
            <div className="yo-reviews__scores-input">
              <InputExtended
                defaultValue={review.maxScore}
                idSuffix={review.id}
                label="Score to"
                name="maxScore"
                onChange={this.onChangeMaxScore}
                type="text"
                validation={validationRules.POINT}
                width="narrow"
              />
            </div>
            <div className="yo-reviews__icons">
              <div className="yo-reviews__icon-delete">
                <IconButtons>
                  <IconButton icon="delete" onClick={this.onClickDelete} />
                </IconButtons>
              </div>
            </div>
          </div>
          <InputExtended
            autofocus
            defaultValue={review.description}
            label="Feedback"
            onChange={this.onChangeDescription}
            type="rich-text"
          />
          {enableShare ? (
            <ShareInput
              description={share.description}
              dispatch={this.props.dispatch}
              idSuffix={review.id}
              imageId={share.imageId}
              onUpdateDescription={this.onUpdateShareDescription}
              onUpdateImage={this.onUpdateShareImage}
              onUpdateTitle={this.onUpdateShareTitle}
              title={share.title}
            />
          ) : (
            <div>Sharing is not enabled</div>
          )}
        </div>
        <div className="yo-reviews__preview">
          {review.description && (
            <Notification>
              <RichTextContent content={review.description} />
            </Notification>
          )}
          {review.share && (
            <SharePreview
              description={review.share.description}
              imageId={review.share.imageId}
              title={review.share.title}
            />
          )}
        </div>
      </div>
    )
  }
}
Review.defaultProps = {
  enableShare: false,
}

Review.propTypes = {
  review: PropTypes.shape({
    // review description.
    description: PropTypes.string,
    // review id.
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

    // review minimum score.
    minScore: PropTypes.string,
    // review maximum score.
    maxScore: PropTypes.string,
    share: PropTypes.shape({}),
  }).isRequired,
  enableShare: PropTypes.bool,
}

export default withTranslation(['common'])(connect()(Review))
