import React from 'react'
import { withTranslation } from 'react-i18next'
import './node-menu-item.scss'

const NodeMenuItem = ({ nodeType, onClick, t }) => (
  <div
    className="yo-node-menu__item"
    data-node-type-id={nodeType.id}
    data-testid={`node-menu__${nodeType.id}`}
    key={nodeType.id}
    onClick={onClick}
    role="button"
    tabIndex="0"
  >
    <div className="yo-node-menu__item-name">{nodeType.displayName}</div>
    <div className="yo-node-menu__item-description">{t(nodeType.description)}</div>
  </div>
)

export default withTranslation(['NodeMenuItem'])(NodeMenuItem)
