// Actions
const HANDLE_DATA = 'editing/HANDLE_DATA'
const UPDATE = 'editing/SET'

// Action creators
export const updateEditing = examData => {
  return {
    type: UPDATE,
    examData,
  }
}

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE: {
      return { ...action.examData }
    }
    case HANDLE_DATA: {
      return {
        status: 'edit',
        parameters: {
          nodeId: action.data.rootId,
        },
      }
    }
    default:
      return state
  }
}
