import React from 'react'
import { HashRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom'
import AppHeader from './layout/AppHeader/AppHeader'
import Editor from './layout/Editor/Editor'
import ExamList from './layout/ExamList/ExamList'
import Page from './layout/Layouts/Layouts'
import NavBar from './layout/NavBar/NavBar'
import NodeMenu from './layout/NodeMenu/NodeMenu'
import { GoogleLogin, useGoogleAuth } from './misc/GoogleLogin/GoogleLogin.tsx'
import GoogleLogout from './misc/GoogleLogin/GoogleLogout.tsx'
import ExamWizard from './layout/ExamWizard/ExamWizard'
import Dropdown from './layout/Dropdown/Dropdown'
import Toaster from './layout/Toaster/Toaster'
import Dialog from './layout/Dialog/Dialog'
import Questions from './questions/Question/QuestionsContainer'
import AuthRoute from './misc/Auth/AuthRoute'
import ExamRoutes from './routes/ExamRoutes/ExamRoutes'
import { fetchExams, fetchAuthorsExams } from '../redux/modules/exams/exams'
import NavigationBlocker from './misc/NavigationBlocker/NavigationBlocker'
import SeriesCreate from './series/SeriesCreate/SeriesCreate'
import SeriesList from './series/SeriesList/SeriesList'
import SeriesContainer from './series/SeriesContainer/SeriesContainer'
import './app.scss'
import { store } from '../index'
import { untaintExam } from '../redux/modules/exam/exam'

export const Header = (
  <div>
    <AppHeader />
    <NavBar />
  </div>
)

export const Sidebar = (
  <div className="yo-sidebar__content">
    <NodeMenu />
    <Editor />
  </div>
)

export const decorations = {
  dropdown: <Dropdown />,
  toaster: <Toaster />,
  dialog: <Dialog />,
}

const createExamLayout = () => (
  <Page {...decorations} header={<AppHeader />} main={<ExamWizard />} />
)
const examList = fetcher => () => <Page header={Header} main={<ExamList fetchExams={fetcher} />} />
const QuestionList = () => <Page header={Header} main={<Questions />} />
const createSeriesLayout = () => (
  <Page {...decorations} header={<AppHeader />} main={<SeriesCreate />} />
)
const SeriesListLayout = () => (
  <Page {...decorations} header={<AppHeader />} main={<SeriesList />} />
)
const SeriesContainerLayout = () => (
  <Page {...decorations} header={<AppHeader />} main={<SeriesContainer />} />
)

const getConfirmation = (message, callback) => {
  if (window.confirm(message)) {
    store.dispatch(untaintExam())
    callback(true)
  } else {
    callback(false)
  }
}

const Routes = withRouter(({ history }) => {
  const onLogin = () => {
    if (history.location.pathname === '/login') {
      const urlParams = new URLSearchParams(history.location.search)
      history.push(urlParams.get('redirectTo') || '/')
    }
  }
  const isInitialized = useGoogleAuth({ onLogin })

  return (
    <Switch>
      <AuthRoute component={createExamLayout} path="/exams/create" />
      <AuthRoute component={ExamRoutes} path="/exam/:id" />
      <AuthRoute component={examList(fetchAuthorsExams)} path="/exams/by-author/:authorID" />
      <AuthRoute component={examList(fetchExams)} path="/exams/list" />
      <AuthRoute component={QuestionList} path="/questions/by-tags/:tagName/:tagId" />
      <AuthRoute component={createSeriesLayout} path="/series/create" />
      <AuthRoute component={SeriesListLayout} exact path="/series/list" />
      <AuthRoute component={SeriesContainerLayout} exact path="/series/by-uuid/:seriesId" />
      <Route path="/login" render={() => <GoogleLogin showLoginButton={isInitialized} />} />
      <Route component={GoogleLogout} path="/logout" />
      <Redirect exact from="/" to="/exams/list" />
    </Switch>
  )
})

const App = () => (
  <HashRouter getUserConfirmation={getConfirmation}>
    <div className="yo-app">
      <NavigationBlocker />
      <Routes />
    </div>
  </HashRouter>
)

export default App
