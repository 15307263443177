import React from 'react'
import { connect } from 'react-redux'
import { updateOption } from '../../../redux/modules/options/options'
import OptionFormItem from './OptionFormItem'
import { getExamState } from '../../../redux/modules/exam/selectors'
import { getAllNodes } from '../../../redux/modules/nodes/selectors'

const shouldRenderOptionFormItem = ({ exam, optionField, node }) => {
  // examTypeFilter can hide options for specific exam types
  if (optionField.examTypeFilter && !optionField.examTypeFilter.includes(exam.exam_type)) {
    return false
  }

  // settingFilter can also hide options
  if (optionField.settingFilter) {
    switch (typeof optionField.settingFilter) {
      case 'function':
        return optionField.settingFilter(node.settings)
      case 'string':
        return Boolean(node.settings[optionField.settingFilter])
      default:
        return true
    }
  }

  return true
}

/**
 * Component for creating forms for option entities.
 *
 * @param {function} onChange - this function is called when option field is changed
 * @param {object} exam - current exam
 * @param {array} nodes - all questions of current exam
 * @param {object} option - Option entity
 * @param {array} optionFields - Field definition for option fields
 */
const OptionForm = ({ onChange, exam, nodes, option, optionFields }) => {
  const node = nodes[option.parent]
  const form = optionFields
    .filter(optionField => shouldRenderOptionFormItem({ exam, option, optionField, node }))
    .map(optionField => (
      <OptionFormItem
        key={optionField.name}
        onChange={onChange}
        option={option}
        optionField={optionField}
      />
    ))

  return <div>{form}</div>
}

const mapStateToProps = state => ({
  nodes: getAllNodes(state),
  exam: getExamState(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: ({ e, fieldName }) => {
    const optionId = ownProps.option.id
    const { parent } = ownProps.option
    let value

    if (e.target) {
      ;({ value } = e.target)

      // For checkboxes use the "checked" attribute instead of "value".
      if (e.target.nodeName === 'INPUT' && e.target.getAttribute('type') === 'checkbox') {
        value = e.target.checked
      }
    } else {
      value = e
    }

    // Update the desired field in the option.
    dispatch(updateOption(optionId, { parent, [fieldName]: value }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(OptionForm)
