import React from 'react'
import { connect } from 'react-redux'
import { fromEvent } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import Input from '../../elements/Input/Input'
import { filterExamsBySearch } from '../../../redux/modules/filters/exams/byExamSearch'
import { updatePage } from '../../../redux/modules/paginations/paginations'
import './exam-search.scss'

class ExamSearch extends React.Component {
  componentDidMount() {
    const searchField = document.getElementById('yo-exam-search__field')

    // Observe changes in inputField
    fromEvent(searchField, 'input')
      // Only emit input value every 400 ms
      .pipe(debounceTime(400))
      // Fetch exams after new value is emitted
      .subscribe(() => this.props.fetchExams())
  }

  render() {
    const { filters, onChangeSearch } = this.props
    return (
      <div className="yo-exam-search">
        <Input
          attributes={{ placeholder: 'Search...', id: 'yo-exam-search__field' }}
          className="yo-exam-search__input"
          defaultValue={filters.search}
          onChange={onChangeSearch}
          type="text"
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.examsFilters,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeSearch: e => {
    dispatch(filterExamsBySearch(e.target.value))
  },
  fetchExams: () => {
    const { onFetchExams } = ownProps
    dispatch(updatePage(0))
    onFetchExams()
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ExamSearch)
