import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { createSeries } from '../../../redux/modules/series/series'
import Button from '../../elements/Button/Button.tsx'
import SeriesForm from '../SeriesForm/SeriesForm'
import './series-create.scss'
import PrimaryHeader from '../../elements/Typography/PrimaryHeader.tsx'

const SeriesCreate = ({ series, onCreate, history, isLoading }) => {
  const serieName = series.name.trim()

  return (
    <div className="yo-series">
      <PrimaryHeader>Series</PrimaryHeader>
      <SeriesForm series={series} />
      <div className="yo-series__create">
        <Button
          attributes={{ 'data-testid': 'button-save-series' }}
          isDisabled={isLoading || serieName.length === 0}
          onClick={() => onCreate(series, history)}
          text="Create"
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  series: state.series.series,
  isLoading: state.series.loading,
})
const mapDispatchToProps = dispatch => ({
  onCreate: (series, history) => {
    dispatch(createSeries(series, history))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeriesCreate))
