import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import i18n from '../../../utils/i18n'

type LangProps = {
  language: string
  children: React.ReactNode
}

/**
 * This component wraps children inside I18nextProvider and handles
 * the change of language when it is changed in state.
 */
const LanguageSwitcher = ({ language, children }: LangProps) => {
  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

const mapStateToProps = (state, ownProps) => ({
  language: ownProps.language || state.exam.language,
})

export default connect(mapStateToProps)(LanguageSwitcher)
