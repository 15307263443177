import React from 'react'
import { connect } from 'react-redux'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Toast from './Toast'
import './toaster.scss'

const Toaster = ({ toasts }) => {
  const items = toasts.map(toast => (
    <CSSTransition
      classNames="yo-toast"
      key={toast.id}
      timeout={{ appear: 250, enter: 250, exit: 250 }}
    >
      <Toast id={toast.id} message={toast.message} ttl={toast.ttl} type={toast.type} />
    </CSSTransition>
  ))

  return <TransitionGroup className="yo-toaster">{items}</TransitionGroup>
}

const mapStateToProps = state => ({
  toasts: state.toasts,
})

export default connect(mapStateToProps)(Toaster)
