import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import Fragment from '../../node/Fragment/Fragment'
import { optionLayoutTypes } from '../../../constants/definitions/entities/entities.ts'
import { getNodeType } from '../../../redux/modules/nodes/selectors'
import { hasContent } from '../../../utils/common'
import '../MultipleChoiceQuestion/multiple-choice-question.scss'
import './vote-question.scss'
import { getFeedbackLoading } from '../../../redux/modules/statistics/selectors'
import VoteQuestionOption from './VoteQuestionOption'
import { getTotalCount, getStats, getStatisticsCreatedAt } from '../../../utils/examHelpers'

const getIsStatisticsHidden = node => node?.examClassification?.hide_statistics

const VoteQuestion = props => {
  const {
    children,
    content,
    examType,
    id,
    node,
    options,
    settings = {},
    yleTunnus,
    isFeedbackLoading,
    hideStatistics,
    displayResults,
    examIsOpen,
    submitted,
    t,
    value,
    i18n,
    editing,
  } = props

  // Conditions for displaying voting stats
  const voteHasEnded = !examIsOpen && !isFeedbackLoading
  const loggedInUserSubmittedNode =
    hasContent(node.statistics) && node.submitted && yleTunnus.userId && !isFeedbackLoading
  const shouldShowStats =
    (editing && displayResults) ||
    (!editing && !hideStatistics && (voteHasEnded || loggedInUserSubmittedNode))

  const classes = classnames('yo-multiple-choice-question__options', {
    'yo-multiple-choice-question__options--two-columns':
      settings.optionsLayout === optionLayoutTypes.labels,
  })

  // Has user already selected max number of answers
  const maxAnswers = parseInt(settings.maxAnswers, 10) || 1
  const numSelectedValues = Object.values(value || {}).filter(v => v).length
  const newAnswersAllowed = examIsOpen && (maxAnswers === 1 || numSelectedValues < maxAnswers)

  // For stats
  const totalCount = getTotalCount(node, { plusOneSelectedOption: false })
  const statisticsCreatedAt = getStatisticsCreatedAt(node, i18n.language?.substr(0, 2))

  return (
    <Fragment
      displayNumber={content.displayNumber}
      examType={examType}
      text={<RichTextContent content={content.text} />}
    >
      {children}
      {
        <div className={classes}>
          {options.map(option => (
            <VoteQuestionOption
              editing={editing}
              isLastInInputGroup={option === options[options.length - 1]}
              key={option.id}
              multivote={maxAnswers > 1}
              newAnswersAllowed={newAnswersAllowed}
              nodeId={id}
              option={option}
              showStats={shouldShowStats}
              stats={getStats(node, option, totalCount, {
                plusOneSelectedOption: false,
              })}
              submitted={submitted}
              value={value}
            />
          ))}
        </div>
      }
      {shouldShowStats && (
        <>
          <div className="yo-vote-results__total-count">{t('total', { total: totalCount })}</div>
          {editing && (
            <div className="yo-vote-results__updated-at">
              {t('updatedAt', { time: statisticsCreatedAt })}
            </div>
          )}
        </>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { node } = ownProps
  const nodeType = getNodeType(state, node.nodeTypeId)
  return {
    yleTunnus: state.yleTunnus,
    hasScoring: nodeType.scoring === true,
    hideStatistics: getIsStatisticsHidden(node),
    isFeedbackLoading: getFeedbackLoading(state),
  }
}

export default withTranslation(['VoteQuestion'])(withRouter(connect(mapStateToProps)(VoteQuestion)))
