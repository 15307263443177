import { useSelector } from 'react-redux'
import { getEmbedOptions } from '../../redux/modules/embed/selectors'
import useAutoDarkMode from '../useAutoDarkMode/useAutoDarkMode'

const useYdsTheme = (): 'light' | 'dark' => {
  const embedOptions: { darkMode: 'on' | 'off' | 'auto' | undefined } = useSelector(getEmbedOptions)
  const autoTheme = useAutoDarkMode()
  const isEmbedded = Object.keys(embedOptions).length !== 0

  if (!isEmbedded) {
    return autoTheme
  }

  switch (embedOptions.darkMode) {
    case 'on':
      return 'dark'
    case 'off':
      return 'light'
    case 'auto':
      return autoTheme
    default:
      return 'light'
  }
}

export default useYdsTheme
