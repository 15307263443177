import { createSelector } from 'reselect'
import { sort, descend, prop, compose } from 'ramda'
import {
  getNodeClassification,
  getExamRoot,
  getNode,
  getAllQuestions,
  getAllNodes,
} from '../nodes/selectors'
import { getOptions } from '../options/selectors'
import { getAllDescendantIds } from '../node/node.ts'
import { getCharactersShare, characterQuestionsCompleted } from '../characters/selectors'
import { getExamReviewsShare } from '../reviews/selectors'
import { getCarouselActiveState } from '../carousel/selectors'
import {
  fetchImsData,
  createImsImageUrl,
} from '../../../components/shortcodes/ImsImage/ImsImageHelper'
import { ARCHTYPE_TEST, DEFAULT_EXAM_LANGUAGE, VOTE } from '../../../constants/definitions/exams'
import * as types from '../../../constants/definitions/entities/entities.ts'

export const getExamState = state => state.exam
export const getExamType = createSelector(
  [getExamState, getNodeClassification, getCarouselActiveState],
  (exam, nodeClassification, isCarouselActive) =>
    isCarouselActive && nodeClassification ? nodeClassification.exam_type : exam.exam_type,
)

export const getExamLanguage = state => state.exam.language
export const getExamLanguageOrDefault = createSelector(
  [getExamLanguage],
  language => language || DEFAULT_EXAM_LANGUAGE,
)

export const getExamStatisticsHidden = state => state.exam.hide_statistics

export const getIsOpen = state => state.exam.isOpen !== false

export const getExamEndTime = createSelector(
  [getExamState, getNodeClassification, getCarouselActiveState],
  (exam, nodeClassification, isCarouselActive) =>
    isCarouselActive && nodeClassification ? nodeClassification.endTime : exam.endTime,
)

export const getHideReview = createSelector(
  [getExamState, getNodeClassification],
  (exam, assignmentClassification) => {
    if (exam.hideReviewButtons === undefined) {
      return assignmentClassification ? assignmentClassification.hideReviewButtons : null
    }
    return exam ? exam.hideReviewButtons : null
  },
)

export const getShareConfig = createSelector(
  [getCharactersShare, getExamReviewsShare, getExamRoot, getExamType],
  (charactersShare, reviewsShare, examRoot, examType) => {
    const fileName = `47-${examRoot.id}.json`
    const shareArr = examType === ARCHTYPE_TEST ? charactersShare : reviewsShare
    const uploadDataPromises = shareArr
      .map(share => {
        if (share && share.imageId) {
          return fetchImsData(share.imageId).then(result => {
            const { width, height } = result.data
            const imageUrl = createImsImageUrl({
              aspect: null,
              imsData: result.data,
            })
            return {
              id: share.id,
              title: share.title,
              description: share.description,
              image: imageUrl,
              width,
              height,
            }
          })
        }
        return null
      })
      .filter(i => i !== null)
    return Promise.all(uploadDataPromises).then(uploadData => ({
      uploadData,
      fileName,
    }))
  },
)

export const getOptionsOfFirstChildNodeOfExam = state => {
  const [firstNodeId] = getAllDescendantIds(state.entities.nodes, getExamState(state).uuid)
  if (firstNodeId) {
    const { options } = getNode(state, firstNodeId)
    return getOptions(state.entities, firstNodeId, options)
  }
  return []
}

const sortByScoreDescending = sort(descend(compose(parseInt, prop('score'))))

export const getTopScoredQuestionOptionId = state => {
  const [firstQuestion] = getAllQuestions(state)
  const selectedOptions = firstQuestion?.value ?? {}
  const [topScoredOption] = sortByScoreDescending(Object.values(selectedOptions))
  return topScoredOption?.id
}

const isNodeScoring = nodeTypeId => types.nodeTypes[nodeTypeId]?.scoring

export const getExamSubmitted = createSelector([getAllNodes, getExamState], (nodes, exam) => {
  if (exam.exam_type === VOTE) {
    return Object.values(nodes)
      .filter(n => !types.rootNodeTypes.includes(n.nodeTypeId))
      .every(n => n.submitted)
  }

  const scoreNodes = Object.values(nodes).filter(node => node && isNodeScoring(node.nodeTypeId))
  return (
    scoreNodes.length > 0 &&
    scoreNodes.filter(node => !(node.completed && node.submitted)).length === 0
  )
})

export const getIsExamCompleted = createSelector(
  [getExamType, characterQuestionsCompleted, getExamSubmitted],
  (examType, questionsCompleted, examSubmitted) => {
    if (examType === ARCHTYPE_TEST) {
      return questionsCompleted
    }
    return examSubmitted
  },
)
