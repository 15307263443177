import React from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
  $createTextNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  createCommand,
  LexicalCommand,
  TextNode,
} from 'lexical'
import shortcodes, {
  Shortcode,
  ShortcodeName,
  InteractiveShortcodeName,
} from '../../../../constants/definitions/shortcodes'
import { $createShortcodeNode } from '../nodes/ShortcodeNode'
import { DisconnectedDialog } from '../../../layout/Dialog/Dialog'

export type InsertShortcodeCommandProps = {
  text: string
  name: ShortcodeName
}

export type InitInsertShortcodeCommandProps = {
  name: Exclude<ShortcodeName, InteractiveShortcodeName>
  autoFillProperty?: string
}

export const INSERT_SHORTCODE_COMMAND: LexicalCommand<InsertShortcodeCommandProps> = createCommand()
// eslint-disable-next-line max-len
export const INIT_INSERT_SHORTCODE_COMMAND: LexicalCommand<InitInsertShortcodeCommandProps> = createCommand()

interface ShortcodeDialogProps {
  shortcode: Shortcode
  onSubmit: (text: string, name: ShortcodeName) => void
  onCancel: () => void
  initialValues: { [property: string]: string }
}

const ShortcodeDialog = ({
  shortcode,
  onSubmit,
  onCancel,
  initialValues,
}: ShortcodeDialogProps) => {
  const [values, setValues] = React.useState({})

  React.useEffect(() => {
    setValues(initialValues)
  }, [initialValues])

  return (
    <DisconnectedDialog
      isShortcode
      onClearDialog={onCancel}
      onClickSubmit={shortcodeText => onSubmit(shortcodeText, shortcode.shortcode)}
      setDialogValue={(key, value) => setValues({ ...values, [key]: value })}
      values={values}
      {...shortcode}
    />
  )
}

export const toShortcode = (property: string, value: string | number, shortcode: ShortcodeName) => {
  return `[${shortcode} ${property}=${value}]`
}

const ShortcodePlugin = () => {
  const [editor] = useLexicalComposerContext()
  const [editShortcode, setEditShortcode] = React.useState<Shortcode | null>(null)
  const [editShortcodeInitialValues, setEditShortcodeInitialValues] = React.useState<{
    [property: string]: string
  }>({})

  React.useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        INSERT_SHORTCODE_COMMAND,
        ({ text, name }: InsertShortcodeCommandProps) => {
          editor.update(() => {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
              const shortcodeNode = $createShortcodeNode(text, name)
              selection.insertNodes([shortcodeNode])
            }
          })
          return true
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        INIT_INSERT_SHORTCODE_COMMAND,
        ({ name, autoFillProperty }: InitInsertShortcodeCommandProps) => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            const isPointSelection = selection.anchor.is(selection.focus)
            if (autoFillProperty && !isPointSelection) {
              const hasOneDialogField = shortcodes[name].dialogFields.length === 1
              if (hasOneDialogField) {
                const text = toShortcode(autoFillProperty, selection.getTextContent(), name)
                editor.dispatchCommand(INSERT_SHORTCODE_COMMAND, { text, name })
              } else {
                setEditShortcodeInitialValues({ [autoFillProperty]: selection.getTextContent() })
                setEditShortcode(shortcodes[name])
              }
            } else {
              setEditShortcodeInitialValues({})
              setEditShortcode(shortcodes[name])
            }
          }
          return true
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor])

  return editShortcode ? (
    <ShortcodeDialog
      initialValues={editShortcodeInitialValues}
      onCancel={() => setEditShortcode(null)}
      onSubmit={(text, name) => editor.dispatchCommand(INSERT_SHORTCODE_COMMAND, { text, name })}
      shortcode={editShortcode}
    />
  ) : null
}

const SHORTCODE_REGEX = /\[[A-Z]+ [^\]]*\]/
const SHORTCODE_NAME_REGEX = /\[([A-Z]+) [^\]]*\]/

export const $convertShortcodeStrings = (): void => {
  const root = $getRoot()
  const textNodes = root.getAllTextNodes()

  const insertShortcodeNode = (shortcode: string, node: TextNode) => {
    const shortcodeStartPos = node.getTextContent().indexOf(shortcode)
    const shortcodeEndPos = shortcodeStartPos + shortcode.length
    const restOfText = node.getTextContent().slice(shortcodeEndPos)
    node.spliceText(shortcodeStartPos, node.getTextContent().length - shortcodeStartPos, '')
    // eslint-disable-next-line prefer-destructuring
    const shortcodeName = shortcode.match(SHORTCODE_NAME_REGEX)![1]
    const shortcodeNode = $createShortcodeNode(shortcode, shortcodeName as ShortcodeName)
    node.insertAfter(shortcodeNode)
    if (restOfText) {
      const restOfNode = $createTextNode(restOfText)
      shortcodeNode.insertAfter(restOfNode)
      return restOfNode
    }
    return null
  }

  for (const node of textNodes) {
    const shortcode = node.getTextContent().match(SHORTCODE_REGEX)
    if (shortcode) {
      const restOfNode = insertShortcodeNode(shortcode[0], node)
      if (restOfNode) {
        textNodes.push(restOfNode)
      }
    }
  }

  root.selectEnd()
}

export default ShortcodePlugin
