import React, { memo, Fragment, useEffect, useRef } from 'react'
import { is } from 'ramda'
import sum from 'hash-sum'
import remark from 'remark'
import remark2react from 'remark-react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import {
  replacePlaceholders,
  shortcodes,
  newLines,
  fixHeadingNewLines,
} from './RichTextContentHelpers'
import { getExamLanguage } from '../../../redux/modules/exam/selectors'

const RichTextContent = memo(props => {
  const { content, ignoreNewlines, ignoreShortcodes, props: childProps, language } = props

  /**
   * elements object will contain all the React elements (links, subscript texts etc.)
   * that have been created from special markdown tags such as
   * [LINK text=Ilta-Sanomat|url=www.iltasanomat.fi]
   */

  let elements = {}

  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    elements = {}
  })

  /**
   * Parses the original markdown content.
   * The markdown could look like the following:
   *  ### Basic title
      ### &NewLine;

      - first
      - second
      - [LINK text=Ilta-Sanomat|url=www.iltasanomat.fi]
      - [LINK text=Iltalehti|url=www.iltalehti.fi]
      - fifth
   */
  const fixContent = () => {
    if (!content) {
      return []
    }

    const headingCount = (content.match(/###/g) || []).length

    let fixedContent = content.trim()

    if (ignoreNewlines) {
      fixedContent = fixedContent.replace(/\r?\n|\r/g, '')
    }

    if (headingCount > 0) {
      fixedContent = fixHeadingNewLines(fixedContent, headingCount)
    }

    // Fix escaped underscores
    fixedContent = fixedContent.replace(/\\_/g, '_')

    fixedContent = newLines(fixedContent, elements)

    // Shortcodes are replaced by a key of the element
    if (!ignoreShortcodes) {
      fixedContent = shortcodes(fixedContent, { ...childProps, language }, elements)
    }

    // Shortcodes are replaced with React elements
    fixedContent = replacePlaceholders(fixedContent, elements)

    return fixedContent
  }

  const renderContent = (content, index) => {
    if (is(String, content)) {
      const key = sum(content)
      return (
        <Fragment key={`${key}-${index}`}>
          {
            remark()
              .use(remark2react)
              .processSync(content).contents
          }
        </Fragment>
      )
    }
    return content
  }

  return (
    <span {...childProps.attributes} data-testid="rich-text-content">
      {fixContent().map((content, i) => renderContent(content, i))}
    </span>
  )
})

RichTextContent.defaultProps = {
  ignoreNewlines: false,
  ignoreShortcodes: false,
  props: {},
  content: '',
}

RichTextContent.propTypes = {
  content: PropTypes.string,
  language: PropTypes.string.isRequired,
  props: PropTypes.shape({}),
  ignoreNewlines: PropTypes.bool,
  ignoreShortcodes: PropTypes.bool,
}

const mapStateToProps = state => ({
  language: getExamLanguage(state),
})

export const RichTextContentWithoutHoCs = RichTextContent
export default connect(mapStateToProps)(RichTextContent)
