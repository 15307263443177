import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MATRICULATION_EXAM_TYPE } from '../../../constants/definitions/exams'
import { addOption } from '../../../redux/modules/node/actions.ts'
import { createOption } from '../../../redux/modules/options/options'
import { getOptions } from '../../../redux/modules/options/selectors'
import Button from '../../elements/Button/Button.tsx'
import { Accordion } from '../../node/Accordion/Accordion'
import OptionItem from './OptionItem'
import Separator from '../Separator/Separator'

/**
 * Component for creating accordion list of options in a node entity.
 *
 * @param {object} node - Parent node of the options
 * @param {object} nodeType - Object describing the option node
 * @param {array} options - Array of option entity objects
 * @param {function} onAddOptionClick - Click hanlder for adding new options
 */
const EditorOptions = ({ node, nodeType, options, onAddOptionClick }) => {
  // Create items for accordion.
  const items = options.map((option, i) => (
    <div data-testid={`editor-option-${option.id}`} key={`editor-option-${option.id}`}>
      <OptionItem nodeId={node.id} nodeType={nodeType} option={option} />
      {i + 1 < options.length && <Separator key={`editor-option-separator-${option.id}`} />}
    </div>
  ))

  const inputId = 'yo-input__editor-options'

  const optionItems =
    items.length > 0 ? <Accordion id={inputId}>{items}</Accordion> : <Accordion id={inputId} />

  return (
    <div>
      <label className="yo-input__label" htmlFor={inputId}>
        Options
        {optionItems}
        <Button
          attributes={{ 'data-testid': 'button__add-option' }}
          isDisabled={items.length >= nodeType.maxNumberOfOptions}
          onClick={onAddOptionClick}
          size="sm"
          text="Add option"
        />
      </label>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  exam: state.exam,
  options: getOptions(state.entities, ownProps.node.id, ownProps.node.options),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onAddOptionClick() {
    const { exam } = stateProps
    const { dispatch } = dispatchProps
    const { choiceFields, optionFields } = ownProps.nodeType
    const actions = bindActionCreators({ addOption, createOption }, dispatch)
    let option = {}

    // Check if node should have multiple values for options.
    if (choiceFields && choiceFields.length > 0) {
      option = Object.assign(option, { multivalue: true, choices: [] })
    }

    // Set case sensitivity option fields to be checked by default for "yo-koe" exams.
    if (
      exam.exam_type === MATRICULATION_EXAM_TYPE &&
      optionFields.find(field => field.name === 'isCaseSensitive')
    ) {
      option = { ...option, isCaseSensitive: true }
    }

    // Create option and get the id.
    const optionId = actions.createOption(option, ownProps.node.id)

    // Add the option to the node.
    actions.addOption(optionId, ownProps.node.id)
  },
})

export default connect(mapStateToProps, null, mergeProps)(EditorOptions)
