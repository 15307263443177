import React from 'react'
import defaultProps from './defaultProps'

const HorizontalDots = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M10.001,7.8C8.786,7.8,7.8,8.785,7.8,10s0.986,2.2,2.201,2.2S12.2,11.215,12.2,10S11.216,7.8,10.001,7.8z   M3.001,7.8C1.786,7.8,0.8,8.785,0.8,10s0.986,2.2,2.201,2.2S5.2,11.214,5.2,10S4.216,7.8,3.001,7.8z M17.001,7.8  C15.786,7.8,14.8,8.785,14.8,10s0.986,2.2,2.201,2.2S19.2,11.215,19.2,10S18.216,7.8,17.001,7.8z"
      fill={fill}
    />
  </svg>
)

HorizontalDots.defaultProps = defaultProps

export default HorizontalDots
