import React from 'react'
import { connect } from 'react-redux'
import Input from '../../elements/Input/Input'
import { updateNodeSetting } from '../../../redux/modules/node/actions.ts'

const EditorSettingsDuration = ({ node, onDurationChange }) => (
  <div>
    <Input
      defaultValue={node.settings.duration}
      inline
      label="Duration (sec)"
      onChange={onDurationChange}
      type="number"
      width="narrow"
    />
  </div>
)

const mapDispatchToProps = (dispatch, ownProps) => {
  const { node } = ownProps

  return {
    onDurationChange: e => {
      dispatch(
        updateNodeSetting(node.id, {
          duration: e.target.value,
        }),
      )
    },
  }
}

export default connect(null, mapDispatchToProps)(EditorSettingsDuration)
