// Get the next id for an option.
function getNextOptionId(state) {
  const { options } = state.entities

  let ids = Object.keys(options).map(key => options[key].id)
  // @todo Remove after there are no hardcoded ids in exam states.
  ids = ids.filter(id => Number.isInteger(id))

  // No options yet, use 1 as first id.
  if (!ids.length) {
    return 1
  }

  // Get the last id and increment it by one.
  const lastId = Math.max(...ids)
  return lastId + 1
}

export default getNextOptionId
