import jwtDecode from 'jwt-decode'
import moment from 'moment'

// getToken :: Token
export const getToken = () => {
  try {
    return sessionStorage.getItem('token')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return null
  }
}

export const setToken = token => {
  try {
    sessionStorage.setItem('token', token)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

export const removeToken = () => {
  try {
    sessionStorage.removeItem('token')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

// getUserID :: Token -> String
export const getUserID = token => jwtDecode(token).sub

export const getUserEmail = token => jwtDecode(token).email

export const getExpiration = token => new Date(jwtDecode(token).exp * 1000)

export const isAboutToExpire = token => {
  const expiration = getExpiration(token)
  const now = new Date()
  return moment(now) >= moment(expiration).subtract(3, 'minute')
}
