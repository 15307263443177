import React from 'react'
import defaultProps from './defaultProps'

const Up = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path className="yo-icon-svg-path" d="M10 2.5l7.5 7.5H14v7H6v-7H2.5L10 2.5z" fill={fill} />
  </svg>
)

Up.defaultProps = defaultProps

export default Up
