import React from 'react'
import { TextInput } from '@yleisradio/yds-components-react'
import classnames from 'classnames'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { updateValue } from '../../../redux/modules/node/actions.ts'
import Input from '../../elements/Input/Input'
import ProgressBar from '../../elements/ProgressBar/ProgressBar.tsx'
import Fragment from '../../node/Fragment/Fragment'
import MultipleChoiceOption, {
  extractImages,
  MultipleChoiceOptionImageContent,
} from '../MultipleChoiceQuestion/MultipleChoiceOption.tsx'
import OptionAnswers from '../../elements/OptionAnswers/OptionAnswers.tsx'
import './vote-question.scss'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'

const FreeTextInput = ({ checked, option, value, handleOptionChange }) => (
  <div className="free-text-input-container">
    <TextInput
      isDisabled={!checked}
      label="Free text input"
      labelOptions={{ isHidden: true }}
      onChange={e => handleOptionChange({ optionId: option.id, optionValue: e.target.value })}
      value={(value && value[option.id]) || ''}
    />
  </div>
)

const CheckboxOption = props => {
  const {
    isLastInInputGroup,
    disabled,
    option,
    attributes,
    checked,
    nodeId,
    handleOptionChange,
    value,
    showStats,
    stats: { count, percentage },
    editing,
  } = props
  const optionClasses = classnames({
    'yo-input--last': isLastInInputGroup,
    'yo-input--disabled': disabled,
  })
  return (
    <Fragment key={option.id}>
      <div className="yo-vote-question__option">
        <Input
          attributes={attributes}
          checked={checked}
          className={optionClasses}
          idSuffix={option.id}
          label={option.text}
          name={`${nodeId}--options`}
          onChange={
            disabled
              ? () => false
              : () =>
                  handleOptionChange({
                    optionId: option.id,
                    // eslint-disable-next-line no-nested-ternary
                    optionValue: checked ? undefined : option.freeTextInput ? '' : option.text,
                  })
          }
          type="checkbox"
        />
        {option.freeTextInput && (
          <Input
            className="yo-vote-question__free-text-input"
            defaultValue={(value && value[option.id]) || ''}
            onChange={e => handleOptionChange({ optionId: option.id, optionValue: e.target.value })}
            type="text"
          />
        )}
      </div>
      {showStats && (
        <>
          {!Number.isNaN(percentage) && (
            <ProgressBar count={count} height=".5em" progress={percentage} showProgress />
          )}
          {editing && (
            <OptionAnswers
              isSumChecked={option.freeTextInput}
              optionId={option.id}
              questionUuid={nodeId}
            />
          )}
        </>
      )}
    </Fragment>
  )
}

const VoteQuestionOption = props => {
  const {
    stats,
    multivote,
    newAnswersAllowed,
    showStats,
    option,
    value,
    handleOptionChange,
  } = props
  const { count, percentage } = stats
  const checked = Boolean(value && Object.keys(value).includes(option.id.toString()))
  const disabled = !newAnswersAllowed && !checked
  const attributes = {
    'data-testid': 'input__MULTIPLE_CHOICE_QUESTION',
    'data-value': option.text,
    'data-option-id': option.id,
    disabled,
  }
  const text = extractImages(option.text)
  const content = (
    <MultipleChoiceOptionImageContent
      count={count}
      images={text.images}
      percentage={percentage}
      showStats={showStats}
    />
  )
  const richText = <RichTextContent content={text.labelWithoutImages || ''} />
  if (multivote) {
    return (
      <CheckboxOption {...props} attributes={attributes} checked={checked} disabled={disabled} />
    )
  }
  return (
    <>
      <MultipleChoiceOption
        attributes={attributes}
        checked={checked}
        content={content}
        hasIcon={false}
        label={richText}
        onChange={
          disabled
            ? () => false
            : () =>
                handleOptionChange({
                  optionId: option.id,
                  // eslint-disable-next-line no-nested-ternary
                  optionValue: checked ? undefined : option.freeTextInput ? '' : option.text,
                })
        }
      />
      {option.freeTextInput && checked && (
        <FreeTextInput
          checked={checked}
          handleOptionChange={handleOptionChange}
          option={option}
          value={value}
        />
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleOptionChange: ({ optionId, optionValue }) => {
    const { multivote, nodeId, submitted, value: existingAnswers } = ownProps

    const newAnswers = multivote
      ? { ...existingAnswers, [optionId]: optionValue }
      : { [optionId]: optionValue }

    if (!submitted) {
      dispatch(
        updateValue(
          nodeId,
          // Filters out the undefined values
          R.reject(R.isNil, newAnswers),
          null,
          true,
        ),
      )
    }
  },
})

export default connect(null, mapDispatchToProps)(VoteQuestionOption)
