import { createSelector } from 'reselect'
import { getExamState } from '../exam/selectors'
import { hasContent } from '../../../utils/common'

export const getSeriesData = state => state.series.data
export const getSeriesLoading = state => state.series.loading

export const getExamSeries = createSelector([getSeriesData, getExamState], (series, exam) =>
  exam.series_uuids ? series.filter(x => exam.series_uuids.includes(x.uuid)) : null,
)

export const getSeriesClassification = createSelector([getSeriesData], series =>
  hasContent(series) ? series[0].classification : {},
)
