import { FieldTypeEditorFormatter, NodeType, NodeTypeCategory, TehtavaNode } from './types'
import * as mFieldTypes from './matriculationExamFieldTypes'
import * as gFieldTypes from './genericFieldTypes'
import {
  evaluateCompletionValueIsObject,
  evaluateCompletionForMultipleChoiceGapQuestion,
} from './nodeStateCompletionEvaluators'

export const TEXTBOX_QUESTION: TehtavaNode = {
  id: NodeType.TEXTBOX_QUESTION,
  name: 'TextboxQuestion',
  displayName: 'Textbox question',
  description: 'Write answer in a textbox',
  type: NodeTypeCategory.QUESTION,
  feedback: true,
  reviewable: true,
  scoring: false,
  childNodeTypeIds: [],
  fields: [
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  settingFields: [gFieldTypes.tags],
  maxNumberOfOptions: 1,
  optionFields: [gFieldTypes.placeholder],
  choiceFields: [],
  evaluateCompleted: () => true,
  shortcode: 'TEXTBOX',
}

export const DROPDOWN_QUESTION: TehtavaNode = {
  id: NodeType.DROPDOWN_QUESTION,
  name: 'DropdownQuestion',
  displayName: 'Dropdown question',
  description: 'Choose value from dropdown menu',
  type: NodeTypeCategory.QUESTION,
  feedback: true,
  reviewable: true,
  scoring: false,
  childNodeTypeIds: [],
  fields: [
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  settingFields: [gFieldTypes.tags],
  maxNumberOfOptions: 1,
  optionFields: [],
  choiceFields: [{ ...gFieldTypes.text, displayName: 'Option' }],
  evaluateCompleted: () => true,
  shortcode: 'DROPDOWN',
}

export const MULTIPLE_CHOICE_GAP_QUESTION: TehtavaNode = {
  id: NodeType.MULTIPLE_CHOICE_GAP_QUESTION,
  name: 'MultipleChoiceGapQuestion',
  displayName: 'Multiple choice gap question',
  description: 'multiple-choice-gap-question',
  type: NodeTypeCategory.QUESTION,
  feedback: true,
  scoring: true,
  reviewable: true,
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    mFieldTypes.jokerQuestion,
    mFieldTypes.materialQuestion,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  optionFields: [gFieldTypes.hint],
  choiceFields: [{ ...gFieldTypes.text, displayName: 'Option' }, gFieldTypes.correct],
  settingFields: [gFieldTypes.points, gFieldTypes.requireAllCorrect, gFieldTypes.tags],
  evaluateCompleted: evaluateCompletionForMultipleChoiceGapQuestion,
  childNodeTypeIds: [],
  shortcode: 'SELECT',
}

export const CUSTOMIZED_FEEDBACK: TehtavaNode = {
  id: NodeType.CUSTOMIZED_FEEDBACK,
  name: 'CustomizedFeedback',
  description: 'customized-feedback',
  displayName: 'Customized multiple choice question',
  type: NodeTypeCategory.QUESTION,
  scoring: true,
  feedback: true,
  reviewable: true,
  childNodeTypeIds: [],
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  optionFields: [
    { ...gFieldTypes.text, displayName: 'Option' },
    gFieldTypes.correctReviewOnly,
    gFieldTypes.correctNotReviewOnly,
    {
      ...gFieldTypes.points,
      description: undefined,
    },
    {
      name: 'feedback',
      displayName: 'Feedback',
      editorFormatter: FieldTypeEditorFormatter.richText,
    },
  ],
  settingFields: [
    gFieldTypes.points,
    gFieldTypes.customizedPoints,
    gFieldTypes.demandCorrect,
    gFieldTypes.optionsLayout,
    gFieldTypes.noVisualization,
    gFieldTypes.requireAllCorrect,
    gFieldTypes.tags,
  ],
  evaluateCompleted: evaluateCompletionValueIsObject,
}

export const ARCHTYPE_MULTIPLE_CHOICE: TehtavaNode = {
  id: NodeType.ARCHTYPE_MULTIPLE_CHOICE,
  name: 'ArchtypeMultipleChoice',
  description: 'Multiple choice question',
  displayName: 'Multiple choice question',
  type: NodeTypeCategory.QUESTION,
  scoring: false,
  feedback: true,
  reviewable: false,
  childNodeTypeIds: [],
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  optionFields: [{ ...gFieldTypes.text, displayName: 'Option' }],
  settingFields: [
    gFieldTypes.optionsLayout,
    {
      ...gFieldTypes.demandCorrect,
      description: '',
    },
    gFieldTypes.tags,
  ],
  evaluateCompleted: evaluateCompletionValueIsObject,
}

export const ARCHTYPE_CUSTOMIZED_FEEDBACK: TehtavaNode = {
  id: NodeType.ARCHTYPE_CUSTOMIZED_FEEDBACK,
  name: 'ArchtypeCustomizedFeedback',
  description: 'Multiple choice question with customized feedback options',
  displayName: 'Customized multiple choice question',
  type: NodeTypeCategory.QUESTION,
  scoring: false,
  feedback: true,
  reviewable: false,
  childNodeTypeIds: [],
  fields: [
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  optionFields: [
    { ...gFieldTypes.text, displayName: 'Option' },
    {
      name: 'feedback',
      displayName: 'Feedback',
      editorFormatter: FieldTypeEditorFormatter.richText,
    },
  ],
  settingFields: [gFieldTypes.optionsLayout, gFieldTypes.tags],
  evaluateCompleted: evaluateCompletionValueIsObject,
}

export const POLL_MULTIPLE_CHOICE: TehtavaNode = {
  id: NodeType.POLL_MULTIPLE_CHOICE,
  name: 'PollMultipleChoice',
  description: 'Multiple choice question',
  displayName: 'Multiple choice question',
  type: NodeTypeCategory.QUESTION,
  scoring: false,
  feedback: true,
  reviewable: true,
  childNodeTypeIds: [],
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  optionFields: [{ ...gFieldTypes.text, displayName: 'Option' }],
  settingFields: [gFieldTypes.optionsLayout, gFieldTypes.tags],
  evaluateCompleted: evaluateCompletionValueIsObject,
}

export const POLL_CUSTOMIZED_FEEDBACK: TehtavaNode = {
  id: NodeType.POLL_CUSTOMIZED_FEEDBACK,
  name: 'PollCustomizedFeedback',
  description: 'Poll question with separate feedback for each answer',
  displayName: 'Customized feedback poll',
  type: NodeTypeCategory.QUESTION,
  scoring: true,
  feedback: true,
  reviewable: true,
  childNodeTypeIds: [],
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  optionFields: [
    { ...gFieldTypes.text, displayName: 'Option' },
    {
      name: 'feedback',
      displayName: 'Feedback',
      editorFormatter: FieldTypeEditorFormatter.richText,
    },
  ],
  settingFields: [gFieldTypes.optionsLayout, gFieldTypes.tags],
  evaluateCompleted: evaluateCompletionValueIsObject,
}

export const MULTIPLE_CHOICE_QUESTION: TehtavaNode = {
  id: NodeType.MULTIPLE_CHOICE_QUESTION,
  name: 'MultipleChoiceQuestion',
  displayName: 'Multiple choice question',
  description: 'multiple-choice-question',
  type: NodeTypeCategory.QUESTION,
  scoring: true,
  feedback: true,
  reviewable: true,
  childNodeTypeIds: [],
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    mFieldTypes.jokerQuestion,
    mFieldTypes.materialQuestion,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  optionFields: [
    { ...gFieldTypes.text, displayName: 'Option' },
    gFieldTypes.correctReviewOnly,
    gFieldTypes.correctNotReviewOnly,
  ],
  settingFields: [
    gFieldTypes.timedQuestion,
    gFieldTypes.points,
    gFieldTypes.optionsLayout,
    gFieldTypes.tags,
  ],
  evaluateCompleted: evaluateCompletionValueIsObject,
}
