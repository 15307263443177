import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { storeExamToAPI } from '../../../redux/middleware/examService/examService'
import { newExam } from '../../../redux/modules/exams/exams'
import Button from '../../elements/Button/Button.tsx'
import './wizard.scss'
import { getExamRoot, getNode } from '../../../redux/modules/nodes/selectors'
import ExamTypeBox from './ExamTypeBox'
import * as CustomPropTypes from '../../../constants/definitions/propTypes'
import PrimaryHeader from '../../elements/Typography/PrimaryHeader.tsx'

class ExamWizard extends React.Component {
  componentDidMount() {
    const { location } = this.props

    // Create new exam upon entering, unless redirected from series
    // and exam is already created.
    if (!location.state || !location.state.addToSeries) {
      this.props.onEnter()
    }
  }

  render() {
    const { exam, onClickSave, history, rootNode, t } = this.props

    const handleOnClick = () => {
      onClickSave(
        {
          examId: exam.uuid,
        },
        history,
      )
    }

    return (
      <div className="yo-wizard">
        <span className="yo-wizard__heading">
          <PrimaryHeader>{t('create-exam')}</PrimaryHeader>
        </span>
        <span className="yo-wizard__create-buttons">
          <Button
            attributes={{ 'data-testid': 'button-create-exam-upper' }}
            onClick={handleOnClick}
            text={t('create-exam')}
          />
        </span>
        <span className="yo-wizard__exam-type">
          <ExamTypeBox exam={exam} rootNode={rootNode} />
        </span>
        <span className="yo-wizard__create-buttons">
          <Button
            attributes={{ 'data-testid': 'button-create-exam-lower' }}
            onClick={handleOnClick}
            text={t('create-exam')}
          />
        </span>
      </div>
    )
  }
}

ExamWizard.propTypes = {
  exam: CustomPropTypes.exam,
  onEnter: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
}

ExamWizard.defaultProps = {
  exam: null,
}

const goToSettings = (examId, history) => history.push(`/exam/${examId}/settings`)

const mapStateToProps = state => ({
  exam: state.exam,
  wizard: state.wizard,
  rootNode: getExamRoot(state),
  // Needed for wizardFields
  node: getNode(state, state.exam.uuid),
})

const mapDispatchToProps = dispatch => ({
  onEnter: () => {
    dispatch(newExam())
  },
  onClickSave: ({ examId }, history) => {
    dispatch(storeExamToAPI())
    goToSettings(examId, history)
  },
  dispatch,
})

export default withTranslation(['ExamWizard'])(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamWizard)),
)
