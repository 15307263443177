export function getParameters(state) {
  // Make sure parameters is defined as object.
  if (typeof state.editing.parameters === 'object') {
    return state.editing.parameters
  }

  return null
}

export function getNodeId(state) {
  const parameters = getParameters(state)

  // Make sure parameters is defined and nodeId within it.
  if (parameters && 'nodeId' in parameters) {
    return parameters.nodeId
  }

  return null
}

export function getStatus(state) {
  if ('status' in state.editing) {
    return state.editing.status
  }

  return null
}
