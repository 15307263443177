import React from 'react'
import { connect } from 'react-redux'
import { updateValue, submitNode } from '../../../redux/modules/node/actions.ts'
import Input from '../../elements/Input/Input'
import InputCounters from '../../elements/Input/InputCounters'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import Fragment from '../../node/Fragment/Fragment'

const EssayQuestion = ({
  content,
  value,
  onChangeValue,
  onBlur,
  settings = {},
  validate,
  examType,
}) => (
  <Fragment
    displayNumber={content.displayNumber}
    examType={examType}
    text={<RichTextContent content={content.text} />}
  >
    <Input
      attributes={{ 'data-testid': 'input__ESSAY_QUESTION' }}
      disabled={validate}
      onBlur={onBlur}
      onChange={onChangeValue}
      textareaSize={settings.textareaSize}
      type="textarea"
      value={value}
    />
    {!(settings.hideWordCounter || settings.textareaSize === '1') && (
      <InputCounters value={value} />
    )}
  </Fragment>
)

const mapStateToProps = (state, ownProps) => {
  const { id, value } = ownProps

  return {
    // Get the value from node or default to empty string.
    value: value && typeof value[id] !== 'undefined' ? value[id] : '',
    // Whether the answer should be validated.
    validate: ownProps.submitted,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeValue: e => {
    const value = {
      [ownProps.id]: e.target.value.trim() === '' ? '' : e.target.value,
    }

    dispatch(updateValue(ownProps.id, value, ownProps.evaluateCompleted))
  },
  onBlur: e => {
    const { submitted, id, node } = ownProps
    const isAutosubmit = node?.examClassification?.hide_review_buttons
    const hasValue = e.target.value !== ''
    if (isAutosubmit && hasValue && !submitted) {
      dispatch(submitNode(id))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(EssayQuestion)
