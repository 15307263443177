// Actions
const CLEAR_COLLAPSED_NODES = 'editor/CLEAR_COLLAPSED_NODES'
const TOGGLE_COLLAPSED_NODES = 'editor/TOGGLE_COLLAPSED_NODES'
const TOGGLE_SIZE = 'editor/TOGGLE_SIZE'
const SET_OPENED_FOR_EDITING_AT = 'editor/SET_OPENED_FOR_EDITING_AT'
const CLEAR_OPENED_FOR_EDITING_AT = 'editor/SET_OPENED_FOR_EDITING_AT'

// Action creators
export const toggleEditorSize = () => ({
  type: TOGGLE_SIZE,
})

export const toggleCollapsedNodes = nodeId => ({
  type: TOGGLE_COLLAPSED_NODES,
  nodeId,
})

export const clearCollapsedNodes = () => ({
  type: CLEAR_COLLAPSED_NODES,
})

export const setOpenedForEditingAt = openedForEditingAt => ({
  type: SET_OPENED_FOR_EDITING_AT,
  openedForEditingAt,
})

export const clearOpenedForEditingAt = () => ({
  type: CLEAR_OPENED_FOR_EDITING_AT,
})

// Reducer
const reducer = (
  state = { size: 'small', collapsedNodes: [], openedForEditingAt: undefined },
  action = {},
) => {
  const { collapsedNodes } = state
  const size = state.size === 'small' ? 'large' : 'small'

  const { nodeId, openedForEditingAt } = action

  switch (action.type) {
    case TOGGLE_SIZE: {
      return { ...state, size }
    }

    case TOGGLE_COLLAPSED_NODES: {
      if (collapsedNodes.includes(nodeId)) {
        collapsedNodes.splice(collapsedNodes.indexOf(nodeId), 1)
      } else {
        collapsedNodes.push(nodeId)
      }
      return { ...state, collapsedNodes }
    }

    case CLEAR_COLLAPSED_NODES: {
      return { ...state, collapsedNodes: [] }
    }

    case SET_OPENED_FOR_EDITING_AT: {
      return { ...state, openedForEditingAt }
    }

    case CLEAR_OPENED_FOR_EDITING_AT: {
      return { ...state, openedForEditingAt: undefined }
    }

    default: {
      return state
    }
  }
}

export default reducer
