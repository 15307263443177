// Actions
const CLEAR_EXAM_LIST_LOADING_STATE = 'load_state/CLEAR_EXAM_LIST_LOADING_STATE'
export const CLEAR_EXAM_LOAD_STATE = 'load_state/CLEAR_EXAM_LOAD_STATE'
const CLEAR_QUESTIONS_LOAD_STATE = 'load_state/CLEAR_QUESTIONS_LOAD_STATE'
export const SET_CAROUSEL_ACTIVE = 'load_state/SET_CAROUSEL_ACTIVE'
export const CLEAR_CAROUSEL_ACTIVE = 'load_state/CLEAR_CAROUSEL_ACTIVE'
const SET_EXAM_LIST_LOADING_STATE = 'load_state/SET_EXAM_LIST_LOADING_STATE'
export const SET_EXAM_LOAD_STATE = 'load_state/SET_EXAM_LOAD_STATE'
const SET_QUESTIONS_LOAD_STATE = 'load_state/SET_QUESTIONS_LOAD_STATE'
const SET_EXAM_ERROR_STATE = 'load_state/SET_EXAM_ERROR_STATE'

// Action creators
export function clearExamListLoading() {
  return {
    type: CLEAR_EXAM_LIST_LOADING_STATE,
  }
}

export function clearExamLoadState() {
  return {
    type: CLEAR_EXAM_LOAD_STATE,
  }
}

export function clearQuestionsLoadState() {
  return {
    type: CLEAR_QUESTIONS_LOAD_STATE,
  }
}

export const setCarouselActive = () => ({
  type: SET_CAROUSEL_ACTIVE,
})

export const clearCarouselActive = () => ({
  type: CLEAR_CAROUSEL_ACTIVE,
})

export function setExamListLoading() {
  return {
    type: SET_EXAM_LIST_LOADING_STATE,
  }
}

export function setExamLoadState(nodeId, loaded = false) {
  return {
    type: SET_EXAM_LOAD_STATE,
    nodeId,
    loaded,
  }
}

export function setQuestionsLoadState() {
  return {
    type: SET_QUESTIONS_LOAD_STATE,
  }
}

export function setExamErrorState(nodeId, errorMessage = '') {
  return {
    type: SET_EXAM_ERROR_STATE,
    nodeId,
    errorMessage,
  }
}

// Reducer
const defaultState = {
  examListLoading: false,
  isCarouselActive: false,
  loaded: false,
  nodeId: null,
  questionsLoaded: false,
  errorMessage: '',
}

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case CLEAR_EXAM_LIST_LOADING_STATE:
      return {
        ...state,
        examListLoading: defaultState.examListLoading,
      }

    case CLEAR_EXAM_LOAD_STATE:
      return {
        ...state,
        loaded: defaultState.loaded,
        nodeId: defaultState.nodeId,
      }

    case CLEAR_QUESTIONS_LOAD_STATE:
      return {
        ...state,
        questionsLoaded: defaultState.questionsLoaded,
      }

    case SET_CAROUSEL_ACTIVE:
      return { ...state, isCarouselActive: true }

    case CLEAR_CAROUSEL_ACTIVE:
      return { ...state, isCarouselActive: false }

    case SET_EXAM_LIST_LOADING_STATE:
      return {
        ...state,
        examListLoading: true,
      }

    case SET_EXAM_LOAD_STATE:
      return {
        // We can use ...defaultState because the other properties should be at their default values
        // when an exam has been opened (=loaded)
        ...defaultState,
        loaded: action.loaded,
        nodeId: action.nodeId,
      }

    case SET_EXAM_ERROR_STATE:
      return {
        ...state,
        nodeId: action.nodeId,
        errorMessage: action.errorMessage,
      }

    case SET_QUESTIONS_LOAD_STATE:
      return {
        // when single question(s) have been opened (=loaded) instead of an exam
        ...state,
        questionsLoaded: true,
      }

    default:
      return state
  }
}
