// Get array of options.
// eslint-disable-next-line import/prefer-default-export
export const getOptions = (entities, parentId, optionIds) => {
  const options = optionIds
    .map(optionId => entities.options[`${parentId}--${optionId}`])
    .filter(option => option !== undefined)

  return options.map(option => {
    let label = ''
    // Primarily use text as option label.
    if (option.text) {
      label = option.text
    } else if (option.correct && typeof option.correct !== 'boolean') {
      // Secondarily use correct answer as option label if it is not boolean.
      label = option.correct
    }

    // Get choices for each option.
    if ('choices' in option && option.choices.length > 0) {
      const choices = option.choices.map(choiceId => {
        const choice = entities.choices[choiceId]

        return Object.assign({}, choice, { label: choice.text || '' })
      })

      // For multivalue options create label by joining text from choices.
      label = Object.keys(choices)
        .map(id => choices[id].label)
        .join(', ')

      option = Object.assign({}, option, { choices })
    }

    return {
      ...option,
      label: `(id: ${option.id}) ${label}`,
      parent: parentId,
    }
  })
}
