import AreenaClip from './shortcodes/AreenaClip/AreenaClip'
import GapOption from './questions/GapQuestion/GapOption.tsx'
import GapSelect from './questions/GapQuestion/GapSelect'
import DropdownShortcode from './questions/DropdownQuestion/DropdownShortcode.tsx'
import TextboxShortcode from './questions/TextboxQuestion/TextboxShortcode.tsx'
import ImsImage from './shortcodes/ImsImage/ImsImage'
import { SubScript, SuperScript } from './shortcodes/TextTypes/TextTypes'
import Link from './shortcodes/Link/Link'
import Reference from './shortcodes/Reference/Reference'
import MATH from './shortcodes/Math/Math.tsx'

// Proprety name == short code in content
const shortCodeMapping = {
  CLIP: AreenaClip,
  CITE: Reference,
  GAP: GapOption,
  IMS: ImsImage,
  LINK: Link,
  MATH,
  SELECT: GapSelect,
  SUB: SubScript,
  SUP: SuperScript,
  DROPDOWN: DropdownShortcode,
  TEXTBOX: TextboxShortcode,
}

export default shortCodeMapping
