import i18n from 'i18next'
import en from '../constants/i18n/en.json'
import fi from '../constants/i18n/fi.json'
import sv from '../constants/i18n/sv.json'

i18n.init({
  resources: {
    'en-US': en,
    'fi-FI': fi,
    'sv-FI': sv,
  },
  fallbackLng: 'en-US',
  keySeparator: '|',
  ns: ['editor', 'widget'],
  defaultNS: 'widget',
  interpolation: {
    // Disable escaping as it is handled by React.
    escapeValue: false,
  },
})

export default i18n
