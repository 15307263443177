import React from 'react'
import classnames from 'classnames'
import PrimaryHeader from '../../elements/Typography/PrimaryHeader.tsx'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import Fragment from '../../node/Fragment/Fragment'
import './exam-info.scss'

/**
 * Outputs the info text of the exam
 */

const ExamInfo = ({ settings, content, isCarousel }) => {
  if (!settings || !content) {
    return null
  }
  const classes = classnames('yo-exam-info', {
    'yo-exam-info__carousel': isCarousel,
  })

  /**
   * Don't display the empty container as it will still reserve some space
   * and the space is noticeable especially when embedded.
   */

  if (settings.hideTitle && (content.text.length === 0 || content.text === '&NewLine;')) {
    return null
  }
  return (
    <div className={classes}>
      <div>
        {!settings.hideTitle && <PrimaryHeader>{content.title}</PrimaryHeader>}
        {content.text.length > 0 && <Fragment text={<RichTextContent content={content.text} />} />}
      </div>
    </div>
  )
}

export default ExamInfo
