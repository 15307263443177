import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Tags from '../../elements/Tags/Tags'
import './question.scss'
import SecondaryHeader from '../../elements/Typography/SecondaryHeader.tsx'

const QuestionItem = q => (
  <div className="yo-question-list__question-item">
    <SecondaryHeader>
      <Link className="yo-question-list__exam-link" to={`/exam/${q.exam_uuid}/editor`}>
        {q.exam_name}
      </Link>
    </SecondaryHeader>

    <div className="yo-question-list__order-number">Question {q.order_number}</div>

    <Tags link nodes={[q]} readOnly />

    <div>{q.main_text}</div>
  </div>
)

export default withTranslation(['QuestionItem'])(connect()(QuestionItem))
