import React, { useEffect, useCallback } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { fetchSeriesData } from '../../../redux/middleware/seriesService/seriesService'
import { getFilterSeriesId } from '../../../redux/modules/filters/exams/selectors'
import { getSeriesData } from '../../../redux/modules/series/selectors'
import { Spinner } from '../../elements/Loader/Loader'
import './series-exam-order.scss'
import { updateExistingSeries } from '../../../redux/modules/series/series'
import { generateExamOrder } from '../../../redux/middleware/dataService/stateSerializer'
import TertiaryHeader from '../../elements/Typography/TertiaryHeader.tsx'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return generateExamOrder(result)
}

const SeriesExamOrder = ({
  seriesId,
  seriesData: { exams, classification },
  onChangeExamOrder,
  fetchSeriesData,
}) => {
  useEffect(() => {
    fetchSeriesData(seriesId)
  }, [])

  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      onChangeExamOrder({
        uuid: seriesId,
        newOrder: reorder(exams, result.source.index, result.destination.index),
      })
    },
    [exams],
  )

  if (!exams) {
    return <Spinner />
  }
  return (
    <>
      <TertiaryHeader>Edit exam order</TertiaryHeader>
      <div className="yo-series-exam-order__description">
        Drag and drop the exams to the correct order then click Save. Only published exams are
        available here.
      </div>
      <div className="yo-series-exam-order__row yo-series-exam-order__header">
        <div className="yo-series-exam-order__order yo-series-exam-order__header">#</div>
        <div className="yo-series-exam-order__name yo-series-exam-order__header">Exam name</div>
        <div className="yo-series-exam-order__publish yo-series-exam-order__header">
          Published at
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {exams
                .sort((a, b) => classification.examOrder[a.uuid] - classification.examOrder[b.uuid])
                .map((exam, index) => {
                  const order = classification.examOrder[exam.uuid]
                  return (
                    <Draggable draggableId={exam.uuid} index={index} key={exam.uuid}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            className={classnames('yo-series-exam-order__row', {
                              'yo-series-exam-order__dragging': snapshot.isDragging,
                            })}
                            key={exam.uuid}
                          >
                            <div className="yo-series-exam-order__order">{order + 1}</div>
                            <div className="yo-series-exam-order__name">{exam.name}</div>
                            <div className="yo-series-exam-order__publish">
                              {moment(exam.published_at).format('DD.MM.YYYY')}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

const mapStateToProps = state => ({
  seriesData: getSeriesData(state)[0],
  seriesId: getFilterSeriesId(state),
})

const mapDispatchToProps = dispatch => ({
  onChangeExamOrder: ({ uuid, newOrder }) => {
    dispatch(
      updateExistingSeries({
        uuid,
        fieldName: 'examOrder',
        value: newOrder,
        isClassification: true,
      }),
    )
  },
  fetchSeriesData: seriesId => {
    dispatch(fetchSeriesData([seriesId], false))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SeriesExamOrder)
