import { apiURL, parseJSON, GET } from '../../middleware/dataService/apiV1'
import { applyFeedbackStats, applyFeedbackStatSums } from '../nodes/nodes'

const CLEAR_FEEDBACK_LOADING = 'statistics/CLEAR_FEEDBACK_LOADING'
const SET_FEEDBACK_LOADING = 'statistics/SET_FEEDBACK_LOADING'
const CLEAR_FEEDBACK_SUMS_LOADING = 'statistics/CLEAR_FEEDBACK_SUMS_LOADING'
const SET_FEEDBACK_SUMS_LOADING = 'statistics/SET_FEEDBACK_SUMS_LOADING'
// Action creators
const clearFeedbackStatsLoading = () => ({
  type: CLEAR_FEEDBACK_LOADING,
})

const setFeedbackStatsLoading = () => ({
  type: SET_FEEDBACK_LOADING,
})

const toUrlParams = params =>
  Object.keys(params)
    .filter(p => params[p])
    .map(p => `${p}=${params[p]}`)
    .join('&')

export const fetchFeedbackStats = (
  uuids,
  { countryFilter = null, isInEditor = false } = {},
) => dispatch => {
  dispatch(setFeedbackStatsLoading())

  const urlParams = toUrlParams({ question_uuids: uuids.join(','), country_filter: countryFilter })

  return (isInEditor
    ? GET(`/exams/stats.json?${urlParams}`) // include google auth header
    : fetch(apiURL(`/public/stats.json?${urlParams}`))
  )
    .then(parseJSON)
    .then(body => {
      dispatch(applyFeedbackStats({ ...body }))
      dispatch(clearFeedbackStatsLoading())
    })
}

const clearFeedbackStatSumsLoading = () => ({
  type: CLEAR_FEEDBACK_SUMS_LOADING,
})

const setFeedbackStatSumsLoading = () => ({
  type: SET_FEEDBACK_SUMS_LOADING,
})
export const fetchFeedbackStatSums = (
  uuids,
  { countryFilter = null, isInEditor = false } = {},
) => dispatch => {
  dispatch(setFeedbackStatSumsLoading())

  const urlParams = toUrlParams({ question_uuids: uuids.join(','), country_filter: countryFilter })

  return isInEditor
    ? GET(`/exams/statsums.json?${urlParams}`)
    : fetch(apiURL(`/public/statsums.json?${urlParams}`))
        .then(parseJSON)
        .then(body => {
          dispatch(applyFeedbackStatSums({ ...body }))
          dispatch(clearFeedbackStatSumsLoading())
        })
}

export const fetchFeedbackStatsByExamUuid = (
  uuid,
  { countryFilter = null, isInEditor = false } = {},
) => dispatch => {
  dispatch(setFeedbackStatsLoading())

  const urlParams = toUrlParams({ exam_uuid: uuid, country_filter: countryFilter })

  return (isInEditor
    ? GET(`/exams/stats.json?${urlParams}`)
    : fetch(apiURL(`/public/stats.json?${urlParams}`))
  )
    .then(parseJSON)
    .then(body => {
      dispatch(applyFeedbackStats({ ...body }))
      dispatch(clearFeedbackStatsLoading())
    })
}

// Reducer
const defaultState = {
  loading: {
    feedback: false,
    feedbackSums: false,
  },
  /*
  insert all statistics data here
  example:
  data: {
    feedback: [],
    dashboard: [],
    somethingElse: [],
  }
  */
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_FEEDBACK_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          feedback: false,
        },
      }

    case SET_FEEDBACK_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          feedback: true,
        },
      }

    case CLEAR_FEEDBACK_SUMS_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          feedbackSums: false,
        },
      }

    case SET_FEEDBACK_SUMS_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          feedbackSums: true,
        },
      }

    default:
      return state
  }
}

export default reducer
