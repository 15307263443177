// Constants
export const ALL_LANGUAGES = 'all-languages'

// Actions
const CLEAR_LANGUAGE_FILTER = 'filters/exams/CLEAR_LANGUAGE'
const SET_LANGUAGE_FILTER = 'filters/exams/SET_LANGUAGE'

// Action creators
export const filterExamsByLanguage = language => ({
  type: SET_LANGUAGE_FILTER,
  language,
})

export const resetLanguageFilter = () => ({
  type: CLEAR_LANGUAGE_FILTER,
})

// Reducer
const defaultState = ALL_LANGUAGES

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_LANGUAGE_FILTER:
      return action.language

    case CLEAR_LANGUAGE_FILTER:
      return defaultState

    default:
      return state
  }
}
