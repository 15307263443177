import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import './tabs.scss'

class Tabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: props.selected,
    }
  }

  // Creates a label for a single tab
  createLabel(index, { hide, label }) {
    if (hide) {
      return null
    }

    const labelClassName = classnames('yo-tabs__link', {
      'yo-tabs__link--active': this.state.selected === index,
    })

    return (
      <span
        className={labelClassName}
        data-testid={`tab-${(label || '').toLowerCase()}`}
        key={index}
        onClick={() => this.setState({ selected: index })}
        role="button"
        tabIndex="0"
      >
        {label}
      </span>
    )
  }

  // Renders tabs labels.
  renderLabels() {
    const labels = this.props.children
      .map((child, index) => this.createLabel(index, child.props))
      .filter(child => child !== null)

    if (labels.length > 1) {
      return (
        <div className="yo-tabs__labels">
          <div className="yo-tabs__links">{labels}</div>
        </div>
      )
    }

    return null
  }

  // Renders content for the active tab.
  renderContent() {
    // No tab selected, render nothing.
    if (typeof this.state.selected === 'undefined') {
      return null
    }

    return this.props.children[this.state.selected]
  }

  render() {
    return (
      <div className="yo-tabs">
        {this.renderLabels()}
        {this.renderContent()}
      </div>
    )
  }
}

Tabs.propTypes = {
  // Zero-based index of selected tab.
  selected: PropTypes.number,
}

Tabs.defaultProps = {
  selected: 0,
}

// Wrapper component for tab content.
export const TabPanel = ({ children }) => <div className="yo-tabs__panel">{children}</div>

export default withTranslation(['Tabs'])(connect()(Tabs))
