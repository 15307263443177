import React from 'react'
import ImsField from './ImsField'
import MathField from './MathField'
import Separator from '../Separator/Separator'
import Input from '../../elements/Input/Input'
import Select from '../../elements/Select/Select'

const DialogField = props => {
  const { field, index, onUpdateInputValues, values } = props
  const onChangeValue = e => onUpdateInputValues(field.name, e.target.value)
  const onClickCheckbox = e => onUpdateInputValues(field.name, e.target.checked)

  const dataTestId = `dialog-field-${field.name}`

  if (field.type === 'ims') {
    return (
      <ImsField
        description={field.description}
        key={field.name}
        label={field.displayName}
        onUpdateInputValues={onUpdateInputValues}
      />
    )
  }

  if (field.type === 'radios') {
    return (
      <div className="yo-dialog__label" key={field.name}>
        <span className="yo-dialog__title">{field.displayName}</span>
        <div className="yo-dialog__input-column">
          {field.options.map(option => (
            <Input
              defaultChecked={field.defaultValue && option.name === field.defaultValue}
              id={`option-${option.id}`}
              key={option.id}
              label={option.name}
              name={field.name}
              onClick={onChangeValue}
              type="radio"
              value={option.name}
            />
          ))}
        </div>
      </div>
    )
  }

  if (field.type === 'math') {
    return (
      <MathField
        key={field.name}
        label={field.displayName}
        onUpdateInputValues={onUpdateInputValues}
      />
    )
  }

  if (field.type === 'checkbox') {
    const checked = values[field.name] === undefined ? field.defaultChecked : values[field.name]
    return (
      <label className="yo-dialog__label" htmlFor={field.id} key={field.name}>
        <span className="yo-dialog__title">{field.displayName}</span>
        <Input
          className="yo-dialog__checkbox"
          defaultChecked={checked}
          description={field.description}
          id={field.id}
          name={field.name}
          onChange={onClickCheckbox}
          type="checkbox"
        />
      </label>
    )
  }

  if (field.type === 'range') {
    const sliderValue = values[field.name] === undefined ? field.defaultValue : values[field.name]
    return (
      <label className="yo-dialog__label" htmlFor={field.id} key={field.name}>
        <span className="yo-dialog__title">{field.displayName}</span>
        <Input
          className="yo-dialog__checkbox"
          defaultValue={sliderValue}
          description={field.description}
          id={field.id}
          name={field.name}
          onChange={onChangeValue}
          type="range"
        />
      </label>
    )
  }

  if (field.type === 'select') {
    return (
      <Select
        attributes={{ 'data-testid': dataTestId }}
        className="yo-dialog__select"
        defaultValue={values[field.name] || field.defaultValue}
        key={field.name}
        label={field.displayName}
        onChange={onChangeValue}
        options={field.options}
      />
    )
  }

  if (field.type === 'separator') {
    return <Separator key={field.name} />
  }

  const inputId = `yo-dialog__title--${field.name}`
    .replace(/ /g, '-')
    .toLowerCase()
    .trim()

  return (
    <label className="yo-dialog__label" htmlFor={inputId} key={field.name}>
      <span className="yo-dialog__title">{field.displayName}</span>
      <input
        autoFocus={index === 0}
        className="yo-dialog__input"
        data-testid={dataTestId}
        defaultValue={values[field.name] || field.defaultValue}
        id={inputId}
        onChange={onChangeValue}
      />
    </label>
  )
}

export default DialogField
