import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { examTypeOptions, getDefaultExamSettings } from '../../../constants/definitions/exams'
import { updateExam, updateExamType } from '../../../redux/modules/exam/exam'
import { updateNodeTypeId } from '../../../redux/modules/node/actions.ts'
import { getRootNodeTypeId } from '../../../utils/examHelpers'
import i18n from '../../../utils/i18n'
import * as CustomPropTypes from '../../../constants/definitions/propTypes'

const en = i18n.getFixedT('en')

const ExamTypeBox = ({ exam, onChooseExamType }) =>
  examTypeOptions.map(type => {
    type.name = en(`common:${type.id}`)
    type.description = en(`ExamTypeDefinition:${type.id}`)
    type.example = en(`ExamTypeExample:${type.id}`)

    const selected = exam.exam_type === type.id
    const examTypeClassNames = classnames('yo-settings__exam-type-selection', {
      'yo-settings__exam-type-selection--current': selected,
    })

    return (
      <label className={examTypeClassNames} key={type.id}>
        <input
          checked={selected}
          className="yo-settings__exam-type-selection-input"
          data-testid={`exam-type-${type.id}`}
          name="create-exam__type"
          onChange={e => e.target.checked && onChooseExamType(type.id)}
          type="radio"
        />
        <span className="yo-settings__exam-type-selection-text-wrapper">
          <span className="yo-settings__exam-type-selection-name">{type.name}</span>
          <span className="yo-settings__exam-type-selection-description">{type.description}</span>
          {type.example && (
            <span className="yo-settings__exam-type-selection-description">
              <br />
              <a
                className="yo-settings__exam-type-selection-description-link"
                href={type.example}
                rel="noreferrer noopener"
                target="_blank"
              >
                Example {type.name} exam
              </a>
            </span>
          )}
        </span>
      </label>
    )
  })

ExamTypeBox.propTypes = {
  exam: CustomPropTypes.exam,
  onChooseExamType: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChooseExamType: examType => {
    const {
      rootNode,
      location: { state },
      exam,
    } = ownProps
    const rootNodeTypeId = getRootNodeTypeId(examType)
    const { series_uuids, showExamSeries } = exam
    const settings =
      state && state.addToSeries
        ? Object.assign(getDefaultExamSettings(examType), {
            series_uuids,
            showExamSeries,
          })
        : getDefaultExamSettings(examType)

    dispatch(updateNodeTypeId(rootNode.id, rootNodeTypeId))
    dispatch(updateExam(settings))
    dispatch(updateExamType(examType))
  },
})

export default withRouter(connect(null, mapDispatchToProps)(ExamTypeBox))
