import SUBJECTS from '../../../constants/i18n/subjects'
import i18n from '../../../utils/i18n'
import { FINNISH, SWEDISH } from '../../../constants/definitions/exams'
import { sortByName } from '../../../utils/common'

// translate to provided language
export const translateSubject = (subjectId, language = null) => {
  let subjectLanguage = language || i18n.language
  if (![SWEDISH, FINNISH].includes(subjectLanguage)) {
    subjectLanguage = FINNISH
  }
  const subject = SUBJECTS.find(x => x.id === subjectId)
  return subject ? subject[subjectLanguage] : null
}

// translate to provided language and sort subjects array
export const getTranslatedSubjects = (subjects, language = null) => {
  const translatedSubjects = subjects.map(subject => {
    subject.name = translateSubject(subject.id, language)
    return subject
  })
  return sortByName(translatedSubjects)
}
