import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import Input from '../../elements/Input/Input'
import { IconButton } from '../../elements/IconButtons/IconButtons'
import shortcodes from '../../../constants/definitions/shortcodes.tsx'
import { setDialog } from '../../../redux/modules/dialog/dialog'
import './share-input.scss'

class ShareInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.onClickImage = this.onClickImage.bind(this)
  }

  onClickImage() {
    const { dispatch, onUpdateImage } = this.props

    const data = {
      ...shortcodes.IMS_ID,
      isShortcode: false,
      // Pass callback to handle value returned by the dialog.
      onClickSubmit: value => onUpdateImage(value.id),
    }

    dispatch(setDialog(data))
  }

  render() {
    const {
      title = '',
      description = '',
      imageId = '',
      onUpdateTitle,
      onUpdateDescription,
      idSuffix,
      t,
    } = this.props

    return (
      <Fragment>
        <div className="yo-sharing-input__description">
          All
          <span className="yo-sharing-input__required">* </span>
          fields below are compulsory for sharing and needs to be filled out.
        </div>
        <Input
          attributes={{ 'data-testid': 'share-input-title' }}
          autofocus
          defaultValue={title}
          idSuffix={idSuffix}
          label={t('title')}
          onChange={onUpdateTitle}
          required
          type="text"
        />
        <Input
          attributes={{ 'data-testid': 'share-input-description' }}
          autofocus
          defaultValue={description}
          idSuffix={idSuffix}
          label={t('description')}
          onChange={onUpdateDescription}
          required
          type="text"
        />
        <div>
          {t('image')}
          <span className="yo-sharing-input__required">*</span>
        </div>
        <div className="yo-sharing-input__image">
          <IconButton
            attributes={{ 'data-testid': 'share-input-image' }}
            height={20}
            icon="image"
            onClick={this.onClickImage}
            title={t('image')}
            width={20}
          />
          <div className="yo-sharing-input__image--id" data-testid="share-input-image-id">
            Public ID: {imageId}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation(['ShareInput'])(ShareInput)
