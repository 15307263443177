import uuid from 'uuid'
import { reject, update } from 'ramda'

// Actions
export const ADD = 'toasts/ADD'

const BURN = 'toasts/BURN'
const CLEAR = 'toasts/CLEAR'
const FLIP = 'toasts/FLIP'

// Default TTL
const timeToLive = 1250

//! Caution: non-functional programming paradigm ahead!
// Here we default `toastIndex` to -1, which is what `getToastIndex()` would return anyway if toast
// is not found. After the initial `case FLIP`, this will never be reset to the initial value, but
// that is okay, as our `getToastIndex()` function handles the value from then on.
let toastIndex = -1

// Sets the `toastIndex` variable to the index of the Toast that is being flipped.
const getToastIndex = (state, action) => {
  toastIndex = state.findIndex(toast => toast.id === action.toast.id)
  return toastIndex
}

// Reducer
const reducer = (state = [], action) => {
  switch (action.type) {
    case ADD:
      return [...state, action.toast]

    case BURN:
      return reject(
        // Remove the toast mentioned in the action from the state.
        toast => toast.id === action.toastId,
        state,
      )

    case CLEAR:
      return []

    case FLIP:
      getToastIndex(state, action)
      if (toastIndex === -1) {
        // Didn't find a Toast to flip, let's load it to the Toaster instead...
        return [...state, action.toast]
      }
      return update(toastIndex, Object.assign({}, state[toastIndex], action.toast), state)

    default:
      return state
  }
}

// Action creators
export const addToast = (type, message, ttl = timeToLive, id = uuid.v4()) => ({
  type: ADD,
  toast: {
    id,
    message,
    ttl,
    type,
  },
})

export const burnToast = toastId => ({
  type: BURN,
  toastId,
})

export const clearToasts = () => ({
  type: CLEAR,
})

export const flipToast = (id = uuid.v4(), type, message, ttl = timeToLive) => ({
  type: FLIP,
  toast: {
    id,
    message,
    ttl,
    type,
  },
})

export default reducer
