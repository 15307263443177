import { createSelector } from 'reselect'
import { subjectHasCategories } from '../../../components/misc/Settings/settingFunctions'
import { DEFAULT_CATEGORIES } from '../../../constants/definitions/exams'
import i18n from '../../../utils/i18n'
import { getExamState } from '../exam/selectors'

export const getMetaOptions = state => state.defaultCarousel.metaOptions
export const getTopics = state => state.defaultCarousel.topics
export const getQuestionTypes = state => state.defaultCarousel.questionTypes
export const getTags = state => state.defaultCarousel.tags

export const getTopicIds = createSelector([getTopics], topics => topics.map(x => x.id))

export const getCarouselEmbedOptions = createSelector(
  [getMetaOptions, getTopicIds, getQuestionTypes, getTags, getExamState],
  (metaOptions, topicIds, questionTypes, tags, exam) => {
    const tagIds = [...tags.map(t => t.id), ...tags.map(t => t.alternativeIds)]
    const category =
      subjectHasCategories(exam) && exam.category === '' ? DEFAULT_CATEGORIES : exam.category
    const embedOptions = {
      subject: exam.subject,
      category,
      tags: topicIds.length ? [...topicIds, ...tagIds] : tagIds,
      options: metaOptions.filter(x => x.value).map(x => x.id),
      questionTypes: questionTypes.filter(x => x.value).map(x => x.id),
      lang: i18n.language,
      scrollIntoView: true,
    }
    return embedOptions
  },
)

export const getDefaultCarouselSelection = createSelector(
  [getMetaOptions, getTopics, getQuestionTypes, getTags],
  (metaOptions, topics, questionTypes, tags) => ({
    metaOptions: metaOptions.filter(x => x.value).map(x => x.id),
    topics: topics.map(x => x['fi-FI']),
    questionTypes: questionTypes.filter(x => x.value).map(x => x.id),
    tags: tags.map(x => x.analyticsTitle),
  }),
)
