import {
  fetchExamList,
  fetchExamListAuthor,
  CREATE,
} from '../../middleware/examService/examService'

// Actions.
export const DELETE = 'exams/DELETE'
const RECEIVE = 'exams/RECEIVE'

// Reducer.
export default function reducer(state = [], action) {
  switch (action.type) {
    case RECEIVE:
      // Always return new exam listing fetched from server
      // to keep the client and server synced
      return Object.assign([], action.exams)
    default:
      return state
  }
}

// Action creators.
export function deleteExam(uuid) {
  return {
    type: DELETE,
    uuid,
  }
}

export function fetchExams() {
  return dispatch => dispatch(fetchExamList())
}

export function fetchAuthorsExams() {
  return dispatch => dispatch(fetchExamListAuthor())
}

export function receiveExams(exams) {
  return {
    type: RECEIVE,
    exams,
  }
}

export function newExam() {
  return {
    type: CREATE,
  }
}
