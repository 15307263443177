import React, { useEffect, useState } from 'react'
import { Delete, Search } from '@yleisradio/yds-icons-react'
import { TextInput } from '@yleisradio/yds-components-react'
import Button from '../../elements/Button/Button'
import { GET } from '../../../redux/middleware/dataService/apiV1'
import config from '../../../config'
import './answers.scss'
import { Spinner } from '../../elements/Loader/Loader'

type AnswerProps = {
  fetchExam: any
  uuid: string
}

interface Question {
  main_text: string
  uuid: string
}

type Answer = {
  yletunnus: string
  nickname: string
  answers: {
    [key: string]: {
      answer_data: {
        [key: string]: string
      }
      question_text: string
      question_type: string
      question_uuid: string
    }
  }
}

const getAnswer = (answerData?: { [key: string]: string | any }): string => {
  if (!answerData) {
    return ''
  }

  try {
    return Object.values(answerData)
      .map(v => v.slice(0, 200))
      .join(',')
  } catch (e) {
    return JSON.stringify(answerData).slice(0, 200)
  }
}

const AnswersTable = (props: any) => {
  const { answers, limit, offset, questions, onSetOffset, onSetSearchParameters } = props
  const [localSearchParams, setLocalSearchParams] = useState({})

  const searchParamCount = Object.values(localSearchParams).filter(v => !!v).length
  const allSearchParamsUsed = searchParamCount >= 5

  const questionUuids = Object.keys(questions)

  const handleSubmit = event => {
    event.preventDefault()
    onSetOffset(0)
    onSetSearchParameters(localSearchParams)
  }

  const clearSearchParams = () => {
    setLocalSearchParams({})
    onSetOffset(0)
    onSetSearchParameters({})
  }

  return (
    <div>
      <form className="yo-answers__form">
        <table className="yo-answers__table" data-testid="answers-table">
          <thead>
            <tr>
              <th>Nickname</th>
              {Object.keys(questions).map((headerQuestionUuid, index) => (
                <th key={headerQuestionUuid}>
                  <div>{questions[headerQuestionUuid]?.slice(0, 200)}</div>
                  <div>
                    <TextInput
                      data-testid={`search-input-${index}`}
                      id={headerQuestionUuid}
                      isDisabled={allSearchParamsUsed && !localSearchParams[headerQuestionUuid]}
                      label=""
                      onChange={(e: any) => {
                        const newValue = { [headerQuestionUuid]: e.target.value }

                        setLocalSearchParams(searchParameters => ({
                          ...searchParameters,
                          ...newValue,
                        }))
                      }}
                      placeholder="Search"
                      type="text"
                      value={localSearchParams[headerQuestionUuid] || ''}
                    />
                  </div>
                </th>
              ))}
              <th>
                <Button
                  iconBefore={<Delete />}
                  isDisabled={Object.values(localSearchParams).filter(v => !!v).length === 0}
                  onClick={clearSearchParams}
                  size="sm"
                  text="Clear"
                  variant="secondary"
                ></Button>
                <Button
                  iconBefore={<Search />}
                  onClick={handleSubmit}
                  size="sm"
                  text="Search"
                  variant="primary"
                ></Button>
              </th>
            </tr>
          </thead>
          <tbody data-testid="answers-tbody">
            {answers.map((row: Answer) => (
              <tr key={row.yletunnus}>
                <td>{row.nickname}</td>
                {questionUuids.map(questionUuid => {
                  return (
                    <td key={questionUuid}>{getAnswer(row.answers[questionUuid]?.answer_data)}</td>
                  )
                })}
                <td>
                  <a
                    className="yo-answers--table-contact-link"
                    href={`${config.contacts_app_url}?surrogate_id=${row.yletunnus}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Contact
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
          <caption>
            {answers.length === 0 && (
              <div className="yo-answers__no-answers" data-testid="empty-message">
                No answers
              </div>
            )}
            <div className="yo-answers--offset-buttons">
              <Button
                isDisabled={offset === 0}
                onClick={() => {
                  onSetOffset(offset - 1)
                }}
                size="xs"
                text="Prev"
                variant="text"
              />
              <Button
                isDisabled={answers.length < limit}
                onClick={() => {
                  onSetOffset(offset + 1)
                }}
                size="xs"
                text="Next"
                variant="text"
              />
            </div>
          </caption>
        </table>
      </form>
    </div>
  )
}

const Answers = ({ uuid }: AnswerProps) => {
  const [questions, setQuestions] = useState<Array<Question>>([])
  const [answers, setAnswers] = useState<Array<Answer>>([])
  const [isLoading, setIsLoadingStatus] = useState(true)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [searchParameters, setSearchParameters] = useState({})
  const [offset, setOffset] = useState(0)

  const limit = 20

  const fetchAnswerData = async () => {
    setIsLoadingStatus(true)
    let queryString = `/exams/answers/${uuid}.json?limit=${limit}&offset=${offset * limit}`

    const searchString = Object.entries(searchParameters)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, val]) => !!val)
      .map(([key, val]) => `${key}=${val}`)
      .join(',')

    if (searchString) {
      queryString = `${queryString}&search=${encodeURIComponent(searchString)}`
    }

    const answerData = await GET(queryString)
    const results = await answerData.json()

    // Snatch questions from the answer result array into a [{uuid: question_text}] array
    const extractedQuestions = results.data
      .flatMap(d =>
        Object.values(d.answers).map((v: any) => ({ id: v.question_uuid, text: v.question_text })),
      )
      .reduce((a, b) => ({ [b.id]: b.text, ...a }), {})

    if (isFirstLoad) {
      setQuestions(extractedQuestions)
    }
    setAnswers(results.data)
    setIsLoadingStatus(false)
    setIsFirstLoad(false)
  }

  useEffect(() => {
    fetchAnswerData()
  }, [offset, searchParameters])

  return (
    <div>
      <AnswersTable
        answers={answers}
        limit={limit}
        offset={offset}
        onFetchAnswerData={fetchAnswerData}
        onSetOffset={setOffset}
        onSetSearchParameters={setSearchParameters}
        questions={questions}
        setOffset={setOffset}
      />
      {isLoading && (
        <div className="yo-answers--logs__spinner-wrapper">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default Answers
