import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import uuid from 'uuid'
import { getAllDescendantIds } from '../../../redux/modules/node/node.ts'
import { removeAndDeleteChild, updateNode } from '../../../redux/modules/node/actions.ts'
import { getSiblings } from '../../../redux/modules/nodes/selectors'
import { updateEditing } from '../../../redux/modules/editing/editing'
import { getNodeId } from '../../../redux/modules/editing/selectors'
import { IconButtons, IconButton } from '../../elements/IconButtons/IconButtons'
import { toggleCollapsedNodes } from '../../../redux/modules/editor/editor'
import NodePlaceholder from '../NodePlaceholder/NodePlaceholder'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import { Accordion, AccordionItem } from '../Accordion/Accordion'
import './node-editable.scss'
import { flipToast } from '../../../redux/modules/toasts/toasts'
import { copyToClipboard } from '../../../utils/common'
import { Dots } from '../../elements/Loader/Loader'
import { QUESTION_ID } from '../../../redux/modules/questions/questions'
import { nodeTypes as NODETYPES } from '../../../constants/definitions/entities/entities.ts'
import { deleteChoicesByQuestionId } from '../../../redux/modules/characters/characters'

const NodeEditable = props => {
  const {
    children,
    descendantIds,
    expanded,
    highlighted,
    id,
    isFirst,
    isLoading,
    isLast,
    node,
    nodeType,
    onDeleteClick,
    onDownClick,
    onEditClick,
    onEmbedClick,
    onExpandedClick,
    onCopyClick,
    onUpClick,
    order,
    parentId,
    t,
  } = props

  const suffix = (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <IconButtons>
        <IconButton
          attributes={{ 'data-testid': 'edit-node-button' }}
          icon="edit"
          onClick={onEditClick}
          title={t('NodeEditable:edit')}
        />
        {parentId && (
          <IconButton icon="delete" onClick={onDeleteClick} title={t('NodeEditable:delete')} />
        )}
        {parentId && !descendantIds.length && (
          <IconButton icon="copy" onClick={onCopyClick} title={t('NodeEditable:copy')} />
        )}
        {!isLast && parentId && (
          <IconButton icon="down" onClick={onDownClick} title={t('NodeEditable:down')} />
        )}
        {!isFirst && parentId && (
          <IconButton icon="up" onClick={onUpClick} title={t('NodeEditable:up')} />
        )}
        {parentId && (
          <IconButton
            icon="export"
            onClick={onEmbedClick}
            title={t('NodeEditable:Copy to clipboard')}
          />
        )}
      </IconButtons>
    </div>
  )

  if (!parentId) {
    return (
      <div>
        <div
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {suffix}
        </div>
        {children}
      </div>
    )
  }

  const description = `${children[0].props.content.displayNumber || ''} ${children[0].props.content
    .text || ''}`
  const title = <span className="yo-node-editable__title">{nodeType.displayName}</span>

  return (
    <div>
      {isFirst &&
        ((isLoading && <Dots />) || (
          <NodePlaceholder
            attributes={{ 'data-testid': 'node-placeholder-first' }}
            order={1}
            parentId={parentId}
          />
        ))}
      <Accordion>
        <AccordionItem
          description={
            <RichTextContent content={description.trim()} ignoreNewlines ignoreShortcodes />
          }
          id={id}
          isHighlighted={highlighted}
          isInitiallyExpanded={expanded}
          onExpand={onExpandedClick}
          settings={node.settings}
          suffix={suffix}
          title={title}
        >
          {children}
        </AccordionItem>
      </Accordion>
      {(isLoading && <Dots />) || (
        <NodePlaceholder
          attributes={{ 'data-testid': 'node-placeholder-last' }}
          order={order + 1}
          parentId={parentId}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const editingNodeId = getNodeId(state)
  const { collapsedNodes } = state.editor

  return {
    descendantIds: getAllDescendantIds(state.entities.nodes, ownProps.id),
    editingNodeId,
    expanded: collapsedNodes.includes(ownProps.id) === false,
    highlighted: editingNodeId === ownProps.id,
    isLoading: state.questions.isFetching,
    siblingNodes: getSiblings(state, ownProps.parentId),
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { editingNodeId, descendantIds } = stateProps

  return {
    ...stateProps,
    ...ownProps,
    onExpandedClick: () => dispatch(toggleCollapsedNodes(ownProps.id)),
    onEditClick: e => {
      e.stopPropagation()
      dispatch(
        updateEditing({
          status: 'edit',
          parameters: {
            nodeId: ownProps.id,
          },
        }),
      )
    },
    onDeleteClick: e => {
      e.stopPropagation()

      // Display a confirmation dialog for the user before deleting
      const delConfirmed = window.confirm(ownProps.t('common:confirm-delete', { item: 'question' }))

      if (delConfirmed) {
        // If the node to be removed or any of its descendants
        // is the current node in edit state, then remove it from edit state.
        if (ownProps.id === editingNodeId || descendantIds.includes(editingNodeId)) {
          dispatch(updateEditing(null))
        }

        // Delete the node including its children.
        dispatch(removeAndDeleteChild(ownProps.parentId, ownProps.node))

        if (ownProps.node.nodeTypeId === NODETYPES.ARCHTYPE_MULTIPLE_CHOICE.id) {
          dispatch(deleteChoicesByQuestionId(ownProps.node.id))
        }
      }
    },
    onUpClick: e => {
      e.stopPropagation()
      const newOrder = ownProps.order - 1

      // Update node order with a decremented one.
      dispatch(updateNode(ownProps.id, { order: newOrder }))

      // Get sibling node with a same order as the decremented one.
      stateProps.siblingNodes.forEach(node => {
        if (node.order === newOrder) {
          // Increment node order.
          dispatch(updateNode(node.id, { order: ownProps.order }))
        }
      })
    },
    onDownClick: e => {
      e.stopPropagation()
      const newOrder = ownProps.order + 1

      // Update node order with a incremented one.
      dispatch(updateNode(ownProps.id, { order: newOrder }))

      // Get sibling node with a same order as the incremented one.
      stateProps.siblingNodes.forEach(node => {
        if (node.order === newOrder) {
          // Decrement node order.
          dispatch(updateNode(node.id, { order: ownProps.order }))
        }
      })
    },
    onEmbedClick: e => {
      e.stopPropagation()
      const childIds = descendantIds.map(x => `47-${x}`)
      const uuids = [`47-${ownProps.id}`, ...childIds]

      const clipboardEl = copyToClipboard(uuids.join(','))
      if (clipboardEl && clipboardEl.value) {
        dispatch(flipToast(uuid.v4(), 'success', 'Assignment ID copied to clipboard.'))
      }
    },
    onCopyClick: e => {
      e.stopPropagation()
      localStorage.setItem(QUESTION_ID, ownProps.id)
      dispatch(flipToast(null, 'success', 'Question copied!', 2500))
    },
  }
}

export default withTranslation(['NodeEditable'])(
  connect(mapStateToProps, null, mergeProps)(NodeEditable),
)
