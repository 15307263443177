import React from 'react'
import { Button as YdsButton } from '@yleisradio/yds-components-react'
import classnames from 'classnames'
import { SvgProps } from '@yleisradio/yds-icons-react'

interface Props {
  accessibleText?: string
  text?: string
  title?: string
  onClick?: (event: React.MouseEventHandler<HTMLButtonElement>) => void
  attributes?: {}
  classNames?: string[]
  isDisabled?: boolean
  iconAfter?: React.ReactElement<SvgProps>
  iconBefore?: React.ReactElement<SvgProps>
  removePadding?: boolean
  iconOnly?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
  variant?: 'primary' | 'text' | 'secondary'
  fullWidth?: boolean
}

const Button: React.FC<Props> = ({
  accessibleText,
  text,
  title,
  onClick,
  attributes = {},
  classNames = [],
  isDisabled = false,
  iconAfter = undefined,
  iconBefore = undefined,
  iconOnly = false,
  size = 'md',
  variant = 'primary',
  fullWidth = false,
  removePadding = undefined,
}) => {
  const className = classnames(classNames)

  return (
    // @ts-ignore
    <YdsButton
      accessibleText={accessibleText}
      className={className}
      iconAfter={iconAfter}
      iconBefore={iconBefore}
      iconOnly={iconOnly}
      isDisabled={isDisabled}
      onClick={e => {
        if (!isDisabled && onClick) {
          onClick(e)
        }
      }}
      removePadding={removePadding}
      {...attributes}
      fullWidth={fullWidth}
      size={size}
      text={text}
      title={title}
      variant={variant}
    />
  )
}

export default Button
