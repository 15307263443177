/* eslint-disable max-lines */
import uuid from 'uuid'
import * as R from 'ramda'
import { ThunkAction } from 'redux-thunk'
import * as stateSerializer from '../../middleware/dataService/stateSerializer'
import { getOptions } from '../options/selectors'
import { getParentNode, getSiblings } from '../nodes/selectors'
import * as actionTypes from './actionTypes'
import {
  NodeContent,
  NodeState,
  NodeStateSettings,
  EvaluateCallback,
  FieldType,
  NodeType,
} from '../../../constants/definitions/entities/types'

export * from './actionTypes'

export enum ReorderNodesOperation {
  reorder = 'reorder',
  delete = 'delete',
  create = 'create',
}

// Action creators
export const createNode = (node: NodeState): { type: String; node: NodeState } => ({
  type: actionTypes.CREATE,
  node: {
    id: uuid.v4(),
    content: {},
    childIds: [],
    options: [],
    visible: true,
    completed: false,
    submitted: false,
    feedback: '',
    settings: {},
    tags: [],
    ...node,
  },
})

export const updateNode = (nodeId: string, node: NodeState) => ({
  type: actionTypes.UPDATE,
  nodeId,
  node,
})

export const updateNodeContent = (nodeId: string, content: NodeContent) => ({
  type: actionTypes.UPDATE_CONTENT,
  nodeId,
  content,
})

export const updateNodeSetting = (nodeId: string, setting: NodeStateSettings) => ({
  type: actionTypes.UPDATE_SETTING,
  nodeId,
  setting,
})

export const removeContent = (nodeId: string, content: NodeContent) => ({
  type: actionTypes.REMOVE_CONTENT,
  nodeId,
  content,
})

export const deleteNode = (nodeId: string) => ({
  type: actionTypes.DELETE,
  nodeId,
})

export const reorderNodes = (
  parentId: string,
  node: NodeState,
  operation: ReorderNodesOperation,
) => ({
  type: actionTypes.REORDER,
  parentId,
  node,
  operation,
})

export const addChild = (nodeId: string, childId: string) => ({
  type: actionTypes.ADD_CHILD,
  nodeId,
  childId,
})

export const removeChild = (nodeId: string, childId: string) => ({
  type: actionTypes.REMOVE_CHILD,
  nodeId,
  childId,
})

export interface CreateChildNodeInfo {
  nodeTypeId: NodeType
  order: number
  settings?: [FieldType]
}

export const createAndAddChild = (
  nodeId: string,
  node: CreateChildNodeInfo,
): ThunkAction<string, any, unknown, any> => (dispatch): string => {
  const action = createNode(R.omit(['settings'], node))
  const childId = action.node.id!
  dispatch(action)
  dispatch(addChild(nodeId, childId))
  dispatch(reorderNodes(nodeId, action.node, ReorderNodesOperation.create))
  if (node.settings) {
    node.settings.forEach(s => {
      dispatch(updateNodeSetting(childId, R.assoc(s.name, s.defaultValue, {})))
    })
  }
  return childId
}

export const removeAndDeleteChild = (
  nodeId: string,
  node: NodeState,
): ThunkAction<void, any, unknown, any> => dispatch => {
  if (node.id) {
    dispatch(removeChild(nodeId, node.id)) // from parent
    dispatch(deleteNode(node.id))
  }
  // Hide child nodes also
  if (node.childIds) {
    if (node.childIds.length > 0) {
      node.childIds.map(id => dispatch(deleteNode(id)))
    }
  }
  dispatch(reorderNodes(nodeId, node, ReorderNodesOperation.delete))
}

export const copyNode = (
  parentId: string,
  node: NodeState,
): ThunkAction<string, any, unknown, any> => dispatch => {
  const action = createNode({
    ...node,
    id: uuid.v4(),
  })
  const childId = action.node.id!
  dispatch(action)
  dispatch(addChild(parentId, childId))
  dispatch(reorderNodes(parentId, action.node, ReorderNodesOperation.create))
  return childId
}

export const addTag = (nodeId: string, tag: string) => ({
  type: actionTypes.ADD_TAG,
  nodeId,
  tag,
})

export const removeTag = (nodeId: string, tag: string) => ({
  type: actionTypes.REMOVE_TAG,
  nodeId,
  tag,
})

export const addOption = (optionId: string, nodeId: string) => ({
  type: actionTypes.ADD_OPTION,
  optionId,
  nodeId,
})

export const removeOption = (optionId: string, nodeId: string) => ({
  type: actionTypes.REMOVE_OPTION,
  optionId,
  nodeId,
})

export const removeValue = (nodeId, valueId, evaluateCallback: EvaluateCallback = () => false) => ({
  type: actionTypes.REMOVE_VALUE,
  nodeId,
  valueId,
  evaluateCallback,
})

export const updateValue = (
  nodeId: string,
  value: any,
  evaluateCallback: EvaluateCallback,
  overwrite: boolean = false,
) => ({
  type: actionTypes.UPDATE_VALUE,
  nodeId,
  value,
  evaluateCallback,
  overwrite,
})

export const updateValuesOrder = (nodeId: string, removedOrder: number) => ({
  type: actionTypes.UPDATE_VALUES_ORDER,
  nodeId,
  removedOrder,
})

export const updateValuesOrderScored = (
  nodeId: string,
  removedOrder: number,
  scores: number[],
) => ({
  type: actionTypes.UPDATE_VALUES_ORDER_SCORED,
  nodeId,
  removedOrder,
  scores,
})

export const saveOptionOrder = (nodeId, optionOrder) => ({
  type: actionTypes.SAVE_OPTION_ORDER,
  nodeId,
  optionOrder,
})

export const clearValue = (nodeId: string) => ({
  type: actionTypes.CLEAR_VALUE,
  nodeId,
})

export const saveState = (): ThunkAction<void, any, unknown, any> => (dispatch, getState) => {
  stateSerializer.serializeForAPI(getState())
}

export const setScore = (
  nodeId: string,
  options: any, // TODO: Create type for options
) => ({
  type: actionTypes.SET_SCORE,
  nodeId,
  options,
})

export const setMaxScore = (
  nodeId: string,
  options: any, // TODO: Create type for options
) => ({
  type: actionTypes.SET_MAX_SCORE,
  nodeId,
  options,
})

const setNodeScore = (nodeId: string): ThunkAction<void, any, unknown, any> => (
  dispatch,
  getState,
) => {
  const state = getState()
  const node = state.entities.nodes[nodeId]
  dispatch(setScore(nodeId, getOptions(state.entities, node.id, node.options)))
}

export const clearScore = (nodeId: string) => ({
  type: actionTypes.CLEAR_SCORE,
  nodeId,
})

export const updateNodeTypeId = (nodeId: string, nodeTypeId: NodeType) => ({
  type: actionTypes.UPDATE_ROOT_NODETYPE_ID,
  nodeId,
  nodeTypeId,
})

export const submit = (nodeId: string) => ({
  type: actionTypes.SUBMIT,
  nodeId,
})

export const unsubmit = (nodeId: string) => ({
  type: actionTypes.UNSUBMIT,
  nodeId,
})

export const submitNode = (nodeId: string): ThunkAction<any, any, unknown, any> => (
  dispatch,
  getState,
) => {
  dispatch(submit(nodeId))
  // Get parent node to submit it too.
  const parentNode = getParentNode(getState(), nodeId)
  if (parentNode) {
    dispatch(submit(parentNode.id))
  }
  return dispatch(setNodeScore(nodeId))
}

export const unsubmitNode = (nodeId: string): ThunkAction<void, any, unknown, any> => (
  dispatch,
  getState,
) => {
  dispatch(unsubmit(nodeId))
  // Get parent node and sibling nodes for the unsubmitted node.
  const state = getState()
  const parentNode = getParentNode(state, nodeId)
  // If node has parent and no submitted siblings unsubmit the parent node.
  if (parentNode) {
    const siblingNodes = getSiblings(state, parentNode.id)
    if (siblingNodes.every(node => node.submitted !== true)) {
      dispatch(unsubmit(parentNode.id))
    }
  }
}
