import React, { Component } from 'react'
import { connect } from 'react-redux'
import { knuthShuffle } from 'knuth-shuffle'
import { withRouter } from 'react-router-dom'
import { updateValue, removeValue, saveOptionOrder } from '../../../redux/modules/node/actions.ts'
import CombineQuestion from './CombineQuestion'
import { isInEditor } from '../../../utils/common'

class CombineQuestionContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valueA: null,
      valueB: null,
    }

    this.onClickDetach = this.onClickDetach.bind(this)
    this.onClickValue = this.onClickValue.bind(this)
  }

  componentDidMount() {
    this.updateOptionOrder(this.props.valuesA)
  }

  componentDidUpdate(prevProps) {
    // Update after user's each answer
    if (JSON.stringify(prevProps.valuesA) !== JSON.stringify(this.props.valuesA)) {
      this.updateOptionOrder(this.props.valuesA)
    }
  }

  onClickValue(value, target) {
    const { valueA, valueB } = this.state
    const isFirstSelect = !valueA && !valueB
    const isReselect = (valueA && target === 'valueA') || (valueB && target === 'valueB')

    if (isFirstSelect || isReselect) {
      this.setState({ [target]: value })
    } else {
      if (valueA && target === 'valueB') {
        this.props.combineValues(this.state.valueA, value)
      } else if (valueB && target === 'valueA') {
        this.props.combineValues(value, this.state.valueB)
      }
      this.setState({ valueA: null, valueB: null })
    }
  }

  onClickDetach(id, value) {
    this.props.detachValues(id, value)
  }

  updateOptionOrder(valuesA) {
    this.props.saveOptionOrder({
      original: Object.values(valuesA),
      shuffled: knuthShuffle(Object.keys(valuesA))
        .map(key => valuesA[key])
        .filter(key => typeof key !== 'undefined'),
    })
  }

  render() {
    const {
      optionOrder,
      location,
      valuesB,
      combinedValues,
      correctPairs,
      content,
      submitted,
      examType,
    } = this.props
    const props = {
      combinedValues,
      correctPairs,
      content,
      examType,
      valuesA: (isInEditor(location) ? optionOrder.original : optionOrder.shuffled) || [],
      valuesB,
      valueA: this.state.valueA,
      valueB: this.state.valueB,
      onClickDetach: this.onClickDetach,
      onClickValue: this.onClickValue,
      submitted,
    }

    return <CombineQuestion {...props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const valuePairs = ownProps.value || {}
  const valuesA = {}
  const valuesB = {}
  const combinedValues = []
  const correctPairs = []

  ownProps.options.forEach(option => {
    const { id, valueA, valueB } = option
    // Each option should have value a and value b.
    if (!valueA || !valueB) {
      return
    }

    // Each option contains value a and b, which are the connectable values.
    // Extract those to separate arrays.
    valuesA[id] = { id, value: valueA }
    valuesB[id] = { id, value: valueB }

    correctPairs.push({
      optionId: id,
      valueA,
      valueB,
    })
  })

  Object.entries(valuePairs).forEach(([a, b]) => {
    // Add data for connected values.
    if (valuesA[a] && valuesB[b]) {
      combinedValues.push({
        optionId: a,
        valueA: valuesA[a].value,
        valueB: valuesB[b].value,
        correct: a.toString() === b.toString(),
      })
    }

    // Delete connected values.
    delete valuesA[a]
    delete valuesB[b]
  })

  return {
    combinedValues,
    correctPairs,
    valuesA: Object.values(valuesA),
    valuesB: Object.values(valuesB),
    optionOrder: state.entities.nodes[ownProps.id].optionOrder || {},
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { optionOrder } = stateProps
  return {
    ...stateProps,
    ...ownProps,
    combineValues: (id, value) => {
      dispatch(updateValue(ownProps.id, { [id]: value }, ownProps.evaluateCompleted))
      dispatch(
        saveOptionOrder(ownProps.id, {
          original: optionOrder.original.filter(order => order.id !== id),
          shuffled: optionOrder.shuffled.filter(order => order.id !== id),
        }),
      )
    },
    detachValues: id => {
      dispatch(removeValue(ownProps.id, id, ownProps.evaluateCompleted))
    },
    saveOptionOrder: order => {
      dispatch(saveOptionOrder(ownProps.id, order))
    },
  }
}

export default withRouter(connect(mapStateToProps, null, mergeProps)(CombineQuestionContainer))
