import React from 'react'
import defaultProps from './defaultProps'

const Cross = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 1200 1200" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M0 264.84L335.16 600 0 935.16 264.84 1200 600 864.84 935.16 1200 1200 935.16 864.84 600 1200 264.84 935.16 0 600 335.16 264.84 0 0 264.84z"
      fill={fill}
    />
  </svg>
)

Cross.defaultProps = defaultProps

export default Cross
