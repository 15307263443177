import React from 'react'
import Input from '../../elements/Input/Input'
import {
  deleteCharacterChoice,
  addCharacterChoices,
} from '../../../redux/modules/characters/characters'
import CharacterImportant from './CharacterImportant'

class CharacterChoices extends React.Component {
  constructor(props) {
    super(props)
    this.onOptionChange = this.onOptionChange.bind(this)
  }

  onOptionChange(e) {
    const { dispatch, characterId, questionId, choices } = this.props
    const value = Number(e.target.value)
    const choice = choices.find(x => x.option_id === value)
    if (choice) {
      dispatch(deleteCharacterChoice(characterId, questionId, value))
    } else {
      const newChoice = {
        character_id: characterId,
        question_id: questionId,
        option_id: value,
        important: choices.length > 0 && choices[0].important,
      }
      dispatch(addCharacterChoices([newChoice]))
    }
  }

  render() {
    const { dispatch, options, choices, characterId, questionId, hasLabel } = this.props
    const displayChoices = options.map(x => {
      const choice = choices.find(a => a.option_id === x.id)
      return (
        <Input
          className="yo-character-choice"
          defaultChecked={Boolean(choice)}
          key={x.id}
          onChange={this.onOptionChange}
          type="checkbox"
          value={x.id}
        />
      )
    })

    return (
      <div className="yo-character-choices">
        <CharacterImportant
          answer={choices.length && choices[0].important}
          characterId={characterId}
          disabled={!choices.length}
          dispatch={dispatch}
          hasLabel={hasLabel}
          questionId={questionId}
        />
        {displayChoices}
      </div>
    )
  }
}

export default CharacterChoices
