import React from 'react'
import { connect } from 'react-redux'
import {
  LISTENING_COMPREHENSION,
  MATRICULATION_EXAM_TYPE,
} from '../../../constants/definitions/exams'
import InputExtended from '../../elements/Input/InputExtended'
import Select from '../../elements/Select/Select'
import {
  removeContent,
  updateNodeContent,
  updateNodeSetting,
} from '../../../redux/modules/node/actions.ts'

const shouldRender = ({ node, exam, field }) => {
  // Dont render, if examTypeFilter does not match current exam
  if (field.examTypeFilter && !field.examTypeFilter.includes(exam.exam_type)) {
    return false
  }

  // ... or if subjectFilter does not match current exam subject
  if (field.subjectFilter) {
    if (!field.subjectFilter.find(subject => subject.id === exam.subject)) {
      return false
    }
    // Dont render questionTypes for listening comprehension exams
    if (exam.category === LISTENING_COMPREHENSION) {
      return false
    }
  }

  // ... or if settingFilter does not match current node setting
  return !(field.settingFilter && !node.settings[field.settingFilter])
}

const shouldDisable = ({ node, exam, field }) => {
  switch (field.name) {
    case 'title': {
      return (
        exam.exam_type === MATRICULATION_EXAM_TYPE &&
        (!node.settings || node.settings.overrideTitle !== true)
      )
    }
    default:
      return false
  }
}

// Renders input for each field defined in content type.
const EditorFields = ({
  exam,
  fields,
  node,
  onUpdateNodeSetting,
  onUpdateNodeContent,
  onRemoveContent,
  selectOptions,
}) => {
  const displayFields = fields.map((field, index) => {
    if (!shouldRender({ node, exam, field })) {
      return null
    }

    const handleChange = e => {
      const value = field.editorFormatter === 'rich-text' ? e : e.target.value
      // Clear question type selection if -removed- is selected
      if (field.name === 'questionType' && value === '-removed-') {
        onRemoveContent(node.id, field.name)
      }
      // Otherwise update the field.
      else {
        onUpdateNodeContent(node.id, { [field.name]: value })
      }
    }

    let ComponentName = InputExtended

    const props = {
      attributes: { 'data-testid': `editor-field-${field.name}` },
      autofocus: index === 0,
      defaultValue: node.content[field.name],
      description: field.description,
      fieldName: field.name,
      inline: field.inline,
      isSeparator: field.isSeparator,
      label: field.displayName,
      nodeId: node.id,
      onChange: handleChange,
      options: selectOptions,
      type: field.editorFormatter,
      validation: field.validation,
      width: field.displayWidth,
      disabled: shouldDisable({ node, exam, field }),
    }

    if (field.editorFormatter === 'select') {
      ComponentName = Select
      props.options = field.options
    } else if (field.editorFormatter === 'checkbox') {
      props.defaultChecked = node.settings && node.settings[field.name] === true
      props.onChange = e => {
        onUpdateNodeSetting(node.id, { [field.name]: e.target.checked })
      }
    }

    return <ComponentName key={`${node.id}_${field.name}`} {...props} />
  })

  return <div className="yo-editor__editor">{displayFields}</div>
}

const mapDispatchToProps = dispatch => ({
  onUpdateNodeContent: (nodeId, value) => {
    dispatch(updateNodeContent(nodeId, value))
  },
  onUpdateNodeSetting: (nodeId, setting) => {
    dispatch(updateNodeSetting(nodeId, setting))
  },
  onRemoveContent: (nodeId, fieldName) => {
    dispatch(removeContent(nodeId, fieldName))
  },
})

export default connect(undefined, mapDispatchToProps)(EditorFields)
