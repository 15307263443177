import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as PropTypes from '../../../constants/definitions/propTypes'
import './publish.scss'
import { PublishedCheckbox, PublishedAtInput } from '../Settings/Settings'
import PrimaryHeader from '../../elements/Typography/PrimaryHeader.tsx'

const Publish = props => {
  return (
    <div className="yo-publishform">
      <PrimaryHeader>Publish</PrimaryHeader>
      <PublishedAtInput {...props} key="published-at-input" />
      <PublishedCheckbox {...props} key="published-checkbox" />
    </div>
  )
}

Publish.propTypes = {
  exam: PropTypes.exam.isRequired,
}

const mapStateToProps = state => ({
  exam: state.exam,
})

export default withRouter(connect(mapStateToProps)(Publish))
