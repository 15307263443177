import classnames from 'classnames'
import React, { ReactNode } from 'react'
import './spaced-group.scss'

interface Props {
  children: ReactNode
  wrap?: boolean
  center?: boolean
  topSpacing?: boolean
  bottomSpacing?: boolean
  classNames: string[]
}

const SpacedGroup = ({ children, wrap, center, topSpacing, bottomSpacing, classNames }: Props) => {
  const className = classnames(
    'yo-spaced-group',
    {
      'yo-spaced-group--wrap': wrap,
      'yo-spaced-group--center': center,
      'yo-spaced-group--bottom-spacing': bottomSpacing,
      'yo-spaced-group--top-spacing': topSpacing,
    },
    classNames,
  )
  return <div className={className}>{children}</div>
}

export default SpacedGroup
