import * as actions from './actions.ts'

const defaultState = {
  articleUrl: undefined,
}

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case actions.SET_ARTICLE_URL:
      return { ...state, articleUrl: action.url }
    default:
      return state
  }
}
