import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import QuestionList from './QuestionList'
import { fetchQuestionsByTags } from '../../../redux/middleware/questionService/questionService'

class Questions extends React.Component {
  componentDidMount() {
    const uuids = this.props.match.params.tagId.split(',')
    this.props.getQuestions(uuids)
  }

  componentDidUpdate(prevProps) {
    // Fetch questions again if tag id changes
    if (prevProps.match.params.tagId !== this.props.match.params.tagId) {
      const uuids = this.props.match.params.tagId.split(',')
      this.props.getQuestions(uuids)
    }
  }

  render() {
    const { match, questions } = this.props
    return (
      <div>
        <div className="yo-question-header">Questions</div>
        <div className="yo-question-header__info">
          TAG: {match.params.tagName} ID: {match.params.tagId}
        </div>
        <QuestionList questions={questions.data} />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getQuestions: uuids => {
    dispatch(fetchQuestionsByTags(uuids))
  },
})

const mapStateToProps = state => ({
  questions: state.questions,
})

export default withTranslation(['QuestionList'])(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Questions)),
)
