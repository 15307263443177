import React from 'react'
import { MathJaxContext, MathJax, MathJax2Config } from 'better-react-mathjax'
// import MathJax from 'react-mathjax2'
import config from '../../../config'
import './math.scss'

// mathjax 2.7.9 is hosted in tehtava-static
// https://github.com/Yleisradio/tehtava-static/tree/master/src/mathjax-2.7.9
const MATHJAX_URL = `${config.tehtava_static_url}/mathjax-2.7.9/MathJax.js?config=TeX-MML-AM_CHTML`

const Math = ({ text }: { text: string }) => {
  if (!text) {
    return null
  }
  const config: MathJax2Config = {
    tex2jax: {
      inlineMath: [['$', '$']],
    },
  }
  return (
    <span className="yo-math__wrapper">
      <MathJaxContext config={config} src={MATHJAX_URL} version={2}>
        <MathJax inline>{`$${text}$`}</MathJax>
      </MathJaxContext>
    </span>
  )
}

export default Math
