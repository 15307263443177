import { knuthShuffle } from 'knuth-shuffle'
import { rejectChildren } from '../../../utils/common'

// Actions
export const CLEAR = 'carousel/CLEAR'
const CLEAR_LOADING_STATE = 'carousel/CLEAR_LOADING_STATE'
const SET_CURRENT_ITEM = 'carousel/SET_CURRENT_ITEM'
const SET_ITEMS = 'carousel/SET_ITEMS'
const SET_LOADED_ITEMS = 'carousel/SET_LOADED_ITEMS'
const SET_LOADING_STATE = 'carousel/SET_LOADING_STATE'
const SHUFFLE_ITEMS = 'carousel/SHUFFLE_ITEMS'
export const SET_DEFAULT_CAROUSEL = 'carousel/SET_DEFAULT_CAROUSEL'
const RESET_INDEX = 'carousel/RESET_INDEX'

// Reducer
const defaultState = {
  currentItemIndex: null,
  firstLoadedItemIndex: null,
  isLoading: false,
  items: null,
  lastLoadedItemIndex: null,
  isDefaultCarousel: false,
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CLEAR: {
      return { ...defaultState }
    }

    case CLEAR_LOADING_STATE: {
      return { ...state, isLoading: false }
    }

    case SET_CURRENT_ITEM: {
      /**
       * Because of historical reasons the action.currenItemIndex is actually the next index
       * (current index + 1), not the current. Previously when we had only one item,
       * the carousel would break while trying to return the next index, which of course
       * would not exist.
       */
      const currentIndex = state.items.length === 1 ? 0 : action.currentItemIndex

      return { ...state, currentItemIndex: currentIndex }
    }
    case SET_ITEMS: {
      return {
        ...state,
        // Save only top-level items in the state
        items: rejectChildren(action.items),
      }
    }
    case SET_LOADED_ITEMS: {
      return {
        ...state,
        // Let's be sure not to go below zero index.
        firstLoadedItemIndex: Math.max(0, action.start),
        // Let's also be sure not to go past the final item. Subtract one so we have the correct
        // amount of items.
        lastLoadedItemIndex: Math.min(action.end - 1, state.items.length - 1),
      }
    }

    case SET_LOADING_STATE: {
      return { ...state, isLoading: true }
    }

    case SHUFFLE_ITEMS: {
      // make sure we have items to shuffle
      if (state.items) {
        return {
          ...state,
          items: knuthShuffle(state.items),
        }
      }
      return state
    }

    case SET_DEFAULT_CAROUSEL: {
      return { ...state, isDefaultCarousel: true }
    }

    case RESET_INDEX: {
      return {
        ...state,
        firstLoadedItemIndex: null,
        lastLoadedItemIndex: null,
        currentItemIndex: null,
      }
    }

    default: {
      return state
    }
  }
}

// Action creators
export const clearCarousel = () => ({
  type: CLEAR,
})

export const clearCarouselLoading = () => ({
  type: CLEAR_LOADING_STATE,
})

export const setCurrentCarouselItem = item => ({
  type: SET_CURRENT_ITEM,
  currentItemIndex: item,
})

export const setCarouselItems = items => ({
  type: SET_ITEMS,
  items,
})

export const setLoadedCarouselItems = (start, count) => ({
  type: SET_LOADED_ITEMS,
  start,
  end: start + count,
})

export const setCarouselLoading = () => ({
  type: SET_LOADING_STATE,
})

export const shuffleCarouselItems = () => ({
  type: SHUFFLE_ITEMS,
})

export const setDefaultCarousel = () => ({
  type: SET_DEFAULT_CAROUSEL,
})

export const resetCarouselIndex = () => ({
  type: RESET_INDEX,
})
