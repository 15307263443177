import React from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { ListItemNode, ListNode } from '@lexical/list'
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  BOLD_STAR,
  HEADING,
  ITALIC_STAR,
  ORDERED_LIST,
  UNORDERED_LIST,
} from '@lexical/markdown'
import { HeadingNode } from '@lexical/rich-text'
import './rich-text-editor.scss'
import ToolbarPlugin, { ToolbarPluginOption } from './plugins/ToolbarPlugin'
import AutoFocusPlugin from './plugins/AutoFocusPlugin'
import { ShortcodeNode } from './nodes/ShortcodeNode'
import ShortcodePlugin, { $convertShortcodeStrings } from './plugins/ShortcodePlugin'

const markdownTransformers = [HEADING, BOLD_STAR, ITALIC_STAR, ORDERED_LIST, UNORDERED_LIST]

interface RichTextEditorProps {
  onChange: (value: string) => void
  initialValue?: string
  options?: Array<ToolbarPluginOption>
}

const initialConfig = {
  namespace: 'RichTextEditor',
  theme: {
    // CSS classnames
    paragraph: 'yo-rich-text-editor-style__paragraph',
    text: {
      bold: 'yo-rich-text-editor-style__bold',
      italic: 'yo-rich-text-editor-style__italic',
    },
  },
  onError: e => {
    throw e
  },
  nodes: [HeadingNode, ListNode, ListItemNode, ShortcodeNode],
}

const RichTextEditor = ({ initialValue, onChange, options }: RichTextEditorProps) => {
  return (
    <LexicalComposer
      initialConfig={{
        ...initialConfig,
        editorState: initialValue
          ? () => {
              $convertFromMarkdownString(initialValue, markdownTransformers)
              $convertShortcodeStrings()
            }
          : undefined,
      }}
    >
      <div className="yo-rich-text-editor" data-testid="rich-text-editor">
        <ToolbarPlugin options={options} />
        <div className="yo-rich-text-editor__inner">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className="yo-rich-text-editor__textarea"
                spellCheck={false}
                testid="rich-text-editor-textarea"
              />
            }
            placeholder={<div className="yo-rich-text-editor__placeholder">Enter text...</div>}
          />
          <OnChangePlugin
            ignoreSelectionChange
            onChange={editorState => {
              editorState.read(() => {
                const text = $convertToMarkdownString(markdownTransformers)
                onChange(text)
              })
            }}
          />
          <AutoFocusPlugin />
          <ListPlugin />
          <MarkdownShortcutPlugin transformers={markdownTransformers} />
          <ShortcodePlugin />
          <HistoryPlugin />
        </div>
      </div>
    </LexicalComposer>
  )
}

export default RichTextEditor
