import { ALL_EXAM_TYPES } from '../redux/modules/filters/exams/byExamType'
import { ALL_LANGUAGES } from '../redux/modules/filters/exams/byLanguage'
import { ALL_SUBJECTS } from '../redux/modules/filters/exams/bySubject'
import { subjectHasCategories } from '../components/misc/Settings/settingFunctions'
import { DEFAULT_CATEGORIES } from '../constants/definitions/exams'

export const getQueryParams = (
  store,
  { subject, sortBy, direction, published, examType, lang, category },
) => {
  const state = store.getState()
  const { limit, page } = state.entities.paginations
  const { exam } = state
  const offset = limit * page
  const filterArr = []
  filterArr.push(`limit=${limit}`)
  filterArr.push(`offset=${offset}`)

  if (subject) {
    filterArr.push(`subject=${subject}`)
  }
  if (sortBy) {
    filterArr.push(`sort_by=${sortBy}`)
  }
  if (direction) {
    filterArr.push(`direction=${direction}`)
  }
  if (published !== undefined) {
    filterArr.push(`published=${published}`)
  }
  if (examType) {
    filterArr.push(`exam_type=${examType}`)
  }
  if (lang) {
    filterArr.push(`lang=${lang}`)
  }
  if (subjectHasCategories(exam)) {
    filterArr.push(`categories=${category || DEFAULT_CATEGORIES}`)
  }
  return filterArr.join('&')
}

export const getPaginatedUrl = store => {
  const state = store.getState()
  const { limit, page, sortBy, sortDirection } = state.entities.paginations
  const { examType, language, subject, seriesId, search } = state.examsFilters
  const published = state.examsFilters.publishStatus === 'published'
  const offset = limit * page

  return (
    `limit=${limit}` +
    `&offset=${offset}` +
    `&sort_by=${sortBy}` +
    `&direction=${sortDirection}` +
    `&published=${published}` +
    `${examType !== ALL_EXAM_TYPES ? `&exam_type=${examType}` : ''}` +
    `${language !== ALL_LANGUAGES ? `&lang=${language}` : ''}` +
    `${subject !== ALL_SUBJECTS ? `&subject=${subject}` : ''}` +
    `${seriesId && seriesId !== '' ? `&series_uuid=${seriesId}` : ''}` +
    `${search !== '' ? `&search_text=${search}` : ''}`
  )
}

/**
 * FIXME: this function's name is misleading and it should be changed:
 * perhaps to 'hasEditorUnsavedChanges'?
 * This function is used for preventing the exam data to be refetched
 * while the user switches a tab in an unsaved editor.
 * @param {*} store - The whole app state
 * @param {*} nodeId - Current exam id
 * @returns {boolean} - Indicating if the loadState object contains the particular exam id
 */
export const isFetching = (store, nodeId) => {
  const { loadState } = store.getState()

  const stateNodeId = loadState && loadState.nodeId ? loadState.nodeId : null
  return stateNodeId === nodeId
}
