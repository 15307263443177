import React from 'react'

const INTERVAL_STEP = 200

function withTimer(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        progress: 100,
        step: 0,
      }
      this.startTimer = this.startTimer.bind(this)
      this.stopTimer = this.stopTimer.bind(this)
      this.setStep = this.setStep.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
      const { progress } = this.state
      if ((!progress || progress <= 0) && progress !== prevState.progress) {
        this.stopTimer()
      }
    }

    componentWillUnmount() {
      this.stopTimer()
    }

    setStep(duration) {
      this.stopTimer()
      this.setState({
        progress: 100,
        step: (100 * (INTERVAL_STEP / 1000)) / duration,
      })
    }

    startTimer() {
      this.intervalHandle = setInterval(() => {
        this.setState(prevState => ({
          progress: prevState.progress - prevState.step,
        }))
      }, INTERVAL_STEP)
    }

    stopTimer() {
      if (this.intervalHandle) {
        clearInterval(this.intervalHandle)
      }
    }

    render() {
      return (
        <WrappedComponent
          setStep={this.setStep}
          startTimer={this.startTimer}
          stopTimer={this.stopTimer}
          timerProgress={this.state.progress}
          {...this.props}
        />
      )
    }
  }
}

export default withTimer
