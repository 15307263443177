import React from 'react'
import { YdsThemeProvider } from '@yleisradio/yds-components-react'
import useYdsTheme from '../../../hooks/useYdsTheme/useYdsTheme'

interface Props {
  children: React.ReactNode
}

const YdsAutoThemeProvider = ({ children }: Props) => {
  const theme = useYdsTheme()
  return <YdsThemeProvider theme={theme}>{children}</YdsThemeProvider>
}

export default YdsAutoThemeProvider
