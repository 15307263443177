/* eslint-disable max-lines */
import React from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import {
  updateValue,
  updateNode,
  submitNode,
  setMaxScore,
} from '../../../redux/modules/node/actions.ts'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import Fragment from '../../node/Fragment/Fragment'
import MultipleChoiceOption, {
  getStatus,
  extractImages,
  MultipleChoiceOptionImageContent,
  getStatusAriaLabel,
} from './MultipleChoiceOption.tsx'
import { optionLayoutTypes } from '../../../constants/definitions/entities/entities.ts'
import withTimer from '../../elements/TimerHOC/TimerHOC'
import TimerStart from '../../elements/TimerStart/TimerStart'
import { getHideReview } from '../../../redux/modules/exam/selectors'
import { getNodeType } from '../../../redux/modules/nodes/selectors'
import { getCarouselActiveState } from '../../../redux/modules/carousel/selectors'
import { REVIEW_ONLY_EXAM_TYPE } from '../../../constants/definitions/exams'
import { getStats, getTotalCount } from '../../../utils/examHelpers'
import { hasContent, isInEditor } from '../../../utils/common'
import './multiple-choice-question.scss'
import { getFeedbackLoading } from '../../../redux/modules/statistics/selectors'
import CheckboxOption from '../CustomizedFeedback/CheckboxOption.tsx'

const Option = withTranslation()(props => {
  const {
    examType,
    handleOnChange,
    hideStatistics,
    isFeedbackLoading,
    node,
    option,
    settings,
    submitted,
    value,
    yleTunnus,
    hasCorrectAnswers,
    id,
    t,
  } = props
  const totalCount = getTotalCount(node)
  const { percentage, count } = getStats(node, option, totalCount)
  const checked = Boolean(value && value[option.id]?.trim() === option.text?.trim())
  // Is the option the correct one.
  const correct = typeof option.correct !== 'undefined' && option.correct === true
  const isReviewOnlyExam = examType === REVIEW_ONLY_EXAM_TYPE
  const status = getStatus({ submitted, checked, correct, isReviewOnlyExam, hasCorrectAnswers })
  const hasIcon = checked && submitted && !isReviewOnlyExam
  const attributes = {
    'aria-label': getStatusAriaLabel(t, status),
    'data-testid': 'input__MULTIPLE_CHOICE_QUESTION',
    'data-value': option.text,
    'data-option-id': option.id,
    disabled: submitted,
  }

  // Show stats if they're not hidden,
  // user is logged in yleTunnus and feedback data is available
  const showStats =
    !hideStatistics &&
    hasContent(node.statistics) &&
    node.submitted &&
    yleTunnus.userId &&
    !isFeedbackLoading

  const text = extractImages(option.text)
  const richText = <RichTextContent content={text.labelWithoutImages || ''} />

  if (settings.demandCorrect) {
    return (
      <CheckboxOption
        attributes={attributes}
        checked={checked}
        content={
          <MultipleChoiceOptionImageContent
            count={count}
            images={text.images}
            percentage={percentage}
            showStats={showStats}
          />
        }
        hasIcon={hasIcon}
        id={id}
        label={richText}
        name={`node-option-${id}`}
        onChange={handleOnChange}
        status={status}
      />
    )
  }
  return (
    <MultipleChoiceOption
      attributes={attributes}
      checked={checked}
      content={
        <MultipleChoiceOptionImageContent
          count={count}
          images={text.images}
          percentage={percentage}
          showStats={showStats}
        />
      }
      hasIcon={hasIcon}
      label={richText}
      onChange={handleOnChange}
      status={status}
    />
  )
})

const MultipleChoiceQuestion = props => {
  const {
    children,
    content,
    hideReview,
    examType,
    node,
    options,
    settings = {},
    questionStarted,
    location,
    timerProgress,
    second,
    isCarouselActive,
    onStartQuestion,
    handleSubmit,
  } = props
  const hasCorrectAnswers = Boolean(options.find(x => x.correct === true))
  const renderedOptions = options.map(option => {
    const isLastOption = option === options[options.length - 1]
    return (
      <Option
        hasCorrectAnswers={hasCorrectAnswers}
        isLastOption={isLastOption}
        key={option.id}
        option={option}
        {...props}
      />
    )
  })
  const classes = classnames('yo-multiple-choice-question__options', {
    'yo-multiple-choice-question__options--two-columns':
      settings.optionsLayout === optionLayoutTypes.labels,
  })
  return (
    <fieldset className="yo-fieldset">
      <Fragment
        displayNumber={content.displayNumber}
        examType={examType}
        text={
          <legend>
            <RichTextContent content={content.text} />
          </legend>
        }
      >
        {children}
        <TimerStart
          hideReview={hideReview}
          isCarouselActive={isCarouselActive}
          isEditor={isInEditor(location)}
          nodeId={node.id}
          onStartQuestion={onStartQuestion}
          onTimerComplete={handleSubmit}
          questionSettings={settings}
          questionStarted={questionStarted}
          renderClasses={classes}
          renderOptions={renderedOptions}
          second={second}
          submitted={node.submitted}
          timerProgress={timerProgress}
        />
      </Fragment>
    </fieldset>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { node, timerProgress } = ownProps
  const nodeType = getNodeType(state, node.nodeTypeId)
  const duration = Number(node.settings.duration)
  const timerValid = typeof node.timer === 'number'

  return {
    submitted: ownProps.submitted,
    yleTunnus: state.yleTunnus,
    timerProgress: timerValid ? (node.timer / duration) * 100 : timerProgress,
    second: timerValid ? node.timer : (timerProgress * duration) / 100,
    hideReview: getHideReview(state),
    hasScoring: nodeType.scoring === true,
    isCarouselActive: getCarouselActiveState(state),
    isFeedbackLoading: getFeedbackLoading(state),
    hideStatistics: node?.examClassification?.hide_statistics,
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { second } = stateProps
  const { dispatch } = dispatchProps

  return {
    ...stateProps,
    ...ownProps,
    handleSubmit: (nodeId = null) => {
      const { settings } = ownProps
      if (settings.timedQuestion) {
        const { id, options, stopTimer } = ownProps
        dispatch(updateNode(nodeId || id, { timer: second }))
        dispatch(submitNode(nodeId || id))
        dispatch(setMaxScore(nodeId || id, options))
        stopTimer()
      }
    },
    handleOnChange: e => {
      const { id, evaluateCompleted, submitted, settings } = ownProps
      const value = e.target.checked ? e.target.getAttribute('data-value') : undefined
      const optionId = e.target.getAttribute('data-option-id')
      if (!submitted) {
        dispatch(updateValue(id, { [optionId]: value }, evaluateCompleted, !settings.demandCorrect))
      }
      if (settings.timedQuestion) {
        const { options, stopTimer } = ownProps
        dispatch(updateNode(id, { timer: second }))
        dispatch(submitNode(id))
        dispatch(setMaxScore(id, options))
        stopTimer()
      }
    },
    onStartQuestion: () => {
      const {
        settings: { duration, timedQuestion },
        id,
        startTimer,
        setStep,
      } = ownProps
      if (timedQuestion && duration) {
        setStep(Number(duration))
        startTimer()
        dispatch(updateNode(id, { questionStarted: true }))
      }
    },
  }
}

export default compose(
  withTimer,
  connect(mapStateToProps, null, mergeProps),
  withRouter,
)(MultipleChoiceQuestion)
