import React from 'react'
import { updateCharacterImportant } from '../../../redux/modules/characters/characters'
import Input from '../../elements/Input/Input'

class CharacterImportant extends React.Component {
  constructor(props) {
    super(props)
    this.onClickImportant = this.onClickImportant.bind(this)
  }

  onClickImportant(e) {
    const { dispatch, characterId, questionId } = this.props
    dispatch(updateCharacterImportant(characterId, questionId, e.target.checked))
  }

  render() {
    const { answer, disabled } = this.props

    return (
      <div className="yo-character-important">
        <Input
          attributes={{ disabled }}
          className="yo-character-important__checkbox"
          defaultChecked={answer}
          onChange={this.onClickImportant}
          type="checkbox"
        />
      </div>
    )
  }
}

export default CharacterImportant
