import React from 'react'
import defaultProps from './defaultProps'

const Italic = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 32 32" width={width} xmlns="http://www.w3.org/2000/svg">
    <path className="yo-icon-svg-path" d="M28 2v2h-4L14 28h4v2H4v-2h4L18 4h-4V2z" fill={fill} />
  </svg>
)

Italic.defaultProps = defaultProps

export default Italic
