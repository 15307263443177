import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateValue, removeValue, updateValuesOrder } from '../../../redux/modules/node/actions.ts'
import { OrderQuestion } from './OrderQuestion'

const OrderQuestionContainer = props => <OrderQuestion {...props} />

const mapStateToProps = (state, ownProps) => ({
  selectedValues: state.entities.nodes[ownProps.id].value || {},
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClickOption: option => {
    const correct = option.order === ownProps.options.map(opt => opt.id).indexOf(option.id)
    dispatch(
      updateValue(ownProps.id, { [option.id]: { ...option, correct } }, ownProps.evaluateCompleted),
    )
  },
  onClickSelectedValue: option => {
    dispatch(updateValuesOrder(ownProps.id, option.order))
    dispatch(removeValue(ownProps.id, option.id.toString(), ownProps.evaluateCompleted))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderQuestionContainer))
