import { useEffect, useState } from 'react'
import useTimeout from '../useTimeout/useTimeout'

export default function useIsClassNameInViewport(className, delay, options = {}) {
  const [isIntersecting, setIntersecting] = useState(false)
  const [shouldObserve, setShouldObserve] = useState(false)
  useTimeout(() => setShouldObserve(true), delay)

  const observer = new IntersectionObserver(([entry]) => {
    setIntersecting(entry.isIntersecting)
  }, options)

  const [element] = document.getElementsByClassName(className)

  useEffect(() => {
    if (shouldObserve) {
      observer.observe(element)
    }
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [shouldObserve])

  return isIntersecting
}
