import { FieldTypeOption } from './types'

const questionTypes: FieldTypeOption[] = [
  {
    id: 'grammar',
    name: 'Kielioppi ja sanasto',
  },
  {
    id: 'reading',
    name: 'Luetun ymmärtäminen',
  },
  {
    id: 'listening-comprehension',
    name: 'Kuullunymmärtäminen',
  },
  {
    id: 'writing',
    name: 'Tekstin tuottaminen',
  },
  {
    id: '-removed-',
    name: '(Empty)',
  },
]

export default questionTypes
