import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import CharacterChoices from './CharacterChoices'
import CharacterLabels from './CharacterLabels'

class CharacterChoicesContainer extends PureComponent {
  render() {
    const { character, questions, dispatch, hasLabel } = this.props

    const tableRows = questions.map(q => {
      const choices = character.choices.filter(x => x.question_id === q.id)

      return (
        <div className="yo-character-question" key={`character-question-${q.id}`}>
          <div className="yo-character-question__wrapper">
            {hasLabel && <CharacterLabels question={q} />}
            <CharacterChoices
              characterId={character.id}
              choices={choices}
              dispatch={dispatch}
              hasLabel={hasLabel}
              options={q.optionsData}
              questionId={q.id}
            />
          </div>
        </div>
      )
    })

    return <div>{tableRows}</div>
  }
}

export default withTranslation(['Character'])(CharacterChoicesContainer)
