import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import './sidebar.scss'

const Sidebar = ({ children, size }) => {
  if (!children) {
    return null
  }

  const classNames = classnames('yo-sidebar', {
    'yo-sidebar--expanded': size === 'large',
  })

  return <div className={classNames}>{children}</div>
}

const mapStateToProps = state => ({
  size: state.editor.size,
})

export default connect(mapStateToProps)(Sidebar)
