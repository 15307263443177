import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FINNISH } from '../../../constants/definitions/exams'
import {
  removeValue,
  updateValue,
  updateValuesOrderScored,
} from '../../../redux/modules/node/actions.ts'
import { hasContent } from '../../../utils/common'
import { getScoreSums } from '../../../utils/examHelpers'
import OptionButton from '../../elements/OptionButton/OptionButton.tsx'
import Button from '../../elements/Button/Button.tsx'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import Fragment from '../../node/Fragment/Fragment'
import '../VoteQuestion/vote-question.scss'
import './scored-vote-question.scss'
import useIsClassNameInViewport from '../../../hooks/useIsClassNameInViewport/useIsClassNameInViewport'

const ScoredVoteQuestion = props => {
  const {
    content,
    displayResults,
    examIsOpen,
    examType,
    hideStatistics,
    isFeedbackLoading,
    node,
    onClickOption,
    onClickSelectedValue,
    options,
    selectedValues,
    submitted,
    t,
    yleTunnus,
  } = props

  // Conditions for displaying voting stats
  // TODO this is the same as VoteQuestion; combine
  const voteHasEnded = !examIsOpen && !isFeedbackLoading
  const loggedInUserSubmittedNode =
    hasContent(node.statisticSums) && node.submitted && yleTunnus?.userId && !isFeedbackLoading
  const editorViewsResults = displayResults && !isFeedbackLoading
  const shouldShowStats =
    editorViewsResults || (!hideStatistics && (voteHasEnded || loggedInUserSubmittedNode))

  const selectedPosition = Object.values(selectedValues).length

  const maxAnswers = node.settings.scoredVoteScoring?.max || 1
  const scores = node.settings.scoredVoteScoring?.scores || []

  const optionItems = options && (
    <div className="yo-scored-vote-question__options">
      {options.map(option =>
        Object.keys(selectedValues).includes(option.id.toString()) ? null : (
          <OptionButton
            isClickable={!submitted && examIsOpen && selectedPosition < maxAnswers}
            key={option.id}
            onClick={() => onClickOption({ ...option, selectedPosition }, scores[selectedPosition])}
            type={null}
          >
            <RichTextContent content={option.text} />
          </OptionButton>
        ),
      )}
    </div>
  )

  // TODO this is almost the same as OrderQuestion; combine
  // differences: show score instead of index
  const selectedItems = (
    <div className="yo-scored-vote-question__selected--column">
      {Object.values(selectedValues)
        .sort((a, b) => a.selectedPosition - b.selectedPosition)
        .map((option, i) => (
          <div className="yo-scored-vote-question__option--wrapper" key={`${option.id}--selected`}>
            <div className="yo-scored-vote-question__option--index">{scores[i]}</div>
            <OptionButton
              isClickable={!submitted}
              isSelected
              onClick={() => onClickSelectedValue(option)}
            >
              <RichTextContent content={option.text} />
            </OptionButton>
          </div>
        ))}
    </div>
  )

  const renderOptionStats = () => {
    return options
      .map(option => ({
        ...option,
        score: getScoreSums(node, option),
      }))
      .sort(({ score: scoreA }, { score: scoreB }) => scoreB - scoreA)
      .map((option, index) => {
        return (
          <div className="yo-scored-vote-results__option-result" key={`option-result-${option.id}`}>
            <div className="yo-scored-vote-results__option-result-title">{`${index + 1}. ${
              option.text
            }`}</div>
            <div className="yo-scored-vote-results__option-result-values">{`${option.score.toLocaleString(
              FINNISH,
            )} ${t('points')}`}</div>
          </div>
        )
      })
  }

  const canGivePoints = !submitted && selectedPosition < maxAnswers

  const nodeReviewIsVisible = useIsClassNameInViewport('yo-node-review', 3000, {
    threshold: 0.45,
    rootMargin: '10px',
  })

  const handleOnScrollToReviewClick = () => {
    const [nodeReviewElement] = document.getElementsByClassName('yo-node-review')
    nodeReviewElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' })
  }

  return (
    <div>
      <Fragment
        displayNumber={content.displayNumber}
        examType={examType}
        text={<RichTextContent content={content.text} />}
      />

      <div className="yo-scored-vote-question__selected">{selectedItems}</div>
      {canGivePoints && (
        <div className="yo-scored-vote-question__info">
          {t('give-points-to', { points: scores[selectedPosition] })}
        </div>
      )}
      {selectedPosition >= maxAnswers && !submitted && (
        <div className="yo-scored-vote-question__info">
          {t('scoring-complete')}
          {!nodeReviewIsVisible && (
            <Button
              classNames={['yo-scored-vote-question__info-button']}
              onClick={handleOnScrollToReviewClick}
              size="sm"
              text={t('scroll-down-to-submit')}
            />
          )}
        </div>
      )}
      {options && !submitted && optionItems}
      <div className="yo-scored-vote-question__stats">{shouldShowStats && renderOptionStats()}</div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  selectedValues: state.entities.nodes[ownProps.id].value || {},
  yleTunnus: state.yleTunnus,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClickOption: (option, score) => {
    dispatch(
      updateValue(ownProps.id, { [option.id]: { ...option, score } }, ownProps.evaluateCompleted),
    )
  },
  onClickSelectedValue: option => {
    dispatch(
      updateValuesOrderScored(
        ownProps.id,
        option.selectedPosition,
        ownProps.node.settings.scoredVoteScoring.scores,
      ),
    )
    dispatch(removeValue(ownProps.id, option.id.toString(), ownProps.evaluateCompleted))
  },
})

export default withTranslation(['ScoredVoteQuestion'])(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ScoredVoteQuestion)),
)
