/* eslint-disable max-lines */
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { isEmpty, keys, values, union, prepend } from 'ramda'
import classnames from 'classnames'
import i18n from '../../../utils/i18n'
import {
  MATRICULATION_EXAM_TYPE,
  EDUCATION_EXAM_TYPE,
  ENGLISH,
  FINNISH,
  OTHER_EXAM_TYPE,
  TV_EPISODE,
  SWEDISH,
  getAllSubjectOptions,
  getSubjectOptions,
  ARCHTYPE_TEST,
  POLL,
  VOTE,
  REVIEW_ONLY_EXAM_TYPE,
} from '../../../constants/definitions/exams'
import { duplicateExam } from '../../../redux/middleware/examService/examService'
import { deleteExam } from '../../../redux/modules/exams/exams'
import { resetAllFilters } from '../../../redux/modules/filters/exams/resetFilters'
import {
  ALL_EXAM_TYPES,
  filterExamsByExamType,
} from '../../../redux/modules/filters/exams/byExamType'
import {
  ALL_LANGUAGES,
  filterExamsByLanguage,
} from '../../../redux/modules/filters/exams/byLanguage'
import { filterExamsByPublishStatus } from '../../../redux/modules/filters/exams/byPublishStatus'
import {
  ALL_SUBJECTS,
  filterExamsBySubject,
  resetSubjectFilter,
} from '../../../redux/modules/filters/exams/bySubject'
import {
  resetPaginations,
  updateSortBy,
  updateSortDirection,
  updateLimit,
  updatePage,
} from '../../../redux/modules/paginations/paginations'
import { clearExamLoadState } from '../../../redux/modules/loadState/loadState'
import Button from '../../elements/Button/Button.tsx'
import { IconButtons, IconButton } from '../../elements/IconButtons/IconButtons'
import { Spinner } from '../../elements/Loader/Loader'
import Select from '../../elements/Select/Select'
import Icon from '../../elements/Icon/Icon'
import Paginate from './Paginate'
import ExamSearch from './ExamSearch'
import './exam-list.scss'
import { clearValidations } from '../../../redux/modules/validation/validation'
import { formatDate } from '../../../utils/common'
import { filterExamsBySeriesId } from '../../../redux/modules/filters/exams/bySeriesId'

class ExamListContainer extends React.Component {
  componentDidMount() {
    const {
      match,
      onFetchExamsBySeriesId,
      clearExamLoadState,
      clearValidations,
      onClickResetFilters,
      history,
    } = this.props
    if (match.params.seriesId) {
      onFetchExamsBySeriesId(match.params.seriesId)
    } else if (history.action !== 'POP') {
      // reset filters unless back navigation
      onClickResetFilters()
    }
    clearExamLoadState()
    clearValidations()
  }

  render() {
    return <ExamList {...this.props} />
  }
}

const linkToAuthorExams = ({ name, uuid }) => (
  <Link className="yo-exam-list__author" key={uuid} to={`/exams/by-author/${uuid}`}>
    {name}
  </Link>
)

const ExamItemRow = ({
  updated_at,
  classification,
  name,
  onClickDelete,
  onClickDuplicate,
  uuid,
  t,
  history,
  match,
}) => {
  const authors = keys(classification.authors).map(uuid =>
    linkToAuthorExams({ ...classification.authors[uuid], uuid }),
  )

  const iconButtonContent = !match.params.seriesId ? (
    <div className="yo-exam-list__action--wrapper">
      <a
        data-exam-uuid={uuid}
        onClick={e => onClickDuplicate(e, history)}
        role="button"
        tabIndex="0"
      >
        <IconButton
          attributes={{ 'data-testid': 'duplicate-exam' }}
          height="16"
          icon="copy"
          title={t('duplicate')}
          width="16"
        />
      </a>
      {!classification.published && (
        <a data-exam-uuid={uuid} onClick={onClickDelete} role="button" tabIndex="0">
          <IconButton
            attributes={{ 'data-testid': 'delete-exam' }}
            height="12"
            icon="cross"
            title={t('delete')}
            width="12"
          />
        </a>
      )}
    </div>
  ) : null
  return (
    <div className="yo-exam-list__table-row" key={uuid}>
      <span
        className="yo-exam-list__table-cell yo-exam-list__table-cell--name"
        data-column-title={t('name')}
      >
        <Link to={`/exam/${uuid}/editor`}>{name}</Link>
      </span>

      <span
        className="yo-exam-list__table-cell yo-exam-list__table-cell--author"
        data-column-title={t('author')}
      >
        {authors}
      </span>

      <span
        className="yo-exam-list__table-cell yo-exam-list__table-cell--updated-at"
        data-column-title={t('updated-at')}
      >
        {formatDate(new Date(updated_at))}
      </span>

      <span
        className="yo-exam-list__table-cell yo-exam-list__table-cell--actions"
        data-column-title={t('actions')}
      >
        <IconButtons>{iconButtonContent}</IconButtons>
      </span>
    </div>
  )
}

const ExamList = ({
  exams,
  filters,
  loading,
  paginations,
  onChangeSort,
  onChangePublishStatus,
  onChangeFilterByExamType,
  onChangeFilterByLanguage,
  onChangeFilterBySubject,
  onClickCreate,
  onClickDelete,
  onClickDuplicate,
  onClickResetFilters,
  onChangeLimit,
  onChangePage,
  fetchExams,
  match,
  t,
  history,
}) => {
  const examListTable = (type, exams, paginations) => {
    if (isEmpty(exams)) {
      return <div>{t('no-exams', { context: type })}</div>
    }

    const column = columnType => {
      const { sortBy, sortDirection } = paginations
      const changeDirection = direction => (direction === 'asc' ? 'desc' : 'asc')
      const direction = sortBy === columnType ? changeDirection(sortDirection) : sortDirection
      const iconDirection = direction =>
        direction === 'asc' ? <Icon icon="triangleDown" /> : <Icon icon="triangleUp" />

      return (
        <span
          className={classnames(
            'yo-exam-list__table-cell',
            `yo-exam-list__table-cell--${columnType.replace('_', '-')}`,
            'yo-exam-list__table-cell--sortable',
          )}
          onClick={e => onChangeSort(e, columnType, direction)}
          role="button"
          tabIndex="0"
        >
          {sortBy === columnType ? iconDirection(direction) : null}
          {t(columnType)}
        </span>
      )
    }

    return (
      <div className="yo-exam-list__table">
        <div className="yo-exam-list__table-header">
          {column('name')}
          {column('author')}
          {column('updated_at')}
          <span className="yo-exam-list__table-cell yo-exam-list__table-cell--actions">
            {t('actions')}
          </span>
        </div>
        {exams.map(exam =>
          ExamItemRow({
            ...exam,
            onClickDelete,
            onClickDuplicate,
            t,
            history,
            match,
          }),
        )}
      </div>
    )
  }

  const examList = (type, exams) => {
    const titleSpan = (type, currentType) => (
      <div
        className={classnames('yo-exam-list__publish-title', {
          'yo-exam-list__publish-title--active': type === currentType,
        })}
        data-testid={`exams-${currentType}`}
        onClick={e => onChangePublishStatus(e, currentType)}
        role="presentation"
      >
        {t('title', { context: currentType })}
      </div>
    )

    return (
      <div className="yo-exam-list__list">
        <div className="yo-exam-list__pagination--wrapper">
          <div className="yo-exam-list__title--wrapper">
            {titleSpan(type, 'unpublished')}
            {titleSpan(type, 'published')}
            <ExamSearch onFetchExams={fetchExams} />
          </div>
          <div>
            <Paginate onChangeLimit={onChangeLimit} onChangePage={onChangePage} />
          </div>
        </div>

        {loading ? <Spinner /> : examListTable(type, exams, paginations)}
      </div>
    )
  }

  const en = i18n.getFixedT('en')

  const languageFilterOptions = [ALL_LANGUAGES, ENGLISH, FINNISH, SWEDISH].map(language => ({
    id: language,
    name: en(`common:${language}`),
  }))

  const examTypeFilterOptions = [
    ALL_EXAM_TYPES,
    OTHER_EXAM_TYPE,
    ARCHTYPE_TEST,
    VOTE,
    POLL,
    EDUCATION_EXAM_TYPE,
    MATRICULATION_EXAM_TYPE,
    TV_EPISODE,
    REVIEW_ONLY_EXAM_TYPE,
  ].map(type => ({ id: type, name: en(`common:${type}`) }))

  const defaultSubjectOption = {
    id: ALL_SUBJECTS,
    name: en(`common:${ALL_SUBJECTS}`),
  }

  const subjectFilterOptions =
    filters.examType === ALL_EXAM_TYPES
      ? prepend(defaultSubjectOption, getAllSubjectOptions())
      : union([defaultSubjectOption], getSubjectOptions(filters.examType))
  // Show header only outside of series
  const showHeader = !match.params.seriesId
  return (
    <div className="yo-exam-list">
      {showHeader && (
        <div className="yo-exam-list__header">
          <Button
            attributes={{ 'data-testid': 'create-exam' }}
            classNames={['yo-exam-list__button']}
            onClick={() => onClickCreate(history)}
            text={t('new-exam')}
          />
          <div className="yo-exam-list__filters">
            <Select
              attributes={{ 'data-testid': 'select-exam-type' }}
              className="yo-exam-list__filter"
              defaultValue={filters.examType}
              label="Exam type"
              onChange={onChangeFilterByExamType}
              options={examTypeFilterOptions}
            />
            <Select
              attributes={{ 'data-testid': 'select-exam-language' }}
              className="yo-exam-list__filter"
              defaultValue={filters.language}
              label="Language"
              onChange={onChangeFilterByLanguage}
              options={languageFilterOptions}
            />
            <Select
              attributes={{ 'data-testid': 'select-exam-subject' }}
              className="yo-exam-list__filter"
              defaultValue={filters.subject}
              label="Subject"
              onChange={onChangeFilterBySubject}
              options={subjectFilterOptions}
            />
            <Button onClick={onClickResetFilters} size="sm" text={t('reset-filters')} />
          </div>
        </div>
      )}
      {examList(filters.publishStatus, exams)}
    </div>
  )
}

// Combine filter conditions
const filterConditions = (exam, { subject }) =>
  subject === ALL_SUBJECTS || subject === exam.classification.subject

const getVisibleExams = (exams, examFilters) =>
  exams.filter(exam => filterConditions(exam, examFilters))

const mapStateToProps = state => ({
  exams: getVisibleExams(values(state.entities.exams), state.examsFilters),
  filters: state.examsFilters,
  loading: state.loadState.examListLoading,
  paginations: state.entities.paginations,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps
  const { fetchExams, t } = ownProps
  const { filters } = stateProps

  return {
    ...stateProps,
    ...ownProps,
    clearExamLoadState: () => {
      dispatch(clearExamLoadState())
    },
    fetchExams: () => {
      dispatch(fetchExams())
    },
    onClickCreate: history => {
      history.push('/exams/create')
    },
    onClickDelete: e => {
      e.preventDefault()
      const uuid = e.currentTarget.getAttribute('data-exam-uuid')
      // Display a confirmation dialog for the user before deleting the exam
      const delConfirmed = window.confirm(t('common:confirm-delete', { item: 'exam' }))

      if (delConfirmed) {
        dispatch(deleteExam(uuid))
      }
    },
    onClickDuplicate: (e, history) => {
      e.preventDefault()
      e.stopPropagation()
      const uuid = e.currentTarget.getAttribute('data-exam-uuid')
      dispatch(duplicateExam(uuid, history))
    },
    onClickResetFilters: () => {
      dispatch(resetAllFilters())
      dispatch(resetPaginations())
      dispatch(fetchExams())
    },
    onChangeFilterByExamType: e => {
      const subjects = getSubjectOptions(e.target.value)

      // Reset if current subject filter does not exists in chosen exam type
      if (subjects && !subjects.find(s => s.id === filters.subject)) {
        dispatch(resetSubjectFilter())
      }
      dispatch(filterExamsByExamType(e.target.value))
      dispatch(resetPaginations())
      dispatch(fetchExams())
    },
    onFetchExamsBySeriesId: id => {
      dispatch(filterExamsBySeriesId(id))
      dispatch(fetchExams())
    },
    onChangeFilterByLanguage: e => {
      dispatch(filterExamsByLanguage(e.target.value))
      dispatch(resetPaginations())
      dispatch(fetchExams())
    },
    onChangeFilterBySubject: e => {
      dispatch(filterExamsBySubject(e.target.value))
      dispatch(resetPaginations())
      dispatch(fetchExams())
    },
    onChangePublishStatus: (e, status) => {
      e.preventDefault()
      dispatch(filterExamsByPublishStatus(status))
      dispatch(resetPaginations())
      dispatch(fetchExams())
    },
    onChangeSort: (e, sortBy, sortDirection) => {
      e.preventDefault()
      dispatch(resetPaginations())
      dispatch(updateSortBy(sortBy))
      dispatch(updateSortDirection(sortDirection))
      dispatch(fetchExams())
    },
    onChangeLimit: e => {
      dispatch(updateLimit(e.target.value))
      dispatch(resetPaginations())
      dispatch(fetchExams())
    },
    onChangePage: data => {
      dispatch(updatePage(data.selected))
      dispatch(fetchExams())
    },
    clearValidations: () => {
      dispatch(clearValidations())
    },
  }
}

export default withTranslation(['ExamList'])(
  withRouter(connect(mapStateToProps, null, mergeProps)(ExamListContainer)),
)
