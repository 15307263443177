import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { isEmpty, omit } from 'ramda'
import { bindActionCreators } from 'redux'
import uuid from 'uuid'
import { flipToast } from '../../../redux/modules/toasts/toasts'
import { updateEditing } from '../../../redux/modules/editing/editing'
import { getParameters, getStatus } from '../../../redux/modules/editing/selectors'
import { createAndAddChild, copyNode } from '../../../redux/modules/node/actions.ts'
import { getChildNodeTypes, getNode } from '../../../redux/modules/nodes/selectors'
import { nodeTypes as NODETYPES } from '../../../constants/definitions/entities/entities.ts'
import SidebarHeader from '../Sidebar/SidebarHeader'
import './node-menu.scss'
import { createOptionWithId, addChoice } from '../../../redux/modules/options/options'
import { getCopiedQuestion, QUESTION_ID } from '../../../redux/modules/questions/questions'
import { createChoice } from '../../../redux/modules/choices/choices'
import NodeMenuItem from '../NodeMenuItem/NodeMenuItem'

// helpers
const copyQuestion = (nodeCopy, actions, options, order, parentId, choices, allowedNodeTypes) => {
  const compatible = allowedNodeTypes.map(({ id }) => id).includes(nodeCopy.nodeTypeId)
  if (!compatible) {
    const insertedNodeDisplayName = NODETYPES[nodeCopy.nodeTypeId]?.displayName || 'Unknown Type'
    actions.flipToast(
      uuid.v4(),
      'error',
      `Cannot insert question type "${insertedNodeDisplayName}"`,
    )
    // actions.
  } else {
    // Copy node and get the new id.
    const nodeId = actions.copyNode(parentId, {
      ...nodeCopy,
      order,
    })

    const nodeOptions = nodeCopy.options
      .map(optionId => options[`${nodeCopy.id}--${optionId}`])
      .filter(option => option !== undefined)

    // copy options with new id.
    nodeOptions &&
      nodeOptions.forEach(option => {
        const newOption = {
          ...omit(['id', 'parent'], option),
          choices: [],
        }
        actions.createOptionWithId(option.id, newOption, nodeId)
        // copy choices with new id
        option.choices &&
          option.choices.forEach(choice => {
            const choiceId = actions.createChoice(omit(['id'], choices[choice]))
            actions.addChoice(choiceId, option.id, nodeId)
          })
      })
    // Set the copied node to edit state.
    actions.updateEditing({
      status: 'edit',
      parameters: {
        nodeId,
      },
    })
  }
}

const NodeMenu = ({ render, nodeTypes, onClick, nodes, onClickPaste }) => {
  if (!render) {
    return null
  }
  // render question copy option only if questionId is copied to localStorage
  const items = nodeTypes.map(
    nodeType =>
      (nodeType.id !== NODETYPES.QUESTION_COPY.id ||
        (nodeType.id === NODETYPES.QUESTION_COPY.id && localStorage.getItem(QUESTION_ID))) && (
        <NodeMenuItem
          key={nodeType.id}
          nodeType={nodeType}
          onClick={
            nodeType.id === NODETYPES.QUESTION_COPY.id
              ? e => onClickPaste(e, nodes, nodeTypes)
              : onClick
          }
        />
      ),
  )

  return (
    <div className="yo-node-menu">
      <SidebarHeader title="Create content" />
      <div className="yo-node-menu__items">{items}</div>
    </div>
  )
}

const mapStateToProps = state => {
  // Get editing status and parameters for it.
  const editingStatus = getStatus(state)
  const editingParameters = getParameters(state)

  // Verify we have proper editing status.
  if (editingStatus !== 'add') {
    return {
      render: false,
    }
  }

  // Get parent node.
  const parentNode = getNode(state, editingParameters.parentId)
  const nodeType = NODETYPES[parentNode.nodeTypeId]

  // Get allowed child node types.
  const allowedNodeTypes = getChildNodeTypes(state, nodeType)

  return {
    render: true,
    editingStatus,
    editingParameters,
    nodeTypes: Object.keys(allowedNodeTypes).map(key => allowedNodeTypes[key]),
    nodes: state.entities.nodes,
    exam: state.exam,
    options: state.entities.options,
    choices: state.entities.choices,
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  if (!stateProps.render) {
    return {
      ...stateProps,
    }
  }

  const { dispatch } = dispatchProps
  const { order, parentId } = stateProps.editingParameters
  const actions = bindActionCreators(
    {
      createAndAddChild,
      updateEditing,
      copyNode,
      createOptionWithId,
      getCopiedQuestion,
      addChoice,
      createChoice,
      flipToast,
    },
    dispatch,
  )

  return {
    ...ownProps,
    ...stateProps,
    onClick: e => {
      // Create node and get the id.
      const nodeType = e.target.getAttribute('data-node-type-id')
      const nodeId = actions.createAndAddChild(parentId, {
        nodeTypeId: nodeType,
        order,
        settings: NODETYPES[nodeType].settingFields,
      })

      // Set the newly created to node to edit state.
      actions.updateEditing({
        status: 'edit',
        parameters: {
          nodeId,
        },
      })
    },
    onClickPaste: (e, nodes, nodeTypes) => {
      const nodeCopy = Object.assign({}, nodes[localStorage.getItem(QUESTION_ID)])
      // check if question exists in redux store
      if (!isEmpty(nodeCopy)) {
        copyQuestion(
          nodeCopy,
          actions,
          stateProps.options,
          order,
          parentId,
          stateProps.choices,
          nodeTypes,
        )
        localStorage.removeItem(QUESTION_ID)
      }
      // if not -> fetch it from api
      else {
        actions.getCopiedQuestion([localStorage.getItem(QUESTION_ID)]).then(data => {
          const nodeCopy = Object.assign(
            {},
            data.entities.nodes[Object.keys(data.entities.nodes)[0]],
          )
          const options = Object.assign({}, data.entities.options)
          const choices = Object.assign({}, data.entities.choices)

          // Lets make sure we have question to copy
          if (!isEmpty(nodeCopy)) {
            copyQuestion(nodeCopy, actions, options, order, parentId, choices, nodeTypes)
            // keep questionId in localStorage until question is fetched and copied
            // so nodeMenu doesn't change too early
            localStorage.removeItem(QUESTION_ID)
          }
        })
      }
    },
  }
}

export default withTranslation(['NodeMenu'])(connect(mapStateToProps, null, mergeProps)(NodeMenu))
