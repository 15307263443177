import { AnyAction } from 'redux'
import { ConfirmDialogStatus } from './ConfirmDialogManager'
import * as actions from './actions'

interface ConfirmDialogState {
  status: ConfirmDialogStatus
  message: string | null
}

const defaultState: ConfirmDialogState = {
  status: ConfirmDialogStatus.Hidden,
  message: null,
}

export default function reducer(state = defaultState, action: AnyAction) {
  switch (action.type) {
    case actions.SHOW:
      return { ...state, status: ConfirmDialogStatus.Shown, message: action.message }
    case actions.RESOLVE:
      return {
        ...state,
        status: action.accepted ? ConfirmDialogStatus.Accepted : ConfirmDialogStatus.Declined,
      }
    case actions.CLEAR:
      return { ...defaultState }
    default:
      return state
  }
}
