import React from 'react'
import defaultProps from './defaultProps'

const Subscript = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 1792 1792" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M1025 1369v167h-248l-159-252-24-42q-8-9-11-21h-3q-1 3-2.5 6.5t-3.5 8-3 6.5q-10 20-25 44l-155 250h-258v-167h128l197-291-185-272h-137v-168h276l139 228q2 4 23 42 8 9 11 21h3q3-9 11-21l25-42 140-228h257v168h-125l-184 267 204 296h109zm639 217v206h-514l-4-27q-3-45-3-46 0-64 26-117t65-86.5 84-65 84-54.5 65-54 26-64q0-38-29.5-62.5t-70.5-24.5q-51 0-97 39-14 11-36 38l-105-92q26-37 63-66 80-65 188-65 110 0 178 59.5t68 158.5q0 66-34.5 118.5t-84 86-99.5 62.5-87 63-41 73h232v-80h126z"
      fill={fill}
    />
  </svg>
)

Subscript.defaultProps = defaultProps

export default Subscript
