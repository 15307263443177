// Constants
export const ALL_EXAM_TYPES = 'all-exam-types'

// Actions
const CLEAR_EXAM_TYPE_FILTER = 'filters/exams/CLEAR_EXAM_TYPE'
const SET_EXAM_TYPE_FILTER = 'filters/exams/SET_EXAM_TYPE'

// Action creators
export const filterExamsByExamType = examType => ({
  type: SET_EXAM_TYPE_FILTER,
  examType,
})

export const resetExamTypeFilter = () => ({
  type: CLEAR_EXAM_TYPE_FILTER,
})

// Reducer
const defaultState = ALL_EXAM_TYPES

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_EXAM_TYPE_FILTER:
      return action.examType

    case CLEAR_EXAM_TYPE_FILTER:
      return defaultState

    default:
      return state
  }
}
