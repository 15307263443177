import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from '../Dropdown/Dropdown'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Toaster from '../Toaster/Toaster'
import Dialog from '../Dialog/Dialog'
import './layouts.scss'

const Page = ({ dropdown, header, main, sidebar, toaster, dialog }) => (
  <div className="yo-page">
    {header && <Header>{header}</Header>}
    <div className="yo-page__content">
      {sidebar && <Sidebar>{sidebar}</Sidebar>}
      <div className="yo-main">{main}</div>
    </div>
    <div className="yo-decorators">
      {toaster && <Toaster />}
      {dropdown && <Dropdown />}
      {dialog && <Dialog />}
    </div>
  </div>
)

Page.defaultProps = {
  dialog: null,
  dropdown: null,
  header: null,
  sidebar: null,
  toaster: null,
}

Page.propTypes = {
  dialog: PropTypes.element,
  dropdown: PropTypes.element,
  header: PropTypes.element,
  main: PropTypes.element.isRequired,
  sidebar: PropTypes.element,
  toaster: PropTypes.element,
}

export default Page
