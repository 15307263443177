import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import React from 'react'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  RangeSelection,
  REDO_COMMAND,
  UNDO_COMMAND,
} from 'lexical'
import { $wrapNodes } from '@lexical/selection'
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import { ListNodeTagType } from '@lexical/list/LexicalListNode'
import { i18n } from 'i18next'
import { $createHeadingNode, HeadingNode } from '@lexical/rich-text'
import { withTranslation } from 'react-i18next'
import {
  Bold,
  Image,
  Italic,
  Link,
  List,
  RotateCcw,
  RotateCw,
  Video,
} from '@yleisradio/yds-icons-react'
import { DropdownMenu } from '@yleisradio/yds-components-react'
import Icon from '../../Icon/Icon'
import {
  INIT_INSERT_SHORTCODE_COMMAND,
  INSERT_SHORTCODE_COMMAND,
  toShortcode,
} from './ShortcodePlugin'
import Button from '../../Button/Button'
import { InteractiveShortcodeName } from '../../../../constants/definitions/shortcodes'

export type ToolbarPluginOption = {
  attributes: { 'data-shortcode': InteractiveShortcodeName }
  id: number
  name: string
}

interface ToolbarPluginProps {
  t: i18n['t']
  options?: Array<ToolbarPluginOption>
}

const isSelectionList = (selection: RangeSelection): ListNodeTagType | false => {
  const node = selection.anchor.getNode()
  const parentList = $getNearestNodeOfType(node, ListNode)
  if (parentList) {
    return parentList.getTag()
  }
  return false
}

const isSelectionHeading = (selection: RangeSelection): boolean => {
  const node = selection.anchor.getNode()
  const parentHeading = $getNearestNodeOfType(node, HeadingNode)
  return Boolean(parentHeading)
}

const Divider = () => <div className="yo-toolbar-plugin__divider" />

const ToolbarPlugin = ({ t, options }: ToolbarPluginProps) => {
  const [editor] = useLexicalComposerContext()
  const [isBold, setIsBold] = React.useState(false)
  const [isItalic, setIsItalic] = React.useState(false)
  const [isHeading, setIsHeading] = React.useState(false)
  const [isList, setIsList] = React.useState<'ul' | 'ol' | false>(false)
  const [canUndo, setCanUndo] = React.useState<boolean>(false)
  const [canRedo, setCanRedo] = React.useState<boolean>(false)

  const updateToolbar = React.useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      setIsList(isSelectionList(selection))
      setIsHeading(isSelectionHeading(selection))
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
    }
  }, [editor])

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload: boolean) => {
          setCanUndo(payload)
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload: boolean) => {
          setCanRedo(payload)
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor, updateToolbar])

  const onToggleHeading = React.useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        if (isHeading) {
          $wrapNodes(selection, () => $createParagraphNode())
        } else {
          $wrapNodes(selection, () => $createHeadingNode('h3'))
        }
      }
    })
  }, [editor, isHeading])

  const commonButtonProps = {
    size: 'xs' as 'xs',
    variant: 'text' as 'text',
    classNames: ['yo-rich-text-editor__toolbar-button'],
    iconOnly: true,
  }

  return (
    <div className="yo-rich-text-editor__toolbar">
      <Button
        accessibleText={t('RichTextEditor:undo')}
        attributes={{ 'data-testid': 'rich-toolbar-button-undo' }}
        iconBefore={<RotateCcw className={!canUndo ? 'disabled' : undefined} />}
        isDisabled={!canUndo}
        onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
        title={t('RichTextEditor:undo')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:redo')}
        attributes={{ 'data-testid': 'rich-toolbar-button-redo' }}
        iconBefore={<RotateCw className={!canRedo ? 'disabled' : undefined} />}
        isDisabled={!canRedo}
        onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
        title={t('RichTextEditor:redo')}
        {...commonButtonProps}
      />
      <Divider />
      <Button
        accessibleText={t('RichTextEditor:bold')}
        attributes={{ 'data-testid': 'rich-toolbar-button-bold' }}
        iconBefore={<Bold className={isBold ? 'active' : undefined} />}
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
        title={t('RichTextEditor:bold')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:italic')}
        attributes={{ 'data-testid': 'rich-toolbar-button-italic' }}
        iconBefore={<Italic className={isItalic ? 'active' : undefined} />}
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
        title={t('RichTextEditor:italic')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:heading')}
        attributes={{ 'data-testid': 'rich-toolbar-button-heading' }}
        iconBefore={<Icon className={isHeading ? 'active' : undefined} icon="H3" />}
        onClick={onToggleHeading}
        title={t('RichTextEditor:heading')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:unorderedlist')}
        attributes={{ 'data-testid': 'rich-toolbar-button-unordered-list' }}
        iconBefore={<List className={isList === 'ul' ? 'active' : undefined} />}
        onClick={() =>
          isList === 'ul'
            ? editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
            : editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
        }
        title={t('RichTextEditor:unorderedlist')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:orderedlist')}
        attributes={{ 'data-testid': 'rich-toolbar-button-ordered-list' }}
        iconBefore={<Icon className={isList === 'ol' ? 'active' : undefined} icon="OrderedList" />}
        onClick={() =>
          isList === 'ol'
            ? editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
            : editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
        }
        title={t('RichTextEditor:orderedlist')}
        {...commonButtonProps}
      />
      <Divider />
      <Button
        accessibleText={t('RichTextEditor:superscript')}
        attributes={{ 'data-testid': 'rich-toolbar-button-superscript' }}
        iconBefore={<Icon icon="superscript" />}
        onClick={() =>
          editor.dispatchCommand(INIT_INSERT_SHORTCODE_COMMAND, {
            name: 'SUP',
            autoFillProperty: 'text',
          })
        }
        title={t('RichTextEditor:superscript')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:subscript')}
        attributes={{ 'data-testid': 'rich-toolbar-button-subscript' }}
        iconBefore={<Icon icon="subscript" />}
        onClick={() =>
          editor.dispatchCommand(INIT_INSERT_SHORTCODE_COMMAND, {
            name: 'SUB',
            autoFillProperty: 'text',
          })
        }
        title={t('RichTextEditor:subscript')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:image')}
        attributes={{ 'data-testid': 'rich-toolbar-button-image' }}
        iconBefore={<Image />}
        onClick={() =>
          editor.dispatchCommand(INIT_INSERT_SHORTCODE_COMMAND, {
            name: 'IMS',
            autoFillProperty: 'text',
          })
        }
        title={t('RichTextEditor:image')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:areena')}
        attributes={{ 'data-testid': 'rich-toolbar-button-clip' }}
        iconBefore={<Video />}
        onClick={() => editor.dispatchCommand(INIT_INSERT_SHORTCODE_COMMAND, { name: 'CLIP' })}
        title={t('RichTextEditor:areena')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:link')}
        attributes={{ 'data-testid': 'rich-toolbar-button-link' }}
        iconBefore={<Link />}
        onClick={() =>
          editor.dispatchCommand(INIT_INSERT_SHORTCODE_COMMAND, {
            name: 'LINK',
            autoFillProperty: 'text',
          })
        }
        title={t('RichTextEditor:link')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:source')}
        attributes={{ 'data-testid': 'rich-toolbar-button-source' }}
        iconBefore={<Icon icon="quote" />}
        onClick={() =>
          editor.dispatchCommand(INIT_INSERT_SHORTCODE_COMMAND, {
            name: 'CITE',
            autoFillProperty: 'source',
          })
        }
        title={t('RichTextEditor:source')}
        {...commonButtonProps}
      />
      <Button
        accessibleText={t('RichTextEditor:math')}
        attributes={{ 'data-testid': 'rich-toolbar-button-math' }}
        iconBefore={<Icon icon="equation" />}
        onClick={() => editor.dispatchCommand(INIT_INSERT_SHORTCODE_COMMAND, { name: 'MATH' })}
        title={t('RichTextEditor:math')}
        {...commonButtonProps}
      />
      {options && options.length > 0 && (
        <DropdownMenu
          id={options[0].name}
          menuId={`${options[0].name}-menu`}
          onChange={o =>
            editor.dispatchCommand(INSERT_SHORTCODE_COMMAND, {
              text: toShortcode('id', o.id, o.attributes?.['data-shortcode']),
              name: o.attributes?.['data-shortcode'],
            })
          }
          options={options.map(o => ({ text: o.name, value: o }))}
          placeholder="Option"
          value={undefined}
        />
      )}
    </div>
  )
}

export default withTranslation()(ToolbarPlugin)
