import React from 'react'
import { add, evolve, is, prop } from 'ramda'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import CharactersReview from './CharactersReview'
import ExamReview from './ExamReview'
import VoteReview from './VoteReview'
import ReviewOnlyReview from './ReviewOnlyReview'
import {
  ARCHTYPE_TEST,
  VOTE,
  POLL,
  REVIEW_ONLY_EXAM_TYPE,
} from '../../../constants/definitions/exams'
import { getExamId } from '../../../redux/modules/nodes/selectors'
import {
  getIsExamCompleted,
  getExamType,
  getExamState,
} from '../../../redux/modules/exam/selectors'
import { getCharacterReviewWithMostPoints } from '../../../redux/modules/characters/selectors'

interface Props {
  isCarousel?: boolean
  examType: string
  isCompleted: boolean
  review: any
  score: number
  maxScore: number
  exam: any
  examId: any
}

const ReviewComponent = ({ examType, ...props }) => {
  switch (examType) {
    case ARCHTYPE_TEST:
      return <CharactersReview {...props} />

    case VOTE:
      return <VoteReview {...props} />

    case POLL:
      return null

    case REVIEW_ONLY_EXAM_TYPE:
      return <ReviewOnlyReview {...props} />

    default:
      return <ExamReview {...props} />
  }
}

const generateReview = (reviews, score) =>
  reviews.find(x => x.minScore <= score && x.maxScore >= score)

const getReview = ({ examType, state, score }) => {
  const { reviews } = state.entities
  switch (examType) {
    case ARCHTYPE_TEST:
      return getCharacterReviewWithMostPoints(state)

    default:
      return generateReview(reviews, score)
  }
}

const ExamReviewContainer: React.FC<Props> = ({ isCompleted, ...rest }: Props) => {
  return (
    <div data-testid="exam-review-container"> {isCompleted && <ReviewComponent {...rest} />} </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { nodes } = state.entities
  const { score, maxScore } = Object.keys(nodes).reduce(
    (prev, curr) => {
      const node = nodes[curr]

      if (!node) {
        return prev
      }

      // Get value from node by key.
      const getValue = (node, key) => {
        const value = prop(key, node)

        // Get value which is a Number and not NaN, otherwise fallback to 0.
        return is(Number, value) && !Number.isNaN(value) ? value : 0
      }

      const { exclude, visible } = node
      const score = getValue(node, 'score')
      const maxScore = getValue(node, 'maxScore')

      // Skip scoring for the node.
      if (exclude === true || !visible) {
        return prev
      }

      return evolve(
        {
          score: add(score),
          maxScore: add(maxScore),
        },
        prev,
      )
    },
    { score: 0, maxScore: 0 },
  )

  const examType = getExamType(state)
  const review = getReview({ examType, state, score })

  return {
    isCompleted: getIsExamCompleted(state),
    examType,
    review,
    score,
    maxScore,
    exam: getExamState(state),
    examId: getExamId(state),
    ...ownProps,
  }
}

export default withTranslation()(connect(mapStateToProps)(ExamReviewContainer))
