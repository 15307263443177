import React from 'react'
import { connect } from 'react-redux'
import { updateEditing } from '../../../redux/modules/editing/editing'
import './node-placeholder.scss'

const NodePlaceholder = ({ active, onClick, attributes }) => {
  const text = active ? 'Select content type' : 'Add content'

  return (
    <div className="yo-node-placeholder" onClick={onClick} role="button" tabIndex="0">
      <span {...attributes} className="yo-node-placeholder__title">
        {text}
      </span>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  let active = false
  const { status } = state.editing

  if (status === 'add' && typeof state.editing.parameters !== 'undefined') {
    const { parentId, order } = state.editing.parameters

    // Whether the currect node placeholder is active (has been clicked)
    // and related content type selection is show in sidebar.
    active = parentId === ownProps.parentId && order === ownProps.order
  }
  return {
    active,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(
      updateEditing({
        status: 'add',
        parameters: {
          parentId: ownProps.parentId,
          order: ownProps.order,
        },
      }),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NodePlaceholder)
