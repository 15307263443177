import uuid from 'uuid'
import { GET, POST, apiURL, parseJSON } from '../dataService/apiV1'
import {
  setSeriesLoading,
  clearSeriesLoading,
  receiveSeries,
  receiveSeriesData,
  seriesStored,
} from '../../modules/series/series'
import { serializeSeriesFromApi, serializeSeriesForApi } from '../dataService/stateSerializer'
import { handleLoadError } from '../examService/examService'
import { flipToast } from '../../modules/toasts/toasts'

const FETCH_SERIES_LIST = 'api/FETCH_SERIES_LIST'
const FETCH_SERIES_DATA = 'api/FETCH_SERIES_DATA'
const STORE_SERIES_TO_API = 'api/STORE_SERIES_TO_API'

export const fetchSeriesData = (uuids, isLoggedIn) => {
  const url = isLoggedIn
    ? `/answers/series?series_uuids=${uuids.join(',')}&limit=10`
    : `/public/series?series_uuids=${uuids.join(',')}`
  return {
    type: FETCH_SERIES_DATA,
    url,
  }
}

export const fetchSeriesList = () => ({
  type: FETCH_SERIES_LIST,
})

export const postSeries = series => ({
  type: STORE_SERIES_TO_API,
  series,
})

const seriesService = () => next => action => {
  next(action)

  switch (action.type) {
    case FETCH_SERIES_LIST:
      next(setSeriesLoading())
      return GET('/series')
        .then(parseJSON)
        .then(body => {
          next(receiveSeries(body.data))
          next(clearSeriesLoading())
        })
        .catch(err => {
          next(clearSeriesLoading())
          handleLoadError(err)
        })

    case FETCH_SERIES_DATA:
      return fetch(apiURL(action.url), {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'include',
        method: 'GET',
      })
        .then(parseJSON)
        .then(body => {
          next(receiveSeriesData(serializeSeriesFromApi(body.data)))
          next(clearSeriesLoading())
        })
        .catch(handleLoadError)

    case STORE_SERIES_TO_API:
      next(setSeriesLoading())
      return POST('/series', JSON.stringify(serializeSeriesForApi(action.series)))
        .then(parseJSON)
        .then(() => {
          next(flipToast(uuid.v4(), 'success', 'Save succesful'))
          next(clearSeriesLoading())
          next(seriesStored())
        })

    default:
      break
  }
  return null
}

export default seriesService
