import React from 'react'
import defaultProps from './defaultProps'

const Check = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 1200 1200" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M1004.237 99.153l-611.44 611.44L194.492 512.29 0 706.778l198.305 198.307 195.762 195.763L588.56 906.355 1200 294.915 1004.237 99.154z"
      fill={fill}
    />
  </svg>
)

Check.defaultProps = defaultProps

export default Check
