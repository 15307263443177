import React from 'react'
import defaultProps from './defaultProps'

const TriangleDown = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path className="yo-icon-svg-path" d="M5,6h10l-5,9L5,6z" fill={fill} />
  </svg>
)

TriangleDown.defaultProps = defaultProps

export default TriangleDown
