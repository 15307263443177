import React from 'react'
import { withTranslation } from 'react-i18next'
import Autocomplete from 'react-autocomplete'
import { connect } from 'react-redux'
import { uniqBy } from 'ramda'
import { fromEvent } from 'rxjs'
import { debounceTime, map, filter } from 'rxjs/operators'
import classnames from 'classnames'
import { Loader } from '../Loader/Loader'
import { addTag } from '../../../redux/modules/node/actions.ts'
import { addLocalTag } from '../../../redux/modules/defaultCarousel/defaultCarousel'
import './autocomplete.scss'
import { apiURL } from '../../../redux/middleware/dataService/apiV1'
import config from '../../../config'
import { getExamLanguage } from '../../../redux/modules/exam/selectors'

class AutocompleteInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      loading: false,
      value: '',
    }
  }

  componentDidMount() {
    const { metaApi, localMetaApi } = this.props
    const inputField = document.getElementById('yo-autocomplete-input')

    // Observe changes in inputField
    fromEvent(inputField, 'input')
      // Only search when input value is two or more characters long
      .pipe(filter(event => event.target.value.length > 1))
      .pipe(map(event => event.target.value))
      // Only emit input value every 500 ms
      .pipe(debounceTime(500))
      // Call the appropriate API when new value is emitted
      .subscribe(value => {
        if (metaApi) {
          this.getMetadata(value)
        } else if (localMetaApi) {
          this.getLocalMetadata(value)
        }
      })
  }

  getMetadata(value) {
    const { language } = this.props
    const { meta_api_app_id: apiId, meta_api_app_key: apiKey, meta_api_url: metaApiUrl } = config

    // Use the first two characters of the language code
    const apiLang = language.substr(0, 2)
    const apiQuery = encodeURIComponent(value)
    const apiUrl = `${metaApiUrl}concept_search.json?q=${apiQuery}&lang=${apiLang}&app_key=${apiKey}&app_id=${apiId}`

    if (undefined !== apiQuery) {
      fetch(apiUrl)
        .then(res => {
          if (res.ok) {
            return res.json()
          }
          this.setState({ loading: false })
          throw new Error('Meta-API call failed')
        })
        .then(res => {
          // Only allow results that have an id and whose exactMatch contains a valid resource.
          const filtered = res.data.filter(
            item => item.id && item.exactMatch.some(element => element.match(/^(finto|wikidata)/)),
          )
          // Only return euro top 100 hits
          const topHits = filtered.slice(0, 100)
          this.setState({ data: topHits, loading: false })
        })
    }
  }

  getLocalMetadata(value) {
    const { language } = this.props
    const lang = language.substr(0, 2)
    fetch(apiURL(`/public/tags.json?title=${value}&lang=${lang}`))
      .then(res => {
        if (res.ok) {
          return res.json()
        }
        this.setState({ loading: false })
        throw new Error('Failed to load local meta api tags')
      })
      .then(res => {
        const filteredTags = res.data.map(tag => {
          // LOGIC FOR ADDING ALTERNATIVE IDS
          const alternativeIds = []

          // Search for duplicate title only from that element forward
          for (let index = res.data.indexOf(tag) + 1; index < res.data.length; index++) {
            const element = res.data[index]
            if (tag.title[lang] === element.title[lang]) {
              alternativeIds.push(element.tag_id)
            }
          }

          return {
            id: tag.tag_id,
            title: tag.title[lang],
            alternativeIds,
            analyticsTitle: tag.title.fi,
          }
        })

        // Only keep the first duplicate tag
        this.setState({
          data: uniqBy(x => x.title, filteredTags),
          loading: false,
        })
      })
  }

  handleOnChange = (event, value) => {
    value === ''
      ? this.setState({ value, loading: false })
      : this.setState({ value, loading: true })
  }

  handleOnKeyPress = e => {
    const { data } = this.state
    if (e.key === 'Enter' && data.length > 0) {
      this.handleOnSelect(data[0].id, data[0])
    }
  }

  handleOnSelect = (value, item) => {
    const { metaApi, addTag, addLocalTag } = this.props

    if (metaApi) {
      addTag(value)
    } else {
      addLocalTag(item)
    }
    this.setState({ data: [], value: '' })
  }

  renderItem(item, isHighlighted) {
    const { loading } = this.state
    const itemClassNames = classnames('yo-autocomplete-item', {
      'yo-autocomplete-item--highlighted': isHighlighted,
    })

    const itemHitsClassNames = classnames('yo-autocomplete-item__hits', {
      'yo-autocomplete-item__hits--highlighted': isHighlighted,
    })

    return (
      <div className={itemClassNames} id={item.id} key={item.id} title={item.disambiguationHint}>
        <div className="yo-autocomplete-item__row">
          <span className={itemHitsClassNames}>{item.contentHits}</span>
          <span className="yo-autocomplete-item__title">{item.title}</span>
        </div>
        {isHighlighted && !loading && (
          <div className="yo-autocomplete-item__description">{item.disambiguationHint}</div>
        )}
      </div>
    )
  }

  renderMenuItems(items, t) {
    const { loading, value } = this.state
    // When the input field (=the query) is empty, we don't want to display anything,
    // regardless of `this.state.loading`. Thus, let's create an "empty" variable.
    let menuItems

    // Let's check if we actually need to display something...
    if (!loading && value.length > 0) {
      // We are not waiting for Meta-API and a search has indeed been conducted.
      // Also, the input field has not been completely emptied, so we want to display the results.
      // Let's display all returned items.
      menuItems = items
    } else if (loading && value.length > 1) {
      // Waiting for results from Meta-api. Meta-api requires the search string to be two or more
      // characters long, so we will display our loading animation only then.
      // Thus, instead of items, let's display a loading bar!
      menuItems = (
        <div>
          <Loader />
          <div className="yo-autocomplete-item">
            {t('loading')}
            ...
          </div>
        </div>
      )
    } else if (value.length === 1) {
      // If a single character has been typed, display an element telling that at least two
      // characters are needed for the search to begin.
      menuItems = <div className="yo-autocomplete-item">{t('min-chars')}</div>
    }

    const menuClassNames = classnames('yo-autocomplete-menu', {
      'yo-autocomplete-menu--hidden': value.length < 1,
    })

    // Return the correct items nicely wrapped inside a div
    return <div className={menuClassNames}>{menuItems}</div>
  }

  render() {
    const { label, t } = this.props
    const { data, value } = this.state
    return (
      <div>
        <label className="yo-input__label" htmlFor="autocomplete">
          {label}
          <Autocomplete
            data-testid="yo-autocomplete-input"
            getItemValue={item => item.id}
            inputProps={{
              className: 'yo-input__input yo-input__input--text',
              id: 'yo-autocomplete-input',
              onKeyPress: this.handleOnKeyPress,
              placeholder: t('search-for-tags'),
            }}
            items={data}
            onChange={(event, value) => this.handleOnChange(event, value)}
            onSelect={(value, item) => this.handleOnSelect(value, item)}
            renderItem={(item, isHighlighted) => this.renderItem(item, isHighlighted)}
            renderMenu={items => this.renderMenuItems(items, t)}
            value={value}
            wrapperProps={{
              className: 'yo-autocomplete',
              style: null,
            }}
          />
        </label>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: getExamLanguage(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addTag(tag) {
    dispatch(addTag(ownProps.nodeId, tag))
  },
  addLocalTag(tag) {
    dispatch(addLocalTag(tag))
  },
})

export default withTranslation(['Autocomplete'])(
  connect(mapStateToProps, mapDispatchToProps)(AutocompleteInput),
)
