// Constants
export const PUBLISHED = 'published'
export const UNPUBLISHED = 'unpublished'

// Actions
const CLEAR_PUBLISH_STATUS_FILTER = 'filters/exams/CLEAR_PUBLISH_STATUS'
const SET_PUBLISH_STATUS_FILTER = 'filters/exams/SET_PUBLISH_STATUS'

// Action creators
export const filterExamsByPublishStatus = publishStatus => ({
  type: SET_PUBLISH_STATUS_FILTER,
  publishStatus,
})

export const resetPublishStatusFilter = () => ({
  type: CLEAR_PUBLISH_STATUS_FILTER,
})

// Reducer
const defaultState = UNPUBLISHED

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PUBLISH_STATUS_FILTER:
      return action.publishStatus

    case CLEAR_PUBLISH_STATUS_FILTER:
      return defaultState

    default:
      return state
  }
}
