// Actions.
const UPDATE_LIMIT = 'paginations/UPDATE_LIMIT'
const UPDATE_PAGE = 'paginations/UPDATE_PAGE'
const UPDATE_SORT_BY = 'paginations/UPDATE_SORT_BY'
const UPDATE_SORT_DIRECTION = 'paginations/UPDATE_SORT_DIRECTION'
const UPDATE_COUNT_TOTAL = 'paginations/UPDATE_COUNT_TOTAL'
const RESET_PAGINATIONS = 'paginations/RESET_PAGINATIONS'

const DEFAULT_LIMIT = 10
const DEFAULT_PAGE = 0
const DEFAULT_SORT_BY = 'updated_at'
const DEFAULT_SORT_DIRECTION = 'desc'
export const LIMIT_ARRAY = [DEFAULT_LIMIT, 30, 50, 100]

const defaultState = {
  limit: DEFAULT_LIMIT,
  page: DEFAULT_PAGE,
  sortBy: DEFAULT_SORT_BY,
  sortDirection: DEFAULT_SORT_DIRECTION,
}

// Reducer.
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_PAGE:
      return { ...state, page: action.page }

    case UPDATE_LIMIT:
      return { ...state, limit: action.limit }

    case UPDATE_SORT_BY:
      return { ...state, sortBy: action.sortBy }

    case UPDATE_SORT_DIRECTION:
      return { ...state, sortDirection: action.sortDirection }

    case UPDATE_COUNT_TOTAL:
      return { ...state, countTotal: action.countTotal }

    case RESET_PAGINATIONS:
      return {
        ...state,
        page: DEFAULT_PAGE,
        sortBy: DEFAULT_SORT_BY,
        sortDirection: DEFAULT_SORT_DIRECTION,
      }

    default:
      return state
  }
}

// Action creators.
export function updateTotalCount(countTotal) {
  return {
    type: UPDATE_COUNT_TOTAL,
    countTotal,
  }
}
export function updatePage(page) {
  return {
    type: UPDATE_PAGE,
    page,
  }
}

export function updateLimit(limit) {
  return {
    type: UPDATE_LIMIT,
    limit,
  }
}

export function updateSortBy(sortBy) {
  return {
    type: UPDATE_SORT_BY,
    sortBy,
  }
}

export function resetPaginations() {
  return {
    type: RESET_PAGINATIONS,
  }
}

export function updateSortDirection(sortDirection) {
  return {
    type: UPDATE_SORT_DIRECTION,
    sortDirection,
  }
}
