import React from 'react'
import './exam-review.scss'
import Notification from '../../elements/Notification/Notification.tsx'
import { getShareUrl } from '../../layout/ShareReview/ShareReviewHelper'
import ShareReview from '../../layout/ShareReview/ShareReview'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'

const ReviewOnlyReview = ({ review, exam, examId }) => {
  const url = review ? getShareUrl(review.share, examId) : null
  const displayShare = exam.enableShare && url && review.share
  const hasReview = review && review.description

  return (
    <div className="yo-exam-review" data-testid="review-only-review">
      {hasReview && (
        <Notification>
          <div className="yo-node-review-notification-content">
            <RichTextContent content={review.description} />
          </div>
        </Notification>
      )}

      {displayShare && <ShareReview review={review} url={url} />}
    </div>
  )
}

export default ReviewOnlyReview
