import React from 'react'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'
import AutocompleteInput from './AutocompleteInput'
import Input from './Input'
import RichTextEditor from '../RichTextEditor/RichTextEditor.tsx'
import Tags from '../Tags/Tags'
import './input.scss'

// Component for rendering extended versions of inputs (autocomplete and rich-text).
// Will fallback to rendering normal inputs.
const InputExtended = props => {
  let component

  switch (props.type) {
    case 'autocomplete-meta-api':
      component = (
        <div>
          <AutocompleteInput {...props} metaApi />
          <Tags {...props} />
        </div>
      )
      break

    case 'autocomplete-local-meta-api':
      component = (
        <div>
          <AutocompleteInput {...props} localMetaApi />
          <Tags {...props} localMetaApi />
        </div>
      )
      break

    case 'rich-text':
      component = <RichText {...props} />
      break

    // Fallback to rendering a normal input.
    default:
      return <Input {...props} />
  }

  return (
    <div
      className={classNames('yo-input', {
        'yo-input--separator': props.isSeparator === true,
      })}
    >
      {component}
    </div>
  )
}

const RichText = ({ defaultValue, label, onChange, options, autofocus, attributes }) => (
  <div className="yo-input__label">
    {label}
    <RichTextEditor initialValue={defaultValue} onChange={onChange} options={options} />
  </div>
)

export default withTranslation()(InputExtended)
