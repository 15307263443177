import { prop, reject, sortBy, both, is, complement, equals } from 'ramda'

// Sorts an array of objects using the value of `name`
export const sortByName = sortBy(prop('name'))

export const flattenArrays = array => Array.prototype.concat(...array)

/** Get random item from list */
export const sample = array => array[Math.floor(Math.random() * array.length)]

export const rejectChildren = questions => {
  // An array that will hold the IDs of all child questions
  const childIds = []

  // Push all child IDs from the questions to `childIds`
  questions.map(question =>
    question.child_ids ? question.child_ids.map(childId => childIds.push(childId)) : null,
  )

  // Decide if a question is a child of some other node
  const isChild = node => childIds.includes(node.uuid)

  // Finally, return the valid questions by rejecting all child nodes from `questions`.
  return Object.values(reject(isChild, questions))
}

export const removeFalsy = array => array.filter(item => Boolean(item))

export const scrollToHash = () => {
  const currentLocation = window.location.hash.slice(1)
  const currentHash =
    currentLocation.lastIndexOf('#') !== -1
      ? currentLocation.slice(currentLocation.lastIndexOf('#'))
      : ''

  if (currentHash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = currentHash.slice(1)
      const element = document.getElementById(id)

      if (element) {
        element.scrollIntoView()
      }
    }, 0)
  }
}

export const scrollToView = (
  elementId,
  filters = { behavior: 'smooth', block: 'start', inline: 'start' },
) => {
  const el = document.getElementById(elementId)
  // let's make sure we have element to scroll to
  if (el && typeof el.scrollIntoView === 'function') {
    // Give time for DOM to render before trying to scroll into view
    setTimeout(() => {
      el.scrollIntoView(filters)
    }, 0)
  }
}

export const strContainsImage = str => str && str.includes('[IMS id=')

export const copyToClipboard = text => {
  let clipboardEl = document.getElementById('tehtavaClipboardEl')
  // Create element for copy if it doesn't exist
  if (!clipboardEl) {
    clipboardEl = document.createElement('textarea')
    clipboardEl.style.fontSize = '12pt' // Prevent zooming on iOS
    clipboardEl.setAttribute('tabindex', '0') // so it can be focused
    // hide element
    clipboardEl.style.position = 'fixed'
    clipboardEl.style.pointerEvents = 'none'
    clipboardEl.style.opacity = 0
    clipboardEl.id = 'tehtavaClipboardEl'
  }
  clipboardEl.value = text
  document.body.appendChild(clipboardEl)
  clipboardEl.select()
  // Finally copy text to clipboard
  document.execCommand('copy')
  return clipboardEl
}

export const isInEditor = location =>
  IS_EMBED
    ? false
    : location?.pathname?.endsWith('editor') || window.location.href.endsWith('editor')

export const isInPreview = location =>
  IS_EMBED
    ? false
    : location?.pathname?.endsWith('preview') || window.location.href.endsWith('preview')

export const isEditing = location => isInEditor(location) || isInPreview(location)

export const isValidNumber = both(is(Number), complement(equals(NaN)))
export const hasContent = array => array && array.length > 0
export const getOptionIdByNodeId = nodeId => {
  // sessionStorage.getItem() can throw Security Error for example
  // if user has cookies disabled (#1033)
  try {
    return sessionStorage.getItem(`tehtava_poll_${nodeId}`)
  } catch (_) {
    return undefined
  }
}
export const isCookiesEnabled = () => navigator.cookieEnabled
export const formatDate = d => d.toLocaleString('sv')
