import React from 'react'
import classnames from 'classnames'
import './separator.scss'

const Separator = ({ className }) => {
  const classNames = classnames('yo-separator', className)
  return <hr className={classNames} />
}

export default Separator
