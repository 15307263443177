import React from 'react'
import defaultProps from './defaultProps'

const Swap = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M14,5H4V3L0,6.5L4,10V8h10V5z M20,13.5L16,10v2H6v3h10v2L20,13.5z"
      fill={fill}
    />
  </svg>
)

Swap.defaultProps = defaultProps

export default Swap
