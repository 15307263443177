import React from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import AnswerValidator from '../../node/AnswerValidator/AnswerValidator.tsx'
import './series-exams.scss'

const SeriesScore = ({ exam, title, showScore }) => {
  return (
    <div
      className="yo-exams__item yo-exams__item--score yo-exams__scores"
      data-column-title={title}
    >
      {showScore ? `${exam.scores?.score || '-'} / ${exam.scores.max_score}` : null}
    </div>
  )
}

const SeriesExams = ({ exams, onClickExam, title, headers, t, seriesClassification = {} }) => {
  if (!exams || exams.length < 1) {
    return null
  }

  const { hideStatistics } = seriesClassification

  const items = exams.map(exam => {
    const hasScoresAndAnswers =
      exam.scores && exam.scores.count_answers && exam.scores.count_exam_questions

    const examCompleted =
      exam.scores && exam.scores.count_answers === exam.scores.count_exam_questions
    const percentage = hasScoresAndAnswers
      ? Math.round((exam.scores.count_answers / exam.scores.count_exam_questions) * 100)
      : null
    // Show percentages if exam has answers, unless user has answered all questions
    // Then show check mark
    const percentageContent = hasScoresAndAnswers ? (
      <div>
        {examCompleted && <AnswerValidator correct />}
        {!examCompleted && <span>{percentage} %</span>}
      </div>
    ) : null
    // Show scores when exam has score and max_score
    // Otherwise add whitespace to keep columns correctly aligned
    const showScore = exam.scores && exam.scores.score !== null && exam.scores.max_score !== null

    return (
      <div className="yo-exams__row" key={exam.uuid}>
        <div
          className="yo-exams__item yo-exams__item--name yo-exams__link-text"
          data-column-title={t('name')}
          onClick={() => onClickExam(exam)}
          role="button"
          tabIndex="0"
        >
          {exam.name}
        </div>
        {!hideStatistics && <SeriesScore exam={exam} showScore={showScore} title={t('score')} />}
        <div
          className="yo-exams__item yo-exams__item--progress yo-exams__scores"
          data-column-title={t('done')}
        >
          {percentageContent}
        </div>
      </div>
    )
  })

  const headersColumns = headers
    .filter(header => {
      return hideStatistics && header.id === 'score' ? null : header
    })
    .filter(Boolean)
    .map(header => {
      const classes = classnames('yo-exams__header-item', header.class)
      return (
        <div className={classes} key={header.id}>
          {header.name}
        </div>
      )
    })

  return (
    <div className="yo-exams">
      <div className="yo-exams__title">{title}</div>
      <div className="yo-exams__header">{headersColumns}</div>
      <div className="yo-exams__items">{items}</div>
    </div>
  )
}

SeriesExams.defaultProps = {
  headers: [],
}
export default withTranslation(['Series'])(SeriesExams)
