// Actions.
const SET_ROOT_ID = 'embed/SET_ROOT_ID'
const SET_EMBED_OPTIONS = 'embed/SET_EMBED_OPTIONS'
const SET_ASSIGNMENT = 'embed/SET_ASSIGNMENT'

const initialState = {
  isAssignment: false,
  embedOptions: {},
}

// Reducer.
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ROOT_ID:
      return { ...state, rootElementId: action.rootElementId }

    case SET_EMBED_OPTIONS:
      return { ...state, embedOptions: action.embedOptions }

    case SET_ASSIGNMENT:
      return { ...state, isAssignment: true }

    default:
      return state
  }
}

// Action creators.
export const setRootElementId = rootElementId => ({
  type: SET_ROOT_ID,
  rootElementId,
})

export const setEmbedOptions = embedOptions => ({
  type: SET_EMBED_OPTIONS,
  embedOptions,
})

export const setAssignment = () => ({
  type: SET_ASSIGNMENT,
})
