import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { deleteChoice, updateChoice } from '../../../redux/modules/choices/choices'
import { removeChoice } from '../../../redux/modules/options/options'
import { IconButtons, IconButton } from '../IconButtons/IconButtons'
import IconToggle from '../IconToggle/IconToggle'
import './choice-input.scss'

const ChoiceInput = ({ choice, onChange, onClickCorrect, onClickDelete, nodeType }) => (
  <div className="yo-choice-input">
    <input
      className="yo-choice-input__text"
      data-testid="input-option-choice"
      defaultValue={choice.text}
      onChange={onChange}
    />
    <IconButtons>
      {nodeType.scoring && (
        <IconToggle
          attributes={{ 'data-testid': 'button-choice-correct' }}
          checked={choice.correct === true || false}
          handleChange={onClickCorrect}
          icon="check"
        />
      )}
      <IconButton icon="delete" key="delete" onClick={onClickDelete} />
    </IconButtons>
  </div>
)

ChoiceInput.propTypes = {
  nodeType: PropTypes.shape({
    scoring: PropTypes.bool,
  }).isRequired,
  // Choice entity.
  choice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string,
    correct: PropTypes.bool,
  }).isRequired,
  // Handler for changing choice text.
  onChange: PropTypes.func.isRequired,
  // Handler for toggling the choice to be correct.
  onClickCorrect: PropTypes.func.isRequired,
  // Handler for deleting the choice.
  onClickDelete: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const actions = bindActionCreators(
    {
      removeChoice,
      deleteChoice,
      updateChoice,
    },
    dispatch,
  )
  const { choice, option } = ownProps

  return {
    onChange: e => {
      // Update the desired field in the option.
      actions.updateChoice(choice.id, { text: e.target.value.trim() })
    },
    onClickDelete: () => {
      // Display a confirmation dialog for the user before deleting
      const delConfirmed = window.confirm(ownProps.t('common:confirm-delete', { item: 'choice' }))

      if (delConfirmed) {
        // Remove the choice from option.
        actions.removeChoice(choice.id, option.id, option.parent)

        // Delete choice entity.
        actions.deleteChoice(choice.id)
      }
    },
    onClickCorrect: checked => {
      // Update the desired field in the option.
      actions.updateChoice(choice.id, { correct: checked })
    },
  }
}

export default withTranslation()(connect(null, mapDispatchToProps)(ChoiceInput))
