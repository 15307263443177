import React, { useState } from 'react'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import Icon from '../../elements/Icon/Icon'
import './accordion.scss'
import './accordion-item.scss'
import { KEYCODE_ENTER, KEYCODE_SPACE } from '../../../constants/definitions/keycodes'

export const Accordion = ({ children, id, title }) => (
  <div className="yo-accordion" id={id}>
    {title && <div className="yo-accordion__title">{title}</div>}
    {children && <div className="yo-accordion__items">{children}</div>}
  </div>
)

const convertTime = time => (time.length === 1 ? `0${time}` : time)

export const AccordionItem = withTranslation(['Accordion'])(
  ({
    id,
    isHighlighted,
    isInvalid,
    onExpand,
    isInitiallyExpanded,
    title,
    description,
    children,
    suffix,
    settings,
    t,
  }) => {
    const [isExpanded, setIsExpanded] = useState(Boolean(isInitiallyExpanded) || false)

    const handleClick = () => {
      setIsExpanded(!isExpanded)
      // call if parent has onExpand() function passed to props
      // e.g. NodeEditabe will store the collapsed nodes to state
      if (onExpand) {
        onExpand()
      }
    }

    const handleKeyDown = e => {
      const isSelfPressed = e.target === e.currentTarget
      const isSpaceOrEnter = e.keyCode === KEYCODE_SPACE || e.keyCode === KEYCODE_ENTER
      if (isSelfPressed && isSpaceOrEnter) {
        e.preventDefault()
        handleClick()
      }
    }

    const wrapperClassName = classnames(
      'yo-accordion-item',
      { 'yo-accordion-item--expanded': isExpanded },
      { 'yo-accordion-item--highlighted': isHighlighted },
      { 'yo-accordion-item--invalid': isInvalid },
    )

    const headerClassName = classnames('yo-accordion-item__header', {
      'yo-accordion-item__header--expanded': isExpanded,
    })

    const accordionToggleIcon = isExpanded ? <Icon icon="minimize" /> : <Icon icon="maximize" />

    const timestamps =
      settings &&
      settings.timestamps &&
      settings.timestamps.map(ts => `${convertTime(ts.min)}:${convertTime(ts.sec)}`).join(', ')

    return (
      <div className={wrapperClassName} id={id}>
        <div
          aria-pressed={isExpanded}
          className={headerClassName}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex="0"
        >
          <div className="yo-accordion-item__prefix">
            <div className="yo-accordion-item__toggle">{accordionToggleIcon}</div>
          </div>
          <div className="yo-accordion-item__title">
            {title}
            {Boolean(timestamps) && (
              <span className="yo-accordion-item__description">
                {timestamps} {`(${settings.duration || '0'}s)`}
              </span>
            )}
            {!isExpanded && description !== undefined && (
              <span className="yo-accordion-item__description">{description}</span>
            )}
          </div>
          <div className="yo-accordion-item__suffix">{suffix}</div>
        </div>
        {isExpanded && <div className="yo-accordion__content">{children}</div>}
      </div>
    )
  },
)
