import React from 'react'
import defaultProps from './defaultProps'

const YleTunnusLogin = ({ width, height }) => (
  <svg height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path d="M0 0h24v24H0z" id="a" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask fill="white" id="b">
        <use xlinkHref="#a" />
      </mask>
      <path
        className="yo-tunnus-login-icon-path yo-icon-svg-path"
        d="M12 0c2.2 0 4.2.5 6 1.6A12.1 12.1 0 0 1 22.4 6c1 1.8 1.6 3.8 1.6 6s-.5 4.2-1.6 6a12.1 12.1 0 0 1-4.4 4.4c-1.8 1-3.8 1.6-6 1.6a11.6 11.6 0 0 1-6-1.6A12.1 12.1 0 0 1 1.6 18 11.6 11.6 0 0 1 0 12c0-2.2.5-4.2 1.6-6A12.1 12.1 0 0 1 6 1.6C7.8.6 9.8 0 12 0m6.2 20.4v-.7a3 3 0 0 0-1-2.1 3 3 0 0 0-2.1-1c-.1 0-.4 0-.7.2a7.8 7.8 0 0 1-2.4.4 7.8 7.8 0 0 1-2.4-.4l-.7-.2a3 3 0 0 0-2.2 1 3 3 0 0 0-.9 2.1v.7c1.8 1.4 4 2 6.2 2s4.4-.6 6.2-2m1.5-1.3a10.2 10.2 0 0 0 2-3.3 10.3 10.3 0 0 0-.7-9A10.5 10.5 0 0 0 17.2 3 10.2 10.2 0 0 0 6.8 3 10.5 10.5 0 0 0 3 6.8a10.2 10.2 0 0 0-.7 9 10.2 10.2 0 0 0 2 3.3A4.8 4.8 0 0 1 6 16.2c.8-.7 1.8-1.1 3-1.1.3 0 .7 0 1.2.2.6.2 1.3.3 1.9.3l2-.3 1.1-.2c1.2 0 2.2.4 3 1.1a4.8 4.8 0 0 1 1.6 2.9M12 5.4c1.2 0 2.2.4 3 1.3.8.8 1.3 1.8 1.3 3 0 1.1-.5 2.1-1.3 3a4 4 0 0 1-3 1.2 4 4 0 0 1-3-1.2 4.1 4.1 0 0 1-1.3-3c0-1.2.5-2.2 1.3-3 .8-.9 1.8-1.3 3-1.3m0 7c.7 0 1.4-.3 2-.8a2.6 2.6 0 0 0 .7-2c0-.7-.3-1.3-.8-1.8A2.6 2.6 0 0 0 12 7c-.7 0-1.4.2-2 .8a2.6 2.6 0 0 0-.7 1.9c0 .7.3 1.4.8 1.9a2.6 2.6 0 0 0 1.9.8"
        mask="url(#b)"
      />
      <path d="M23.6 4.6a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" fill="red" />
    </g>
  </svg>
)

YleTunnusLogin.defaultProps = defaultProps

export default YleTunnusLogin
