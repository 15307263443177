import React from 'react'
import './primary-header.scss'

interface Props {
  className?: string
  children?: React.ReactNode
}

const PrimaryHeader = ({ children, className }: Props) => {
  return <h2 className={className || 'yo-primary-header'}>{children}</h2>
}

export default PrimaryHeader
