import uuid from 'uuid'
import i18n from '../../../utils/i18n'
import { GET, apiURL, parseJSON } from '../dataService/apiV1'
import { serializeAnswersForApi, serializeQuestionsFromAPI } from '../dataService/stateSerializer'
import { handleLoadError } from '../examService/examService'
import { applyAnswers, setMaxScores } from '../../modules/nodes/nodes'
import { addToast, flipToast } from '../../modules/toasts/toasts'
import {
  setQuestionsLoading,
  receiveQuestions,
  clearQuestionsLoading,
  handleQuestionsData,
} from '../../modules/questions/questions'
import { PERSIST_TTL } from '../../../components/layout/Toaster/Toast'
import { setQuestionsLoadState } from '../../modules/loadState/loadState'
import { clearCarouselLoading } from '../../modules/carousel/carousel'
import { POLL } from '../../../constants/definitions/exams'
import { SCORED_VOTE_QUESTION } from '../../../constants/definitions/entities/questionNodes.ts'
import { fetchFeedbackStats, fetchFeedbackStatSums } from '../../modules/statistics/statistics'
import { fetchPollStats } from '../pollService/pollService'

const DELETE_QUESTION_ANSWERS = 'api/DELETE_QUESTION_ANSWERS'
const FETCH_QUESTION_ANSWERS = 'api/FETCH_QUESTION_ANSWERS'
const FETCH_QUESTIONS_BY_TAGS = 'api/FETCH_QUESTIONS_BY_TAGS'
const FETCH_QUESTIONS_BY_UUIDS = 'api/FETCH_QUESTIONS_BY_UUIDS'

export const deleteAnswers = uuids => ({
  type: DELETE_QUESTION_ANSWERS,
  uuids,
})

export const fetchQuestionAnswers = uuids => ({
  type: FETCH_QUESTION_ANSWERS,
  uuids,
})

export const fetchQuestionsByTags = tags => ({
  type: FETCH_QUESTIONS_BY_TAGS,
  tags,
})

export const searchQuestionsByUUIDs = uuids => ({
  type: FETCH_QUESTIONS_BY_UUIDS,
  uuids,
})

const postAnswers = (uri, data, state) =>
  fetch(apiURL(uri), {
    body: JSON.stringify(serializeAnswersForApi(data, state)),
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'include',
    method: 'POST',
  })

// Chained async calls won't work under questionService so we use
// Async thunk action creator which posts answers and then fetches statistics.
export function saveAnswersAndFetchStats(data, examType) {
  return (dispatch, getState) => {
    const toastUUID = uuid.v4()
    const uri = examType === POLL ? '/public/polls' : '/answers/question'

    if (examType !== POLL) {
      dispatch(addToast('progress', 'Saving...', PERSIST_TTL, toastUUID))
    }
    return postAnswers(uri, data, getState())
      .then(resp => {
        const scoreSumNodeIds = data
          .filter(({ nodeTypeId }) => nodeTypeId === SCORED_VOTE_QUESTION.id)
          .map(node => node.id)
        const regularNodeIds = data
          .filter(({ id }) => !scoreSumNodeIds.includes(id))
          .map(node => node.id)
        if (examType === POLL) {
          data.forEach(node =>
            sessionStorage.setItem(`tehtava_poll_${node.id}`, Object.keys([node.value][0])),
          )
          dispatch(fetchPollStats(regularNodeIds))
          return i18n.t('Widget:save_successful')
        }
        if (resp.ok) {
          dispatch(flipToast(toastUUID, 'success', i18n.t('Widget:save_successful')))
          const fetchStatsActions = []
          if (regularNodeIds.length) {
            fetchStatsActions.push(fetchFeedbackStats(regularNodeIds))
          }
          if (scoreSumNodeIds.length) {
            fetchStatsActions.push(fetchFeedbackStatSums(scoreSumNodeIds))
          }
          return Promise.all(fetchStatsActions.map(dispatch))
        }
        throw new Error('error saving answer')
      })
      .catch(() =>
        dispatch(flipToast(toastUUID, 'error', i18n.t('Widget:save_failed'), PERSIST_TTL)),
      )
  }
}

const questionService = store => next => action => {
  next(action)

  switch (action.type) {
    case DELETE_QUESTION_ANSWERS:
      return fetch(apiURL(`/answers/question?question_uuids=${action.uuids.join(',')}`), {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'include',
        method: 'DELETE',
      })
        .then(parseJSON)
        .catch(handleLoadError)

    case FETCH_QUESTION_ANSWERS:
      return fetch(apiURL(`/answers/questions?question_uuids=${action.uuids.join(',')}`), {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'include',
        method: 'GET',
      })
        .then(parseJSON)
        .then(data => {
          next(applyAnswers(data.data, store.getState()))
          next(setMaxScores(store.getState().entities.options))
        })

    case FETCH_QUESTIONS_BY_TAGS:
      next(setQuestionsLoading())
      return GET(`/questions/search.json?tags=${action.tags.join(',')}`)
        .then(parseJSON)
        .then(body => next(receiveQuestions(body.data)))

    case FETCH_QUESTIONS_BY_UUIDS:
      next(setQuestionsLoading())
      return fetch(apiURL(`/public/questions/search.json?uuids=${action.uuids.join(',')}`))
        .then(parseJSON)
        .then(body => {
          next(handleQuestionsData(serializeQuestionsFromAPI(body.data)))
          next(setQuestionsLoadState())
          next(clearQuestionsLoading())
          next(clearCarouselLoading())
        })

    default:
      break
  }
  return null
}

export default questionService
