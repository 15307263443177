import React from 'react'
import { i18n } from 'i18next'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import './progressbar.scss'

interface Props {
  color: string | null
  count: number
  height: string
  progress: number
  showProgress: boolean
  t: i18n['t']
  tReady: boolean
}

const ProgressBar = ({ color, count, height, progress, showProgress, t }: Props) => {
  const outputVoteCount = (count: number) => {
    if (count === 1) {
      return t('vote', { count })
    }
    if (count > 1) {
      return t('vote_plural', { count })
    }
    return null
  }

  const colorStyle = color ? { backgroundColor: color } : null

  return (
    <div className="yo-progressbar__wrapper">
      <div className="yo-progressbar" data-testid="yo-progressbar">
        <span
          className="yo-progressbar__progress"
          style={{
            height: height || '.5rem',
            width: `${progress}%`,
            ...colorStyle,
          }}
        />
      </div>
      {showProgress && progress !== undefined && (
        <span
          className={classnames('yo-progressbar__progress-text', {
            'yo-progressbar__progress-text--grey': color,
          })}
        >
          {progress}%{outputVoteCount(count)}
        </span>
      )}
    </div>
  )
}

export default withTranslation(['ProgressBar'])(ProgressBar)
