import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import {
  getAuthors,
  getNode,
  getNodeType,
  getParentNode,
  getSortedChildIds,
} from '../../redux/modules/nodes/selectors'
import { getOptions } from '../../redux/modules/options/selectors'
import * as contentComponents from '../contentComponents'
import NodeEditable from './NodeEditable/NodeEditable'
import NodePlaceholder from './NodePlaceholder/NodePlaceholder'
import NodeReview from './NodeReview/NodeReview'
import { scrollToHash } from '../../utils/common'
import {
  getExamType,
  getExamEndTime,
  getExamStatisticsHidden,
  getIsOpen,
} from '../../redux/modules/exam/selectors'
import { getEmbedOptions, getIsAssignmentEmbed } from '../../redux/modules/embed/selectors'
import './exam-question.scss'
import ConfirmDialogManager from '../../confirmDialog/ConfirmDialogManager.ts'

import { getLoggedInState } from '../../redux/modules/yleTunnus/selectors'

/**
 * Creates a React component that is used recursively when creating
 * first the parent and then it's children.
 */
export const Node = props => {
  useEffect(() => {
    scrollToHash()
  }, [])

  /**
   * Create child components recursively.
   * This is clearly a legacy solution that could be easily rewritten.
   */
  const createChildComponents = () => {
    const { canHaveChildren, editing, node, sortedChildIds } = props
    const attributes = { 'data-testid': 'node-placeholder' }

    if (canHaveChildren && editing) {
      return <NodePlaceholder attributes={attributes} order={1} parentId={node.id} />
    }

    return sortedChildIds.map(id => (
      <ConnectedNode
        editing={editing}
        id={id}
        isFirst={id === sortedChildIds[0]}
        isLast={id === sortedChildIds[sortedChildIds.length - 1]}
        key={id}
        parentId={node.id}
      />
    ))
  }

  const {
    editing,
    isFirst,
    isLast,
    node,
    nodeType,
    options,
    parentId,
    rootElementId,
    showExamName,
    embedOptions,
    t,
  } = props

  // Content component doesn't exist, render nothing.
  if (typeof contentComponents[nodeType.name] === 'undefined') {
    return null
  }

  const dynamicProps = {
    ...props,
    children: createChildComponents(),
    content: node.content,
    evaluateCompleted: nodeType.evaluateCompleted || null,
    id: node.id,
    settings: node.settings || {},
    submitted: node.submitted,
    value: node.value,
    questionStarted: node.questionStarted,
  }

  // Create component dynamically based on the node type (e.g. VoteQuestion)
  const renderableComponent = React.createElement(contentComponents[nodeType.name], dynamicProps)

  if (editing) {
    return (
      <NodeEditable
        id={node.id}
        isFirst={isFirst}
        isLast={isLast}
        node={node}
        nodeType={nodeType}
        order={node.order}
        parentId={parentId}
      >
        {renderableComponent}
        <NodeReview node={node} options={options} />
      </NodeEditable>
    )
  }

  const examYleId = `47-${node.examId}`

  const onClickExamNameLink = async () => {
    const mountExam = await ConfirmDialogManager.show(
      t('confirm-mount-exam-view', { exam: node.examName }),
    )

    if (mountExam) {
      window.tehtavaApp.mount(examYleId, document.getElementById(rootElementId), {
        darkMode: embedOptions.darkMode,
        lang: embedOptions.lang,
      })
    }
  }

  return (
    <div
      className={classnames('yo-exam-question', {
        'yo-exam-question__webview': IS_WEBVIEW_EMBED,
      })}
      id={node.id}
    >
      {renderableComponent}
      <NodeReview node={node} options={options} />
      {showExamName && (
        <div className="yo-exam-question__exam-name">
          {t('from-exam')}
          <span
            className="yo-exam-question__exam-link"
            onClick={onClickExamNameLink}
            role="button"
            tabIndex="0"
          >
            {node.examName}
          </span>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  const authors = getAuthors(state)
  const examType = getExamType(state)
  const examIsOpen = getIsOpen(state)
  const examEndTime = getExamEndTime(state)
  const embedOptions = getEmbedOptions(state)
  const isAssignmentEmbed = getIsAssignmentEmbed(state)
  // Let's only show the exam name on top-level questions (so not on child questions)
  const showExamName =
    state.loadState.questionsLoaded &&
    !getParentNode(state, ownProps.id) &&
    !embedOptions.examId &&
    !isAssignmentEmbed

  // Are we in the carousel or not.
  const carouselActive = state.loadState.isCarouselActive

  if (state.loadState.loaded || state.loadState.questionsLoaded) {
    const node = getNode(state, ownProps.id)
    const nodeType = getNodeType(state, node.nodeTypeId)
    const sortedChildIds = getSortedChildIds(state, node)

    // Check if the node has no children and can have children.
    const canHaveChildren = node.childIds.length === 0 && nodeType.childNodeTypeIds.length > 0

    let options = []
    if ('options' in node) {
      options = getOptions(state.entities, node.id, node.options)
    }

    return {
      authors,
      canHaveChildren,
      carouselActive,
      examType,
      examEndTime,
      examIsOpen,
      hideStatistics: getExamStatisticsHidden(state),
      node,
      nodeType,
      options,
      rootElementId: state.embed.rootElementId,
      showExamName,
      sortedChildIds,
      isLoggedIn: getLoggedInState(state),
      embedOptions,
    }
  }

  return {
    authors,
    canHaveChildren: false,
    carouselActive,
    node: {},
    nodeType: '',
    options: {},
    showExamName,
    sortedChildIds: [],
    isLoggedIn: getLoggedInState(state),
    embedOptions,
  }
}

const ConnectedNode = withTranslation(['Node'])(connect(mapStateToProps)(Node))
export default ConnectedNode
