import classNames from 'classnames'
import React, { MouseEventHandler, ReactNode } from 'react'
import AnswerValidator from '../../node/AnswerValidator/AnswerValidator'
import './option-button.scss'

interface Props {
  children: ReactNode
  hasIcon?: boolean
  isSelected?: boolean
  isClickable?: boolean
  type: 'correct' | 'wrong' | null
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const OptionButton = ({
  children,
  onClick,
  isSelected,
  isClickable = true,
  type,
  hasIcon,
}: Props) => {
  return (
    <button
      className={classNames('yo-option-button', {
        'yo-option-button--wrong': type === 'wrong',
        'yo-option-button--correct': type === 'correct',
        'yo-option-button--selected': isSelected,
        'yo-option-button--clickable': isClickable,
      })}
      onClick={e => {
        isClickable && onClick && onClick(e)
      }}
      type="button"
    >
      <>
        {children}
        {hasIcon && !!type && <AnswerValidator correct={type === 'correct'} />}
      </>
    </button>
  )
}

export default OptionButton
