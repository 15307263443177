import uuid from 'uuid'
import { POST, parseJSON, DELETE } from '../../middleware/dataService/apiV1'
import { serializeSeriesForApi } from '../../middleware/dataService/stateSerializer'
import { fetchSeriesList } from '../../middleware/seriesService/seriesService'
import { flipToast } from '../toasts/toasts'

export const SERIES_STORED = 'series/SERIES_STORED'
export const UPDATE_SERIES = 'series/UPDATE_SERIES'

const SET_SERIES_LOADING = 'series/SET_SERIES_LOADING'
const CLEAR_SERIES_LOADING = 'series/CLEAR_SERIES_LOADING'
const RECEIVE_SERIES = 'series/RECEIVE_SERIES'
const TOGGLE_EDITING = 'series/TOGGLE_EDITING'
const TOGGLE_EDITING_ORDER = 'series/TOGGLE_EDITING_ORDER'
const UPDATE_EXISTING_SERIES = 'series/UPDATE_EXISTING_SERIES'
const RECEIVE_SERIES_DATA = 'series/RECEIVE_SERIES_DATA'
const CLEAR_SERIES_DATA = 'series/CLEAR_SERIES_DATA'

// Action creators
export const setSeriesLoading = () => ({
  type: SET_SERIES_LOADING,
})

export const updateSeries = ({ fieldName, value, isClassification = false }) => ({
  type: UPDATE_SERIES,
  fieldName,
  value,
  isClassification,
})

export const updateExistingSeries = ({ uuid, fieldName, value, isClassification = false }) => ({
  type: UPDATE_EXISTING_SERIES,
  fieldName,
  value,
  uuid,
  isClassification,
})

export const clearSeriesLoading = () => ({
  type: CLEAR_SERIES_LOADING,
})

export const toggleEditing = () => ({
  type: TOGGLE_EDITING,
})

export const toggleEditingOrder = () => ({
  type: TOGGLE_EDITING_ORDER,
})

export const seriesStored = () => ({
  type: SERIES_STORED,
})

export const createSeries = (serie, history) => dispatch => {
  const data = {
    ...serie,
    uuid: uuid.v4(),
  }
  dispatch(setSeriesLoading())
  return POST('/series', JSON.stringify(serializeSeriesForApi(data)))
    .then(parseJSON)
    .then(() => {
      dispatch(clearSeriesLoading())
      history.push('/series/list')
    })
}

export const deleteSeries = id => dispatch => {
  dispatch(setSeriesLoading())
  return DELETE(`/series/${id}.json`)
    .then(parseJSON)
    .then(() => {
      dispatch(flipToast(uuid.v4(), 'success', 'Delete succesful'))
      dispatch(fetchSeriesList())
    })
}

export const receiveSeries = series => ({
  type: RECEIVE_SERIES,
  series,
})

export const receiveSeriesData = data => ({
  type: RECEIVE_SERIES_DATA,
  data,
})

export const clearSeriesData = () => ({
  type: CLEAR_SERIES_DATA,
})

// Reducer
const defaultState = {
  loading: false,
  editing: false,
  editingOrder: false,
  series: {
    uuid: '',
    name: '',
    classification: {},
    description: '',
    tags: [],
    exams: [],
  },
  data: [],
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_SERIES_LOADING:
      return {
        ...state,
        loading: false,
      }

    case SET_SERIES_LOADING:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_SERIES:
      return {
        ...state,
        series: action.isClassification
          ? {
              ...state.series,
              classification: {
                ...state.series.classification,
                [action.fieldName]: action.value,
              },
            }
          : {
              ...state.series,
              [action.fieldName]: action.value,
            },
      }
    case RECEIVE_SERIES:
      return {
        ...defaultState,
        data: action.series,
      }

    case TOGGLE_EDITING:
      return {
        ...state,
        editing: !state.editing,
      }

    case TOGGLE_EDITING_ORDER:
      return {
        ...state,
        editingOrder: !state.editingOrder,
      }

    case UPDATE_EXISTING_SERIES:
      return {
        ...state,
        data: state.data.map(item => {
          if (item.uuid === action.uuid) {
            return action.isClassification
              ? {
                  ...item,
                  classification: {
                    ...item.classification,
                    [action.fieldName]: action.value,
                  },
                }
              : {
                  ...item,
                  [action.fieldName]: action.value,
                }
          }
          return item
        }),
      }

    case RECEIVE_SERIES_DATA:
      return {
        ...state,
        data: action.data,
      }

    case CLEAR_SERIES_DATA:
      return defaultState

    default:
      return state
  }
}

export default reducer
