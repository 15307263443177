import React from 'react'
import defaultProps from './defaultProps'

const Round = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 32 32" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M10,3c-3.866,0-7,3.133-7,7c0,3.865,3.134,7,7,7s7-3.135,7-7C17,6.133,13.866,3,10,3z"
      fill={fill}
    />
  </svg>
)

Round.defaultProps = defaultProps

export default Round
