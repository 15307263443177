import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Close } from '@yleisradio/yds-icons-react'
import OptionButton from '../../elements/OptionButton/OptionButton.tsx'
import Fragment from '../../node/Fragment/Fragment'
import Button from '../../elements/Button/Button.tsx'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import './combine-question.scss'
import { REVIEW_ONLY_EXAM_TYPE } from '../../../constants/definitions/exams'
import SpacedGroup from '../../elements/SpacedGroup/SpacedGroup.tsx'

const getOptionButtonType = ({ isReviewOnlyExam, submitted, correct }) => {
  if (!submitted || isReviewOnlyExam) {
    return null
  }
  return correct ? 'correct' : 'wrong'
}

const zip = (a, b) => a.map((v, i) => [v, b[i]])

const CombineQuestion = ({
  combinedValues,
  correctPairs,
  content,
  examType,
  // Event handlers.
  onClickDetach,
  onClickValue,
  submitted,
  // Currently selected values.
  valueA,
  valueB,
  // List of values to print.
  valuesA,
  valuesB,
  t,
}) => {
  const isReviewOnlyExam = examType === REVIEW_ONLY_EXAM_TYPE
  const correctIds = combinedValues.filter(x => x.correct).map(x => Number(x.optionId))

  return (
    <div>
      <Fragment
        displayNumber={content.displayNumber}
        examType={examType}
        text={<RichTextContent content={content.text} />}
      />
      <div className="yo-combine-question">
        {combinedValues.map(({ correct, optionId, valueA, valueB }) => (
          <SpacedGroup key={optionId}>
            <OptionButton
              hasIcon
              isSelected
              type={getOptionButtonType({ isReviewOnlyExam, submitted, correct })}
            >
              {<RichTextContent content={valueA} />}
            </OptionButton>
            <OptionButton
              isSelected
              type={getOptionButtonType({ isReviewOnlyExam, submitted, correct })}
            >
              {<RichTextContent content={valueB} />}
            </OptionButton>
            {!submitted && (
              <Button
                aria-label={t('detach-answer')}
                classNames={['yo-combine-question__detach']}
                iconBefore={<Close />}
                iconOnly
                onClick={() => onClickDetach(optionId)}
                size="xs"
                variant="secondary"
              />
            )}
          </SpacedGroup>
        ))}
        {valuesA.length === valuesB.length &&
          zip(valuesA, valuesB).map(([valA, valB]) => (
            <SpacedGroup key={valA.id}>
              <OptionButton
                isClickable
                isSelected={valA.id === valueA}
                onClick={() => onClickValue(valA.id, 'valueA')}
                type={null}
              >
                {<RichTextContent content={valA.value} />}
              </OptionButton>
              <OptionButton
                isClickable
                isSelected={valB.id === valueB}
                onClick={() => onClickValue(valB.id, 'valueB')}
                type={null}
              >
                {<RichTextContent content={valB.value} />}
              </OptionButton>
            </SpacedGroup>
          ))}
      </div>
      {submitted && !isReviewOnlyExam && correctIds.length !== correctPairs.length && (
        <div className="yo-combine-question__correct-answers">
          <div className="yo-combine-question__correct-answers-text">{t('correct-answers')}</div>
          {correctPairs
            .filter(x => !correctIds.includes(x.optionId))
            .map(({ valueA, valueB, optionId }) => (
              <SpacedGroup key={optionId}>
                <OptionButton type={null}>{<RichTextContent content={valueA} />}</OptionButton>
                <OptionButton type={null}>{<RichTextContent content={valueB} />}</OptionButton>
              </SpacedGroup>
            ))}
        </div>
      )}
    </div>
  )
}

export default withTranslation(['CombineQuestion'])(withRouter(CombineQuestion))
