import React from 'react'

const RangeInput = ({
  attributes,
  autofocus,
  defaultValue = 100,
  description,
  disabled,
  id,
  label,
  onChange,
  t,
}) => (
  <label className="yo-input__label" htmlFor={id}>
    {label}
    <input
      autoFocus={autofocus}
      className="yo-input__input yo-input__input--range"
      data-testid="yo-input__input--range"
      disabled={disabled}
      id={id}
      onChange={onChange}
      type="range"
      value={defaultValue}
      {...attributes}
    />
    <span>{defaultValue}%</span>
    {description && <div className="yo-input__description">{t(description)}</div>}
  </label>
)

export default RangeInput
