import * as R from 'ramda'

// Actions
import { HANDLE_EXAM_DATA } from '../../middleware/examService/examService'
import { HANDLE_QUESTIONS_DATA } from '../questions/questions'
import { node } from '../node/node.ts'
import { getMaxScore } from './helpers'
import { CREATE, REORDER } from '../node/actions.ts'

const APPLY_ANSWERS = 'nodes/APPLY_ANSWERS'
const LOADED = 'nodes/LOADED'
const LOAD_ERROR = 'nodes/LOAD_ERROR'
const SET_MAX_SCORES = 'nodes/SET_MAX_SCORES'
const APPLY_FEEDBACK_STATS = 'nodes/APPLY_FEEDBACK_STATS'
const APPLY_FEEDBACK_STAT_SUMS = 'nodes/APPLY_FEEDBACK_STAT_SUMS'
const CLEAR_ALL_ANSWERS = 'nodes/CLEAR_ALL_ANSWERS'

function reorder(state, action) {
  const nodes = {}

  // Iterate child nodes.
  state[action.parentId].childIds
    // Filter out newly created/deleted node.
    .filter(id => id !== action.node.id)
    // Get node entity.
    .map(id => state[id])
    .filter(node => node.visible === true)
    // Filter out nodes with lower order than the created/deleted one.
    .filter(node => node.order >= action.node.order)
    .forEach(node => {
      // Increment or decrement order of node depending on operation.
      const alteration = action.operation === 'create' ? 1 : -1

      nodes[node.id] = Object.assign({}, node, {
        order: node.order + alteration,
      })
    })

  return Object.assign({}, state, nodes)
}

// Set max score for each node.
function setStateMaxScores(state, options) {
  const maxScore = R.map(node => {
    return R.assoc('maxScore', getMaxScore(node, options), node)
  })

  return maxScore(state)
}

/**
 * Function for applying answers to nodes.
 *
 * @param {Object} nodes - Node entities
 * @param {array} answers - Array of answers
 * @param {Object} state - State of redux store
 * @param {boolean} isCarousel - Indicates that answers are in carousel
 */
const applyAnswersToNodes = (nodes, answers, state, isCarousel) =>
  R.map(node => {
    const answer = answers.find(item => item.question_uuid === node.id)
    // Score is not added in carousel
    return answer
      ? {
          ...node,
          submitted: true,
          completed: true,
          score: isCarousel ? undefined : answer.score,
          value: answer.answer_data,
        }
      : node
  }, nodes)

const applyFeedbackStatsToNodes = (nodes, { data, meta }) =>
  R.map(node => {
    const statistics = Object.values(data).find(x => x.uuid === node.id)
    return statistics
      ? {
          ...node,
          statistics: statistics.stats,
          statisticsCreatedAt: meta?.created_at,
        }
      : node
  }, nodes)

const applyFeedbackStatSumsToNodes = (nodes, { data, meta }) => {
  return R.map(node => {
    return {
      ...node,
      statisticSums: R.find(R.propEq('uuid', node.id))(data)?.stats,
      statisticsCreatedAt: meta?.created_at,
    }
  }, nodes)
}

export const clearAllAnswers = () => ({
  type: CLEAR_ALL_ANSWERS,
})

export const applyAnswers = (answers, state) => ({
  type: APPLY_ANSWERS,
  answers,
  state,
})

export const applyFeedbackStats = ({ data, meta }) => ({
  type: APPLY_FEEDBACK_STATS,
  data,
  meta,
})

export const applyFeedbackStatSums = ({ data, meta }) => ({
  type: APPLY_FEEDBACK_STAT_SUMS,
  data,
  meta,
})

export function setMaxScores(options) {
  return {
    type: SET_MAX_SCORES,
    options,
  }
}

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case CREATE:
      return Object.assign({}, state, {
        [action.node.id]: action.node,
      })

    case REORDER:
      return reorder(state, action)

    case APPLY_ANSWERS:
      return applyAnswersToNodes(
        Object.assign({}, state),
        action.answers,
        action.state,
        action.isCarousel,
      )

    case APPLY_FEEDBACK_STATS:
      return applyFeedbackStatsToNodes({ ...state }, { ...action })

    case APPLY_FEEDBACK_STAT_SUMS:
      return applyFeedbackStatSumsToNodes({ ...state }, { ...action })

    case LOADED:
    case LOAD_ERROR:
      return state

    case HANDLE_EXAM_DATA:
      return Object.assign({}, action.data.entities.nodes)

    case HANDLE_QUESTIONS_DATA:
      return Object.assign({}, action.data.entities.nodes)

    case SET_MAX_SCORES:
      return {
        ...state,
        ...setStateMaxScores(state, action.options),
      }

    case CLEAR_ALL_ANSWERS:
      return R.map(
        x => ({
          ...x,
          score: null,
          completed: false,
          submitted: false,
          value: null,
          timer: null,
          questionStarted: false,
        }),
        state,
      )
    default:
      if (typeof action.nodeId === 'undefined') {
        return state
      }

      return Object.assign({}, state, {
        [action.nodeId]: node(state[action.nodeId], action),
      })
  }
}
