/* eslint-disable import/prefer-default-export */
import { NodeTypeCategory, TehtavaNode, NodeType } from './types'
import * as gFieldTypes from './genericFieldTypes'

export const TEXT: TehtavaNode = {
  id: NodeType.TEXT,
  name: 'Text',
  displayName: 'Text',
  description: 'text-element',
  type: NodeTypeCategory.CONTENT,
  childNodeTypeIds: [],
  fields: [gFieldTypes.foldOutTitle, gFieldTypes.text],
  settingFields: [gFieldTypes.hideableText],
}
