import { useEffect } from 'react'

import { fetchPollStats } from '../../redux/middleware/pollService/pollService'

const usePollStats = ({ loaded, uuids, dispatch, isPoll }) => {
  useEffect(() => {
    if (loaded && isPoll) {
      dispatch(fetchPollStats(uuids))
    }
  }, [loaded])
}

export default usePollStats
