import React from 'react'
import defaultProps from './defaultProps'

const H3 = ({ fill, width, height }) => (
  <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <text className="yo-icon-svg-text yo-icon-svg-path" fill={fill} x="0" y="15">
      H3
    </text>
  </svg>
)

H3.defaultProps = defaultProps

export default H3
