// Config.js
//
// export conf based on the profile (NODE_ENV)
//
// Usage: import config from './config'

// config for yle tunnus sdk
const yleTunnusConfig = {
  test: {
    trackingAppName: 'yle_tehtava_app_test',
    initiatingApp: 'yle_tehtava_app_test',
    environment: 'test',
    myInfoUrl: 'https://tunnus-test.yle.fi/#omat-tiedot',
    url: 'https://tunnus-sdk.test.yle.fi/v1/index.js',
  },
  prod: {
    trackingAppName: 'yle_tehtava_app_prod',
    initiatingApp: 'yle_tehtava_app_prod',
    environment: 'production',
    myInfoUrl: 'https://tunnus.yle.fi/#omat-tiedot',
    url: 'https://tunnus-sdk.yle.fi/v1/index.js',
  },
}

const GOOGLE_CLIENT_ID = '557086016938-6ulro02681curfdbmq6gjv601hsg3oj5.apps.googleusercontent.com'

const config = {
  // local development w/ API
  development: {
    env: 'development',
    backend: {
      url: 'https://local.tehtava-api.yle.fi:51804',
    },
    contacts_app_url: 'https://tunnus-contacts.apps-test.yle.fi/index.html',
    google_client_id: GOOGLE_CLIENT_ID,
    images_api_url:
      'https://images.cdn.yle.fi/image/upload/fl_keep_iptc,f_auto,fl_progressive/q_80',
    ims_url: 'https://ims.yle.fi',
    json_storage_api_app_id: 'tehtava_app_202012_dev_prod',
    json_storage_api_app_key: '96462f9ea3d7170ec39c39ef959588ff',
    json_storage_api_url: 'https://json-storage.api.yle.fi/v1/',
    meta_api_app_id: 'tehtava_app_202012_dev_prod',
    meta_api_app_key: '96462f9ea3d7170ec39c39ef959588ff',
    meta_api_url: 'https://meta.api.yle.fi/v1/',
    math_editor: 'https://math-demo.abitti.fi/',
    share_api_url: 'https://share.api-test.yle.fi/share/tehtava',
    yleTunnus: yleTunnusConfig.test,
    tehtava_static_url: 'https://tehtava-static.test.ylestatic.fi',
    tehtava_ui_url: 'http://local.tehtava-ui.yle.fi:3000',
  },
  // deployed live test env
  test: {
    env: 'test',
    backend: {
      url: 'https://tehtava.api-test.yle.fi',
    },
    contacts_app_url: 'https://tunnus-contacts.apps-test.yle.fi/index.html',
    google_client_id: GOOGLE_CLIENT_ID,
    images_api_url:
      'https://images.cdn.yle.fi/image/upload/fl_keep_iptc,f_auto,fl_progressive/q_80',
    ims_url: 'https://ims.yle.fi',
    json_storage_api_app_id: 'tehtava_app_202012_dev_prod',
    json_storage_api_app_key: '96462f9ea3d7170ec39c39ef959588ff',
    json_storage_api_url: 'https://json-storage.api.yle.fi/v1/',
    meta_api_app_id: 'tehtava_app_202012_dev_prod',
    meta_api_app_key: '96462f9ea3d7170ec39c39ef959588ff',
    meta_api_url: 'https://meta.api.yle.fi/v1/',
    math_editor: 'https://math-demo.abitti.fi/',
    share_api_url: 'https://share.api-test.yle.fi/share/tehtava',
    yleTunnus: yleTunnusConfig.test,
    tehtava_static_url: 'https://tehtava-static.test.ylestatic.fi',
    tehtava_ui_url: 'https://tehtava-ui.apps-test.yle.fi',
  },
  // deployed live production env
  prod: {
    env: 'prod',
    backend: {
      url: 'https://tehtava.api.yle.fi',
    },
    contacts_app_url: 'https://tunnus-contacts.apps.yle.fi/index.html',
    google_client_id: GOOGLE_CLIENT_ID,
    images_api_url:
      'https://images.cdn.yle.fi/image/upload/fl_keep_iptc,f_auto,fl_progressive/q_80',
    ims_url: 'https://ims.yle.fi',
    json_storage_api_app_id: 'tehtava_app_202012_prod',
    json_storage_api_app_key: '86a5824c09676754de92ee809c9c677e',
    json_storage_api_url: 'https://json-storage.api.yle.fi/v1/',
    meta_api_app_id: 'tehtava_app_202012_prod',
    meta_api_app_key: '86a5824c09676754de92ee809c9c677e',
    meta_api_url: 'https://meta.api.yle.fi/v1/',
    math_editor: 'https://math-demo.abitti.fi/',
    share_api_url: 'https://share.api.yle.fi/share/tehtava',
    yleTunnus: yleTunnusConfig.prod,
    tehtava_static_url: 'https://tehtava-static.ylestatic.fi',
    tehtava_ui_url: 'https://tehtava-ui.apps.yle.fi',
  },

  // local automated test env
  get dev_test() {
    // Inherit base settings from development environment.
    return Object.assign(this.development, {
      env: 'dev_test',
    })
  },
}

const getConfig = () => {
  const appEnv = process.env.REACT_APP_ENV || 'development'

  if (Object.keys(config).includes(appEnv)) {
    return config[appEnv]
  }
  return config.development
}

export default getConfig()
