// Actions
const CLEAR_SERIES_ID_FILTER = 'filters/exams/CLEAR_SERIES_ID'
const SET_SERIES_ID_FILTER = 'filters/exams/SET_SERIES_ID'

// Action creators
export const filterExamsBySeriesId = SeriesId => ({
  type: SET_SERIES_ID_FILTER,
  SeriesId,
})

export const resetSeriesIdFilter = () => ({
  type: CLEAR_SERIES_ID_FILTER,
})

// Reducer
const defaultState = ''

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SERIES_ID_FILTER:
      return action.SeriesId

    case CLEAR_SERIES_ID_FILTER:
      return defaultState

    default:
      return state
  }
}
