import React from 'react'
import sum from 'hash-sum'
import shortCodes from '../../shortCodeComponents'
import SHORTCODES from '../../../constants/definitions/shortcodes.tsx'

// Replaces placeholders with react components.
export const replacePlaceholders = (text, elements) => {
  const textParts = text.split(/(\{\{[a-z0-9-]+\}\})/).filter(text => text !== '')

  return textParts.map(text => {
    const match = text.match(/\{\{([a-z0-9-]+)\}\}/)

    // Text is a placeholder.
    // Return matching component.
    if (match) {
      return elements[match[1]]
    }

    // Text is non main level content.
    return text
  })
}

// Gets a unique key for every element within text content.
const getKey = (text, elements, useHash = true) => {
  const key = useHash ? sum(text) : text

  // Key doesn't exist for any other element, use that one.
  if (typeof elements[key] === 'undefined') {
    return key
  }

  let suffixedKey = key
  let index = 1

  // Key exits, create one with suffix.
  while (typeof elements[suffixedKey] !== 'undefined') {
    suffixedKey = `${key}-${index}`
    index += 1
  }

  return suffixedKey
}

export const newLines = (text, elements) =>
  text.replace(/\n\n/g, () => {
    const key = getKey('br', elements, false)
    elements[key] = React.createElement('br', { key })

    return `{{${key}}}`
  })

// Functions for validating shortcodes.
const validateShortcode = {
  GAP: props => {
    const { id, options } = props

    // Id and options should be defined.
    if (!id || !options) {
      return false
    }

    // The embedded option should exist on the node.
    const option = options.find(option => option.id.toString() === id)
    if (typeof option === 'undefined') {
      return false
    }

    return true
  },
}

// Replace shortcodes with placeholders and create React components for replacement.
export const shortcodes = (text, props = {}, elements) =>
  text.replace(/\[([A-Z]+)\s?(.*?)\]/g, (match, shortcode, parameters) => {
    // Should be a valid shortcode.
    if (typeof shortCodes[shortcode] === 'undefined') {
      return match
    }

    // Object to store parameters of a single shortcode instance.
    const ownProps = {}

    // Parse parameters from shortcode.
    if (parameters) {
      // Fix html entities
      const fixedParameters = parameters.replace(/&#95;/g, '_').replace(/&#93;/g, ']')

      // MATH shortcodes can't be splitted by '|'
      if (shortcode === SHORTCODES.MATH.shortcode) {
        ownProps.text = fixedParameters.split(/=(.+)/)[1]?.trim()
      } else {
        fixedParameters
          .split('|')
          .filter(p => typeof p !== 'undefined')
          .forEach(parameter => {
            // Split only by the first "=" character.
            const keyValue = parameter.split(/=(.+)/).filter(text => text !== '')

            // Every parameter should have key and value.
            if (keyValue.length === 2) {
              const [key, value] = keyValue

              ownProps[key.trim()] = value.trim()
            }
          })
      }
    }

    const key = getKey(match, elements)
    const shortcodeProps = Object.assign({}, ownProps, props)

    // Validate shortcode.
    if (shortcode in validateShortcode && !validateShortcode[shortcode](shortcodeProps)) {
      return match
    }

    shortcodeProps.key = key
    elements[key] = React.createElement(shortCodes[shortcode], shortcodeProps)

    return `{{${key}}}`
  })

export const fixHeadingNewLines = content => {
  const resultArray = []
  const delimiter = '###'
  const contentArray = content.split(delimiter)
  for (let i = 0; i < contentArray.length; i++) {
    resultArray.push(contentArray[i].replace(/\r?\n|\r/, ''))
  }
  return resultArray.join(delimiter)
}

export const isEmpty = content => !content || content === '&NewLine;\n'
