import { createSelector } from 'reselect'
import * as R from 'ramda'

import { nodeTypes } from '../../../constants/definitions/entities/entities.ts'
import { getOptions } from '../options/selectors'
import { sample } from '../../../utils/common'

const IMPORTANT_POINTS = 2
const DEFAULT_POINTS = 1

const getEntitiesState = state => state.entities

export const getCharactersState = state => state.entities.characters
export const getCharactersInfo = state => state.entities.characters.info

export const getCharacterQuestions = createSelector([getEntitiesState], entities => {
  const { nodes } = entities
  const questions = Object.values(nodes)
    .filter(
      x =>
        !R.isNil(x) &&
        (x.nodeTypeId === nodeTypes.ARCHTYPE_CUSTOMIZED_FEEDBACK.id ||
          x.nodeTypeId === nodeTypes.ARCHTYPE_MULTIPLE_CHOICE.id) &&
        x.visible,
    )
    .sort((a, b) => a.order - b.order)

  return questions.map(q => {
    const optionsData = getOptions(entities, q.id, q.options)
    return {
      ...q,
      optionsData,
    }
  })
})

export const getCharacterData = createSelector([getCharactersState], characters =>
  characters.info.map(x => {
    const choices = characters.choices.filter(c => c.character_id === x.id) || []
    return {
      ...x,
      choices,
    }
  }),
)

export const getCreatedOrEditedCharacter = createSelector([getCharactersState], characters =>
  characters.info.find(x => x.id === characters.editId || x.id === characters.createId),
)

export const getCharacterReviewsSortedByPoints = createSelector(
  [getCharactersState, getCharacterQuestions],
  (characters, questions) => {
    return characters.info
      .map(c => {
        // Add points to character array
        const points = characters.choices
          // Get character values
          .filter(x => x.character_id === c.id)
          // Calculate points
          .reduce((sum, obj) => {
            const node = questions.find(q => q.id === obj.question_id)
            if (!node || !node.completed) {
              return sum
            }
            // Filter out undefined from answers
            const answers = Object.keys(node.value).filter(key => node.value[key] !== undefined)
            // If character answer is included in answers add points
            if (answers.includes(String(obj.option_id))) {
              return obj.important ? sum + IMPORTANT_POINTS : sum + DEFAULT_POINTS
            }
            return sum
          }, 0)
        return {
          ...c,
          points,
        }
      })
      .sort((a, b) => b.points - a.points)
  },
)

export const getCharacterReviewWithMostPoints = createSelector(
  [getCharacterReviewsSortedByPoints],
  reviews =>
    reviews.length ? sample(reviews.filter(r => r.points === reviews[0].points)) : undefined,
)

export const characterQuestionsCompleted = createSelector(
  [getCharacterQuestions],
  questions => questions.filter(q => !q.completed).length === 0,
)

export const getCharactersShare = createSelector([getCharactersInfo], charactersInfo =>
  R.pluck('share', charactersInfo),
)
