import React from 'react'
import classnames from 'classnames'
import './answer-validator.scss'
import { Check, Close } from '@yleisradio/yds-icons-react'
import { withTranslation } from 'react-i18next'
import { i18n } from 'i18next'

interface Props {
  correct: boolean
  t: i18n['t']
}

const AnswerValidator = ({ correct, t }: Props) => {
  const classNames = classnames('yo-answer-validator', {
    'yo-answer-validator--incorrect': !correct,
  })

  return (
    <span
      aria-label={correct ? t('common:correct-answer') : t('common:wrong-answer')}
      className={classNames}
      data-testid="answer-validator"
    >
      {correct ? <Check /> : <Close />}
    </span>
  )
}

export default withTranslation()(AnswerValidator)
