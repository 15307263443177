import React from 'react'
import { connect } from 'react-redux'
import { updateValue } from '../../../redux/modules/node/actions.ts'
import Fragment from '../../node/Fragment/Fragment'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import { NodeType } from '../../../constants/definitions/entities/types.ts'
import './gap-question.scss'

const GapQuestion = ({ content, text, examType, submitted, onBlur, onChange, value, options }) => (
  <Fragment displayNumber={content.displayNumber} examType={examType} text={text}>
    <div className="yo-gap-question">
      <RichTextContent
        content={content.text}
        props={{
          options,
          value,
          onBlur,
          onChange,
          submitted,
        }}
      />
    </div>
  </Fragment>
)

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: e => {
    const optionIndex = e.target.getAttribute('data-option-index')
    const value = Object.assign(
      {},
      {
        [optionIndex]: e.target.value.trim() === '' ? null : e.target.value,
      },
    )

    dispatch(updateValue(ownProps.id, value, ownProps.evaluateCompleted))
  },
  onBlur: () => {
    const { submitted, value, options, node } = ownProps
    const isAutosubmit = node?.examClassification?.hide_review_buttons
    const gapAnswers = Object.values(value || {}).filter(v => !!v)
    const hasAnsweredAllGaps = gapAnswers.length === options.length

    if (
      isAutosubmit &&
      hasAnsweredAllGaps &&
      !submitted &&
      ownProps.node.nodeTypeId === NodeType.GAP_QUESTION
    ) {
      dispatch(updateValue(ownProps.id, value, () => true))
    }
  },
})

export default connect(null, mapDispatchToProps)(GapQuestion)
