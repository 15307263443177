import React from 'react'
import defaultProps from './defaultProps'

const Up = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path className="yo-icon-svg-path" d="M2.5 10H6V3h8v7h3.5L10 17.5 2.5 10z" fill={fill} />
  </svg>
)

Up.defaultProps = defaultProps

export default Up
