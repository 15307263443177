import { GET, apiURL, parseJSON } from '../../middleware/dataService/apiV1'
import { serializeQuestionsFromAPI } from '../../middleware/dataService/stateSerializer'
import { setCarouselItems } from '../carousel/carousel'
import { setQuestionsLoadState } from '../loadState/loadState'

// Actions
export const HANDLE_QUESTIONS_DATA = 'questions/HANDLE_QUESTIONS_DATA'
export const CLEAR_LOADING_STATE = 'questions/CLEAR_LOADING_STATE'
const RECEIVE_QUESTIONS = 'questions/RECEIVE'
const SET_LOADING_STATE = 'questions/SET_LOADING_STATE'

export const QUESTION_ID = 'tehtavaQuestionId'

// Action creators
export const clearQuestionsLoading = () => ({
  type: CLEAR_LOADING_STATE,
})

export const setQuestionsLoading = () => ({
  type: SET_LOADING_STATE,
})

export const receiveQuestions = questions => ({
  type: RECEIVE_QUESTIONS,
  questions,
})

export const handleQuestionsData = data => ({
  type: HANDLE_QUESTIONS_DATA,
  data,
})

const getFilterURL = (
  url,
  { tags, subject, examType, year, semester, lang, examId, options, questionTypes, category },
) => {
  const filterArr = []

  if (tags && tags.length > 0) {
    filterArr.push(`tags=${tags.join(',')}`)
  }
  if (examId) {
    filterArr.push(`exam_uuid=${examId.substr(3)}`)
  }
  if (lang) {
    filterArr.push(`lang=${lang}`)
  }
  if (subject) {
    filterArr.push(`subject=${subject}`)
  }
  if (examType) {
    filterArr.push(`exam_types=${examType}`)
  }
  if (year) {
    filterArr.push(`year=${year}`)
  }
  if (semester) {
    filterArr.push(`semester=${semester}`)
  }
  if (options) {
    filterArr.push(`options=${options.join(',')}`)
  }
  if (questionTypes) {
    filterArr.push(`question_types=${questionTypes.join(',')}`)
  }
  if (category) {
    filterArr.push(`categories=${category}`)
  }
  return filterArr.length > 0 ? `${url}?${filterArr.join('&')}` : url
}

export const searchCarouselQuestions = filters => dispatch => {
  dispatch(setQuestionsLoading())
  return fetch(apiURL(getFilterURL('/public/questions/carousel.json', filters)))
    .then(parseJSON)
    .then(body => {
      // Let's set loadState.questionsLoaded to true so our carousel gets rendered properly
      dispatch(setQuestionsLoadState())
      dispatch(setCarouselItems(body.data))
      dispatch(clearQuestionsLoading())
    })
}

export const getCopiedQuestion = uuids => dispatch => {
  dispatch(setQuestionsLoading())
  return GET(`/questions/search.json?uuids=${uuids.join(',')}`)
    .then(parseJSON)
    .then(body => {
      dispatch(clearQuestionsLoading())
      return serializeQuestionsFromAPI(body.data)
    })
}
// Reducer
const defaultState = {
  isFetching: false,
  data: [],
}

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_LOADING_STATE:
      return {
        ...state,
        isFetching: false,
      }

    case SET_LOADING_STATE:
      return {
        ...state,
        isFetching: true,
      }

    case RECEIVE_QUESTIONS:
      return {
        ...state,
        data: action.questions,
        isFetching: false,
      }

    default:
      return state
  }
}

export default reducer
