import React from 'react'
import defaultProps from './defaultProps'

const Equation = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M19.753,10.909c-0.624-1.707-2.366-2.726-4.661-2.726c-0.09,0-0.176,0.002-0.262,0.006l-0.016-2.063c0,0,3.41-0.588,3.525-0.607s0.133-0.119,0.109-0.231c-0.023-0.111-0.167-0.883-0.188-0.976c-0.027-0.131-0.102-0.127-0.207-0.109c-0.104,0.018-3.25,0.461-3.25,0.461s-0.012-1.953-0.013-2.078c-0.001-0.125-0.069-0.158-0.194-0.156s-0.92,0.014-1.025,0.016s-0.164,0.049-0.162,0.148c0.002,0.1,0.033,2.307,0.033,2.307s-3.061,0.527-3.144,0.543c-0.084,0.014-0.17,0.053-0.151,0.143s0.19,1.094,0.208,1.172c0.018,0.08,0.072,0.129,0.188,0.107c0.115-0.019,2.924-0.504,2.924-0.504l0.035,2.018c-1.077,0.281-1.801,0.824-2.256,1.303c-0.768,0.807-1.207,1.887-1.207,2.963c0,1.586,0.971,2.529,2.328,2.695c3.162,0.387,5.119-3.06,5.769-4.715c1.097,1.506,0.256,4.354-2.094,5.98c-0.043,0.029-0.098,0.129-0.033,0.207c0.065,0.078,0.541,0.662,0.619,0.756c0.08,0.096,0.206,0.059,0.256,0.023C19.394,15.862,20.545,13.077,19.753,10.909z M12.367,14.097c-0.966-0.121-0.944-0.914-0.944-1.453c0-0.773,0.327-1.58,0.876-2.156c0.335-0.354,0.75-0.621,1.229-0.799l0.082,4.277C13.225,14.097,12.811,14.151,12.367,14.097z M14.794,13.544l0.046-4.109c0.084-0.004,0.166-0.01,0.252-0.01c0.773,0,1.494,0.145,1.885,0.361C17.368,10.003,15.954,12.499,14.794,13.544z M5.844,5.876c-0.03-0.094-0.103-0.145-0.196-0.145h-1.95c-0.093,0-0.165,0.051-0.194,0.144c-0.412,1.299-3.48,10.99-3.496,11.041c-0.017,0.051-0.011,0.076,0.062,0.076h1.733c0.075,0,0.099-0.023,0.114-0.072c0.015-0.051,1.008-3.318,1.008-3.318h3.496c0,0,0.992,3.268,1.008,3.318c0.016,0.049,0.039,0.072,0.113,0.072h1.734c0.072,0,0.078-0.025,0.062-0.076C9.324,16.866,6.255,7.175,5.844,5.876z M3.226,12.194l1.447-5.25l1.447,5.25H3.226z"
      fill={fill}
    />
  </svg>
)

Equation.defaultProps = defaultProps

export default Equation
