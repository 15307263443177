import React from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import Icon from '../../elements/Icon/Icon'
import { burnToast } from '../../../redux/modules/toasts/toasts'
import './toast.scss'

export const PERSIST_TTL = -1

class Toast extends React.Component {
  constructor(props) {
    super(props)
    this.handleClickToast = this.handleClickToast.bind(this)
  }

  componentDidMount() {
    const { burnToast, id, ttl } = this.props
    // Persist toast if ttl = PERSIST_TTL
    if (ttl !== PERSIST_TTL) {
      setTimeout(() => burnToast(id), ttl)
    }
  }

  componentDidUpdate(prevProps) {
    const { burnToast, id, ttl } = this.props
    if (prevProps.ttl !== ttl && ttl !== PERSIST_TTL) {
      setTimeout(() => burnToast(id), ttl)
    }
  }

  handleClickToast() {
    const { burnToast, id } = this.props
    burnToast(id)
  }

  render() {
    const { type, message } = this.props
    const types = {
      error: { fill: 'red', icon: 'cross' },
      progress: { fill: 'gray', icon: 'cycle' },
      success: { fill: 'green', icon: 'check' },
    }

    // If the Toast is of an unknown type (or when the value for a known type is undefined),
    // return empty strings for Icon's fill and icon props.
    // Otherwise, return the value of the respective keys.
    const getFill = R.propOr('', 'fill')
    const getIcon = R.propOr('', 'icon')

    return (
      <div className="yo-toast" onClick={this.handleClickToast} role="button" tabIndex="0">
        <div className="yo-toast__message">{message}</div>
        <div className={`yo-toast__icon yo-toast__icon--${type}`}>
          <Icon fill={getFill(types[type])} icon={getIcon(types[type])} />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  burnToast(id) {
    dispatch(burnToast(id))
  },
})

export default connect(null, mapDispatchToProps)(Toast)
