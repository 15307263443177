import React from 'react'
import { connect } from 'react-redux'
import {
  getCharacterQuestions,
  getCharacterData,
  getCharactersState,
  getCreatedOrEditedCharacter,
} from '../../../redux/modules/characters/selectors'
import { createCharacter } from '../../../redux/modules/characters/characters'
import CharacterChoicesContainer from './CharacterChoicesContainer'
import CharacterEdit from './CharacterEdit'
import CharacterHeader from './CharactersHeader'
import Button from '../../elements/Button/Button.tsx'
import { getExamState } from '../../../redux/modules/exam/selectors'
import './characters.scss'

class CharactersContainer extends React.Component {
  refContainer = React.createRef()

  refHeader = React.createRef()

  // Attach scroll to CharacterHeader
  componentDidMount() {
    this.refContainer.current.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    this.refContainer.current.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = event => {
    // Scroll header as well when container is scrolled using forwarded ref.
    if (this.refContainer?.current && this.refHeader?.current && event) {
      this.refHeader.current.scrollLeft = event.target.scrollLeft
    }
  }

  render() {
    const {
      characters,
      characterTableData,
      questions,
      dispatch,
      editedCharacter,
      examState,
      onClickAddCharacter,
    } = this.props

    const characterTable = characterTableData.map((character, i) => (
      <CharacterChoicesContainer
        character={character}
        dispatch={dispatch}
        hasLabel={i === 0}
        key={`character-container-${character.id}`}
        questions={questions}
      />
    ))

    return (
      <>
        <div className="yo-characters__add">
          <Button onClick={onClickAddCharacter} size="sm" text="Add character" />
        </div>
        <div className="yo-characters" ref={this.refContainer}>
          {editedCharacter && (
            <CharacterEdit
              character={editedCharacter}
              characters={characters}
              dispatch={dispatch}
              enableShare={examState.enableShare}
              questions={questions}
            />
          )}
          {!editedCharacter && (
            <>
              <CharacterHeader
                characters={characters}
                dispatch={dispatch}
                questions={questions}
                ref={this.refHeader}
              />
              <div className="yo-characters__container">{characterTable}</div>
            </>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  characters: getCharactersState(state),
  questions: getCharacterQuestions(state),
  characterTableData: getCharacterData(state),
  editedCharacter: getCreatedOrEditedCharacter(state),
  examState: getExamState(state),
})

const mapDispatchToProps = dispatch => ({
  onClickAddCharacter: () => {
    dispatch(createCharacter())
  },
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CharactersContainer)
