import * as R from 'ramda'
import { NodeOption, NodeState } from '../../../constants/definitions/entities/types'
import { getScore, getMaxScore } from '../nodes/helpers'
import * as actionTypes from './actionTypes'

const getChildIds = (state, nodeId) => state?.[nodeId]?.childIds || []

export const getAllDescendantIds = (state, nodeId) =>
  getChildIds(state, nodeId).reduce(
    (acc, childId) => [...acc, childId, ...getAllDescendantIds(state, childId)],
    [],
  )

export function node(state: NodeState = {}, action) {
  //! Caution: non-functional programming paradigm ahead!
  // To satisfy our linting rules, we are not allowed to use consts in `case` blocks. So, we just
  // declare the variables here and assign them where needed.
  let completed: boolean
  let updatedState: NodeState
  let value: NodeOption[]

  switch (action.type) {
    case actionTypes.ADD_CHILD:
      return {
        ...state,
        childIds: [...state.childIds!, action.childId],
      }

    case actionTypes.ADD_OPTION:
      return Object.assign({}, state, {
        options: [...state.options!, action.optionId],
      })

    case actionTypes.ADD_TAG:
      if (typeof state.tags !== 'undefined' && state.tags.includes(action.tag)) {
        return state
      }

      return R.assoc('tags', R.append(action.tag, state.tags!), state)

    case actionTypes.CLEAR_SCORE:
      return R.omit(['score'], state)

    case actionTypes.CLEAR_VALUE:
      return R.pipe(R.omit(['value']), R.assoc('completed', false))(state)

    case actionTypes.DELETE:
      return Object.assign({}, state, { visible: false })

    case actionTypes.REMOVE_CONTENT:
      return {
        ...state,
        content: R.omit([action.content], state.content),
      }

    case actionTypes.REMOVE_CHILD:
      return {
        ...state,
        childIds: state.childIds!.filter(id => id !== action.childId),
      }

    case actionTypes.REMOVE_OPTION:
      return Object.assign({}, state, {
        options: state.options!.filter(id => id.toString() !== action.optionId.toString()),
      })

    case actionTypes.REMOVE_TAG:
      return R.assoc('tags', R.without([action.tag], state.tags!), state)

    case actionTypes.REMOVE_VALUE:
      updatedState = {
        ...state,
        value: R.omit([action.valueId], state.value) as NodeOption[],
      }
      completed = action.evaluateCallback(updatedState)
      return Object.assign({}, state, updatedState, { completed })

    case actionTypes.SET_SCORE:
      return {
        ...state,
        score: getScore(state, action.options),
      }

    case actionTypes.SET_MAX_SCORE:
      return {
        ...state,
        maxScore: getMaxScore(state, action.options),
      }

    case actionTypes.SUBMIT:
      return {
        ...state,
        statistics: null,
        submitted: true,
        completed: true,
        value: state.value
          ? R.map(x => (R.is(String, x) ? (x as any).trim() : x), state.value)
          : null,
      }

    case actionTypes.UNSUBMIT:
      return {
        ...state,
        submitted: false,
        questionStarted: false,
        timer: null,
        statistics: [],
      }

    case actionTypes.UPDATE:
      return Object.assign({}, state, action.node)

    case actionTypes.UPDATE_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          ...action.content,
        },
      }

    case actionTypes.UPDATE_SETTING:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.setting,
        },
      }

    case actionTypes.UPDATE_VALUE:
      // Update or rewrite value depending on action parameters.
      if (!action.overwrite) {
        value = Object.assign({}, state.value, action.value)
      } else {
        value = Object.assign({}, action.value)
      }

      updatedState = Object.assign({}, state, { value })
      completed = action.evaluateCallback ? action.evaluateCallback(updatedState) : false
      return Object.assign({}, state, updatedState, { completed })

    case actionTypes.UPDATE_VALUES_ORDER:
      value = { ...state.value! }
      Object.values(value).forEach(option => {
        option.order = option.order > action.removedOrder ? option.order - 1 : option.order
        option.correct = option.order === state.options?.indexOf(option.id)
      })
      return {
        ...state,
        value,
      }

    case actionTypes.UPDATE_VALUES_ORDER_SCORED:
      value = { ...state.value! }
      Object.values(value).forEach(option => {
        option.score =
          option.selectedPosition > action.removedOrder
            ? action.scores[option.selectedPosition - 1]
            : option.score
        option.selectedPosition =
          option.selectedPosition > action.removedOrder
            ? option.selectedPosition - 1
            : option.selectedPosition
      })
      return {
        ...state,
        value,
      }

    case actionTypes.SAVE_OPTION_ORDER:
      return {
        ...state,
        optionOrder: action.optionOrder,
      }

    case actionTypes.UPDATE_ROOT_NODETYPE_ID:
      return {
        ...state,
        nodeTypeId: action.nodeTypeId,
      }
    default:
      return state
  }
}
