import { Store } from 'redux'
import { useEffect, useState } from 'react'
import { showConfirmDialog, clearConfirmDialog } from './actions'

export enum ConfirmDialogStatus {
  Accepted = 'Accepted',
  Declined = 'Declined',
  Shown = 'Shown',
  Hidden = 'Hidden',
}

class ConfirmDialogManager {
  private static _instance: ConfirmDialogManager

  private _stores: Store[] = []

  private _currentPromiseResolver: ((result: boolean) => void) | null = null

  // eslint-disable-next-line no-empty-function,no-useless-constructor
  private constructor() {}

  private dispatchToStores(action: any) {
    this._stores.forEach(store => store.dispatch(action))
  }

  public subscribeToStore(store: Store) {
    this._stores.push(store)
    store.subscribe(() => {
      const { status }: { status: ConfirmDialogStatus } = store!.getState().confirmDialog
      if (this._currentPromiseResolver) {
        if (status === ConfirmDialogStatus.Accepted) {
          this._currentPromiseResolver(true)
          this.dispatchToStores(clearConfirmDialog())
        }
        if (status === ConfirmDialogStatus.Declined) {
          this._currentPromiseResolver(false)
          this.dispatchToStores(clearConfirmDialog())
        }
      }
      if (status === ConfirmDialogStatus.Hidden) {
        this._currentPromiseResolver = null
      }
    })
  }

  static getInstance(): ConfirmDialogManager {
    if (!ConfirmDialogManager._instance) {
      ConfirmDialogManager._instance = new ConfirmDialogManager()
    }
    return ConfirmDialogManager._instance
  }

  static show = (message: string) => ConfirmDialogManager.getInstance().show(message)

  show(message: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.dispatchToStores(showConfirmDialog({ message }))
      this._currentPromiseResolver = resolve
    })
  }
}

/**
 * Convenience hook wrapper for initiating confirm dialog.
 * Unlike calling `ConfirmDialogManager.show`, `useConfirmDialog` hook will trigger re-rendering.
 * If you need to rely on state changes, use this instead.
 * Usage:
 * ```
 * const submitAnswers = () => {
 *   // ...
 * }
 *
 * const confirmSubmittingAnswers = useConfirmDialog('Should you submit answers?', submitAnswers)
 *
 * return (
 *  <SomeComponent onClick={confirmSubmittingAnswers} />
 * )
 * ```
 * @param message
 * @param onConfirmed
 */
export const useConfirmDialog = (message: string, onConfirmed: () => void) => {
  const [confirmed, setConfirmed] = useState<boolean>(false)
  const asyncConfirm = async () => {
    const result = await ConfirmDialogManager.show(message)
    setConfirmed(result)
  }
  useEffect(() => {
    if (confirmed) {
      onConfirmed()
      setConfirmed(false)
    }
  }, [confirmed])
  return () => asyncConfirm()
}

export default ConfirmDialogManager
