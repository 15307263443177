import React from 'react'
import defaultProps from './defaultProps'

const Flag = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      className="yo-icon-svg-path"
      d="M18.926,5.584c-9.339,13.568-6.142-0.26-14.037,6.357L6.684,19H4.665L1,4.59l1.85-0.664 c8.849-6.471,4.228,5.82,15.637,1.254C18.851,5.033,19.142,5.27,18.926,5.584z"
      fill={fill}
    />
  </svg>
)

Flag.defaultProps = defaultProps

export default Flag
