import * as mFieldTypes from './matriculationExamFieldTypes'
import * as gFieldTypes from './genericFieldTypes'
import { FieldTypeEditorFormatter, NodeType, NodeTypeCategory, TehtavaNode } from './types'
import {
  evaluateCompletedWhenQuestionCountMatchesAnswerCount,
  evaluateCompletedWhenValueIsNotEmpty,
  evaluateCompletionValueIsObject,
  evaluateCompletedWhenQuestionCountMatchesRequiredAnswersCount,
} from './nodeStateCompletionEvaluators'

export const ORDER_QUESTION: TehtavaNode = {
  id: NodeType.ORDER_QUESTION,
  name: 'OrderQuestion',
  displayName: 'Order question',
  description: 'order-question',
  type: NodeTypeCategory.QUESTION,
  feedback: true,
  reviewable: true,
  scoring: true,
  childNodeTypeIds: [],
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    mFieldTypes.jokerQuestion,
    mFieldTypes.materialQuestion,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  settingFields: [gFieldTypes.points, gFieldTypes.requireAllCorrect, gFieldTypes.tags],
  optionFields: [{ ...gFieldTypes.text, displayName: 'Item' }],
  evaluateCompleted: evaluateCompletedWhenQuestionCountMatchesAnswerCount,
}

export const COMBINE_QUESTION: TehtavaNode = {
  id: NodeType.COMBINE_QUESTION,
  name: 'CombineQuestion',
  displayName: 'Combine question',
  description: 'combine-question',
  type: NodeTypeCategory.QUESTION,
  feedback: true,
  reviewable: true,
  scoring: true,
  childNodeTypeIds: [],
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    mFieldTypes.jokerQuestion,
    { ...mFieldTypes.materialQuestion, isSeparator: false },
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  optionFields: [
    {
      name: 'valueA',
      displayName: 'Value A',
      editorFormatter: FieldTypeEditorFormatter.richText,
    },
    {
      name: 'valueB',
      displayName: 'Value B',
      editorFormatter: FieldTypeEditorFormatter.richText,
    },
  ],
  settingFields: [gFieldTypes.points, gFieldTypes.requireAllCorrect, gFieldTypes.tags],
  evaluateCompleted: evaluateCompletedWhenQuestionCountMatchesAnswerCount,
}

export const ESSAY_QUESTION: TehtavaNode = {
  id: NodeType.ESSAY_QUESTION,
  name: 'EssayQuestion',
  displayName: 'Essay question',
  description: 'essay-question',
  type: NodeTypeCategory.QUESTION,
  feedback: true,
  reviewable: true,
  childNodeTypeIds: [],
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    mFieldTypes.jokerQuestion,
    { ...mFieldTypes.materialQuestion, isSeparator: false },
    mFieldTypes.titleEssayQuestion,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  settingFields: [gFieldTypes.textareaSize, gFieldTypes.hideWordCounter, gFieldTypes.tags],
  evaluateCompleted: evaluateCompletedWhenValueIsNotEmpty,
}

export const VOTE_QUESTION: TehtavaNode = {
  id: NodeType.VOTE_QUESTION,
  name: 'VoteQuestion',
  description:
    'Basic vote with predeterminated options. Editor can choose how many picks users can make.',
  displayName: 'Vote Question',
  type: NodeTypeCategory.QUESTION,
  feedback: true,
  scoring: false,
  reviewable: true,
  childNodeTypeIds: [],
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  optionFields: [{ ...gFieldTypes.text, displayName: 'Option' }, gFieldTypes.freeTextInput],
  settingFields: [gFieldTypes.optionsLayout, gFieldTypes.maxAnswers, gFieldTypes.tags],
  evaluateCompleted: evaluateCompletionValueIsObject,
}

export const SCORED_VOTE_QUESTION: TehtavaNode = {
  id: NodeType.SCORED_VOTE_QUESTION,
  name: 'ScoredVoteQuestion',
  description: 'User votes by assigning scores to options. The option with the largest score wins.',
  displayName: 'Scored Vote',
  type: NodeTypeCategory.QUESTION,
  feedback: true,
  scoring: false,
  reviewable: true,
  childNodeTypeIds: [],
  fields: [gFieldTypes.displayNumber, { ...gFieldTypes.text, isSeparator: true }],
  optionFields: [gFieldTypes.text],
  settingFields: [gFieldTypes.tags],
  evaluateCompleted: evaluateCompletedWhenQuestionCountMatchesRequiredAnswersCount,
}

export const GAP_QUESTION: TehtavaNode = {
  id: NodeType.GAP_QUESTION,
  name: 'GapQuestion',
  displayName: 'Gap question',
  description: 'gap-question',
  type: NodeTypeCategory.QUESTION,
  feedback: true,
  reviewable: true,
  scoring: true,
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    mFieldTypes.jokerQuestion,
    mFieldTypes.materialQuestion,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, displayName: 'Question', isSeparator: true },
  ],
  optionFields: [
    gFieldTypes.hint,
    {
      ...gFieldTypes.correct,
      description: 'correct-answer-desc',
      editorFormatter: FieldTypeEditorFormatter.text,
    },
    gFieldTypes.caseSensitive,
  ],
  shortcode: 'GAP',
  settingFields: [gFieldTypes.points, gFieldTypes.tags],
  childNodeTypeIds: [],
}

export const QUESTION_COPY: TehtavaNode = {
  id: NodeType.QUESTION_COPY,
  name: 'PasteQuestion',
  description: 'Insert copied question.',
  displayName: 'Copied question',
  type: NodeTypeCategory.QUESTION,
  childNodeTypeIds: [],
  fields: [],
}
