import { createSelector } from 'reselect'
import { getAllNodes } from '../nodes/selectors'
import { serializeClassificationFromAPI } from '../../middleware/dataService/stateSerializer'

export const getCarouselState = state => state.carousel
export const getCarouselActiveState = state => state.loadState.isCarouselActive

export const getCarouselItemCount = createSelector([getCarouselState], carousel =>
  carousel.items ? carousel.items.length : 0,
)

export const getCarouselProgress = createSelector(
  [getCarouselState, getCarouselItemCount],
  (carousel, itemCount) =>
    // The denominator is 1 less than the actual amount of items in order to mark 100% progress
    // once the user is at the last question.
    itemCount === 1 ? 100 : (carousel.currentItemIndex * 100) / (itemCount - 1),
)

export const getCurrentCarouselItemId = createSelector([getCarouselState], carousel => {
  const { currentItemIndex, items } = carousel
  return items && typeof currentItemIndex === 'number' ? items[currentItemIndex].uuid : undefined
})

export const getCarouselItemClassification = createSelector(
  [getCurrentCarouselItemId, getAllNodes],
  (currentItemId, nodes) => {
    if (currentItemId) {
      const currentNode = Object.values(nodes).find(node => node.id === currentItemId)
      return currentNode ? serializeClassificationFromAPI(currentNode.examClassification) : null
    }
    return null
  },
)

export const getCurrentCarouselNode = createSelector(
  [getAllNodes, getCurrentCarouselItemId],
  (nodes, itemId) => (nodes ? nodes[itemId] : null),
)
