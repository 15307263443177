import { reject, isNil } from 'ramda'
import { createSelector } from 'reselect'
import { serializeClassificationFromAPI } from '../../middleware/dataService/stateSerializer'
import { getEmbedOptions } from '../embed/selectors'
import * as types from '../../../constants/definitions/entities/entities.ts'

export const getNode = (state, id) => state.entities.nodes[id]

export function getSiblings(state, parentId) {
  if (!parentId) {
    return null
  }

  return getNode(state, parentId).childIds.map(id => getNode(state, id))
}

export const getNodes = (state, nodeIds) => nodeIds.map(nodeId => state.entities.nodes[nodeId])

export const getAllNodes = state => state.entities.nodes

export const getNodeTypes = () => types.nodeTypes

export const getNodeType = (state, id) => types.nodeTypes[id]

export const getNodeTags = createSelector([getNode], node => node?.tags)

export const getExamRoot = state => {
  const roots = types.rootNodeTypes
  const nodes = Object.values(state.entities.nodes)
  return nodes.find(node => node && roots.includes(node.nodeTypeId))
}

export const getExamId = createSelector([getExamRoot, getEmbedOptions], (examRoot, embedOptions) =>
  examRoot ? examRoot.id : embedOptions.examId,
)

// Get allowed child node types for certain node type.
export const getChildNodeTypes = (state, nodeType) => {
  const { childNodeTypeIds } = nodeType

  if (!childNodeTypeIds) {
    return null
  }

  return childNodeTypeIds.map(id => types.nodeTypes[id])
}

export const getSortedChildIds = (state, parentNode) => {
  // Let's not include null / undefined nodes
  const nodes = reject(
    isNil,
    getNodes(state, parentNode.childIds).sort((a, b) => a.order - b.order),
  )

  return nodes.map(node => node.id)
}

export function getAuthors(state) {
  return state.exam && state.exam.authors ? state.exam.authors : {}
}

// Get parent node by node id of a child.
export const getParentNode = (state, nodeId) => {
  const { nodes } = state.entities

  // Parent nodes should have node type of "EXAM_QUESTION".
  const isParentNode = (node, nodeId) =>
    node.childIds.includes(nodeId) && node.nodeTypeId === 'EXAM_QUESTION'

  return Object.values(nodes).find(node => isParentNode(node, nodeId))
}
// Helper function for embed assignment, it can have only one parent
const getWrapperQuestion = createSelector([getAllNodes], nodes =>
  Object.values(nodes).find(x => x && x.nodeTypeId === 'EXAM_QUESTION'),
)

export const getAssignmentNode = createSelector(
  [getAllNodes, getWrapperQuestion],
  (nodes, wrapperQuestion) =>
    // Return wrapper question if has one, else the assignment is first node
    wrapperQuestion || Object.values(nodes)[0],
)

export const getNodeClassification = createSelector([getAssignmentNode], node =>
  node ? serializeClassificationFromAPI(node.examClassification) : null,
)

export const getAllQuestions = createSelector([getAllNodes], nodes =>
  Object.values(nodes).filter(x => x && !types.rootNodeTypes.includes(x.nodeTypeId)),
)
