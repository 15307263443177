import React from 'react'
import { HeaderProps } from './SecondaryHeader'
import './tertiary-header.scss'

const className = 'yo-tertiary-header'

const TertiaryHeader = ({ children }: HeaderProps) =>
  IS_EMBED && !IS_WEBVIEW_EMBED ? (
    <h4 className={className}>{children}</h4>
  ) : (
    <h3 className={className}>{children}</h3>
  )
export default TertiaryHeader
