import { TehtavaNode, NodeType, NodeTypeCategory } from './types'
import * as gFieldTypes from './genericFieldTypes'
import * as mFieldTypes from './matriculationExamFieldTypes'
import validationRules from './validationRules'

export const EXAM_ROOT: TehtavaNode = {
  id: NodeType.EXAM_ROOT,
  name: 'ExamRoot',
  displayName: 'Exam root',
  type: NodeTypeCategory.CONTAINER,
  childNodeTypeIds: [
    NodeType.QUESTION_COPY,
    NodeType.TEXT,
    NodeType.MULTIPLE_CHOICE_QUESTION,
    NodeType.GAP_QUESTION,
    NodeType.MULTIPLE_CHOICE_GAP_QUESTION,
    NodeType.COMBINE_QUESTION,
    NodeType.ORDER_QUESTION,
    NodeType.ESSAY_QUESTION,
    NodeType.CUSTOMIZED_FEEDBACK,
    NodeType.EXAM_QUESTION,
  ],
  fields: [
    {
      ...gFieldTypes.title,
      displayName: 'Exam title',
      validation: validationRules.NOT_EMPTY,
    },
    mFieldTypes.overrideTitle,
    gFieldTypes.hideTitle,
    { ...gFieldTypes.text, displayName: 'Exam description' },
  ],
}

export const TV_EPISODE_ROOT: TehtavaNode = {
  id: NodeType.TV_EPISODE_ROOT,
  name: 'TvEpisodeRoot',
  displayName: 'TV Episode',
  type: NodeTypeCategory.CONTAINER,
  childNodeTypeIds: [NodeType.QUESTION_COPY, NodeType.TEXT, NodeType.SECTION],
  fields: [
    {
      ...gFieldTypes.title,
      displayName: 'Episode title',
      validation: validationRules.NOT_EMPTY,
    },
    mFieldTypes.overrideTitle,
    { ...gFieldTypes.text, displayName: 'Episode description' },
  ],
}

export const ARCHTYPE_ROOT: TehtavaNode = {
  id: NodeType.ARCHTYPE_ROOT,
  name: 'ArchtypeRoot',
  displayName: 'Archtype',
  type: NodeTypeCategory.CONTAINER,
  childNodeTypeIds: [
    NodeType.QUESTION_COPY,
    NodeType.TEXT,
    NodeType.ARCHTYPE_MULTIPLE_CHOICE,
    NodeType.ARCHTYPE_CUSTOMIZED_FEEDBACK,
  ],
  fields: [
    {
      ...gFieldTypes.title,
      displayName: 'Archtype title',
      validation: validationRules.NOT_EMPTY,
    },
    mFieldTypes.overrideTitle,
    gFieldTypes.hideTitle,
    { ...gFieldTypes.text, displayName: 'Archtype description' },
  ],
}

export const POLL_ROOT: TehtavaNode = {
  id: NodeType.POLL_ROOT,
  name: 'PollRoot',
  displayName: 'Poll',
  type: NodeTypeCategory.CONTAINER,
  childNodeTypeIds: [
    NodeType.QUESTION_COPY,
    NodeType.TEXT,
    NodeType.POLL_MULTIPLE_CHOICE,
    NodeType.POLL_CUSTOMIZED_FEEDBACK,
  ],
  fields: [
    {
      ...gFieldTypes.title,
      displayName: 'Poll title',
      validation: validationRules.NOT_EMPTY,
    },
    mFieldTypes.overrideTitle,
    gFieldTypes.hideTitle,
    { ...gFieldTypes.text, displayName: 'Poll description' },
  ],
}

export const VOTE_ROOT: TehtavaNode = {
  id: NodeType.VOTE_ROOT,
  name: 'VoteRoot',
  displayName: 'Vote',
  type: NodeTypeCategory.CONTAINER,
  childNodeTypeIds: [
    NodeType.QUESTION_COPY,
    NodeType.TEXT,
    NodeType.VOTE_QUESTION,
    NodeType.SCORED_VOTE_QUESTION,
    NodeType.DROPDOWN_QUESTION,
    NodeType.TEXTBOX_QUESTION,
  ],
  fields: [
    {
      ...gFieldTypes.title,
      displayName: 'Vote title',
      validation: validationRules.NOT_EMPTY,
    },
    mFieldTypes.overrideTitle,
    gFieldTypes.hideTitle,
    { ...gFieldTypes.text, displayName: 'Vote description' },
  ],
}

export const SECTION: TehtavaNode = {
  id: NodeType.SECTION,
  name: 'Section',
  displayName: 'Section',
  description: 'section',
  type: NodeTypeCategory.CONTAINER,
  feedback: true,
  childNodeTypeIds: [NodeType.QUESTION_COPY, NodeType.TEXT, NodeType.MULTIPLE_CHOICE_QUESTION],
  fields: [
    {
      ...gFieldTypes.text,
      displayName: 'Section description',
    },
  ],
  settingFields: [gFieldTypes.startTime, gFieldTypes.endTime],
}

export const EXAM_QUESTION: TehtavaNode = {
  id: NodeType.EXAM_QUESTION,
  name: 'ExamQuestion',
  displayName: 'Question',
  description: 'exam-question',
  type: NodeTypeCategory.CONTAINER,
  feedback: true,
  scoring: true,
  childNodeTypeIds: [
    NodeType.QUESTION_COPY,
    NodeType.TEXT,
    NodeType.MULTIPLE_CHOICE_QUESTION,
    NodeType.GAP_QUESTION,
    NodeType.MULTIPLE_CHOICE_GAP_QUESTION,
    NodeType.COMBINE_QUESTION,
    NodeType.ORDER_QUESTION,
    NodeType.ESSAY_QUESTION,
    NodeType.CUSTOMIZED_FEEDBACK,
  ],
  fields: [
    mFieldTypes.matriculationExamExerciseType,
    mFieldTypes.jokerQuestion,
    mFieldTypes.materialQuestion,
    gFieldTypes.displayNumber,
    { ...gFieldTypes.text, isSeparator: true },
  ],
  settingFields: [gFieldTypes.showTotalPointsForExamQuestion, gFieldTypes.tags],
}

export const REVIEW_ONLY_ROOT: TehtavaNode = {
  id: NodeType.REVIEW_ONLY_ROOT,
  name: 'ReviewOnlyRoot',
  displayName: 'Review Only',
  type: NodeTypeCategory.CONTAINER,
  childNodeTypeIds: [
    NodeType.QUESTION_COPY,
    NodeType.TEXT,
    NodeType.MULTIPLE_CHOICE_QUESTION,
    NodeType.GAP_QUESTION,
    NodeType.MULTIPLE_CHOICE_GAP_QUESTION,
    NodeType.COMBINE_QUESTION,
    NodeType.ORDER_QUESTION,
    NodeType.CUSTOMIZED_FEEDBACK,
    NodeType.EXAM_QUESTION,
  ],
  fields: [
    {
      ...gFieldTypes.title,
      displayName: 'Review only title',
      validation: validationRules.NOT_EMPTY,
    },
    mFieldTypes.overrideTitle,
    gFieldTypes.hideTitle,
    { ...gFieldTypes.text, displayName: 'Review only description' },
  ],
}
