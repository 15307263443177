const prefix = 'confirmDialog/'
export const SHOW = `${prefix}SHOW`
export const RESOLVE = `${prefix}RESOLVE`
export const CLEAR = `${prefix}CLEAR`

export const showConfirmDialog = ({ message }: { message: string }) => ({
  type: SHOW,
  message,
})

export const resolveConfirmDialog = ({ accepted }: { accepted: boolean }) => ({
  type: RESOLVE,
  accepted,
})

export const clearConfirmDialog = () => ({
  type: CLEAR,
})
