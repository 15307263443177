import React from 'react'
import QuestionItem from './QuestionItem'

const QuestionList = props => (
  <div className="yo-question-list">
    {props.questions.map(q => (
      <QuestionItem key={q.uuid} {...q} />
    ))}
  </div>
)

export default QuestionList
