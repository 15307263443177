import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import Page from '../../layout/Layouts/Layouts'
import { decorations, Header, Sidebar } from '../../App'
import SettingsForm from '../../layout/SettingsForm/SettingsForm'
import Reviews from '../../misc/Reviews/ReviewsContainer'
import Answers from '../../layout/Answers/Answers.tsx'
import Publish from '../../misc/Publish/Publish'
import Widget from '../../layout/Widget/Widget'
import ExamLogs from '../../layout/ExamLogs/ExamLogs'
import { untaintExam, clearExam } from '../../../redux/modules/exam/exam'
import { clearOpenedForEditingAt } from '../../../redux/modules/editor/editor'
import { fetchExam } from '../../../redux/middleware/examService/examService'
import CharactersContainer from '../../layout/Characters/CharactersContainer'

// On embed we use widget without router, so we can't export withRouter by default at Widget
const WidgetWithRouter = withRouter(Widget)
/**
 * Wrapper component for Exam nested routes.
 * This way we make sure Exam data is fetched only once for every sub route
 */

const ExamRoutes = ({ match, fetchExam, clearExamState, untaintExam, clearOpenedForEditingAt }) => {
  useEffect(() => {
    fetchExam(match.params.id)

    return () => {
      clearExamState()
      untaintExam()
      clearOpenedForEditingAt()
    }
  }, [match.params.id])

  const editorLayout = () => (
    <Page {...decorations} header={Header} main={<WidgetWithRouter />} sidebar={Sidebar} />
  )
  const reviewsLayout = () => <Page {...decorations} header={Header} main={<Reviews />} />
  const answersLayout = () => (
    <Page {...decorations} header={Header} main={<Answers uuid={match.params.id} />} />
  )
  const settingsLayout = () => <Page {...decorations} header={Header} main={<SettingsForm />} />
  const previewLayout = () => <Page {...decorations} header={Header} main={<WidgetWithRouter />} />
  const publishLayout = () => <Page {...decorations} header={Header} main={<Publish />} />
  const charactersLayout = () => (
    <Page {...decorations} header={Header} main={<CharactersContainer />} />
  )

  const examLogLayout = () => (
    <Page {...decorations} header={Header} main={<ExamLogs match={match} />} />
  )

  return (
    <div>
      <Route component={editorLayout} path="/exam/:id/editor" />
      <Route component={reviewsLayout} path="/exam/:id/reviews" />
      <Route component={answersLayout} path="/exam/:id/answers" />
      <Route component={settingsLayout} path="/exam/:id/settings" />
      <Route component={previewLayout} path="/exam/:id/preview" />
      <Route component={publishLayout} path="/exam/:id/publish" />
      <Route component={charactersLayout} path="/exam/:id/characters" />
      <Route component={examLogLayout} path="/exam/:id/logs" />
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    fetchExam(id) {
      dispatch(fetchExam(id))
    },
    untaintExam() {
      dispatch(untaintExam())
    },
    clearExamState() {
      dispatch(clearExam())
    },
    clearOpenedForEditingAt() {
      dispatch(clearOpenedForEditingAt())
    },
  }
}

export default connect(null, mapDispatchToProps)(ExamRoutes)
