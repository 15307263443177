import React from 'react'
import defaultProps from './defaultProps'

const TriangleUp = ({ fill, width, height }) => (
  <svg height={height} viewBox="0 0 20 20" width={width} xmlns="http://www.w3.org/2000/svg">
    <path className="yo-icon-svg-path" d="M15,14H5l5-9L15,14z" fill={fill} />
  </svg>
)

TriangleUp.defaultProps = defaultProps

export default TriangleUp
