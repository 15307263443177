import React from 'react'
import './secondary-header.scss'

export interface HeaderProps {
  children: React.ReactNode
}

const className = 'yo-secondary-header'

const SecondaryHeader = ({ children }: HeaderProps) =>
  IS_EMBED && !IS_WEBVIEW_EMBED ? (
    <h3 className={className}>{children}</h3>
  ) : (
    <h2 className={className}>{children}</h2>
  )

export default SecondaryHeader
