import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { fetchSeriesList } from '../../../redux/middleware/seriesService/seriesService'
import { deleteSeries, clearSeriesData } from '../../../redux/modules/series/series'
import Button from '../../elements/Button/Button.tsx'
import { IconButton } from '../../elements/IconButtons/IconButtons'
import './series-list.scss'
import { formatDate } from '../../../utils/common'

const SeriesRowItem = ({ name, updated_at, uuid, onClickDelete, t }) => (
  <div className="yo-series-list__row" key={uuid}>
    <div className="yo-series-list__item--name">
      <Link data-testid={`series-list__${name}`} to={`/series/by-uuid/${uuid}`}>
        {name}
      </Link>
    </div>
    <div className="yo-series-list__item--updated">{formatDate(new Date(updated_at))}</div>
    <div className="yo-series-list__item--action">
      <IconButton
        attributes={{ 'data-testid': `delete-series__${name}` }}
        height="12"
        icon="cross"
        onClick={() => onClickDelete(uuid, t)}
        title="delete"
        width="12"
      />
    </div>
  </div>
)

class SeriesList extends React.Component {
  componentDidMount() {
    this.props.fetchSeries()
  }

  render() {
    const { series, t, onClickCreate, history, onClickDelete } = this.props
    const seriesContent = series.map(serie =>
      SeriesRowItem({
        ...serie,
        onClickDelete,
        t,
      }),
    )
    const seriesHeader = (
      <div className="yo-series-list__header">
        <div className="yo-series-list__item--name">Name</div>
        <div className="yo-series-list__item--updated">Last modified</div>
        <div className="yo-series-list__item--action">Actions</div>
      </div>
    )
    const createSeries = (
      <div className="yo-series-container__create">
        <Button
          attributes={{ 'data-testid': 'button-create-series' }}
          onClick={() => onClickCreate(history)}
          text={t('create-series')}
        />
      </div>
    )

    return (
      <div className="yo-series-list">
        {createSeries}
        {seriesHeader}
        {seriesContent}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  series: state.series.data,
})

const mapDispatchToProps = dispatch => ({
  fetchSeries: () => {
    dispatch(fetchSeriesList())
  },
  onClickCreate: history => {
    history.push('/series/create')
  },
  onClickDelete: (uuid, t) => {
    const delConfirmed = window.confirm(t('common:confirm-delete', { item: 'series' }))

    if (delConfirmed) {
      dispatch(deleteSeries(uuid))
    }
  },
  clearSeries: () => {
    dispatch(clearSeriesData())
  },
})
export default withTranslation(['Series'])(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SeriesList)),
)
