import React from 'react'
import classNames from 'classnames'

const NumberInput = ({
  attributes,
  autofocus,
  defaultValue = 0,
  description,
  disabled,
  id,
  label,
  onChange,
  optional,
  t,
  inline,
  width,
}) => {
  const inputClassNames = classNames('yo-input__input yo-input__input--number', {
    'yo-input__input--narrow': width === 'narrow',
    'yo-input__input--inline': inline,
  })

  return (
    <label className="yo-input__label" htmlFor={id}>
      {label}
      {optional && <span className="yo-input__optional">{t('common:optional')}</span>}
      <input
        autoFocus={autofocus}
        className={inputClassNames}
        disabled={disabled}
        id={id}
        onChange={onChange}
        type="number"
        value={defaultValue}
        {...attributes}
      />
      {description && <div className="yo-input__description">{t(description)}</div>}
    </label>
  )
}

export default NumberInput
