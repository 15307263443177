import uuid from 'uuid'
import { HANDLE_EXAM_DATA } from '../../middleware/examService/examService'

// Actions
export const CREATE = 'review/CREATE'
export const DELETE = 'review/DELETE'
export const UPDATE = 'review/UPDATE'
const UPDATE_SHARE = 'review/UPDATE_SHARE'

// Reducer
export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case CREATE:
      return [...state, action.review]

    case DELETE:
      return state.filter(review => review.id !== action.reviewId)

    case UPDATE:
      return state.map(review => {
        if (review.id !== action.reviewId) {
          return review
        }

        return {
          ...review,
          ...action.review,
        }
      })

    case UPDATE_SHARE:
      return state.map(review =>
        review.id === action.reviewId
          ? {
              ...review,
              share: {
                ...review.share,
                ...action.share,
              },
            }
          : review,
      )

    case HANDLE_EXAM_DATA:
      return Object.assign([], action.data.entities.reviews)

    default:
      return state
  }
}

// Action creators
export const createReview = () => ({
  type: CREATE,
  review: {
    id: uuid.v4(),
  },
})

export const updateReview = (reviewId, review) => ({
  type: UPDATE,
  reviewId,
  review,
})

export const updateReviewShare = (reviewId, share) => ({
  type: UPDATE_SHARE,
  reviewId,
  share,
})

export const deleteReview = reviewId => ({
  type: DELETE,
  reviewId,
})

export const getReviews = state => {
  const { reviews } = state.entities
  return reviews
}
