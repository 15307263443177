import React from 'react'

import moment from 'moment'
import Datetime from 'react-datetime'

import { DATE_FORMAT, TIME_FORMAT } from '../../../constants/definitions/datetime'

import 'react-datetime/css/react-datetime.css'

const DateTimePickerInput = ({
  id,
  label,
  onChange,
  defaultValue,
  value,
  attributes,
  description,
  timeFormat = TIME_FORMAT,
  inputProps,
  allowPastDates = false,
  hasNowButton,
  hasClearButton,
  t,
}) => {
  const hasButtons = hasNowButton || hasClearButton
  return (
    <label className="yo-input__label" htmlFor={id}>
      {label}
      {hasButtons && (
        <div className="yo-input__input--buttons">
          {hasNowButton && (
            <div
              className="yo-input__input--date-now-button"
              onClick={() => onChange(moment())}
              role="button"
              tabIndex="0"
            >
              Set current time
            </div>
          )}
          {hasClearButton && (
            <div
              className="yo-input__input--clear-button"
              onClick={() => onChange(undefined)}
              role="button"
              tabIndex="0"
            >
              Clear input
            </div>
          )}
        </div>
      )}
      <Datetime
        className="yo-input__datetimepicker"
        dateFormat={DATE_FORMAT}
        defaultValue={defaultValue}
        inputProps={inputProps}
        isValidDate={current =>
          allowPastDates ? true : current.isAfter(moment().subtract(1, 'day'))
        }
        onChange={onChange}
        timeFormat={timeFormat}
        value={value}
        {...attributes}
        viewDate={new Date()}
      />
      {description && <div className="yo-input__datetimepicker-description">{t(description)}</div>}
    </label>
  )
}

export default DateTimePickerInput
