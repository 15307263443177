export type ShortcodeName =
  | 'CITE'
  | 'CLIP'
  | 'IMS'
  | 'IMS_ID'
  | 'LINK'
  | 'MATH'
  | 'SUB'
  | 'SUP'
  | InteractiveShortcodeName

export type InteractiveShortcodeName = 'GAP' | 'SELECT' | 'DROPDOWN' | 'TEXTBOX'

export type Shortcode = {
  title: string
  shortcode: ShortcodeName
  dialogFields: Array<{
    name: string
    defaultValue?: string
    defaultChecked?: boolean
    displayName: string
    description?: string
    type?: string
    options?: Array<{
      id: string
      name: string
    }>
  }>
}

const shortcodes: { [key in Exclude<ShortcodeName, InteractiveShortcodeName>]: Shortcode } = {
  CITE: {
    title: 'Insert reference',
    shortcode: 'CITE',
    dialogFields: [
      {
        name: 'source',
        displayName: 'Source',
      },
    ],
  },
  CLIP: {
    title: 'Insert Areena clip',
    shortcode: 'CLIP',
    dialogFields: [
      {
        name: 'id',
        displayName: 'Areena Id',
      },
      {
        type: 'separator',
        name: 'separator1',
        displayName: 'Separator 1',
      },
      {
        name: 'autoplay',
        defaultChecked: false,
        displayName: 'Autoplay',
        type: 'checkbox',
      },
    ],
  },
  IMS: {
    title: 'Insert IMS image',
    shortcode: 'IMS',
    dialogFields: [
      {
        name: 'id',
        displayName: 'IMS id',
        type: 'ims',
      },
      {
        name: 'caption',
        displayName: 'Caption',
      },
      {
        defaultValue: 'Original',
        displayName: 'Aspect ratio',
        name: 'aspect',
        options: [
          { id: 'original', name: 'Original' },
          { id: '16:9', name: '16:9' },
          { id: '1:1', name: '1:1' },
          { id: '2:3', name: '2:3' },
          { id: '5:2', name: '5:2' },
        ],
        type: 'radios',
      },
      {
        name: 'showCopyright',
        defaultChecked: true,
        displayName: 'Copyright',
        type: 'checkbox',
      },
      {
        name: 'imageLink',
        defaultChecked: true,
        displayName: 'Image link',
        type: 'checkbox',
      },
      {
        name: 'size',
        description: 'You should normally use only 100% images.',
        displayName: 'Image size',
        type: 'range',
      },
    ],
  },
  IMS_ID: {
    title: 'Insert IMS image',
    shortcode: 'IMS_ID',
    dialogFields: [
      {
        name: 'id',
        displayName: 'IMS ID',
        description: 'Please input IMS ID only',
        type: 'ims',
      },
    ],
  },
  LINK: {
    title: 'Insert link',
    shortcode: 'LINK',
    dialogFields: [
      {
        name: 'url',
        displayName: 'Link url',
      },
      {
        name: 'text',
        displayName: 'Link text',
      },
    ],
  },
  MATH: {
    title: 'Insert math equation',
    shortcode: 'MATH',
    dialogFields: [
      {
        name: 'text',
        displayName: 'Equation',
        type: 'math',
      },
    ],
  },
  SUB: {
    title: 'Insert subscript text',
    shortcode: 'SUB',
    dialogFields: [
      {
        name: 'text',
        displayName: 'Subscript',
      },
    ],
  },
  SUP: {
    title: 'Insert superscript text',
    shortcode: 'SUP',
    dialogFields: [
      {
        name: 'text',
        displayName: 'Superscript',
      },
    ],
  },
}

export default shortcodes
