import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducer'
import examService from './middleware/examService/examService'
import tainter from './middleware/tainter/tainter'
import seriesService from './middleware/seriesService/seriesService'
import questionService from './middleware/questionService/questionService'
import carouselService from './middleware/carouselService/carouselService'
import shareService from './middleware/shareService/shareService'
import pollService from './middleware/pollService/pollService'
import ConfirmDialogManager from '../confirmDialog/ConfirmDialogManager.ts'

export default function(initialState = {}, extraMiddlewares = []) {
  // Enable Redux devtools only in development environment.
  const composeEnhancers =
    process.env.NODE_ENV === 'development'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose

  const middlewares = [
    examService,
    seriesService,
    questionService,
    carouselService,
    shareService,
    pollService,
    tainter,
    ...extraMiddlewares.filter(Boolean),
  ]
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, ...middlewares)),
  )
  ConfirmDialogManager.getInstance().subscribeToStore(store)
  return store
}
