import React from 'react'
import { withTranslation } from 'react-i18next'
import './fragment.scss'
import { Tag } from '@yleisradio/yds-components-react'

/**
 * Component for wrapping part of a node,
 * eg. question or option with proper markup and styles.
 */
const Fragment = ({ displayNumber, text, children, t }) => {
  return (
    <div className="yo-fragment__content">
      {displayNumber && (
        <div className="yo-fragment__display-number">
          <Tag aria-label={`${t('question')} ${displayNumber}`} size="md">
            {displayNumber}
          </Tag>
        </div>
      )}
      {text && <div className="yo-fragment__text">{text}</div>}
      <div className="yo-fragment__children">{children}</div>
    </div>
  )
}

export default withTranslation(['Fragment'])(Fragment)
