import React from 'react'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import ProgressBar from '../../elements/ProgressBar/ProgressBar.tsx'
import Fragment from '../../node/Fragment/Fragment'
import { getStats, getTotalCount } from '../../../utils/examHelpers'
import RichTextContent from '../../misc/RichTextContent/RichTextContent'
import { strContainsImage, getOptionIdByNodeId } from '../../../utils/common'
import Notification from '../../elements/Notification/Notification.tsx'
import './poll-results.scss'

const PollResults = props => {
  const { options, node, examType, t } = props
  const submittedOption = getOptionIdByNodeId(node.id)
  const totalCount = getTotalCount(node)
  const renderOptions = options.map((option, index) => {
    const optionStats = getStats(node, option, totalCount)
    const classes = classnames('yo-poll-results__label', {
      'yo-poll-results__label-selected': Number(submittedOption) === option.id,
      'yo-poll-results__image': strContainsImage(option.text),
    })
    return (
      <div className="yo-poll-results__option" key={option.id}>
        <div className={classes}>
          <RichTextContent content={option.text} />
        </div>
        <ProgressBar
          color={null}
          count={optionStats.count}
          height=".5em"
          progress={optionStats.percentage}
          showProgress
        />
      </div>
    )
  })

  const optionFeedback =
    submittedOption && options && options.find(option => option.id === Number(submittedOption))
  const showFeedback =
    (node && node.feedback) ||
    (optionFeedback && optionFeedback.feedback && optionFeedback.feedback.length > 0)

  return (
    <Fragment
      displayNumber={node.content.displayNumber}
      examType={examType}
      text={<RichTextContent content={node.content.text} />}
    >
      {renderOptions}
      {showFeedback && (
        <Notification>
          <div className="yo-node-review-notification-feedback">
            {optionFeedback && optionFeedback.feedback && (
              <RichTextContent content={optionFeedback.feedback.concat('\n')} />
            )}
            <RichTextContent content={node.feedback} />
          </div>
        </Notification>
      )}
      <div className="yo-poll-results__total-count">
        {t('total')}
        {totalCount}
      </div>
    </Fragment>
  )
}

export default withTranslation(['PollResults'])(PollResults)
